const styles = theme => ({
    input: {
        display: "none"
    },
    imageBox: {
        width: "100%",
        height: "100%",
        cursor: "pointer",
        "&:hover $overlayContainer": {
            opacity: 1
        },
        "&:hover $bigAvatar": {
            borderColor: theme.palette.primary.main,
            color: theme.palette.common.black
        }
    },
    bigAvatar: {
        width: "100%",
        height: "100%",
        border: `1px dashed ${theme.palette.text.secondary}`,
        borderRadius: "10px",
        color: theme.palette.grey.A200,
        background: "transparent",
        transition: "all 0.2s ease",
        "&.error": {
            borderColor: theme.palette.error.main,
            color: theme.palette.error.main,
        }
    },
    logoError: {
        color: theme.palette.error.main,
        fontSize: "0.75rem",
        textAlign: "center"
    },
    logoIcon: {
        fontSize: "200%"
    },
    overlay: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        background: "transparent",
        borderBottom: "50px solid transparent",
        borderLeft: "50px solid rgba(0,0,0,0.6)"
    },
    overlayIcon: {
        position: "absolute",
        left: 5,
        top: 5,
        color: theme.palette.grey.A200,
        fontSize: "140%"
    },
    overlayContainer: {
        position: "absolute",
        zIndex: 1,
        opacity: 0,
        transition: "all 0.2s ease",
        borderTopLeftRadius: 7,
        overflow: "hidden"
    },
    relativePositioning: {
        position: "relative",
        width: "100%",
        height: "100%"
    },
    img: {
        objectFit: "cover"
    }
})

export default styles;
