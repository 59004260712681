import React from "react";
import { useTranslation } from "react-i18next"
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Alert from "@material-ui/lab/Alert";
import styles from "./styles";

const facbookArticle = "https://developers.facebook.com/docs/messenger-platform/send-messages/message-tags#new_supported_tags"

const useStyles = makeStyles(styles, { name: "Default" });

const Default = () => {

    const { t } = useTranslation("facebookMessageTypes");
    const classes = useStyles();

    return (
        <div>
            <Typography className={classes.illustrationText}>{t("choosingContentType")}</Typography>
            <Alert
                severity="warning"
                className={classes.alert}
            >
                {t("misuseContentTypeMaySuspendPageOrBot")}.
            </Alert>
            <Typography className={classes.illustrationText}>
                {t("messagesSentMoreThanADayAfterInteraction")}.<br /><br />
                {t("promotionsCanBeSentByOtn")}.<br /><br />
                {t("sendingMessagesThatDonotMatchContentType")}.<br /><br />
                <a href={facbookArticle} target="blank" className={classes.fbHyperLink}>{t("learnMoreHere")}.</a>
            </Typography>
        </div>
    )
}
export default Default;