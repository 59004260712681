import React, { useEffect } from "react";
import classNames from "classnames";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Background from "../Background/Background";
import LogoIcon from "components/Icons/Logo";
import Header from "components/Header/Header.jsx";

import makeStyles from "@material-ui/core/styles/makeStyles"
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import routePaths from "../../config/routePaths";
import styles from "./styles"

const useStyles = makeStyles(styles, { name: "AfterFbLoginRedirect" });

/**
 * Facebook doesn't allow redirection to dynamic URLs.
 * So, this component was made to let Facebook redirects to it after a user logs in.
 */
const AfterFbLogin = ({ history }) => {
    useEffect(() => {
        const eventId = sessionStorage.getItem("fbLoginEventId");
        if (eventId && history.location.hash) {
            sessionStorage.removeItem("fbLoginEventId");
            history.replace(`${routePaths.FACEBOOK_PAGES_MODAL_REDIRECT(eventId)}${history.location.hash}`);
        }
    }, [history])

    const { t } = useTranslation("AfterFbLoginRedirect");
    const classes = useStyles();
    return (
        <Background>
            <div
                className={classNames(classes.container, classes.containerCentered)}
            >
                <Header
                    absolute
                    color="transparent"
                    brand="Eventsquare"
                    brandLogo={
                        <LogoIcon style={{ height: 24, width: 24, margin: "0 5px" }} />
                    }
                    rightLinks={null}
                />
                <Grid container justify="center" alignItems="center">
                    <Grid container direction="column" alignItems="center">
                        <Typography variant="h4" gutterBottom className={classes.centerText}>
                            {t("errorOccurred")}
                        </Typography>
                        <Button>
                            <Link className={classes.link} to={routePaths.HOME}>
                                {t("backToHome")}
                            </Link>
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Background>
    );
}
export default AfterFbLogin;
