import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const TeamIcon = ({ color, size, ...rest }) => {
  return (
    <SvgIcon
      width={size}
      height={size}
      viewBox={size && `0 0 ${size} ${size}`}
      fill="none"
      {...rest}
    >
      <path
        d="M8 2.5C6.07589 2.5 4.5 4.07589 4.5 6C4.5 7.92411 6.07589 9.5 8 9.5C9.92411 9.5 11.5 7.92411 11.5 6C11.5 4.07589 9.92411 2.5 8 2.5ZM16 2.5C15.5404 2.5 15.0852 2.59053 14.6606 2.76642C14.236 2.94231 13.8501 3.20012 13.5251 3.52513C13.2001 3.85013 12.9423 4.23597 12.7664 4.66061C12.5905 5.08525 12.5 5.54037 12.5 6C12.5 6.45963 12.5905 6.91475 12.7664 7.33939C12.9423 7.76403 13.2001 8.14987 13.5251 8.47487C13.8501 8.79988 14.236 9.05769 14.6606 9.23358C15.0852 9.40947 15.5404 9.5 16 9.5C16.4596 9.5 16.9148 9.40947 17.3394 9.23358C17.764 9.05769 18.1499 8.79988 18.4749 8.47487C18.7999 8.14987 19.0577 7.76403 19.2336 7.33939C19.4095 6.91475 19.5 6.45963 19.5 6C19.5 5.54037 19.4095 5.08525 19.2336 4.66061C19.0577 4.23597 18.7999 3.85013 18.4749 3.52513C18.1499 3.20012 17.764 2.94231 17.3394 2.76642C16.9148 2.59053 16.4596 2.5 16 2.5V2.5ZM8 4C9.11345 4 10 4.88655 10 6C10 7.11345 9.11345 8 8 8C6.88655 8 6 7.11345 6 6C6 4.88655 6.88655 4 8 4ZM4.75 10.5C3.785 10.5 3 11.285 3 12.25V16C3 18.757 5.243 21 8 21C9.0665 21 10.0537 20.6613 10.8662 20.0898C10.6022 19.6528 10.3906 19.1816 10.2441 18.6816C9.63564 19.1916 8.8535 19.5 8 19.5C6.0705 19.5 4.5 17.9295 4.5 16V12.25C4.5 12.1125 4.6125 12 4.75 12H10.0127C10.0642 11.4335 10.2869 10.9155 10.6309 10.5H4.75ZM12.75 10.5C11.785 10.5 11 11.285 11 12.25V17C11 19.757 13.243 22 16 22C18.757 22 21 19.757 21 17V12.25C21 11.285 20.215 10.5 19.25 10.5H12.75Z"
        fill={color}
      />
   {/*    <path d="M7.5 5.25C5.43164 5.25 3.75 6.93164 3.75 9C3.75 11.0684 5.43164 12.75 7.5 12.75C9.56836 12.75 11.25 11.0684 11.25 9C11.25 6.93164 9.56836 5.25 7.5 5.25ZM7.5 12.75C3.7793 12.75 0.75 15.7764 0.75 19.5H14.25C14.25 18.7646 14.1152 18.0527 13.8984 17.3906C13.9746 17.2236 14.0566 17.0566 14.1562 16.8984C14.8887 15.7529 16.1602 15 17.625 15C19.9131 15 21.75 16.8369 21.75 19.125V19.5H23.25V19.125C23.25 16.9482 21.999 15.0439 20.1797 14.1094C21.1348 13.3535 21.75 12.1816 21.75 10.875C21.75 8.60449 19.8955 6.75 17.625 6.75C15.3545 6.75 13.5 8.60449 13.5 10.875C13.5 12.1934 14.124 13.377 15.0938 14.1328C14.3174 14.5283 13.6436 15.0791 13.125 15.7734C11.915 13.9541 9.84375 12.75 7.5 12.75ZM17.625 8.25C19.084 8.25 20.25 9.41602 20.25 10.875C20.25 12.334 19.084 13.5 17.625 13.5C16.166 13.5 15 12.334 15 10.875C15 9.41602 16.166 8.25 17.625 8.25Z"
        fill={color}
      /> */}

    </SvgIcon>
  );
};

export default TeamIcon;
