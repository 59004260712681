const styles = theme => ({
    paper: {
        direction: theme.direction,
        flip: false
    },
    menuItemProgress: {
        color: theme.palette.primary.main,
        paddingRight: theme.spacing(2),
    },
    infoIconRoot: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: theme.spacing(2)
    },
    menuItemContainer: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative'
    },
    printText: {
        marginRight: theme.spacing(2)
    },
    deleteMenuItem: {
        flex: 1
    },
    button: {
        margin: theme.spacing(1),
    },
});

export default styles;
