import React, { useState } from "react"
import _isNil from "lodash/isNil"
import { useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Dialog from '@material-ui/core/Dialog';
import { useTheme } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TextField from '@material-ui/core/TextField';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import FormLabel from '@material-ui/core/FormLabel';
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CustomButton from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import styles from "./styles";

const lengthUnits = { // an enum for units
    CM: "CM",
    INCH: "IN"
}

const useStyles = makeStyles(styles, { name: "NameBadgeModal" });

const NameBadgeModal = ({ isOpen, onClose, onTemplateAdd }) => {
    const [name, setName] = useState("");
    const [unit, setUnit] = useState(lengthUnits.CM);
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);

    const classes = useStyles();
    const { t } = useTranslation("templatesPage");
    const theme = useTheme();

    // any screen width below xs "600px" will set fullscreen to true
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const isFormValid = () => {
        if (name.trim() === "" || height <= 0 || width <= 0 || !width || !height) return false;
        return true;
    }

    const resetModalForm = () => {
        setName("");
        setUnit(lengthUnits.CM);
        setHeight(0);
        setWidth(0);
    }
    return (

        <Dialog
            onExited={resetModalForm}
            open={isOpen}
            onClose={onClose}
            classes={{
                paper: fullScreen ? classes.paperFullScreen : classes.paper
            }
            }
            fullScreen={fullScreen}
        >
            <form
                noValidate // because we rely on our own validation
                onSubmit={e => {
                    e.preventDefault(); // so the app doesn't reload after each submit
                    if (isFormValid()) {
                        onClose();
                        onTemplateAdd({
                            name,
                            unit,
                            height: parseFloat(height),
                            width: parseFloat(width),
                        })
                        return true;
                    }
                    return false;
                }}
            >
                <Card className={fullScreen ? classes.cardFullScreen : classes.card}>

                    <CardHeader className={classes.cardHeader} color="primary">
                        <Typography variant="subtitle1">
                            {t("templatesPage:createModalTitle")}
                        </Typography>
                    </CardHeader>
                    <CardBody>
                        <TextField
                            required
                            label={t("templatesPage:badgeName")}
                            autoFocus
                            className={classes.badgeName}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={name}
                            onChange={event => {
                                setName(event.target.value);
                            }}
                            fullWidth
                        />

                        <div className={classes.bodyContainer} >
                            <div className={classes.canvasPlaceholder} >
                                <TextField
                                    label={t("templatesPage:height")}
                                    type="number"
                                    className={classes.heightTextField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: classes.inputFeild
                                        }
                                    }}
                                    value={height}
                                    onChange={(event) => {
                                        // limiting text field's value between 0 & 999
                                        if (event.target.value < 0 || event.target.value > 999 || event.target.value.length > 3) return;
                                        setHeight(event.target.value);
                                    }}
                                    variant="outlined"
                                />

                                <TextField
                                    label={t("templatesPage:width")}
                                    type="number"
                                    className={classes.widthTextField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: classes.inputFeild
                                        }
                                    }}
                                    value={width}
                                    onChange={(event) => {
                                        // limiting text field's value between 0 & 999
                                        if (event.target.value < 0 || event.target.value > 999 || event.target.value.length > 3) return;
                                        setWidth(event.target.value);
                                    }}
                                    variant="outlined"
                                />


                            </div>
                            <div className={classes.toggleButtonGroup}>
                                <FormLabel className={classes.togglesTitle}>{t("templatesPage:unit")}</FormLabel>
                                <ToggleButtonGroup
                                    value={unit}
                                    exclusive
                                    onChange={(evt, unit) => {
                                        if (!_isNil(unit))
                                            setUnit(unit)
                                    }}
                                >
                                    <ToggleButton
                                        value={lengthUnits.CM}
                                        classes={{
                                            root: classes.toggleButton,
                                        }}
                                    >
                                        <Typography className={classes.togglesContent}>
                                            {t("templatesPage:cm")}
                                        </Typography>
                                    </ToggleButton>
                                    <ToggleButton
                                        value={lengthUnits.INCH}
                                        classes={{
                                            root: classes.toggleButton,
                                        }}
                                    >
                                        <Typography className={classes.togglesContent}>
                                            {t("templatesPage:inch")}
                                        </Typography>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                        </div>

                        <CustomButton
                            type="submit"
                            fullWidth={true}
                            color="primary"
                            size="lg"
                            disabled={!isFormValid()}
                        >
                            {t("templatesPage:create")}
                        </CustomButton>
                        {fullScreen && (
                            <CustomButton
                                fullWidth={true}
                                simple
                                color="primary"
                                size="lg"
                                onClick={onClose}
                                className={classes.noMargins}
                            >
                                {t("templatesPage:cancel")}
                            </CustomButton>
                        )}
                    </CardBody>
                </Card>
            </form>
        </Dialog >
    )
}
export default NameBadgeModal;