import React, { memo } from "react";
import cn from "classnames";
import _get from "lodash/get";
import { TextField } from "@material-ui/core";
import makeStyles from "@material-ui/styles/makeStyles";

import styles from "./styles";

const useStyles = makeStyles(styles, { name: "Textarea" });

const Textarea = (props) => {
    const classes = useStyles();

    const { viewValue, config, onChange, fieldValidation, formState } = props;
    const { templateOptions } = config;
    const { label, description, required } = templateOptions || {};
    const { readOnly } = formState || {};

    const hasError = fieldValidation.isValid === false;
    const errorMsg = Object.values(_get(fieldValidation, 'messages', {}))[0];

    return (<TextField
        className={cn({ [classes.formControl]: true, [classes.readOnly]: readOnly })}
        InputLabelProps={{
            shrink: true,
        }}
        InputProps={{
            readOnly,
        }}
        multiline
        rows="5"
        label={label}
        value={viewValue}
        helperText={errorMsg || description}
        error={hasError}
        required={required}
        onChange={(e) => onChange(e.target.value)}
    />);
}

export default memo(Textarea);