import React from "react";
import compose from "recompose/compose";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
// icons
import LogoIcon from "components/Icons/Logo";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//i18n

import loginPageStyle from "assets/jss/material-kit-react/views/loginPage.jsx";

import image from "assets/img/bg8.jpg";

const LandingContainer = ({ classes, children, routes, ...rest }) => {
  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Eventsquare"
        brandLogo={
          <LogoIcon style={{ height: 24, width: 24, margin: "0 5px" }} />
        }
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8} lg={6}>
              {routes ? routes : children}
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
};

export default compose(withStyles(loginPageStyle))(LandingContainer);
