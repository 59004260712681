import React, { Fragment } from "react"
import ContentLoader from "react-content-loader";
import moment from "moment";
// material-ui components
import makeStyles from "@material-ui/core/styles/makeStyles"
import useTheme from "@material-ui/core/styles/useTheme";
import Grid from "@material-ui/core/Grid"
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography"
// import Divider from "@material-ui/core/Divider"
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
// material-ui icons
import DoneIcon from '@material-ui/icons/Done';
// import DoneAllIcon from '@material-ui/icons/DoneAll';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import { useTranslation } from "react-i18next"
import MessengerIcon from "../../../../components/Icons/MessengerIcon";
import AnnouncementsIcon from "../../../../components/Icons/AnnouncementsIcon";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { numberFormatter } from "./../../../../modules/utils"
import { parser } from "../../TemplateBuilder/core/templateUtils"
import styles from "./styles"

const useStyles = makeStyles(styles, { name: "EventEngagementView" });

const SingleAnnouncement = ({
    id,
    isEmailOnly,
    isMessengerOnly,
    announcementMessage = "",
    fbMessageDeliveredCount,
    fbMessageReadCount,
    emailDeliveredCount,
    emailReadCount,
    createdAt
}) => {
    const classes = useStyles();
    const { t } = useTranslation("eventEngagementView");
    const theme = useTheme();

    const parsables = parser.getParsables(announcementMessage);
    parsables.forEach(parsable => {
        announcementMessage = announcementMessage.replace(`@[${parsable}]`, parser.getLocalizedContextKey(parsable, t, false, false))
    })

    return (
        <Grid item className={classes.mainContainer}>
            {
                !id &&
                <ContentLoader
                    primaryColor={"transparent"}
                    secondaryColor={theme.palette.action.selected}
                    className={classes.contentLoader}
                />
            }

            <Paper elevation={3} className={classes.main}>
                <div className={classes.mainContent}>
                    <Paper elevation={0} className={classes.announcementMessageContainer}>
                        <Typography className={classes.announcementMessage}>
                            {announcementMessage}
                        </Typography>
                    </Paper>
                    <div className={classes.notificationPart}>
                        <div className={classes.subMiddleSection}>

                            <div className={classes.subMiddleTitle}>
                                <DoneIcon className={classes.subMiddleTitleIcon} fontSize="small" />
                                <Typography className={classes.subMiddleTitleText} noWrap>{t("sent")}:</Typography>
                            </div>

                            <div className={classes.flexDisplay}>
                                {
                                    !isEmailOnly &&
                                    <Tooltip
                                        title={fbMessageDeliveredCount}
                                        placement="top"
                                        enterTouchDelay={200}
                                        disableHoverListener={fbMessageDeliveredCount < 999}
                                        disableFocusListener={fbMessageDeliveredCount < 999}
                                        disableTouchListener={fbMessageDeliveredCount < 999}
                                    >
                                        <Badge
                                            max={1000000}
                                            className={classes.badgeIcon}
                                            badgeContent={numberFormatter(fbMessageDeliveredCount)}
                                            color="primary"
                                            classes={{
                                                badge: classes.notificationBadge
                                            }}
                                        >
                                            <MessengerIcon />
                                        </Badge>

                                    </Tooltip>
                                }
                                {
                                    !isMessengerOnly &&
                                    <Tooltip
                                        title={emailDeliveredCount}
                                        placement="top"
                                        enterTouchDelay={200}
                                        disableHoverListener={emailDeliveredCount < 999}
                                        disableFocusListener={emailDeliveredCount < 999}
                                        disableTouchListener={emailDeliveredCount < 999}
                                    >
                                        <Badge
                                            max={1000000}
                                            className={classes.badgeIcon}
                                            badgeContent={numberFormatter(emailDeliveredCount)}
                                            color="primary"
                                            classes={{
                                                badge: classes.notificationBadge
                                            }}
                                        >
                                            <MailOutlineIcon className={classes.emailIcon} />

                                        </Badge>
                                    </Tooltip>
                                }
                            </div>
                        </div>

                        {/* <Divider className={classes.divider} orientation="vertical" flexItem />

                        <div className={classes.subMiddleSection}>

                            <div className={classes.subMiddleTitle}>
                                <DoneAllIcon className={classes.subMiddleTitleIcon} fontSize="small" />
                                <Typography className={classes.subMiddleTitleText} noWrap>{t("read")}:</Typography>
                            </div>

                            <div className={classes.flexDisplay}>
                                {
                                    !isEmailOnly &&
                                    <Tooltip
                                        title={fbMessageReadCount}
                                        placement="top"
                                        enterTouchDelay={200}
                                        disableHoverListener={fbMessageReadCount < 999}
                                        disableFocusListener={fbMessageReadCount < 999}
                                        disableTouchListener={fbMessageReadCount < 999}
                                    >
                                        <Badge
                                            max={1000000}
                                            className={classes.badgeIcon}
                                            badgeContent={numberFormatter(fbMessageReadCount)}
                                            color="primary"
                                            classes={{
                                                badge: classes.notificationBadge
                                            }}
                                        >
                                            <MessengerIcon />
                                        </Badge>

                                    </Tooltip>
                                }
                                {
                                    !isMessengerOnly &&
                                    <Tooltip
                                        title={emailReadCount}
                                        placement="top"
                                        enterTouchDelay={200}
                                        disableHoverListener={emailReadCount < 999}
                                        disableFocusListener={emailReadCount < 999}
                                        disableTouchListener={emailReadCount < 999}
                                    >
                                        <Badge
                                            max={1000000}
                                            className={classes.badgeIcon}
                                            badgeContent={numberFormatter(emailReadCount)}
                                            color="primary"
                                            classes={{
                                                badge: classes.notificationBadge
                                            }}
                                        >
                                            <MailOutlineIcon className={classes.emailIcon} />

                                        </Badge>
                                    </Tooltip>
                                }
                            </div>

                        </div> */}
                    </div>
                </div>
                <div className={classes.cardLowerSection}>
                    <AnnouncementsIcon className={classes.announcementsIcon} size={36} />
                    <div className={classes.flexDisplay}>
                        {
                            !id ?
                                <CircularProgress thickness={3} size={15} color="primary" />
                                :
                                <Fragment>
                                    <AccessTimeIcon className={classes.title} />
                                    <Typography noWrap className={classes.announcedAt}>
                                        {moment(createdAt).format("HH:mm - DD/MM/YYYY")}
                                    </Typography>
                                </Fragment>
                        }
                    </div>
                </div>
            </Paper>
        </Grid >
    )
}

export default SingleAnnouncement;