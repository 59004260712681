const styles = theme => ({
    mainElement: {
        marginTop: theme.spacing(2.25),
        width: "100%",
    },
    selectContainer: {
        marginTop: theme.spacing(3.75)
    },
    mentionsContainer: {
        marginTop: theme.spacing(3)
    },
    bodyContainer: {
        padding: "0 20px"
    },
    maxLengthHelperText: {
        textAlign: "end"
    }
});

export default styles;
