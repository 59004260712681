import React, { Fragment, useState, useEffect, useCallback } from "react"
// material-ui components
import makeStyles from "@material-ui/core/styles/makeStyles"
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
// material-ui icons
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { DateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import { useTranslation } from "react-i18next"
import CustomInput from "components/CustomInput/CustomInput.jsx";
import LogoPicker from "components/LogoPicker";
import styles from "./styles"

const useStyles = makeStyles(styles, { name: "EventSettingsView" });

const EventSettingsForm = ({ eventData, setIsFormValid, onFormUpdate }) => {
    const [name, setName] = useState(eventData.name);
    const [startDate, setStartDate] = useState(moment(eventData.startDate));
    const [endDate, setEndDate] = useState(moment(eventData.endDate));
    const [logo, setLogo] = useState({
        file: null,
        name: null,
        type: null
    });
    const [checked, setChecked] = useState(eventData.isRestrictCheckingModeOn);

    const classes = useStyles();
    const { t } = useTranslation("eventSettingsView");

    const handleInputChange = event => {
        setName(event.target.value);
    };

    const handleDateChange = (date, inputName) => {
        if (inputName === "startDate") {
            setStartDate(date)

        } else {
            setEndDate(date)
        }
    };
    const handleLogoChange = logo => {
        if (logo)
            setLogo({
                file: logo.file,
                name: logo.name,
                type: logo.type
            })
    }

    const isFormValid = useCallback(() => {
        if (startDate > endDate || name.trim() === "") {
            setIsFormValid("formError");
            return false;
        }
        else if ((name !== eventData.name || !endDate.isSame(eventData.endDate)
            || !startDate.isSame(eventData.startDate) || logo.name
            || checked !== eventData.isRestrictCheckingModeOn)
            && startDate <= endDate && name.trim() !== "") {
            setIsFormValid(true);
            return true;
        }
        setIsFormValid(false);
        return false;
    }, [endDate, eventData, logo.name, name, checked, startDate, setIsFormValid]);


    useEffect(() => {
        if (isFormValid()) { // checking for form validation
            onFormUpdate({
                name: name !== eventData.name ? name.trim() : undefined,
                startDate: !startDate.isSame(eventData.startDate) ? startDate : undefined,
                endDate: !endDate.isSame(eventData.endDate) ? endDate : undefined,
                logo: logo,
                isRestrictCheckingModeOn: checked !== eventData.isRestrictCheckingModeOn ? checked : undefined,
            })
        }
    }, [name, startDate, endDate, logo, checked, eventData, isFormValid, onFormUpdate]);

    return (
        <Fragment>
            <div className={classes.form}>
                <div className={classes.logoPickerContainer}>
                    <div className={classes.logoPicker}>
                        <LogoPicker
                            url={logo.file ? logo.file : eventData.logoURL} // to start with the existing event's logo as the default
                            onChange={handleLogoChange}
                        />
                    </div>
                </div>
                <TextField
                    fullWidth
                    required
                    label={t("createEventForm:name")}
                    type="text"
                    id="name"
                    value={name}
                    onChange={handleInputChange}
                    InputLabelProps={{
                        shrink: true
                    }}
                    error={(name.trim() === "")}
                />
                <CustomInput
                    labelText={t("createEventForm:startDate")}
                    id="startDate"
                    formControlProps={{
                        fullWidth: true,
                        required: true
                    }}
                    labelProps={{
                        shrink: true
                    }}
                    renderInput={
                        <DateTimePicker
                            cancelLabel={t("createEventForm:cancel")}
                            okLabel={t("createEventForm:ok")}
                            className={classes.datePicker}
                            value={startDate}
                            onChange={startDate => handleDateChange(startDate, "startDate")}
                            InputProps={{
                                classes: {
                                    root: classes.datePickerRoot
                                }
                            }}
                            format="DD/MM/YY - hh:mm A"
                            minDateMessage={null}
                            disablePast
                        />
                    }
                />
                <CustomInput
                    labelText={t("createEventForm:endDate")}
                    id="endDate"
                    formControlProps={{
                        fullWidth: true,
                        required: true
                    }}
                    labelProps={{
                        shrink: true
                    }}
                    renderInput={
                        <DateTimePicker
                            cancelLabel={t("createEventForm:cancel")}
                            okLabel={t("createEventForm:ok")}
                            className={classes.datePicker}
                            value={endDate}
                            onChange={endDate => handleDateChange(endDate, "endDate")}
                            strictCompareDates
                            InputProps={{
                                classes: {
                                    root: classes.datePickerRoot
                                }
                            }}
                            minDate={startDate ? startDate : undefined}
                            format="DD/MM/YY - hh:mm A"
                            minDateMessage={
                                startDate > endDate &&
                                t("beforeMinimalDate")
                            }
                            disablePast
                        />
                    }
                />

                <div className={classes.checkboxContainer}>
                    <FormControlLabel
                        classes={{
                            root: classes.restrictCheckMode,
                            label: classes.restrictCheckModeLabel,
                        }}
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={() => setChecked(!checked)}
                                color="primary"
                            />
                        }
                        label={t("allowRestrictCheckMode")}
                    />
                    <Tooltip
                        title={t("strictModeDescription")}
                        placement="top"
                        enterTouchDelay={0}
                        classes={{
                            popper: classes.tooltipPopper,
                        }}
                    >
                        <InfoOutlinedIcon onClick={(event) => event.stopPropagation()} className={classes.infoIcon} />
                    </Tooltip>
                </div>
            </div>
        </Fragment >
    )
}

export default EventSettingsForm;