import React, { useRef } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import styles from './styles';

import ReactToPrint from 'react-to-print';

const useStyles = makeStyles(styles);

const Print = ({ trigger, children, ...rest }) => {
    const classes = useStyles();
    const elementToPrintRef = useRef();
    return (
        <>
            <ReactToPrint
                trigger={() => trigger}
                content={() => elementToPrintRef.current}
                {...rest}
            />
            <div className={classes.printContainer}>
                <div ref={elementToPrintRef}>
                    {children}
                </div>
            </div>
        </>
    );
};

export default Print;