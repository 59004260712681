import storageKeys from "../config/storageKeys";

import storage from "./storage.js";

const userInfoChangeSubscribers = [];
const tokenChangeSubscribers = [];

export function getUserInfo() {
  const userInfo = storage.get(storageKeys.USER_INFO);
  const token = storage.get(storageKeys.TOKEN);
  if (userInfo) return { ...userInfo, token };
}

export function isAuthenticated() {
  return storage.get(storageKeys.USER_INFO) && !!storage.get(storageKeys.TOKEN);
}

export function logout() {
  storage.remove(storageKeys.USER_INFO);
  storage.remove(storageKeys.TOKEN);
  notifyUserInfoChangeSubscribers();
  notifyTokenChangeSubscribers();
}

export function login(token, userInfo) {
  storage.set(storageKeys.USER_INFO, userInfo);
  storage.set(storageKeys.TOKEN, token);
  notifyUserInfoChangeSubscribers(userInfo);
  notifyTokenChangeSubscribers(token);
}

export function getToken() {
  return storage.get(storageKeys.TOKEN);
}

export function subscribeToUserInfoChange(handler) {
  userInfoChangeSubscribers.push(handler);
  return () => {
    const index = userInfoChangeSubscribers.indexOf(handler);
    if (index >= 0) {
      userInfoChangeSubscribers.splice(index, 1);
    }
  };
}

export function subscribeToTokenChange(handler) {
  tokenChangeSubscribers.push(handler);
  return () => {
    const index = tokenChangeSubscribers.indexOf(handler);
    if (index >= 0) {
      tokenChangeSubscribers.splice(index, 1);
    }
  };
}

function notifyUserInfoChangeSubscribers(value) {
  userInfoChangeSubscribers.forEach(function (subscriberHandler) {
    subscriberHandler(value);
  });
}

function notifyTokenChangeSubscribers(value) {
  tokenChangeSubscribers.forEach(function (subscriberHandler) {
    subscriberHandler(value);
  });
}

storage.on(storageKeys.USER_INFO, notifyUserInfoChangeSubscribers);
storage.on(storageKeys.TOKEN, notifyTokenChangeSubscribers);
