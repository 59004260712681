const styles = theme => ({
    fbHyperLink: {
        color: "#33a5ff",
        "&:hover": {
            color: "#33a5ff",
            textDecoration: "underline",
        },
    },
    alert: {
        textAlign: "start",
        marginTop: 12,
        marginBottom: 12,
    },
    illustrationText: {
        textAlign: "start",
    },
})

export default styles;
