import React from "react";
import AuthenticationContext from "./AuthenticationContext";
import request from "modules/axios";
import * as authModule from "modules/auth";

class Authenticate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: authModule.getToken(),
      userInfo: authModule.getUserInfo(),
      isAuthenticated: authModule.isAuthenticated(),
      login: this.login,
      logout: this.logout
    };
    this.unSubscribeFromUserInfoChange = null;
    this.unSubscribeFromTokenChange = null;
  }

  componentDidMount() {
    this.unSubscribeFromUserInfoChange = authModule.subscribeToUserInfoChange((newUserInfo) => this.setState({ userInfo: newUserInfo, isAuthenticated: authModule.isAuthenticated() }));
    this.unSubscribeFromTokenChange = authModule.subscribeToTokenChange((newToken) => this.setState({ token: newToken, isAuthenticated: authModule.isAuthenticated() }));
  }
  componentWillUnmount() {
    if (this.unSubscribeFromUserInfoChange) this.unSubscribeFromUserInfoChange();
    if (this.unSubscribeFromTokenChange) this.unSubscribeFromTokenChange();
  }

  login = async (email, password) => {
    if (!email || !password) return;
    try {
      const { data } = await request.post("accounts/authentication/organizer", {
        email,
        password
      });
      const { token, ...userInfo } = data;
      authModule.login(token, userInfo);
    } catch (e) {
      throw e;
    }
  };

  logout = () => {
    authModule.logout();
  };

  render() {
    return (
      <AuthenticationContext.Provider value={this.state}>
        {this.props.children}
      </AuthenticationContext.Provider>
    );
  }
}

export default Authenticate;
