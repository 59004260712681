// ##############################
// // // Info component styles
// #############################

import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  title
} from "assets/jss/material-kit-react.jsx";

const infoStyle = (theme) => ({
  infoArea: {
    maxWidth: "360px",
    margin: "0 auto",
    padding: "0px",
    display: "flex",
  },
  iconWrapper: {
    float: "left",
    marginTop: "24px",
    marginRight: 10
  },
  primary: {
    color: primaryColor
  },
  warning: {
    color: warningColor
  },
  danger: {
    color: dangerColor
  },
  success: {
    color: successColor
  },
  info: {
    color: infoColor
  },
  rose: {
    color: roseColor
  },
  gray: {
    color: grayColor
  },
  icon: {
    width: "36px",
    height: "36px"
  },
  descriptionWrapper: {
    color: grayColor,
    overflow: "hidden",
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column"
  },
  title: {
    ...title,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary
  },
  description: {
    color: theme.palette.text.secondary,
    overflow: "hidden",
    marginTop: "0px",
    fontSize: "14px",
    fontFamily: theme.typography.fontFamily
  },
  iconWrapperVertical: {
    float: "none"
  },
  iconVertical: {
    width: "61px",
    height: "61px"
  }
});

export default infoStyle;
