import _isString from "lodash/isString";
import _isArray from "lodash/isArray";
import _trim from "lodash/trim";
import _isPlainObject from "lodash/isPlainObject";
import _isEmpty from "lodash/isEmpty";
import _toString from "lodash/toString";
import _isNil from "lodash/isNil";

import I18n from 'i18next';

import { Formly, FormlyConfig } from "./Formly";
import TextInput from "./FormlyFields/TextInput";
import Checkbox from "./FormlyFields/Checkbox";
import MultiCheckbox from "./FormlyFields/MultiCheckbox";
import Textarea from "./FormlyFields/Textarea";
import Select from "./FormlyFields/Select";
import ImagePicker from "./FormlyFields/ImagePicker";
import withFieldValidation from "./HOC/withFieldValidation";

FormlyConfig.ValidationsConfig.addType({
    required: {
        expression: ({ viewValue }) => {
            let isValid;
            if (_isString(viewValue))
                isValid = _trim(viewValue).length !== 0;
            else if (_isArray(viewValue) || _isPlainObject(viewValue))
                isValid = !_isEmpty(viewValue)
            else
                isValid = !_isNil(viewValue);

            return isValid;
        },
        message: () => I18n.t("requiredField")
    },
    email: {
        expression: ({ viewValue }) => {
            const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
            return _isNil(viewValue) || regex.test(viewValue);
        },
        message: () => I18n.t("invalidEmail")
    },
    pattern: {
        expression: ({ viewValue, _, param }) => new RegExp(param).test(_toString(viewValue)),
        message: ({ viewValue }) => `${_toString(viewValue)} ${I18n.t("didNotMatchPattern")}`
    },
});

FormlyConfig.FieldsConfig.addType([
    {
        name: 'input',
        component: withFieldValidation(TextInput),
    },
    {
        name: 'text',
        component: withFieldValidation(TextInput),
    },
    {
        name: 'textarea',
        component: withFieldValidation(Textarea),
    },
    {
        name: 'select',
        component: withFieldValidation(Select),
    },
    {
        name: 'checkbox',
        component: withFieldValidation(Checkbox),
    },
    {
        name: 'multiCheckbox',
        component: withFieldValidation(MultiCheckbox),
    },
    {
        name: 'imagePicker',
        component: withFieldValidation(ImagePicker),
    },
]);


export { Formly };
