import React, { memo, useMemo } from "react";
import cn from "classnames";
import _isObjectLike from "lodash/isObjectLike";
import _get from "lodash/get";
import { Box, FormControl, FormLabel, FormGroup, Checkbox, FormControlLabel, FormHelperText } from "@material-ui/core";

import makeStyles from "@material-ui/styles/makeStyles";

import styles from "./styles";

const useStyles = makeStyles(styles, { name: "MultiCheckbox" });

const MultiCheckbox = (props) => {
    const classes = useStyles();
    const { viewValue, config, onChange, fieldValidation, formState } = props;
    const { templateOptions } = config;
    const { label, options, description, required, disabled } = templateOptions || {};
    const { readOnly } = formState || {};
    const optionsFromTemplateOptions = useMemo(() => {
        return (options || []).map((o) => _isObjectLike(o) ? o : { name: o, value: o })
    }, [options]);

    const selectedOptionsMap = useMemo(() => {
        return (viewValue || []).reduce((result, val) => {
            result[val] = true;
            return result;
        }, {})
    }, [viewValue]);
    const hasError = fieldValidation.isValid === false;
    const errorMsg = Object.values(_get(fieldValidation, 'messages', {}))[0];
    return (
        <Box display="flex">
            <FormControl
                component="fieldset"
                className={cn({ [classes.formControl]: true, [classes.readOnly]: readOnly })}
                error={hasError}
                required={required}
                disabled={disabled}
            >
                <FormLabel
                    component="label"
                    classes={{
                        root: classes.mutliChecboxLabelRoot
                    }}
                >
                    {label}
                </FormLabel>
                <FormGroup>
                    {optionsFromTemplateOptions.map((option, index) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!selectedOptionsMap[option.value]}
                                    color="primary"
                                    onChange={e => {
                                        if (readOnly) return;
                                        if (selectedOptionsMap[option.value]) {
                                            onChange(viewValue.filter((v) => v !== option.value));
                                        } else {
                                            onChange([...(viewValue || []), option.value]);
                                        }
                                    }}
                                />
                            }
                            label={option.name}
                            key={index + option.value}
                        />
                    ))}
                </FormGroup>
                {(errorMsg || description) && <FormHelperText>{errorMsg || description}</FormHelperText>}
            </FormControl>
        </Box>
    )
}

export default memo(MultiCheckbox);