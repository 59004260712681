const styles = theme => ({
    mainContainer: {
        marginTop: theme.spacing(2.5),
        width: "100%",
        maxWidth: 1136,
        height: 135,
        position: "relative",
    },
    main: {
        padding: "20px 12px 8px",
        overflow: "hidden",
    },
    subMiddleTitle: {
        display: "flex",
        marginBottom: 10,
    },
    subMiddleTitleIcon: {
        color: theme.palette.action.disabled,
        marginRight: 6,
    },
    subMiddleTitleText: {
        color: theme.palette.text.secondary,
        fontSize: 12,
    },
    alwaysOnSwitch: {
        opacity: 0.5,
    },
    cardUpperSection: {
        display: "flex",
        justifyContent: "space-between",
    },
    cardTitle: {
        display: "flex",
        alignItems: "center",
        flex: 1,
        maxWidth: "75%",
    },
    title: {
        marginRight: 5,
        fontSize: 15,
    },
    notificationPart: {
        display: "flex",
        justifyContent: "center",
        paddingBottom: 20,
        minWidth: 260,
    },
    subMiddleSection: {
        flex: 1,
    },
    divider: {
        marginRight: 30,
    },
    flexDisplay: {
        display: "flex",
    },
    messengerNotifications: {
        position: "relative",
    },
    badgeIcon: {
        marginRight: 30,
        display: "block",
    },
    notificationBadge: {
        zIndex: 5,
        fontWeight: 400,
        fontSize: 10,
    },
    emailIcon: {
        fontSize: 30,
    },
    cardLowerSection: {
        display: "flex",
        justifyContent: "space-between",
        color: theme.palette.action.active,
        alignItems: "flex-end",
    },
    announcementMessageContainer: {
        flex: 1,
        height: 75,
        border: `0.5px solid ${theme.palette.divider}`,
        marginRight: 35,
        marginBottom: 12,
        padding: "5px 12px 12px",
        overflow: "auto",
    },
    announcementsIcon: {
        fontSize: 20,
        color: theme.palette.action.disabled,
    },
    announcedAt: {
        fontSize: 10,
    },
    mainContent: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
    },
    contentLoader: {
        width: "100%",
        position: "absolute",
        height: 135,
    },
    announcementMessage: {
        fontSize: 10,
        wordBreak: "break-word",
    },
    '@media (max-width: 650px)': {
        announcementMessageContainer: {
            marginRight: 0,
            minWidth: "100%",
        },
        contentLoader: {
            height: 222,
        },
        mainContainer: {
            height: 222,
        },
    },
})

export default styles;
