import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const EngagementIcon = ({ color, size, ...rest }) => {
    return (
        <SvgIcon
            width={size}
            height={size}
            viewBox={size && `0 0 ${size} ${size}`}
            fill="none"
            color={color}
            {...rest}
        >
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M7.18559 7.07691C6.06206 6.87689 5.09986 7.0624 4.46991 7.6875C4.22155 7.93395 4.04092 8.22521 3.92803 8.56127L1.32024 16.8845L1.26964 17.2611C1.17266 18.4591 1.74144 19.3188 2.66365 20.2339C2.85556 20.4244 2.85556 20.7156 2.66364 20.9061C2.47173 21.0965 2.17821 21.0965 1.9863 20.9061C1.43574 20.3598 0.974656 19.9022 0.669804 19.0033C0.52607 19.0378 0.404032 19.1155 0.292928 19.2257C-0.282818 19.7971 0.0106997 20.9173 0.992854 21.8919C1.97501 22.8664 3.10392 23.1577 3.67967 22.5864C3.79256 22.4744 3.87158 22.3511 3.90545 22.2055L9.84353 20.4132L10.7354 21.2981C10.9047 21.4662 10.8821 21.6678 10.8596 21.7574C10.8483 21.8359 10.7692 22.0263 10.5322 22.1047L7.41637 23.0457C7.24703 23.0793 7.06641 23.0345 6.94222 22.9113L6.4455 22.4184C6.25359 22.2279 5.96007 22.2279 5.76815 22.4184C5.57624 22.6088 5.57624 22.9001 5.76816 23.0905L6.26488 23.5834C6.63742 23.9531 7.19059 24.0987 7.6986 23.9307L10.8144 22.9897C11.0402 22.9225 11.2547 22.7992 11.4127 22.6424C11.5933 22.4632 11.7288 22.2391 11.7853 21.9815C11.9094 21.4998 11.774 20.9845 11.4127 20.626L10.8708 20.0883L14.2576 19.0577C14.314 19.0465 14.3705 19.0129 14.4156 18.9681C14.7543 18.8561 15.0478 18.6768 15.2962 18.4304C16.7023 17.0351 15.9432 14.2488 13.539 11.4526C13.1642 11.5366 13.0251 11.607 12.6242 11.6083C15.1881 14.3154 15.6052 16.8019 14.6301 17.7695C13.6141 18.7777 10.6225 17.9599 7.78891 15.1481C4.95534 12.3364 4.13123 9.36783 5.14725 8.35963C5.59508 7.91525 6.42672 7.82562 7.45946 8.1051C7.32999 7.77394 7.23778 7.42926 7.18559 7.07691Z"
                fill={color}
            />
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M8.50749 11.1917C8.24783 11.307 8.18303 11.3964 7.96953 11.6083L7.85664 11.7203C7.28089 12.2916 7.57441 13.4118 8.55656 14.3864C9.53871 15.361 10.6676 15.6523 11.2434 15.0809L11.3563 14.9689C12.2933 14.0391 12.2933 12.538 11.3563 11.6083C11.5912 11.7213 11.1609 11.4144 11.3563 11.6083L8.50749 12.8143V11.1917Z"
                fill={color}
            />
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M13.2778 0.222168C10.919 0.222168 9 2.24344 9 4.72759C9 5.93457 9.46542 7.09293 10.2833 7.939L10.2833 9.99997L12.0287 9.03293C12.4389 9.16525 12.8586 9.23301 13.2778 9.23301C15.6366 9.23301 17.5556 7.21174 17.5556 4.72759C17.5556 2.24344 15.6366 0.222168 13.2778 0.222168ZM13.2778 1.17068C15.1647 1.17068 16.7 2.76655 16.7 4.72759C16.7 6.68863 15.1647 8.2845 13.2778 8.2845C12.9403 8.2845 12.6 8.22958 12.2677 8.1224L11.946 8.01866L11.646 8.18446L11.1389 8.46605V7.5268L10.8665 7.24614C10.224 6.58124 9.85556 5.66282 9.85556 4.72759C9.85556 2.76655 11.3909 1.17068 13.2778 1.17068ZM12.85 3.54195L10.7111 5.91323L12.6361 4.96472L13.7056 5.91323L15.8445 3.54195L13.9195 4.49046L12.85 3.54195Z"
                fill={color}
            />
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M17.3572 7.74668L18.5111 8.5L22.1411 6.14574L22.5445 5.88479V5.13888C22.5445 4.67463 22.1835 4.2986 21.7378 4.2986H18.1322C18.1849 4.57041 18.2124 4.85076 18.2124 5.13728V5.13888H21.7378V5.46219L18.5111 7.55387L17.7667 7.06787C17.6503 7.30638 17.513 7.5334 17.3572 7.74668Z"
                fill={color}
            />
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M14.4778 9.53926C14.7563 9.48642 15.0261 9.40907 15.2844 9.30961V10.1597H21.7378V7.37629L22.5445 6.85358V10.1597C22.5445 10.624 22.1835 11 21.7378 11H15.2844C14.8388 11 14.4778 10.624 14.4778 10.1597V9.53926Z"
                fill={color}
            />
        </SvgIcon>

    );
};

export default EngagementIcon;
