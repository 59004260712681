import hexToRgba from "hex-rgba";

const styles = theme => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        boxShadow: `0px 4px 4px ${hexToRgba(theme.palette.common.black, 7)}`,
        paddingBottom: 5,

    },
    flipsContainer: {
        display: 'flex',
    },
    deleteButton: {
        color: theme.palette.text.primary,
        margin: `0 ${theme.spacing(2)}px`,
    },
    flipButton: {
        marginLeft: theme.spacing(2),
        color: theme.palette.text.primary
    },
});

export default styles;
