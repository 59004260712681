import gql from "graphql-tag";
import produce from "immer";
import _get from "lodash/get";
import _findIndex from "lodash/findIndex";

const eventBasicData = gql`
  fragment eventBasicData on Event {
          id
          name
          logo
          logoURL
          startDate
          endDate
          createdAt
          theme {
            primaryColor,
            mode
          }
          connectedFbPage {
            pageId
            name
          }
}`;

const badgeTemplateData = gql`
  fragment badgeTemplateData on BadgeTemplate {
          id
          name
          unit
          height
          width
          movableTemplates
  }`;

const queryCreators = {
  GET_ACC_WITH_EVENTS: gql`
    query getAccount($email: String!) {
      account(email: $email) {
        id
        events {
          ...eventBasicData
        }
      }
    }${eventBasicData}
  `,
  GET_SUPPORTED_LANGUAGES: gql`
    query getSupportedLanguages ($language: String){
        supportedLanguages (language: $language){ 
          language
          name
        }
    }
  `,
  GET_MESSAGE_TRANSLATION: gql`
    query getMessageTranslation($message: String!, $eventId: ObjectId!, $targetLang: String!) {
      messageTranslations(
        message: $message
        eventId: $eventId
        targetLanguage: $targetLang
      ) {
        language
        translation
      }
    }
  `,
  GET_EVENT: {
    query: gql`
      query getEvent($eventID: ObjectId!) {
        event(id: $eventID) {
          ...eventBasicData
          allowOnlineRegistration
          plan
          isRestrictCheckingModeOn
          registrants {
            id
            name
            email
            title
            phone
            eventRegistrationID
            registrationDateTime
            checks {
              id, 
              type, 
              createdAt, 
              registrantID
            }
            addedBy
            addedByAccount {
              fullName
              email
            }
          }
          languages
          registrationForm {
            id
          }
          badgeTemplates{
            ...badgeTemplateData
          }
          owner {
            id
            email
          }
          relatedEvents
        }
      }${eventBasicData}${badgeTemplateData}
    `
  },
  GET_EVENT_BADGE_TEMPLATES: {
    query: gql`
    query getEventBadgeTemplates ($eventID: ObjectId!) {
      event(id: $eventID) {
        id
        badgeTemplates {
          ...badgeTemplateData
        }
      }
    }${badgeTemplateData}
  `},
  GET_EVENT_THEME: {
    query: gql`
      query getEventTheme($eventID: ObjectId!) {
        event(id: $eventID) {
          id
          theme {
            primaryColor
            mode
          }
      }
    }
    `
  },
  GET_EVENT_SETTINGS: {
    query: gql`
      query getEventSettings($eventID: ObjectId!) {
        event(id: $eventID) {
          id
          name
          logoURL
          isRestrictCheckingModeOn
          theme {
            primaryColor,
            mode
          }
          connectedFbPage {
            pageId
            name
          }
          startDate
          endDate
        }
      }
    `
  },
  GET_EVENT_DASHBOARD_DETAILS: {
    query: gql`
      query getEventDashboardDetails($eventID: ObjectId!) {
        event(id: $eventID) {
          id
          startDate
          endDate
          languages
          registrants{
            id
            registrationDateTime
            language
          }
          checks{
            id
            registrantID
            createdAt
            type
          }
          exhibitors{
            id
            name
            leads{
              id
              registrantID
              rating
            }
          }
        }
      }
    `
  },
  GET_EVENT_CHECKS: {
    query: gql`
      query getEventChecks($eventID: ObjectId!) {
        event(id: $eventID) {
          id
          checks {
            id, 
            type, 
            createdAt, 
            registrantID
          }
        }
      }
    `
  },
  GET_EVENT_EXHIBITORS: {
    query: gql`
      query getEventExhibitors($eventID: ObjectId!) {
        event(id: $eventID) {
          id
          exhibitors {
            id
            name
            email
            isActivated
            logoURL
            members
          }
        }
      }
    `
  },
  GET_EVENT_MEMBERS: {
    query: gql`
      query getEventMembers($eventID: ObjectId!) {
        event(id: $eventID) {
          id
          owner{
            id
            email
            firstName
            lastName
            fullName
          }
          membersAccounts{
            id
            email
            firstName
            lastName
            fullName
          }
        }
      }
    `
  },
  GET_UNACCEPTED_INVITATIONS: {
    query: gql`
      query getUnacceptedInvitations($eventID: ObjectId!, $type: InvitationType!) {
        event(id: $eventID) {
          id
          unacceptedInvitationsWithType (type: $type){
            id
            accepted
            invitedBy
            state
            invitedEmail
            invitedName
          }
        }
      }
    `
  },
  GET_ACCOUNT_INVITATION: {
    query: gql`
      query getAccountInvitation($id: ObjectId!) {
        unauthenticatedUserInvitation(id: $id) {
          type
          invitedEmail
        }
      }
    `
  },
  GET_REGISTRATION_DETAILS: {
    query: gql`
      query getRegistrationDetails($eventID:ObjectId! ,$eventRegistrationID: String!) {
        event(id: $eventID) {
          id
          isRestrictCheckingModeOn
          registrants(eventRegistrationID: $eventRegistrationID){
            id
            name
            email
            phone
            checks{
              id
              type
              createdAt
            }
            formData
            registrationDateTime
            eventID
            eventRegistrationID
            capturesByExhibitors {
              id
              captionDate
              exhibitor{
                name
                logoURL
              }
            }
          }
        }
      }
    `
  },
  GET_EVENT_ANNOUNCEMENT_MESSAGES: {
    query: gql`
    query getEventAnnouncementMessages($eventID: ObjectId!) {
      event(id: $eventID) {
        id
        languages
        maxAllowedAnnouncements
        announcementMessage {
          id
          msgTxt
          targetChannels
          fbMessageDeliveredCount
          fbMessageReadCount
          emailDeliveredCount
          emailReadCount
          createdAt
        }
    }
  }
  `
  },
  GET_EVENT_AUTOMATED_MESSAGES: {
    query: gql`
    query getEventAutomatedMessages($eventID: ObjectId!) {
      event(id: $eventID) {
        id
        automatedMessage{
          id
          eventID
          automatedMsgType
          enabled
          fbMessageDeliveredCount
          fbMessageReadCount
          emailDeliveredCount
          emailReadCount
          customMessage
          customMessageTranslations {
            language
            translation
          }
          fbMessageType
          targetChannels
          attachedFiles{
            fileName
            fileType
            url
          }
        }
    }
  }
  `
  },
  GET_FB_ACCOUNT_PAGES: {
    query: gql`
    query listFbPages($token: String!, $eventId: ObjectId!) {
      listAccountFbPages(userShortLivedToken: $token, eventID: $eventId) {
        pageId
        name
        missingPermissions
        connectionStatus
      }
    }
  `
  },
  GET_CONNECTED_FB_PAGE: {
    query: gql`
    query getConnectedFbPage($eventId: ObjectId!) {
      event(id: $eventId) {
       id
        connectedFbPage(isCheckConnection: true) {
          pageId
          name
          missingPermissions
          connectionStatus
        }
      }
    }
  `
  },
  GET_FORM_TRANSLATION: {
    query: gql`
    query getFormTranslation($eventId: ObjectId!) {
      translatedFormResources(eventId: $eventId)
    }
  `
  },
  CONNECT_FB_PAGE: {
    mutation: gql`
    mutation connectFbPage($token: String!, $pageId: String!, $eventId: ObjectId!) {
      connectFbPage(
        userShortLivedToken: $token
        eventID: $eventId
        pageId: $pageId
      ) {
        pageId
        name
        missingPermissions 
        connectionStatus
      }
    }
  `,
    mutate: mutateFn => (fbPageObj, variables) => {
      mutateFn({
        variables: fbPageObj,
        update: (cache, { data: { connectFbPage } }) => {
          const cachedQuery = cache.readQuery({ // fetching connected page in cache
            query: queryCreators.GET_CONNECTED_FB_PAGE.query,
            variables: variables
          });

          cache.writeQuery({ // writing new data into the cache
            query: queryCreators.GET_CONNECTED_FB_PAGE.query,
            data: {
              ...cachedQuery,
              event: {
                ...cachedQuery.event,
                connectedFbPage: connectFbPage,
              }
            },
          });
        }
      });
    }
  },
  DISCONNECT_FB_PAGE: {
    mutation: gql`
    mutation disconnectFbPage($eventId: ObjectId!) {
      disconnectFbPage(
        eventID: $eventId
      ) {
        pageId
        name
        missingPermissions 
        connectionStatus
      }
    }
  `,
    mutate: mutateFn => (fbPageObj, variables) => {
      mutateFn({
        variables: fbPageObj,
        update: (cache, { data: { disconnectFbPage: disconnectedFbPage } }) => {
          const cachedQuery = cache.readQuery({ // fetching connected page in cache
            query: queryCreators.GET_CONNECTED_FB_PAGE.query,
            variables: variables
          });

          cache.writeQuery({ // writing new data into the cache
            query: queryCreators.GET_CONNECTED_FB_PAGE.query,
            data: {
              ...cachedQuery,
              event: {
                ...cachedQuery.event,
                connectedFbPage: disconnectedFbPage,
              }
            },
          });
        }
      });
    }
  },
  CREATE_EVENT: {
    mutation: gql`
      mutation createEvent(
        $name: String!
        $logo: LogoInput!
        $startDate: DateTime!
        $endDate: DateTime!
        $ownerID: String!
        $languages: [String]!
      ) {
        addEvent(
          name: $name
          logo: $logo
          startDate: $startDate
          endDate: $endDate
          ownerID: $ownerID
          languages: $languages
        ) {
          id
          name
          logo
          logoURL
          createdAt
          startDate
          endDate
          languages 
          theme{
            primaryColor
            mode
          }
          connectedFbPage {
            pageId
            name
          }
        }
      }
    `,
    mutate: mutateFn => (eventObj, variables) => {
      mutateFn({
        variables: eventObj,
        optimisticResponse: {
          __typename: "Mutation",
          addEvent: {
            __typename: "Event",
            id: "",
            createdAt: "",
            logoURL: "",
            theme: {
              __typename: "Theme",
              primaryColor: "",
              mode: ""
            },
            ...eventObj
          }
        },
        update: (cache, { data: { addEvent } }) => {
          const data = cache.readQuery({
            query: queryCreators.GET_ACC_WITH_EVENTS,
            variables
          });
          cache.writeQuery({
            query: queryCreators.GET_ACC_WITH_EVENTS,
            data: {
              ...data,
              account: {
                ...data.account,
                events: [...data.account.events, addEvent]
              }
            },
            variables
          });
        }
      });
    }
  },
  UPDATE_EVENT: {
    mutation: gql`
    mutation updateEvent(
      $eventID: ObjectId!
      $name: String
      $startDate: DateTime
      $endDate: DateTime
      $logo: LogoInput
      $theme:ThemeInput
      $isRestrictCheckingModeOn: Boolean
    ) {
      updateEvent(
        eventID: $eventID
        name: $name
        startDate: $startDate
        endDate: $endDate
        logo: $logo
        theme: $theme
        isRestrictCheckingModeOn: $isRestrictCheckingModeOn
      ) {
        id
        logo
        logoURL
        name
        startDate
        endDate
        isRestrictCheckingModeOn
        theme{
          primaryColor
          mode
        }
        connectedFbPage {
          pageId
        }
      }
    }
    `,
    mutate: mutateFn => async (mutationVariables) => {
      await mutateFn({
        variables: mutationVariables
      });
    }
  },
  GET_BADGE_TEMPLATE: {
    query: gql`
      query getBadgeTemplate($templateID: ObjectId!){
        badgeTemplate(id: $templateID){
          ...badgeTemplateData
        }
      }${badgeTemplateData}`
  },
  ADD_BADGE_TEMPLATE: {
    mutation: gql`
      mutation addBadgeTemplate($badge: BadgeTemplateInput!) {
        addBadgeTemplate(badge: $badge ) {
          ...badgeTemplateData
      }
    }${badgeTemplateData}
    `,
    mutate: mutateFn => async (mutationVariables, queryVariables) => {
      await mutateFn({
        variables: mutationVariables,
        optimisticResponse: {
          __typename: "Mutation",
          addBadgeTemplate: {
            __typename: "BadgeTemplate",
            id: "",
            movableTemplates: [],
            ...mutationVariables.badge
          }
        },
        update(cache, { data: { addBadgeTemplate: addedBadge } }) {
          const cachedQuery = cache.readQuery({ // fetching badges in cache
            query: queryCreators.GET_EVENT_BADGE_TEMPLATES.query,
            variables: queryVariables
          });
          cache.writeQuery({ // writing new data into the cache
            query: queryCreators.GET_EVENT_BADGE_TEMPLATES.query,
            data: {
              ...cachedQuery,
              event: {
                ...cachedQuery.event,
                badgeTemplates: cachedQuery.event.badgeTemplates.concat({
                  ...addedBadge // updating cached badges
                })
              }
            },
          });
        }
      });
    }
  },
  ADD_ANNOUNCEMENT_MESSAGE: {
    mutation: gql`
    mutation addAnnouncementMessage($announcement: AnnouncementInput!) {
      addAnnouncementMessage(message: $announcement) {
        id
        msgTxt
        targetChannels
        fbMessageDeliveredCount
        fbMessageReadCount
        emailDeliveredCount
        emailReadCount
        createdAt
      }
    }
    `,
    mutate: mutateFn => async (mutationVariables, queryVariables) => {
      await mutateFn({
        variables: mutationVariables,
        optimisticResponse: {
          __typename: "Mutation",
          addAnnouncementMessage: {
            __typename: "AnnouncementMessage",
            id: "",
            createdAt: "",
            msgTxt: mutationVariables.announcement.msgTxt,
            targetChannels: mutationVariables.announcement.targetChannels,
            fbMessageDeliveredCount: 0,
            fbMessageReadCount: 0,
            emailDeliveredCount: 0,
            emailReadCount: 0,
          }
        },
        update(cache, { data: { addAnnouncementMessage: addAnnouncement } }) {

          const cachedAnnouncementsQuery = cache.readQuery({ // fetching announcements in cache
            query: queryCreators.GET_EVENT_ANNOUNCEMENT_MESSAGES.query,
            variables: queryVariables
          });

          cache.writeQuery({ // writing new data into the cache
            query: queryCreators.GET_EVENT_ANNOUNCEMENT_MESSAGES.query,
            data: {
              ...cachedAnnouncementsQuery,
              event: {
                ...cachedAnnouncementsQuery.event,
                announcementMessage: cachedAnnouncementsQuery.event.announcementMessage.concat({
                  ...addAnnouncement // updating cached announcements
                })
              }
            },
          });
        }
      });
    }
  },
  UPSERT_AUTOMATED_MESSAGE: {
    mutation: gql`
    mutation upsertAutomatedMessage($autoMessage: AutomatedMessageInput!) {
      upsertAutomatedMessage(automatedMessage: $autoMessage) {
        id
        eventID
        automatedMsgType
        enabled
        fbMessageDeliveredCount
        fbMessageReadCount
        emailDeliveredCount
        emailReadCount
        customMessage
        customMessageTranslations {
          language
          translation
        }
        targetChannels
        attachedFiles{
          fileName
          fileType
          url
        }
      }
    }
    `,
    mutate: mutateFn => async (mutationVariables, queryVariables) => {
      await mutateFn({
        variables: mutationVariables,

        update(cache, { data: { upsertAutomatedMessage } }) {

          const cachedQuery = cache.readQuery({ // fetching automated messages in cache
            query: queryCreators.GET_EVENT_AUTOMATED_MESSAGES.query,
            variables: queryVariables
          });

          cache.writeQuery({ // writing new data into the cache
            query: queryCreators.GET_EVENT_AUTOMATED_MESSAGES.query,
            data: {
              ...cachedQuery,
              event: {
                ...cachedQuery.event,
                automatedMessage: cachedQuery.event.automatedMessage.concat({
                  ...upsertAutomatedMessage // updating cache
                })
              }
            },
          });
        }
      });
    }
  },
  DELETE_BADGE_TEMPLATE: {
    mutation: gql`
    mutation deleteBadgeTemplate($badge: ObjectId!) {
      deleteBadgeTemplate(id: $badge) {
          ...badgeTemplateData
      }
    }${badgeTemplateData}
  `,
    mutate: mutateFn => async (mutationVariables, queryVariables) => {
      await mutateFn({
        variables: mutationVariables,
        update(cache, { data: { deleteBadgeTemplate: deletedBadge } }) {
          const cachedQuery = cache.readQuery({
            query: queryCreators.GET_EVENT_BADGE_TEMPLATES.query,
            variables: queryVariables
          });
          cache.writeQuery({
            query: queryCreators.GET_EVENT_BADGE_TEMPLATES.query,
            data: {
              ...cachedQuery,
              event: {
                ...cachedQuery.event,
                badgeTemplates: cachedQuery.event.badgeTemplates.filter(
                  badge => badge.id !== deletedBadge.id
                )
              }
            },
          });
        }
      });
    }
  },
  EDIT_BADGE_TEMPLATE: {
    mutation: gql`
    mutation editBadgeTemplate($badge: BadgeTemplateEditInput!){
      editBadgeTemplate(badge: $badge){
        id
        name
        movableTemplates
      }
    }`
  },
  ADD_INVITATION: {
    mutation: gql`
      mutation addInvitation($invitation: InvitationInput!) {
        addInvitation(invitation: $invitation) {
          id
          accepted 
          invitedBy 
          invitedEmail 
          invitedName
          state
        }
      }
    `,
    mutate: mutateFn => async (mutationVariables, queryVariables) => {
      try {
        await mutateFn({
          variables: mutationVariables,
          update: (cache, { data: { addInvitation: addedInvitation } }) => {
            const cached = cache.readQuery({
              query: queryCreators.GET_UNACCEPTED_INVITATIONS.query,
              variables: queryVariables
            });
            cache.writeQuery({
              query: queryCreators.GET_UNACCEPTED_INVITATIONS.query,
              data: {
                ...cached,
                event: {
                  ...cached.event,
                  unacceptedInvitationsWithType: cached.event.unacceptedInvitationsWithType.concat({
                    ...addedInvitation
                  })
                }
              },
              variables: queryVariables
            });
          }
        });
      } catch (e) {
        throw e;
      }
    }
  },
  DELETE_EVENT_MEMBER: {
    mutation: gql`
      mutation deleteEventMember($id: ObjectId!, $eventID: ObjectId!) {
        deleteEventMember(id: $id, eventID: $eventID) {
          id
        }
      }
    `,
    mutate: mutateFn => async (mutationVariables, { eventID, accountEmail }) => {
      try {
        await mutateFn({
          variables: mutationVariables,
          update: (
            cache,
            { data: { deleteEventMember: deletedMember } }
          ) => {
            const cached = cache.readQuery({
              query: queryCreators.GET_EVENT_MEMBERS.query,
              variables: { eventID }
            });
            cache.writeQuery({
              query: queryCreators.GET_EVENT_MEMBERS.query,
              data: {
                ...cached,
                event: {
                  ...cached.event,
                  membersAccounts: cached.event.membersAccounts.filter(
                    member => member.id !== deletedMember.id
                  )
                }
              },
              variables: { eventID }
            });

            try { // just to be safe if the events were not cached
              const accountData = cache.readQuery({
                query: queryCreators.GET_ACC_WITH_EVENTS,
                variables: { email: accountEmail }
              });

              cache.writeQuery({
                query: queryCreators.GET_ACC_WITH_EVENTS,
                data: {
                  ...accountData,
                  account: {
                    ...accountData.account,
                    events: accountData.account.events.filter(
                      event => event.id !== eventID
                    )
                  }
                },
                variables: { email: accountEmail }
              });
            } catch (error) {
              // do nothing for now
            }
          }
        });
      } catch (e) {
        throw e;
      }
    }
  },
  DELETE_EXHIBITOR: {
    mutation: gql`
      mutation deleteExhibitor($id: ObjectId!, $eventID: ObjectId!) {
        deleteExhibitor(id: $id, eventID: $eventID){
          id
        }
      }
    `,
    mutate: mutateFn => async (mutationVariables, queryVariables) => {
      try {
        await mutateFn({
          variables: mutationVariables,
          update: (
            cache,
            { data: { deleteExhibitor: deletedExhibitor } }
          ) => {
            const cached = cache.readQuery({
              query: queryCreators.GET_EVENT_EXHIBITORS.query,
              variables: queryVariables
            });
            cache.writeQuery({
              query: queryCreators.GET_EVENT_EXHIBITORS.query,
              data: {
                ...cached,
                event: {
                  ...cached.event,
                  exhibitors: cached.event.exhibitors.filter(
                    exhibitor => exhibitor.id !== deletedExhibitor.id
                  )
                }
              },
              variables: queryVariables
            });
          }
        });
      } catch (e) {
        throw e;
      }
    }
  },
  DELETE_INVITATION: {
    mutation: gql`
      mutation deleteInvitation($id: ObjectId!) {
        deleteInvitation(id: $id){
          id
        }
      }
    `,
    mutate: mutateFn => async (mutationVariables, queryVariables) => {
      try {
        await mutateFn({
          variables: mutationVariables,
          update: (
            cache,
            { data: { deleteInvitation: deletedInvitation } }
          ) => {
            const cached = cache.readQuery({
              query: queryCreators.GET_UNACCEPTED_INVITATIONS.query,
              variables: queryVariables
            });
            cache.writeQuery({
              query: queryCreators.GET_UNACCEPTED_INVITATIONS.query,
              data: {
                ...cached,
                event: {
                  ...cached.event,
                  unacceptedInvitationsWithType: cached.event.unacceptedInvitationsWithType.filter(
                    inv => inv.id !== deletedInvitation.id
                  )
                }
              },
              variables: queryVariables
            });
          }
        });
      } catch (e) {
        throw e;
      }
    }
  },
  TOGGLE_ONLINE_REGISTRATIONS: {
    mutation: gql`
      mutation toggleOnlineRegistration($eventID: ObjectId!, $value: Boolean!) {
        toggleOnlineRegistration(eventID: $eventID, value: $value) {
          id
          allowOnlineRegistration
        }
      }
    `
  },
  ADD_REGISTRATION_FORM: {
    mutation: gql`
      mutation upsertRegistrationForm($formSettings: RegistrationFormInput!) {
        upsertRegistrationForm(formSettings: $formSettings) {
          id
          registrationForm {
            id
            notModifiable
            form
          }
        }
      }
    `
  },
  FETCH_EVENT_FORM: {
    query: gql`
      query fetchEventForm($eventID: ObjectId!) {
        event(id: $eventID) {
          id
          languages
          registrationForm {
            id
            notModifiable
            form
            customTranslations
          }
        }
      }
    `
  },
  FETCH_EVENT_DEFAULT_FORM: {
    query: gql`
      query fetchEventDefaultForm ($eventId: ObjectId!) {
        initialRegistrationFormFields(eventId: $eventId) {
          type
          label
          key
          textType
          required
          options
        }
      }
    `
  },
  FETCH_EVENT_REGISTRATION_FORM: {
    query: gql`
      query fetchEventRegistrationForm ($eventID: ObjectId!) {
        eventRegistrationForm(eventID: $eventID)
      }
    `
  },
  ADD_NEW_REGISTRANT: {
    mutation: gql`
      mutation addNewRegistrant($eventID: ObjectId!, $formData: JSON!, $addedBy: ObjectId!, $registrantLanguage: String!){
        addRegistrant(eventID: $eventID, formData: $formData, addedBy: $addedBy, registrantLanguage: $registrantLanguage) {
          id
          email
          formData
          phone
          name
          registrationDateTime
          addedByAccount {
            fullName
            email
          }
        }
      }
    `
  },
  CHECK_REGISTRANT: {
    mutation: gql`
      mutation checkRegistrant($check: CheckInput!) {
        addCheck(check: $check) {
          id
          eventID
          registrantID
          type
          createdAt
        }
      }
    `,
    mutate: mutateFn => async (mutationVariables) => {
      try {
        await mutateFn({
          variables: mutationVariables,
          update: (cache, { data: { addCheck: addedCheck } }) => {
            // update registrant checks
            let cached;
            try {
              cached = cache.readQuery({
                query: queryCreators.GET_EVENT.query,
                variables: { eventID: addedCheck.eventID }
              });
              const data = produce(cached, draft => {
                const registrants = _get(draft, 'event.registrants', []);
                const index = _findIndex(registrants, function (r) { return r.id === addedCheck.registrantID; })
                if (index !== -1) {
                  draft.event.registrants[index].checks = draft.event.registrants[index].checks || [];
                  draft.event.registrants[index].checks.push(addedCheck);
                }
              });
              cache.writeQuery({
                query: queryCreators.GET_EVENT.query,
                data,
                variables: { eventID: addedCheck.eventID }
              });
            } catch (e) {
              if (cached) throw e; // throw error is other error than reading the cache
            }

            //update event checks
            let cachedChecks;
            try {
              cachedChecks = cache.readQuery({
                query: queryCreators.GET_EVENT_CHECKS.query,
                variables: { eventID: addedCheck.eventID }
              });
              const dashboardData = produce(cachedChecks, draft => {
                draft.event.checks = draft.event.checks || [];
                draft.event.checks.push(addedCheck);
              });
              cache.writeQuery({
                query: queryCreators.GET_EVENT_CHECKS.query,
                data: dashboardData,
                variables: { eventID: addedCheck.eventID }
              });
            } catch (e) {
              if (cachedChecks) throw e; // throw error is other error than reading the cache
            }
          }
        });
      } catch (e) {
        throw e;
      }
    }
  },
  CHECK_OUT_ALL_REGISTRANTS: {
    mutation: gql`
      mutation checkOutRegistrants($eventID: ObjectId!) {
        checkOutAllCheckedInRegistrants(eventID: $eventID) {
          id
        }
      }
    `,
  },
  GET_USER_ACCOUNT: {
    query: gql`
      query getUserAccount($email: String!) {
        account(email: $email) {
          id
          fullName
          firstName
          lastName
          email
          logoUrl
        }
      }
    `
  },
  UPDATE_USER_ACCOUNT: {
    mutation: gql`
      mutation updateAccount($accountUpdateInput: UserAccountUpdateInput!) {
        updateUserAccount(account: $accountUpdateInput) {
          id
          fullName
          firstName
          lastName
          email
          logoUrl
        }
      }
    `,
    mutate: mutateFn => async (mutationVariables, queryVariables) => {
      try {
        await mutateFn({
          variables: mutationVariables,
          update: (
            cache,
            { data: { updateUserAccount: updatedAccount } }
          ) => {

            const cached = cache.readQuery({
              query: queryCreators.GET_USER_ACCOUNT.query,
              variables: queryVariables
            });

            cache.writeQuery({
              query: queryCreators.GET_USER_ACCOUNT.query,
              data: {
                ...cached,
                account: {
                  ...updatedAccount,
                }
              },
              variables: queryVariables
            });
          }
        });
      } catch (e) {
        throw e;
      }
    }
  },
  UPDATE_FORM_TRANSLATIONS: {
    mutation: gql`
      mutation updateFormTranslations($eventId: ObjectId!, $customFormTranslations: JSON!) {
        updateFormTranslations(eventId: $eventId, customFormTranslations:$customFormTranslations) {
          customTranslations
        }
      }
    `,
  },
  GET_CONTEXT_DATA: {
    query: gql`
    query getContextData($eventID: ObjectId!, $registrantID: ObjectId!){
      event(id: $eventID){
        id
        name
        logoURL
      }
      registrant(id: $registrantID){
        id
        name
        eventRegistrationID
      }
    }`
  },
  GET_EVENT_CONTEXT_DATA: {
    query: gql`
      query getEventContextData($eventID: ObjectId!) {
        event(id: $eventID) {
          id
          name
          logoURL
        }
      }
    `
  },
  EXHIBITORS_PRINTING_CONTEXT_DATA: {
    query: gql`
      query getExhibitorContextData($eventID: ObjectId!) {
        event(id: $eventID) {
          ...eventBasicData
          badgeTemplates{
            ...badgeTemplateData
          }
        }
      }
      ${eventBasicData}${badgeTemplateData}
    `
  },
  REGISTRANT_SUBSCRIPTION: gql`
  subscription newRegistrantSubscription ($eventId: ObjectId!){
    newRegistrant(eventId: $eventId) {
      id
      name
      email
      title
      phone
      eventRegistrationID
      registrationDateTime
      checks {
        id, 
        type, 
        createdAt, 
        registrantID
      }
      addedBy
      addedByAccount {
        fullName
        email
      }
    }
  }
`,

  CHECK_SUBSCRIPTION: gql`
  subscription newCheckSubscription ($eventId: ObjectId!){
    newCheck(eventId: $eventId) {
      id
      type
      createdAt
      registrantID
    }
  }
`,

};

export default queryCreators;
