export function getFormValidity(formFields = {}) {
  var newFormValidity = true;
  var iterateThrough = function (obj) {
    if (newFormValidity && Object(obj) === obj) // loop only if the current form validity is true && obj is type of object
      for (const key of Object.keys(obj)) {
        if (obj[key].hasOwnProperty('isValid')) {
          if (!obj[key].isValid) {
            newFormValidity = false;
          }
        }
        else
          iterateThrough(obj[key]);
      };
  };

  iterateThrough(formFields);

  return newFormValidity;
}

export function deepEqual(x, y) {
  return (x && y && typeof x === 'object' && typeof y === 'object') ?
    (Object.keys(x).length === Object.keys(y).length) &&
    Object.keys(x).every(function (key) {
      return deepEqual(x[key], y[key]);
    }, true) : (x === y);
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && (obj).constructor === Object;
}

//Lodash function
export function isObject(value) {
  const type = typeof value
  return value != null && (type === 'object' || type === 'function')
}

//Lodash function
export function isNumber(value) {
  return typeof value === 'number' ||
    (isObjectLike(value) && getTag(value) === '[object Number]')
}

//Lodash function
function isObjectLike(value) {
  return typeof value === 'object' && value !== null
}

export function setPropertyValue(obj, propPath, value) {
  var schema = obj;  // a moving reference to internal objects within obj
  var pList = propPath.split('.');
  var len = pList.length;
  for (var i = 0; i < len - 1; i++) {
    var elem = pList[i];
    if (!schema[elem]) schema[elem] = {}
    schema = schema[elem];
  }

  schema[pList[len - 1]] = value;
}

export function setPropertyValueImmutable(obj, propPath, value) {
  const rootObj = { ...obj }
  var schema = rootObj;  // a moving reference to internal objects within obj
  var pList = propPath.split('.');
  var len = pList.length;
  for (var i = 0; i < len - 1; i++) {
    var elem = pList[i];
    if (!schema[elem] || !isObjectLike(schema[elem])) schema[elem] = {}
    else schema[elem] = { ...schema[elem] }
    schema = schema[elem];
  }

  schema[pList[len - 1]] = value;
  return rootObj;
}

export function cloneObject(obj) {
  return Object.assign({}, obj);
};

export function arrayify(obj) {
  if (obj && !Array.isArray(obj)) {
    obj = [obj]
  } else if (!obj) {
    obj = []
  }
  return obj
}



//Lodash function
export function debounce(func, wait, options) {
  const nativeMax = Math.max
  const nativeMin = Math.min

  let lastArgs,
    lastThis,
    maxWait,
    result,
    timerId,
    lastCallTime

  let lastInvokeTime = 0
  let leading = false
  let maxing = false
  let trailing = true

  if (typeof func != 'function') {
    throw new TypeError('Expected a function')
  }
  wait = toNumber(wait) || 0
  if (isObject(options)) {
    leading = !!options.leading
    maxing = 'maxWait' in options
    maxWait = maxing ? nativeMax(toNumber(options.maxWait) || 0, wait) : maxWait
    trailing = 'trailing' in options ? !!options.trailing : trailing
  }

  function invokeFunc(time) {
    const args = lastArgs
    const thisArg = lastThis

    lastArgs = lastThis = undefined
    lastInvokeTime = time
    result = func.apply(thisArg, args)
    return result
  }

  function leadingEdge(time) {
    // Reset any `maxWait` timer.
    lastInvokeTime = time
    // Start the timer for the trailing edge.
    timerId = setTimeout(timerExpired, wait)
    // Invoke the leading edge.
    return leading ? invokeFunc(time) : result
  }

  function remainingWait(time) {
    const timeSinceLastCall = time - lastCallTime
    const timeSinceLastInvoke = time - lastInvokeTime
    const result = wait - timeSinceLastCall

    return maxing ? nativeMin(result, maxWait - timeSinceLastInvoke) : result
  }

  function shouldInvoke(time) {
    const timeSinceLastCall = time - lastCallTime
    const timeSinceLastInvoke = time - lastInvokeTime

    // Either this is the first call, activity has stopped and we're at the
    // trailing edge, the system time has gone backwards and we're treating
    // it as the trailing edge, or we've hit the `maxWait` limit.
    return (lastCallTime === undefined || (timeSinceLastCall >= wait) ||
      (timeSinceLastCall < 0) || (maxing && timeSinceLastInvoke >= maxWait))
  }

  function timerExpired() {
    const time = Date.now()
    if (shouldInvoke(time)) {
      return trailingEdge(time)
    }
    // Restart the timer.
    timerId = setTimeout(timerExpired, remainingWait(time))
  }

  function trailingEdge(time) {
    timerId = undefined

    // Only invoke if we have `lastArgs` which means `func` has been
    // debounced at least once.
    if (trailing && lastArgs) {
      return invokeFunc(time)
    }
    lastArgs = lastThis = undefined
    return result
  }

  function cancel() {
    if (timerId !== undefined) {
      clearTimeout(timerId)
    }
    lastInvokeTime = 0
    lastArgs = lastCallTime = lastThis = timerId = undefined
  }

  function flush() {
    return timerId === undefined ? result : trailingEdge(Date.now())
  }

  function debounced(...args) {
    const time = Date.now()
    const isInvoking = shouldInvoke(time)

    lastArgs = args
    lastThis = this
    lastCallTime = time

    if (isInvoking) {
      if (timerId === undefined) {
        return leadingEdge(lastCallTime)
      }
      if (maxing) {
        // Handle invocations in a tight loop.
        timerId = setTimeout(timerExpired, wait)
        return invokeFunc(lastCallTime)
      }
    }
    if (timerId === undefined) {
      timerId = setTimeout(timerExpired, wait)
    }
    return result
  }
  debounced.cancel = cancel
  debounced.flush = flush
  return debounced
}


/** Used as references for various `Number` constants. */
const NAN = 0 / 0

/** Used to match leading and trailing whitespace. */
const reTrim = /^\s+|\s+$/g

/** Used to detect bad signed hexadecimal string values. */
const reIsBadHex = /^[-+]0x[0-9a-f]+$/i

/** Used to detect binary string values. */
const reIsBinary = /^0b[01]+$/i

/** Used to detect octal string values. */
const reIsOctal = /^0o[0-7]+$/i

/** Built-in method references without a dependency on `root`. */
const freeParseInt = parseInt

function toNumber(value) {
  if (typeof value === 'number') {
    return value
  }
  if (isSymbol(value)) {
    return NAN
  }
  if (isObject(value)) {
    const other = typeof value.valueOf === 'function' ? value.valueOf() : value
    value = isObject(other) ? `${other}` : other
  }
  if (typeof value !== 'string') {
    return value === 0 ? value : +value
  }
  value = value.replace(reTrim, '')
  const isBinary = reIsBinary.test(value)
  return (isBinary || reIsOctal.test(value))
    ? freeParseInt(value.slice(2), isBinary ? 2 : 8)
    : (reIsBadHex.test(value) ? NAN : +value)
}

function isSymbol(value) {
  const type = typeof value
  return type === 'symbol' || (type === 'object' && value != null && getTag(value) === '[object Symbol]')
}

const toString = Object.prototype.toString;

function getTag(value) {
  if (value == null) {
    return value === undefined ? '[object Undefined]' : '[object Null]'
  }
  return toString.call(value)
}