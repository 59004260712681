import hexToRgba from "hex-rgba";

const styles = theme => ({
    fontSize: 'inherit',
    lineHeight: 'inherit',
    backgroundColor: hexToRgba(theme.palette.primary.main, 15),
    borderRadius: 2,
    fontWeight: 'inherit'
});

export default styles;
