import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import Fab from "@material-ui/core/Fab";
import { useTheme } from "@material-ui/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Slider from "@material-ui/core/Slider";
import Fade from "@material-ui/core/Fade";
import styles from "./styles";

const useStyles = makeStyles(styles, { name: "ZoomSliderToggle" });

const ZoomSliderToggle = ({ initVal, sliding, min, max, fill, fit, isExpanded, handleSliderState }) => {
  const { t } = useTranslation("templateBuilder");
  const classes = useStyles();
  const theme = useTheme();

  // any screen width below xs "600px" will set smallScreen to true
  const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const zoomButtonStyles = classNames({
    [classes.button]: true,
    [classes.buttonOn]: isExpanded,
    [classes.buttonOff]: !isExpanded
  });

  const sliderStyles = classNames({
    [classes.slider]: true,
    [classes.sliderOn]: isExpanded,
    [classes.sliderOff]: !isExpanded
  });

  return (

    <div className={classes.container}>
      <div style={{
        flex: 1,
        display: "flex",
        justifyContent: "flex-end"
      }}>
        <div className={sliderStyles}>
          <Fade in={isExpanded} mountOnEnter unmountOnExit>
            <Slider
              valueLabelDisplay="auto"
              onChange={sliding}
              value={initVal}
              valueLabelFormat={value => {
                return Math.trunc(value * 100) + "%";
              }}
              min={min}
              max={max}
              step={0.1}
              classes={{
                markLabel: classes.markLabel
              }}
              marks={[
                {
                  value: fit,
                  label: `${t(`zoomSliderFit`)}`
                },
                {
                  value: fill,
                  label: `${t(`zoomSliderFill`)}`
                }
              ]}
            />
          </Fade>
        </div>
      </div>

      <Fab
        className={classes.zoomButton}
        onClick={handleSliderState}
        size={smallScreen ? "medium" : "large"}
      >
        <div className={zoomButtonStyles}>
          <ZoomInIcon className={classes.buttonDirection} />
        </div>
      </Fab>
    </div>
  );
};

export default ZoomSliderToggle;