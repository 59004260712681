import { useMemo } from 'react';
import { useTheme } from '@material-ui/core/styles';
import zoomCalculator from './zoomCalculator';
import useWindowSize from 'hooks/useWindowSize';


export default function useZoomTransformation(templateNode, containerNode, zoom, fallBackZoomMode = 'fit') {
    useWindowSize(); // used to re-render component on window size changes
    const theme = useTheme();
    const { fitWidthZoom, fitHeightZoom, fitZoom, fillZoom } = zoomCalculator(templateNode, containerNode);
    const currentZoom = zoom ? zoom : ((fallBackZoomMode === 'fit' && fitZoom) || (fallBackZoomMode === 'fill' && fillZoom)) || 1;

    const zoomTransformation = useMemo(() => {
        const centerVertical = fitHeightZoom > currentZoom;
        const centerHorizontal = fitWidthZoom > currentZoom;
        const isLtr = theme.direction === 'ltr';
        return {
            transform: `translate(${centerHorizontal ? (isLtr ? '-50%' : '50%') : 0},${centerVertical ? '-50%' : 0
                } ) scale(${currentZoom})`,
            top: centerVertical ? '50%' : 0,
            left: centerHorizontal && isLtr ? '50%' : undefined,
            right: centerHorizontal && !isLtr ? '50%' : undefined,
            transformOrigin: `${centerVertical ? 'center' : 'top'} ${centerHorizontal ? 'center' : (isLtr ? 'left' : 'right')
                }`
        };
    }, [currentZoom, fitWidthZoom, fitHeightZoom, theme.direction]);
    return zoomTransformation;
}