import React, { Fragment, useState } from 'react'
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormLabel from '@material-ui/core/FormLabel'
import Popover from '@material-ui/core/Popover';
import styles from "./styles";

import { SketchPicker } from 'react-color'

const useStyles = makeStyles(styles, { name: "ColorPicker" });

const ColorPicker = ({
    color = "black", // default value is black
    onChange,
    colorType,
    title,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const classes = useStyles();

    return (
        <Fragment>
            <div className={classes.pickersPos}>
                <FormLabel className={classes.title}>{title}</FormLabel>
                <Button className={classes.swatch} onClick={handleClick}>
                    <div className={classes.color} style={{ background: color }} />
                    <ExpandMoreIcon className={classes.expandMoreIcon} />
                </Button>
            </div>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >
                {
                    <div >
                        <SketchPicker disableAlpha={colorType !== "rgba"} color={color} onChange={colorObj => {
                            colorType === "hex" ? onChange(colorObj.hex) :
                                onChange(`rgba(${colorObj.rgb.r}, ${colorObj.rgb.g}, ${colorObj.rgb.b}, ${colorObj.rgb.a})`)
                        }} />
                    </div>
                }
            </Popover>
        </Fragment >
    )
}

ColorPicker.propTypes = {
    colorType: PropTypes.oneOf([
        "hex",
        "rgba",
    ]).isRequired,
    title: PropTypes.string.isRequired
};
export default ColorPicker