import React from "react";

import TextField from "@material-ui/core/TextField";

import { useTranslation } from "react-i18next";

const CommonInputSettings = ({ inputSettings, onSettingsChange }) => {
  const { t } = useTranslation("createForm");

  return (
    <>
      <TextField
        label={t("createForm:placeholder")}
        value={inputSettings.placeholder || ""}
        onChange={evt =>
          onSettingsChange({
            placeholder: evt.target.value
          })
        }
        margin="normal"
        fullWidth
      />
      <TextField
        label={t("createForm:description")}
        value={inputSettings.description || ""}
        onChange={evt =>
          onSettingsChange({
            description: evt.target.value
          })
        }
        margin="normal"
        fullWidth
      />
    </>
  );
};

export default CommonInputSettings;
