import React, { useState, useContext, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import SharedSettings from "../SharedSettings";
import { MentionsField, Mention } from '../../../../../../components/MentionsField';
import { templatesConfig, parser } from '../../../core/templateUtils';
import TemplateBuilderContext from '../../../../../../contexts/TemplateBuilderContext';
import { useTranslation } from "react-i18next";
import styles from "./styles";
import mentionsInputStyles from '../mentionsInputStyles';

const QRCODE_INPUT_MODES = {
    REGISTRATION_QRCODE: 'registrationQrCode',
    CUSTOM: 'custom'
}
const useStyles = makeStyles(styles, { name: "QRCodeTemplateSettings" });

const QRCodeTemplateSettings = ({ onMoveForward, onMoveBackward, onDelete, qrcode = "", onEdit }) => {
    const { placeholderKeys } = useContext(TemplateBuilderContext);
    const { PARSING_CONSTANTS: { MARKER_START, MARKER_END } } = templatesConfig
    const registrantQrCode =
        `{"rID":"${MARKER_START + 'registrant.id' + MARKER_END}", "eID":"${MARKER_START + 'event.id' + MARKER_END}", "v":1}`

    const [currType, setType] = useState(qrcode === registrantQrCode ? QRCODE_INPUT_MODES.REGISTRATION_QRCODE : QRCODE_INPUT_MODES.CUSTOM); // currently selected type
    const classes = useStyles();
    const { t } = useTranslation(["elementSettingsPanel", "contextData"]);
    const theme = useTheme();

    useEffect(() => {
        setType(qrcode === registrantQrCode ? QRCODE_INPUT_MODES.REGISTRATION_QRCODE : QRCODE_INPUT_MODES.CUSTOM);
    }, [qrcode, registrantQrCode])

    const typeList = [
        {
            value: QRCODE_INPUT_MODES.REGISTRATION_QRCODE,
            label: t(`elementSettingsPanel:${QRCODE_INPUT_MODES.REGISTRATION_QRCODE}`),
        },
        {
            value: QRCODE_INPUT_MODES.CUSTOM,
            label: t(`elementSettingsPanel:${QRCODE_INPUT_MODES.CUSTOM}`),
        },
    ];
    return (
        <div className={classes.mainElement}>
            <SharedSettings onMoveForward={onMoveForward} onMoveBackward={onMoveBackward} onDelete={onDelete} />
            <div className={classes.bodyContainer}>
                <div className={classes.selectContainer}>
                    <TextField
                        label={t(`qRCType`)}
                        select
                        fullWidth
                        value={currType}
                        onChange={evt => {
                            if (evt.target.value === QRCODE_INPUT_MODES.REGISTRATION_QRCODE)
                                onEdit({
                                    qrcode: registrantQrCode
                                });
                            else
                                onEdit({
                                    qrcode: ''
                                });
                            setType(evt.target.value);
                        }}
                    >
                        {typeList.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                {
                    currType === QRCODE_INPUT_MODES.CUSTOM ?
                        <div className={classes.mentionsContainer}>
                            <MentionsField
                                value={qrcode}
                                onChange={qrcode => qrcode.length <= 1024 && onEdit({ qrcode })}
                                label={t(`qRContent`)}
                                FormHelperTextProps={{
                                    className: classes.maxLengthHelperText
                                }}
                                helperText={`${qrcode.length}/1024`}
                                InputProps={{
                                    style: { fontSize: 13, lineHeight: 'inherit' }
                                }}
                                placeholder={t(`textPlaceHolder`)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                size="small"
                                fullWidth
                            >
                                <Mention
                                    markup={`${templatesConfig.PARSING_CONSTANTS.MARKER_START}__id__${templatesConfig.PARSING_CONSTANTS.MARKER_END}`}
                                    trigger={templatesConfig.PARSING_CONSTANTS.TRIGGER}
                                    style={mentionsInputStyles(theme)}
                                    data={placeholderKeys.map(key => ({
                                        display: parser.getLocalizedContextKey(key, t, false, false),
                                        id: key
                                    }))}
                                    displayTransform={display => parser.getLocalizedContextKey(display, t, false)}
                                    renderSuggestion={({ id }) => parser.getLocalizedContextKey(id, t, false, false)}
                                />
                            </MentionsField>
                        </div>
                        : null
                }
            </div>
        </div>
    );
}

export default QRCodeTemplateSettings;