const styles = theme => ({
    paper: {
        overflowY: "inherit",
    },
    paperFullScreen: {
        overflowY: "inherit",
    },
    cardFullScreen: {
        marginBottom: 0,
        paddingTop: 50,
        boxShadow: "none",
        overflowY: "auto",
    },
    card: {
        boxShadow: "none",
        width: 500,
        marginTop: 0,
        maxHeight: "calc(100vh - 64px)",
        overflowY: "inherit",
        marginBottom: -34,
    },
    cardHeader: {
        width: "auto",
        textAlign: "center",
        marginLeft: 20,
        marginRight: 20,
        marginTop: -34,
        padding: "20px 0",
        marginBottom: 15,
    },
    messageTextInput: {
        margin: 8,
    },
    addButton: {
        flex: 1,
    },
    resetButton: {
        width: "fit-content",
        color: theme.palette.text.primary,
        "&:hover,&:focus": {
            color: theme.palette.text.secondary,
        },
    },
    customMessageArea: {
        resize: "none",
        background: theme.palette.background.paper,
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.divider}`,
        minWidth: "100%",
        borderRadius: 4,
        padding: 12,
        "&:focus": {
            border: `1px solid ${theme.palette.primary.main}`,
        },
    },
    cardBody: {
        marginTop: 0,
        overflowY: "auto",
        marginBottom: 0,
        boxShadow: "unset",
    },
    announceButtonIcon: {
        marginLeft: 10,
        fontSize: 20,
        transform: `scaleX(${theme.direction === "ltr" ? 1 : -1})`
    },
})

export default styles;
