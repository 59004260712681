import React from "react";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import ImageIcon from "@material-ui/icons/Image";
import QRCodeIcon from "../../../components/Icons/QRCodeIcon";
import { templatesConfig } from "./core/templateUtils";
import Divider from "@material-ui/core/Divider"
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ShapesIcon from "../../../components/Icons/ShapesIcon"

const useStyles = makeStyles(theme => ({
  container: {
    boxShadow: theme.shadows[2],
    border: theme.palette.divider,
    right: theme.spacing(2.375),
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(7),
    color: theme.palette.text.primary,
    background: theme.palette.background.paper
  },
  addButton: {
    paddingTop: 19,
    paddingBottom: 19,
  }
}));

const AddMovable = ({ onMovableAdd }) => {
  const { TEMPLATE_TYPES } = templatesConfig;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <List disablePadding>
        <ListItem
          className={classes.addButton}
          onClick={() => onMovableAdd(TEMPLATE_TYPES.TEXT)}
          button>
          <TextFieldsIcon />
        </ListItem>

        <Divider />

        <ListItem
          className={classes.addButton}
          onClick={() => onMovableAdd(TEMPLATE_TYPES.QRCODE)}
          button>
          <QRCodeIcon />
        </ListItem>

        <Divider />

        <ListItem
          className={classes.addButton}
          onClick={() => onMovableAdd(TEMPLATE_TYPES.IMAGE)}
          button>
          <ImageIcon />
        </ListItem>

        <Divider />

        <ListItem
          className={classes.addButton}
          onClick={() => onMovableAdd(TEMPLATE_TYPES.SHAPE)}
          button>
          <ShapesIcon />
        </ListItem>
      </List>
    </div>
  );
};

export default AddMovable;
