const styles = theme => ({
  notParseableForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    width: "80%",
    color: theme.palette.divider,
    marginTop: `-${theme.spacing(10)}px`
  }
});

export default styles;
