import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

import _pick from "lodash/pick";
import _includes from "lodash/includes";
import _toLower from "lodash/toLower";
import _some from "lodash/some";
import _filter from "lodash/filter";
import _get from "lodash/get";
import _map from "lodash/map";

import compose from "recompose/compose";
import memoize from "memoize-one";

import { useParams } from "react-router-dom";

import { withStyles, withTheme } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';

import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/SaveAltOutlined";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import AddIcon from "@material-ui/icons/Add";

import Zoom from "@material-ui/core/Zoom";
//graphql
import { Mutation, useQuery, useMutation } from "react-apollo";

//i18n
import { withTranslation } from "react-i18next";

import TablePaginationActions from "components/TablePaginationActions.jsx";
import ExportCSV from "../../../components/ExportCSV/ExportCSV";
import queryCreators from "../../../apollo/queryCreators";
import OverlappingIcons from "../../../components/OverlappingIcons/OverlappingIcons";
import { showGlobalSnackbar } from "../../../components/GlobalSnackbar";
import QRCodeScannerModal from "../../../components/QRCodeScannerModal";
import CopyRegistrantUrl from "../CopyRegistrationUrl";
import CopyKioskUrl from "../CopyKioskUrl";
import CheckOutIcon from "../../../components/Icons/CheckOutIcon";
import CheckInIcon from "../../../components/Icons/CheckInIcon";
import MultipleCheckInIcon from "../../../components/Icons/MultipleChecksIcon";
import MultipleCheckOutIcon from "../../../components/Icons/MultipleCheckOutIcon";
import RegistrationsIcon from "../../../components/Icons/RegistrationsIcon";
import QRCodeIcon from "../../../components/Icons/QRCodeIcon";
import CustomButton from "../../../components/CustomButtons/Button";
import CheckOutVisitorsModal from "./CheckOutVisitorsModal/CheckOutVisitorsModal";
import useAuth from "../../../contexts/AuthenticationContext/useAuth";

import RegistrationModal from "./RegistrationModal";
import ErrorPage from "../ErrorPage";
import SmallScreenRegistrant from "./SmallScreenRegistrant";
import LargeScreenRegistrant from "./LargeScreenRegistrant";

const styles = theme => ({
  content: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(11),
  },
  titleBar: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    borderBottom: `solid 1px ${theme.palette.divider}`,
    color: theme.palette.text.primary
  },
  tableContainer: {
    overflow: "auto" // makes large tables scroll
  },
  tableSearchInput: {
    width: "100%"
  },
  cardNoData: {
    display: "flex",
    minHeight: 300,
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.divider
  },
  noRegistrations: {
    width: "100%",
    textAlign: "center"
  },
  row: {
    position: "relative",
    "&:hover": {
      cursor: "pointer",
    }
  },
  errorMessage: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -5%)",
    textAlign: "center",
    display: "inline-table"
  },
  noRegistrationFormText: {
    textAlign: "center",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    minWidth: 205,
  },
  avatarRoot: {
    backgroundColor: "transparent",
    color: theme.palette.text.primary,
  },
  tablePaginationRoot: {
    flexWrap: "wrap",
    placeContent: "center",
    padding: 0,
  },
  addRegistrantButton: {
    position: "fixed",
    bottom: theme.spacing(2.5),
    alignSelf: "flex-end",
    paddingRight: 12,
    zIndex: 10,
  },
  actionsContainer: {
    padding: "0px 24px",
    textAlign: "start",
  },
  checkOutAllUsersButtonContainer: {
    display: "inline-block",
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
});

const getFilteredRegistrants = memoize((registrants, filterWord) => {
  return _filter(registrants, o => {
    return _some(Object.values(o), v => {
      return _includes(_toLower(v), _toLower(filterWord));
    });
  });
});

const getSortedRegistrants = memoize((registrants, direction) => {
  return registrants.slice(0).sort((a, b) => direction === "asc" ? new Date(a.registrationDateTime) - new Date(b.registrationDateTime) : new Date(b.registrationDateTime) - new Date(a.registrationDateTime));
});

const getPaginatedRegistrants = memoize((registrants, currentPage, pageSize) => {
  return registrants.slice(
    currentPage * pageSize,
    currentPage * pageSize + pageSize
  );
});

const RegistrationsView = props => {
  const { classes, theme, t } = props;
  const { eventID } = useParams(); // the eventID gets passed from the currentURL
  const { userInfo } = useAuth();

  const [registrantsSortOptions, setRegistrantsSortOptions] = useState({ key: undefined, direction: undefined });
  const [registrationTablePaginationOptions, setRegistrationTablePaginationOptions] = useState({ rowsPerPage: 10, currentPage: 0 });
  const [registrantsSearchWord, setRegistrantsSearchWord] = useState("");
  const [isQRCodeModalOn, setIsQRCodeModalOn] = useState(false);
  const [isCheckOutVisitorsModalOn, setIsCheckOutVisitorsModalOn] = useState(false);
  const [isRegistrationModalModalOpen, setIsRegistrationModalModalOpen] = useState(false);
  const [isNewRegistrantAnimOn, setIsNewRegistrantAnimOn] = useState("");
  const isSmallScreenView = useMediaQuery(theme.breakpoints.down(830));

  const [checkOutAllRegistrants] = useMutation(queryCreators.CHECK_OUT_ALL_REGISTRANTS.mutation, {
    onCompleted: (data) => {
      if (_get(data, "checkOutAllCheckedInRegistrants", []).length <= 0) {
        showGlobalSnackbar(t("noCheckedInVisitors"), "error");
      } else {
        showGlobalSnackbar(t("allCheckedInAreCheckedOut"));
      }
    },
    onError: () => {
      showGlobalSnackbar(t("failedToCheckOutAll"), "error");
    }
  });

  const { loading: getEventLoading, error: getEventError, data: getEventData, subscribeToMore: subscribeToMoreRegistrants, } = useQuery(queryCreators.GET_EVENT.query, {
    variables: {
      eventID,
    },
    fetchPolicy: "cache-and-network"
  });


  useEffect(() => {
    subscribeToMoreRegistrants({
      document: queryCreators.REGISTRANT_SUBSCRIPTION,
      variables: { eventId: eventID },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const { newRegistrant } = subscriptionData.data;
        const existingRegistrant = prev.event.registrants.find(({ id }) => id.toString() === newRegistrant.id.toString());
        if (!existingRegistrant) { // to prevent duplicate registrants
          setIsNewRegistrantAnimOn(newRegistrant.id)
          return {
            ...prev,
            event: {
              ...prev.event,
              registrants: [...prev.event.registrants, newRegistrant],
            }
          }
        }
        return prev;
      }
    });

    subscribeToMoreRegistrants({
      document: queryCreators.CHECK_SUBSCRIPTION,
      variables: { eventId: eventID },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const { newCheck } = subscriptionData.data;
        const newRegistrants = (prev.event.registrants || []).map((r) => {
          const existingCheck = (r.checks || []).find(({ id }) => id.toString() === newCheck.id.toString());
          if (newCheck.registrantID !== r.id || existingCheck) return r;
          else return {
            ...r,
            checks: [...(r.checks || []), newCheck]
          }
        })
        return {
          ...prev,
          event: {
            ...prev.event,
            registrants: newRegistrants,
          }
        }
      }
    });

  }, [subscribeToMoreRegistrants, eventID])

  function setRegistrantsTableSortBy(columnKey) {
    const orderDirections = [undefined, "desc", "asc"];
    const currentSortOptions = registrantsSortOptions || {};
    //reset order if order key changed otherwise get the next direction
    const newSortDirection =
      currentSortOptions.key !== columnKey
        ? orderDirections[1]
        : orderDirections[
        (orderDirections.indexOf(currentSortOptions.direction) + 1) %
        orderDirections.length
        ];
    // set order if only direction is defined
    const newSortOptions = {
      key: newSortDirection && columnKey,
      direction: newSortDirection
    };
    setRegistrantsSortOptions(newSortOptions);
  }
  const handleChangePage = (event, page) => {
    let newPaginationOptions = Object.assign(
      {},
      registrationTablePaginationOptions,
      { currentPage: page }
    );
    setRegistrationTablePaginationOptions(newPaginationOptions);
  };

  const handleChangeRowsPerPage = event => {
    let newPaginationOptions = Object.assign(
      {},
      registrationTablePaginationOptions,
      { rowsPerPage: event.target.value, currentPage: 0 }
    );
    setRegistrationTablePaginationOptions(newPaginationOptions);
  };

  const renderRegistrantChecksIcon = (registrant) => {

    if (!registrant.checks) {
      return null;
    }

    let max = registrant.checks.reduce((prev, current) => { // to get the obj with the latest createdAt
      return (prev.createdAt > current.createdAt) ? prev : current
    });

    if (registrant.checks.length > 1) { // if the registrant has checked in/out more than once
      if (max.type === "IN") { // if the last check was IN
        return <MultipleCheckInIcon color="primary" />
      } else {
        return <MultipleCheckOutIcon color="action" />
      }
    } else if (registrant.checks.length === 1) {// registrant has checked in/out ONLY once
      if (max.type === "IN") {
        return <CheckInIcon color="primary" />
      } else {
        return <CheckOutIcon color="action" />
      }
    }
  }

  const REGISTRANTS_TABLE_COLUMNS = [
    { dataKey: "eventRegistrationID", headerLabelI18nKey: "id", csvOnly: true, avatar: () => { } },
    {
      dataKey: "name",
      headerLabelI18nKey: "name",
      avatar: (name = "") => <Avatar> {name.substring(0, 1).toUpperCase()}</Avatar >,
    },
    {
      dataKey: "email",
      headerLabelI18nKey: "email",
      avatar: () => <Avatar classes={{ root: classes.avatarRoot }}><EmailIcon /></Avatar>,
    },
    {
      dataKey: "phone",
      headerLabelI18nKey: "phone",
      avatar: () => <Avatar classes={{ root: classes.avatarRoot }}><PhoneIcon /></Avatar>,
    }
  ];

  const {
    currentPage,
    rowsPerPage
  } = registrationTablePaginationOptions;


  const event = _get(getEventData, "event") || [];
  const fetchedRegistrants = _get(getEventData, "event.registrants") || [];
  const isRestrictCheckingModeOn = _get(getEventData, "event.isRestrictCheckingModeOn");
  const filteredRegistrants = getFilteredRegistrants(
    fetchedRegistrants,
    registrantsSearchWord
  );
  const sortedRegistrants = getSortedRegistrants(
    filteredRegistrants,
    _get(registrantsSortOptions, "direction"),
  );
  const paginatedRegistrants = getPaginatedRegistrants(
    sortedRegistrants,
    currentPage,
    rowsPerPage
  );
  const registrationForm = _get(getEventData, "event.registrationForm");
  const allowOnlineRegistrations = _get(
    getEventData,
    "event.allowOnlineRegistration"
  );
  const eventOwnerID = _get(getEventData, "event.owner.id");

  return (
    <>
      <div>
        <div className={classes.titleBar}>
          <Grid container direction="row" alignItems="center">
            <Grid container item xs={7}>
              <Typography variant="h5" noWrap>
                {t("registrationsView:registrations")}
              </Typography>
            </Grid>
            <Grid container item xs={5} justify="flex-end">
              {registrationForm && (
                <Mutation
                  mutation={
                    queryCreators.TOGGLE_ONLINE_REGISTRATIONS.mutation
                  }
                  onError={() => {
                    showGlobalSnackbar(
                      allowOnlineRegistrations
                        ? t("registrationsView:closeRegistrationFailed")
                        : t("registrationsView:openRegistrationFailed"),
                      "error"
                    );
                  }}
                >
                  {(toggleRegistration, { loading, data }) => {
                    return (
                      <Button
                        onClick={() =>
                          toggleRegistration({
                            variables: {
                              eventID,
                              value: !allowOnlineRegistrations
                            }
                          })
                        }
                        disabled={loading}
                      >
                        {loading ? (
                          <CircularProgress size={25} />
                        ) : allowOnlineRegistrations ? (
                          t("registrationsView:closeRegistration")
                        ) : (
                          t("registrationsView:openRegistration")
                        )}
                      </Button>
                    );
                  }}
                </Mutation>
              )}
            </Grid>
          </Grid>
        </div>
        {
          registrationForm && !getEventError && (
            <Grid
              item
              xs={6} sm={6} md={12} lg={12} xl={12}
              className={classes.actionsContainer}
            >
              <div className={classes.checkOutAllUsersButtonContainer}>
                <Button
                  style={{
                    background: theme.palette.background.paper,
                    paddingTop: theme.spacing(1),
                    paddingBottom: theme.spacing(1),
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2),
                    boxShadow: theme.shadows[1],
                    color: theme.palette.text.primary
                  }}
                  onClick={() => setIsCheckOutVisitorsModalOn(true)}
                >
                  <Typography noWrap>
                    {t("checkOutAllRegistrants")}
                  </Typography>
                  <Box
                    marginLeft={1}
                    marginRight={1}
                    display="flex"
                    alignItems="center"
                  >
                    <CheckOutIcon />
                  </Box >
                </Button>
              </div>
              <CopyRegistrantUrl registrationFormId={registrationForm.id} language={_get(event, "languages[0]")} />
              <CopyKioskUrl eventID={eventID} />
            </Grid>
          )
        }
        {
          getEventError ?
            <div className={classes.errorMessage} >
              <ErrorPage />
            </div> :

            <div className={classes.content}>
              <Paper>
                {!getEventLoading && !getEventError && !registrationForm ? (
                  <div className={classes.cardNoData}>
                    <OverlappingIcons
                      parentIcon={ListAltIcon}
                      childIcon={ErrorIcon}
                    />
                    <Typography color="inherit" variant="h6" className={classes.noRegistrationFormText}>
                      {t("registrationsView:noRegistrationForm")}
                    </Typography>
                    <Box marginTop={1}>
                      <CustomButton
                        color="primary"
                        onClick={() => props.history.push("buildForm")}
                      >
                        {t("registrationsView:navigateToRegistrationForm")}
                      </CustomButton>
                    </Box>
                  </div>
                ) : !getEventLoading && fetchedRegistrants.length === 0 ? (
                  <div className={classes.cardNoData}>
                    <RegistrationsIcon style={{ fontSize: 60 }} />
                    <Typography className={classes.noRegistrations} color="inherit" variant="h6" noWrap>
                      {t("registrationsView:noRegistrations")}
                    </Typography>
                  </div>
                ) : (
                  <div className={classes.tableWrapper}>
                    <Toolbar>
                      <Grid container alignItems="center" direction="row-reverse">
                        <Grid
                          container
                          item
                          xs
                          sm={6}
                          md={6}
                          lg={9}
                          justify="flex-end"
                        >
                          {eventOwnerID === userInfo.id && (
                            <ExportCSV>
                              {download => (
                                <IconButton
                                  aria-label="Toggle password visibility"
                                  onClick={() =>
                                    download(
                                      fetchedRegistrants.map(registrant =>
                                        _pick(registrant, [
                                          "eventRegistrationID",
                                          "name",
                                          "email",
                                          "phone"
                                        ])
                                      ),
                                      "registrants",
                                      REGISTRANTS_TABLE_COLUMNS.map(col =>
                                        t(
                                          `registrationsView:${col.headerLabelI18nKey}`
                                        )
                                      )
                                    )
                                  }
                                >
                                  <SaveIcon />
                                </IconButton>
                              )}
                            </ExportCSV>
                          )}
                        </Grid>
                        <Grid item xs sm={6} md={6} lg={3} className={classes.searchContainer}>
                          <TextField
                            value={registrantsSearchWord}
                            className={classes.tableSearchInput}
                            placeholder={t("registrationsView:search")}
                            onChange={event => {
                              setRegistrantsSearchWord(event.target.value);
                            }}
                            InputProps={{
                              disableUnderline: true,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Zoom
                                    timeout={{
                                      enter:
                                        theme.transitions.duration
                                          .enteringScreen,
                                      exit: 0
                                    }}
                                    in={!!registrantsSearchWord}
                                    mountOnEnter
                                    unmountOnExit
                                  >
                                    <IconButton
                                      onClick={() => {
                                        setRegistrantsSearchWord("");
                                      }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </Zoom>
                                  <Zoom
                                    timeout={{
                                      enter:
                                        theme.transitions.duration
                                          .enteringScreen,
                                      exit: 0
                                    }}
                                    in={!registrantsSearchWord}
                                    mountOnEnter
                                    unmountOnExit
                                  >
                                    <IconButton
                                      aria-label="Toggle password visibility"
                                      onClick={() => {
                                        setRegistrantsSearchWord("");
                                      }}
                                    >
                                      <SearchIcon />
                                    </IconButton>
                                  </Zoom>
                                </InputAdornment>
                              )
                            }}
                          />
                          <Tooltip
                            title={t("searchRegistrantsByQrCode")}
                            placement="top"
                            enterTouchDelay={200}
                          >
                            <IconButton
                              onClick={() => {
                                setIsQRCodeModalOn(true);
                              }}
                            >
                              <QRCodeIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>

                      </Grid>
                    </Toolbar>
                    {(getEventLoading && fetchedRegistrants.length <= 0) && (
                      <LinearProgress style={{ width: "100%" }} />
                    )}
                    {
                      isSmallScreenView ?
                        <Fragment>
                          {
                            paginatedRegistrants.map((registrant, index) => {
                              return (
                                <SmallScreenRegistrant
                                  key={index}
                                  registrantsTableColumns={REGISTRANTS_TABLE_COLUMNS}
                                  registrant={registrant}
                                  event={event}
                                  isRestrictCheckingModeOn={isRestrictCheckingModeOn}
                                  renderRegistrantChecksIcon={renderRegistrantChecksIcon}
                                />
                              )
                            })
                          }
                          <TablePagination
                            classes={{
                              toolbar: classes.tablePaginationRoot,
                            }}
                            component="div"
                            count={sortedRegistrants.length}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            page={currentPage}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={
                              handleChangeRowsPerPage
                            }
                            ActionsComponent={TablePaginationActions}
                            labelRowsPerPage={t(
                              "registrationsView:rowsPerPage"
                            )}
                          />
                        </Fragment>
                        :
                        <div className={classes.tableContainer}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                {_map(REGISTRANTS_TABLE_COLUMNS, column => {
                                  return (
                                    !column.csvOnly &&
                                    <TableCell key={column.dataKey}>
                                      <TableSortLabel
                                        active={
                                          _get(
                                            registrantsSortOptions,
                                            "key"
                                          ) === column.dataKey
                                        }
                                        direction={_get(
                                          registrantsSortOptions,
                                          "direction"
                                        )}
                                        onClick={() =>
                                          setRegistrantsTableSortBy(
                                            column.dataKey
                                          )
                                        }
                                      >
                                        {t(
                                          `registrationsView:${column.headerLabelI18nKey}`
                                        )}
                                      </TableSortLabel>
                                    </TableCell>
                                  );
                                })}
                                <TableCell></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {(fetchedRegistrants.length > 0) &&
                                paginatedRegistrants.map(registrant => {
                                  return (
                                    <LargeScreenRegistrant
                                      key={registrant.id}
                                      registrantsTableColumns={REGISTRANTS_TABLE_COLUMNS}
                                      registrant={registrant}
                                      event={event}
                                      isRestrictCheckingModeOn={isRestrictCheckingModeOn}
                                      renderRegistrantChecksIcon={renderRegistrantChecksIcon}
                                      isNewRegistrantAnimOn={isNewRegistrantAnimOn}
                                      setIsNewRegistrantAnimOn={setIsNewRegistrantAnimOn}
                                    />
                                  );
                                })}
                            </TableBody>
                            <TableFooter>
                              <TableRow>
                                <TablePagination
                                  count={sortedRegistrants.length}
                                  rowsPerPage={rowsPerPage}
                                  rowsPerPageOptions={[10, 25, 50, 100]}
                                  page={currentPage}
                                  onChangePage={handleChangePage}
                                  onChangeRowsPerPage={handleChangeRowsPerPage}
                                  ActionsComponent={TablePaginationActions}
                                  labelRowsPerPage={t("registrationsView:rowsPerPage")}
                                />
                              </TableRow>
                            </TableFooter>
                          </Table>
                        </div>
                    }
                  </div>
                )}
              </Paper>
            </div>
        }

        <RegistrationModal
          open={isRegistrationModalModalOpen}
          onClose={() => setIsRegistrationModalModalOpen(false)}
        />

        <QRCodeScannerModal
          open={isQRCodeModalOn}
          onClose={() => setIsQRCodeModalOn(false)}
          onScan={(eventRegistrationId) => setRegistrantsSearchWord(eventRegistrationId)}
        />
        <CheckOutVisitorsModal
          open={isCheckOutVisitorsModalOn}
          onClose={() => setIsCheckOutVisitorsModalOn(false)}
          onCheckOut={() => {
            checkOutAllRegistrants({
              variables: {
                eventID,
              }
            })
            setIsCheckOutVisitorsModalOn(false);
          }}
        />
      </div>

      <Grid className={classes.addRegistrantButton}>
        <Fab
          onClick={() => { setIsRegistrationModalModalOpen(true) }}
          color="primary"
          size={isSmallScreenView ? "medium" : "large"}
        >
          <AddIcon />
        </Fab>
      </Grid>

    </>
  );
}

RegistrationsView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default compose(
  withTranslation("registrationsView"),
  withTheme,
  withStyles(styles)
)(RegistrationsView);
