const styles = theme => ({
    formControl: {
        margin: `${theme.spacing(1)}px 0`,
    },
    input: {
        display: "none",
    },
    bigAvatar: {
        marginRight: theme.spacing(1),
        width: 100,
        height: 100,
        border: `1px dashed ${theme.palette.text.secondary}`,
        borderRadius: "10px",
        color: theme.palette.grey.A200,
        background: "transparent",
        transition: "all 0.2s ease",
        "&.error": {
            borderColor: theme.palette.error.main,
            color: theme.palette.error.main,
        }
    },
    img: {
        objectFit: "cover",
    },
    inputLabel: {
        fontSize: 12,
        margin: `${theme.spacing(2)}px 0px`,
    },
    imageBox: {
        cursor: "pointer",
        "&:hover": {
            opacity: 1
        },
        "&:hover $bigAvatar": {
            borderColor: theme.palette.primary.main,
            color: theme.palette.common.black
        }
    },
});

export default styles;
