import * as mainStyles from "../../assets/jss/material-kit-react";
import verifyPageStyles from "../../assets/jss/VerifyPage";

const styles = theme => ({
    ...mainStyles,
    ...verifyPageStyles,
    containerCentered: {
        paddingTop: 0,
        display: "flex",
        justifyContent: "center",
    },
    link: {
        "&:hover": {
            color: "#FFFFFF"
        },
        color: "#FFFFFF"
    },
    centerText: {
        textAlign: "center",
    }
});

export default styles;
