import * as XLSX from "xlsx";

const ExportCSV = ({ children }) => {
  const fileExtension = ".xlsx";

  const exportToCSV = (data, fileName, headers) => {
    const ws = XLSX.utils.json_to_sheet(data);
    headers.forEach((header, index) => {
      const address = XLSX.utils.encode_col(index) + "1";
      if (header) {
        ws[address].v = header;
      } else {
        if (header === "") ws[address].v = "";
      }
    });
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileName);
    XLSX.writeFile(wb, fileName + fileExtension);
  };

  return children ? children(exportToCSV) : null;
};

export default ExportCSV;
