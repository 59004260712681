import React, { useState, memo } from "react";
import _map from "lodash/map";
import _get from "lodash/get";

import { useParams } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import KeyIcon from "@material-ui/icons/VpnKey";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Divider from "@material-ui/core/Divider";
import makeStyles from "@material-ui/core/styles/makeStyles";

//i18n
import { useTranslation } from "react-i18next";

import RegistrantActionsMenu from "../../RegistrantActionsMenu";

import routePaths from "config/routePaths";

import styles from "./styles";

const useStyles = makeStyles(styles);

const SmallScreenRegistrant = ({
    registrantsTableColumns,
    registrant,
    event,
    isRestrictCheckingModeOn,
    renderRegistrantChecksIcon
}) => {
    const { eventID } = useParams();

    const { t } = useTranslation("registrationsView");
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRegistrant, setCurrentRegistrant] = useState(null);

    const open = Boolean(anchorEl);

    const isActionMenuOpen = open && currentRegistrant === registrant.id;


    const handleActionsMenuClick = (event, registrantId) => {
        setAnchorEl(event.currentTarget);
        setCurrentRegistrant(registrantId);
    };

    const handleActionsMenuClose = () => {
        setAnchorEl(null);
        setCurrentRegistrant(null);
    };
    console.log()
    return (
        <>
            <Divider />
            <Paper
                elevation={0}
                className={classes.mobileRow}
                onClick={(e) => {
                    window.open(routePaths.REGISTRATION_REDIRECT(eventID, registrant.eventRegistrationID), '_blank')
                    /* if (e.ctrlKey) {
                    } else {
                      props.history.push(routePaths.REGISTRATION_REDIRECT(eventID, registrant.eventRegistrationID))
                    } */
                }}
            >
                <List className={classes.basicInfoList}>
                    {_map(
                        registrantsTableColumns,
                        column => {
                            return (
                                !column.csvOnly &&
                                <ListItem key={column.dataKey}>
                                    <ListItemAvatar>
                                        {column.avatar(registrant.name)}
                                    </ListItemAvatar>
                                    {registrant[column.dataKey] ? registrant[column.dataKey] : t("registrationView:notProvided")}
                                </ListItem>
                            );
                        }
                    )}
                </List>
                <Grid
                    item
                    xs={1} sm={4}
                    className={classes.actionsSection}
                >
                    <div onClick={(event) => event.stopPropagation()}>
                        <RegistrantActionsMenu
                            onClick={handleActionsMenuClick}
                            onClose={handleActionsMenuClose}
                            open={isActionMenuOpen}
                            anchorEl={isActionMenuOpen ? anchorEl : null}
                            event={event}
                            registrant={registrant}
                            isRestrictCheckingModeOn={isRestrictCheckingModeOn}
                        />
                    </div>

                    <div onClick={(event) => event.stopPropagation()}>
                        <Tooltip title={_get(registrant, "eventRegistrationID", "")} enterTouchDelay={0}>
                            <KeyIcon
                                style={{
                                    color: "#d6b818",
                                    transform: "scaleY(-1)",
                                    width: 24,
                                    margin: 16,
                                }}
                            />
                        </Tooltip>
                    </div>
                    {renderRegistrantChecksIcon(registrant)}
                </Grid>
                {
                    !!_get(registrant, "addedByAccount[0].fullName") &&
                    <Typography noWrap className={classes.addedByText}>
                        {`${t("addedBy")}: ${_get(registrant, "addedByAccount[0].fullName")}`}
                    </Typography>
                }
            </Paper>
        </>
    );
}

export default memo(SmallScreenRegistrant);
