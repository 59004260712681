const styles = theme => ({
    button: {
        position: "fixed",
        right: theme.spacing(3.5),
        bottom: theme.spacing(5),
        background: theme.palette.background.paper,
        color: theme.palette.text.primary,
        zIndex: 5,
        transition: "all 0.6s"
    },
    buttonOn: {
        opacity: 0,

    },
    buttonOff: {
        opacity: 1,
    },
    panel: {
        position: "fixed",
        maxWidth: 320,
        height: "70vh",
        background: theme.palette.background.paper,
        borderRadius: 4,
        border: `1px solid ${theme.palette.divider}`,
        right: theme.spacing(2.75),
        bottom: theme.spacing(5),
        zIndex: 6,
        transition: "all 0.6s cubic-bezier(.37,.63,.53,1.3)",
        overflowX: "hidden"
    },
    panelOn: {
        opacity: 1,
        transform: "translateY(-0.5%)",
        zIndex: 6
    },
    panelOff: {
        opacity: 0,
        transform: "translateY(2%)",
        zIndex: -1
    },
    headerContainer: {
        display: "flex",
        paddingTop: 12,
        justifyContent: "space-between",
        width: "100%",
    },
    flipsContainer: {
        display: "flex",
        paddingTop: 5
    },
    settingsIcon: {
        color: theme.palette.text.primary,
        marginLeft: theme.spacing(1.875),
    },
    title: {
        color: theme.palette.text.primary,
        marginLeft: theme.spacing(1.875),
    },
    minimizeIcon: {
        marginRight: theme.spacing(2.25),
    },
    blackColor: {
        color: theme.palette.text.primary,
    },
});

export default styles;
