import React, { useState, useEffect, Fragment, useCallback } from "react";
import { useQuery } from 'react-apollo';
import _get from "lodash/get";
import _isNil from "lodash/isNil"
import Background from "../Background/Background";
import { errorTypes } from "../../enums";
import LogoIcon from "components/Icons/Logo";
import Header from "components/Header/Header.jsx";
import { withStyles, Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";
import request from "../../modules/axios";
import queryString from "query-string";
import { withTranslation } from "react-i18next";
import { compose } from "recompose";
import queryCreators from '../../apollo/queryCreators';

import routePaths from "../../config/routePaths";
import useAuth from 'contexts/AuthenticationContext/useAuth';
import InvitationErrorMessage from "./InvitationErrorMessage";
import styles from "./styles"

const { ACCOUNT_NOT_FOUND, INVALID_INVITATION, INVITATION_ALREADY_ACCEPTED, EXHIBITOR_ALREADY_EXISTS, } = errorTypes;

const InvitationPage = ({ classes, location, t, history }) => {
    const { invId } = queryString.parse(location.search);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { userInfo, logout, isAuthenticated } = useAuth();

    const { loading: invitationLoading, error: invitationError, data } =
        useQuery(queryCreators.GET_ACCOUNT_INVITATION.query, {
            variables: {
                id: invId
            },
            onCompleted: data => {
                if (data && _isNil(data.unauthenticatedUserInvitation)) // if the invitation was deleted from database
                    setErrorMessage(
                        <InvitationErrorMessage
                            message={t("noLongerAvailable")}
                            buttonText={t("home")}
                        />
                    );
            },
            onError: error => {
                const errorCode = _get(error, "graphQLErrors[0].extensions.exception.errorCode");
                switch (errorCode) {
                    case INVALID_INVITATION:
                        setErrorMessage(
                            <InvitationErrorMessage
                                message={t("noLongerAvailable")}
                                buttonText={t("home")}
                            />
                        );
                        break;

                    default:
                        setErrorMessage(
                            <InvitationErrorMessage
                                message={t("errorOccurred")}
                                buttonText={t("home")}
                            />
                        );
                        break;
                }
            }
        });

    const invitedEmail = _get(data, 'unauthenticatedUserInvitation.invitedEmail', "");
    const invitationType = _get(data, 'unauthenticatedUserInvitation.type', "");
    const invitationData = _get(data, 'unauthenticatedUserInvitation', {});
    const invitedIsNotLoggedInUser = userInfo && userInfo.email !== invitedEmail;

    const onInvError = useCallback((error) => {
        switch (error) {
            case ACCOUNT_NOT_FOUND:
                history.replace({
                    pathname: `${routePaths.REGISTER}`,
                    search: `?invId=${invId}`,
                })
                break;

            case INVALID_INVITATION:
                setErrorMessage(
                    <InvitationErrorMessage
                        message={t("noLongerAvailable")}
                        buttonText={t("home")}
                    />
                );
                break;

            case INVITATION_ALREADY_ACCEPTED:
                setErrorMessage(
                    <InvitationErrorMessage
                        message={t("alreadyAccepted")}
                        buttonText={(invitedIsNotLoggedInUser || !isAuthenticated) ? t("login") : t("home")}
                        onClick={invitedIsNotLoggedInUser && logout}
                    />
                );
                break;

            case EXHIBITOR_ALREADY_EXISTS:
                setErrorMessage(
                    <InvitationErrorMessage
                        message={t("alreadyAddedExhibitor")}
                        buttonText={(invitedIsNotLoggedInUser || !isAuthenticated) ? t("login") : t("home")}
                        onClick={invitedIsNotLoggedInUser && logout}
                    />
                );
                break;

            default:
                setErrorMessage(
                    <InvitationErrorMessage
                        message={t("errorOccurred")}
                        buttonText={t("home")}
                    />
                );
                break;
        }
    }, [history, invId, invitedIsNotLoggedInUser, isAuthenticated, logout, t])

    const invitationCheck = useCallback(async () => {
        try {
            await request.post(
                "/accounts/invitation/accept",
                {
                    email: invitedEmail,
                    invId: invId,
                });
            if (invitedIsNotLoggedInUser)
                logout(); // logging out the current user if its not the user with invitation link

            setSuccess(true);

            // exhibitor can't invite organizer. i.e if type contains exhibitor then the inv was sent to an exhibitor
            invitationType.includes("EXHIBITOR") ?
                history.replace(routePaths.EXHIBITOR_LOGIN) : // redirecting to the exhibitor login page
                history.replace(routePaths.ORGANIZER_LOGIN);
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.errorType
            ) {
                setError(error.response.data.errorType);
                onInvError(error.response.data.errorType);
            }
        }
    }, [history, invId, invitationType, invitedEmail, invitedIsNotLoggedInUser, logout, onInvError])

    useEffect(() => {
        if (!success && !error && !invitationError && !invitationLoading && invId) {
            invitationCheck();
        }
    }, [invitationLoading, error, invId, success, invitationCheck, invitationError]);

    return (
        <div>
            <Background>
                <div
                    className={classNames(classes.container, classes.containerCentered)}
                >
                    <Header
                        absolute
                        color="transparent"
                        brand="Eventsquare"
                        brandLogo={
                            <LogoIcon style={{ height: 24, width: 24, margin: "0 5px" }} />
                        }
                        rightLinks={null}
                    />
                    <Grid container justify="center" alignItems="center">
                        <Grid container direction="column" alignItems="center">
                            {(error || invitationError || _isNil(invitationData)) ? errorMessage :
                                <Fragment>
                                    <div
                                        style={{
                                            color: "white",
                                            marginBottom: 10
                                        }}
                                    >
                                        <CircularProgress color="inherit" size={70} thickness={2} />
                                    </div>
                                </Fragment>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Background>
        </div>
    );
};

export default compose(
    withTranslation("invitationPage"),
    withStyles(styles),
)(InvitationPage);
