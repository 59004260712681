import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import CardCustom from "components/Card/Card.jsx";
import CardBodyCustom from "components/Card/CardBody.jsx";
import CardHeaderCustom from "components/Card/CardHeader.jsx";
import CardFooterCustom from "components/Card/CardFooter.jsx";

import AccessTimeIcon from "@material-ui/icons/AccessTime";

import compose from "recompose/compose";
import moment from "moment";
import { withTranslation } from "react-i18next";
import ContentLoader from "react-content-loader";

import { Link } from "react-router-dom";

const styles = theme => ({
  cardImageContainer: {
    borderRadius: 6,
    backgroundColor: theme.palette.background.default,
    height: "100%",
    width: "100%"
  },
  cardTitle: {
    fontWeight: 400,
    color: theme.palette.text.primary
  },
  spinnerContainer: {
    color: theme.palette.grey["800"]
  },
  timeIcon: {
    fontSize: 21,
    margin: "0px 7px",
    color: theme.palette.text.primary
  },
  cardCreateTime: {
    color: theme.palette.text.primary
  }
});

const EventCard = ({ id, name, createdAt, classes, t, i18n, logoURL }) => {
  return (
    <Link
      to={`/events/${id}`}
      onClick={e => {
        if (!id) e.preventDefault();
      }}
    >
      <CardCustom>
        <CardHeaderCustom
          style={{
            borderRadius: 6,
            height: "150px",
            padding: 0,
            boxShadow:
              "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
            //If event isn't created yet we override the pointer cursor with default cursor
            cursor: !id && "default"
          }}
        >
          {id ? (
            <CardMedia
              image={`${logoURL}`}
              className={classes.cardImageContainer}
              title={name}
            />
          ) : (
            <ContentLoader
              style={{
                width: "100%",
                height: "100%"
              }}
            />
          )}
        </CardHeaderCustom>
        <CardBodyCustom>
          <Typography className={classes.cardTitle} variant="h6" noWrap>
            {name}
          </Typography>
        </CardBodyCustom>
        <CardFooterCustom>
          <Typography
            style={{
              alignItems: "center",
              justifyContent: "flex-end",
              flex: 1,
              display: "flex"
            }}
            variant="caption"
            noWrap
          >
            {id ? (
              <>
                <AccessTimeIcon
                  className={classes.timeIcon}
                />
                <div className={classes.cardCreateTime}>
                  {t("eventsPage:createdOn")}{" "}
                  {createdAt &&
                    moment(createdAt)
                      .format("DD/MM/YYYY")}
                </div>

              </>
            ) : (
              <div className={classes.spinnerContainer}>
                <CircularProgress thickness={3} size={15} color="inherit" />
              </div>
            )}
          </Typography>
        </CardFooterCustom>
      </CardCustom>
    </Link>
  );
}

export default compose(
  withTranslation("eventsPage"),
  withStyles(styles)
)(EventCard);
