const styles = theme => ({
    titleBar: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3),
        borderBottom: `solid 1px ${theme.palette.divider}`,
        color: theme.palette.text.primary,
    },
    bodyContainer: {
        flexDirection: "column",
        alignSelf: "center",
        width: "100%",
    },
    selectContainer: {
        marginTop: theme.spacing(2),
        textAlign: "start",
    },
    settingsContainer: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        margin: 30,
    },
    searchContainer: {
        marginTop: theme.spacing(4),
        display: "flex",
        alignItems: "center",
    },
    searchInput: {
        width: "100%",
        border: `1px solid ${theme.palette.text.disabled}`,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    collapse: {
        maxWidth: 400,
        width: "100%",
        alignSelf: "center",
        paddingTop: theme.spacing(2),
        zIndex: 0,
    },
    modalOnButton: {
        placeSelf: "flex-end",
        margin: 12,
    },
    avatarRoot: {
        backgroundColor: "transparent",
        color: theme.palette.text.primary,
    },
    notProvidedData: {
        color: theme.palette.text.disabled,
    },
    modal: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: 12,
    },
    templateViewer: {
        visibility: "hidden",
        position: "absolute",
        zIndex: -1000,
    },
    actionsTitle: {
        marginBottom: theme.spacing(2),
    },
    modalContainer: {
        padding: theme.spacing(2),
        textAlign: "center",
        maxWidth: 300,
        width: "100%",
    },
    qrCode: {
        flex: 1,
    },
    upperSectionContainer: {
        display: "flex",
        flexDirection: "column",
    },
    eventData: {
        textAlign: "center",
        padding: `0px ${theme.spacing(1)}px`,
    },
    eventLogo: {
        display: "unset",
        maxHeight: 200,
        maxWidth: "100%",
        objectFit: "contain",
        borderRadius: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    spinnerContainer: {
        flexGrow: 1,
    },
    noMatchesText: {
        padding: 24,
        textAlign: "start",
    },
    dialog: {
        textAlign: "start",
    },
    listItem: {
        color: theme.palette.text.secondary,
    },
    selectedListItem: {
        color: theme.palette.text.primary,
    },
    selected: {},
});

export default styles;
