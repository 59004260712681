const styles = theme => ({
    disabled: {
        color: theme.palette.text.primary,
        padding: 0,
        border: 0,
        "&:before": {
            border: 0
        }
    },
    textField: {
        flex: 1,
        justifyContent: "center",
    }
})

export default styles;
