import React, { memo } from "react";
import cn from "classnames";
import _get from "lodash/get"
import { FormControl, FormControlLabel, Checkbox as MUICheckbox, FormHelperText } from "@material-ui/core";
import makeStyles from "@material-ui/styles/makeStyles";

import styles from "./styles";

const useStyles = makeStyles(styles, { name: "Checkbox" });

const Checkbox = (props) => {
    const classes = useStyles();
    const { viewValue, config, onChange, fieldValidation, formState } = props;
    const { templateOptions } = config;
    const { label, required, description, disabled } = templateOptions || {};
    const { readOnly } = formState || {};


    const hasError = fieldValidation.isValid === false;
    const errorMsg = Object.values(_get(fieldValidation, 'messages', {}))[0];
    return (
        <FormControl
            component="fieldset"
            className={cn({ [classes.formControl]: true, [classes.readOnly]: readOnly })}
            error={hasError}
            required={required}
            disabled={disabled}
        >
            <FormControlLabel
                label={label}
                control={
                    <MUICheckbox
                        checked={!!viewValue}
                        color="primary"
                        onChange={e => {
                            if (readOnly) return;
                            onChange(!viewValue)
                        }}
                    />
                } />
            {(errorMsg || description) && <FormHelperText>{errorMsg || description}</FormHelperText>}

        </FormControl>
    )
}

export default memo(Checkbox);