const styles = theme => ({
  eventsNoData: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.divider,
    flexGrow: 1
  },
  container: {
    flexGrow: 1
  },
  spinnerContainer: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  body: {
    display: "flex",
    flex: 1,
    flexDirection: "column"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3)
  },
});

export default styles;
