import React from "react";
import { templatesConfig } from "../../core/templateUtils";
import ElementSettingsPanel from ".";

import _forOwn from "lodash/forOwn";

const TemplateSettingsWrapper = ({ type, contentConfig, parsables, isExpanded, handleExpand, handleCollapse, ...props }) => {

    if (!type || !contentConfig) return null;
    const SettingsComponent = templatesConfig.templates[type].settingsComponent;
    const templateProps = { ...contentConfig };
    if (parsables) {
        _forOwn(parsables, (value, key) => templateProps[key] = value);
    }
    return (
        <ElementSettingsPanel
            isExpanded={isExpanded}
            handleExpand={handleExpand}
            handleCollapse={handleCollapse}
            buttonSize={props.buttonSize}
        >
            <SettingsComponent {...props} {...templateProps} />
        </ElementSettingsPanel>
    );
}
export default TemplateSettingsWrapper;