const styles = theme => ({
    titleBar: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3),
        borderBottom: `solid 1px ${theme.palette.divider}`,
        color: theme.palette.text.primary,
    },
    headerContainer: {
        marginTop: 45,
        padding: "0 30px",
        alignItems: "center",
        minWidth: 300,
        display: "flex",
        boxSizing: "border-box",
        color: theme.palette.text.primary,
    },
    sectionTitleLines: {
        flex: 1,
    },
    container: {
        padding: 24,
        display: "flex",
        justifyContent: "center",
        alignSelf: "center",
        flex: 0,
    },
    newAnnouncementsContainer: {
        padding: 24,
        justifyContent: "center",
        alignSelf: "center",
        flex: 0,
        maxWidth: 643,
    },
    announcementsHistory: {
        padding: 24,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    },
    emptyAnnouncementsHistory: {
        minWidth: "100%",
        border: `1px dashed ${theme.palette.divider}`,
        padding: 20,
        flex: 1,
        textAlign: "center",
        color: theme.palette.divider,
        background: theme.palette.background.default,
    },
    newAnnouncementsSection: {
        padding: 20,
        width: "inherit",
        overflow: "hidden",
    },
    newAnnouncementsTextarea: {
        marginBottom: 8
    },
    newAnnouncementsUpperSection: {
        display: "flex",
        marginBottom: 25,
        justifyContent: "space-between",
    },
    newAnnouncementsTitle: {
        marginRight: 25,
        alignSelf: "center",
        fontSize: 14,
    },
    announcementsLeft: {
        display: "flex",
        alignItems: "center",
        position: "relative",
    },
    announcementsLeftText: {
        position: "absolute",
        left: 15,
        fontSize: 10,
    },
    smallTitle: {
        fontSize: 10,
        marginRight: 10,
        color: theme.palette.action.active,
    },
    newAnnouncementsLowerSection: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: theme.spacing(2),
        alignItems: "flex-end",
    },
    sentTo: {
        display: "flex",
        alignItems: "center",
    },
    emptyAnnounceIcon: {
        fontSize: 35
    },
    sentToToggle: {
        border: 0,
        marginRight: 10,
        padding: 3,
        borderRadius: "50%",
        height: "unset",
        opacity: 0.6,
        backgroundColor: "transparent",
        "&$selected": {
            backgroundColor: "transparent",
            opacity: 1,
        },
        "&:hover": {
            padding: 3,
            borderRadius: "50%",
            height: "unset",
        },
    },
    toggleCheckIcon: {
        color: theme.palette.success[700],
        position: "absolute",
        top: 15,
        left: 15,
        fontSize: 20,
        backgroundColor: theme.palette.background.paper,
        borderRadius: "50%",
    },
    textAreaHelperText: {
        marginBottom: theme.spacing(1),
        display: "flex",
    },
    textAreaLimit: {
        fontSize: 10,
        color: theme.palette.action.active,
        marginLeft: "auto",
    },
    textAreaErrorMessage: {
        fontSize: 10,
        color: theme.palette.error.main,
    },
    badgeRoot: {
        marginRight: 20,
    },
    notificationBadge: {
        zIndex: 5,
        fontWeight: 400,
        fontSize: 10,
    },
    spinnerContainer: {
        flexGrow: 1,
    },
    checkedInRegistrantsLabel: {
        color: theme.palette.action.active,
    },
    selectMessageTypeContainer: {
        marginBottom: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    selectMessageType: {
        width: 150,
    },
    announceButtonIcon: {
        marginLeft: 10,
        fontSize: 20,
        transform: `scaleX(${theme.direction === "ltr" ? 1 : -1})`
    },
    selected: {},
})

export default styles;
