import React from "react";
import { withTranslation } from "react-i18next";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // TODO: You can also log the error to an error reporting service (currently we dont have logging service)
    }

    render() {
        const { t, className, errorMsg } = this.props;
        const errMsg = errorMsg || t('somethingWentWrong');

        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <div className={className} style={{ textAlign: "center", color: "red" }}>{errMsg}</div>;
        }

        return this.props.children;
    }
}

export default withTranslation("errorBoundry")(ErrorBoundary);