import React, { Fragment } from "react";
import compose from "recompose/compose";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons
import { Home, AttachMoney, Person } from "@material-ui/icons";
//I18n
import { withTranslation } from 'react-i18next';

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import GlobeIcon from "components/Icons/GlobeIcon";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

const langsArray = [
  { label: "عربي", value: "ar" },
  { label: "English", value: "en" }
];
function HeaderLinks({ ...props }) {
  const { classes, i18n, t } = props;
  return (
    <List className={classes.list}>
      {false && (
        <Fragment>
          <ListItem className={classes.listItem}>
            <Button color="transparent" className={classes.navLink}>
              <Home className={classes.icons} /> {t("home")}
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button color="transparent" className={classes.navLink}>
              <AttachMoney className={classes.icons} /> {t("header:pricing")}
            </Button>
          </ListItem>
        </Fragment>
      )}
      <ListItem className={classes.listItem} classes={{ selected: classes.listItemActive }} selected={true}>
        <Button color="transparent" className={classes.navLink}>
          <Person className={classes.icons} /> {t("header:login")}
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          rtlActive={true}
          buttonIcon={() => <GlobeIcon />}
          dropdownList={langsArray.map(lang => lang.label)}
          onItemClick={langIndex => {
            i18n.changeLanguage(langsArray[langIndex].value);
          }}
        />
      </ListItem>
    </List>
  );
}
export default compose(withTranslation('header'), withStyles(headerLinksStyle))(HeaderLinks);

