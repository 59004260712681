import React from "react";
import { Route, Redirect } from "react-router";
import useAuth from 'contexts/AuthenticationContext/useAuth';

const PrivateRoute = ({ component: Component, render, ...rest }) => {
  const { isAuthenticated } = useAuth();
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          render ? (
            render(props)
          ) : Component ? (
            <Component {...props} />
          ) : null
        ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  )
};

export default PrivateRoute;
