import { darken } from '@material-ui/core/styles';
import hexToRgba from "hex-rgba";
import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
} from "assets/jss/material-kit-react.jsx";
const cardHeaderStyle = theme => ({
  cardHeader: {
    borderRadius: "3px",
    padding: "1rem 15px",
    marginLeft: "15px",
    marginRight: "15px",
    marginTop: "-30px",
    border: "0",
    marginBottom: "0"
  },
  cardHeaderPlain: {
    marginLeft: "0px",
    marginRight: "0px"
  },
  primaryCardHeader: {
    color: theme.palette.primary.contrastText,
    background: `linear-gradient(60deg, ${theme.palette.primary.main}, ${darken(theme.palette.primary.main, 0.1)})`,
    boxShadow:
      `0 12px 20px -10px ${hexToRgba(theme.palette.primary.main, 28)}, 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${hexToRgba(theme.palette.primary.main, 20)}`
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
});

export default cardHeaderStyle;
