const styles = theme => {
    return {
        root: {
            flex: 1,
            display: "flex",
            flexDirection: "column"
        },
        titleBar: {
            backgroundColor: theme.palette.background.paper,
            padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
            borderBottom: `solid 1px ${theme.palette.divider}`,
            color: theme.palette.text.primary,
        },
    };
}

export default styles;
