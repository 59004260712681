import React from 'react';
import { FieldsConfig, WrappersConfig } from './../formlyConfig';
import { _get } from 'lodash';

import FieldGroup from './../components/FieldGroup';
import Field from './../components/Field';
import FormlyExpressions from './FormlyExpressions';
import * as Utils from './Utils';
import FormContext from '../contexts/FormContext';

class PropsManipulator {

  static propsToField(formlyComponentProps) {
    let { index, config, viewValues, model, fieldsValidation, onValueUpdate, formState } = formlyComponentProps;

    var renderingKey = config.type + "_" + config.key + "_" + index;
    //only send the field validation as a prop instead of the fields validation
    var fieldValidation = fieldsValidation[renderingKey] || {};
    //evaluate the expression properties before sending the props to the field
    if (config.hasOwnProperty('expressionProperties'))
      config = this.evaluateExpressionProperties(config, viewValues, model, formState);

    return {
      key: renderingKey,
      renderingKey,
      config,
      viewValues,
      model,
      viewValue: viewValues[config.key],
      modelValue: model[config.key],
      fieldValidation,
      onValueUpdate,
      formState,
    };
  }

  static propsToFieldGroup(formlyComponentProps) {
    let { index, config, viewValues, model, fieldsValidation, formState } = formlyComponentProps;

    var renderingKey = `fieldGroup_${config.key ? `${config.key}_` : ''}${index}`;

    //send a the field group a isolated model if it needs one ... the same for viewValues
    if (config.key) model = model[config.key] ? model[config.key] : {};
    if (config.key) viewValues = viewValues[config.key] ? viewValues[config.key] : {};

    fieldsValidation = fieldsValidation[renderingKey] ? fieldsValidation[renderingKey] : { isValid: true, fields: {} }; // isValid is initialized with true as if the fields are empty isValid is not calculated

    /*
    this function is passed to the fieldGroup fields which is called when the field value changes.
    when this function is invoked it updates the model of the field group and notifies its parent by calling its parent onValueUpdate
    the invocation continues till reaching formly onValueUpdate
  */

    function onValueUpdate(renderingKey, fieldKey, viewValue, modelValue, validationResult) {
      //the field calls this function with its key and the updated value
      //if the fieldGroup have separated model it updates it and send it to the parent model to be updated
      //while if the field group had no separate model it updates send the field key and the new value to its parent to handle updating the model
      if (validationResult === undefined)
        delete fieldsValidation.fields[renderingKey];
      else
        fieldsValidation.fields[renderingKey] = validationResult;
      fieldsValidation.isValid = Utils.getFormValidity(fieldsValidation.fields);

      if (config.key) {
        //clone ViewValues
        viewValues = { ...viewValues };
        viewValues[fieldKey] = viewValue;

        //clone model
        model = { ...model }
        if (modelValue === undefined)
          delete model[fieldKey];
        else
          model[fieldKey] = modelValue;

        formlyComponentProps.onValueUpdate(renderingKey, config.key, viewValues, model, fieldsValidation);
      }
      else
        formlyComponentProps.onValueUpdate(renderingKey, fieldKey, viewValue, modelValue, fieldsValidation);
    }




    return { key: renderingKey, renderingKey, config, viewValues, model, fieldsValidation, onValueUpdate, formState };
  }


  static evaluateExpressionProperties(fieldConf, viewValues, model, formState) {
    let evaluatedConf = fieldConf;
    for (const key of Object.keys(fieldConf.expressionProperties)) {
      //note that key can be a dot separated path as (parent.child.property) so in order to set the
      //value of the key function setPropertyValue is used, which deals with nesting

      // note that field.key could be undefined
      let expressionContext = { "viewValue": viewValues[fieldConf.key], "modelValue": model[fieldConf.key], "model": model, "formState": formState };
      const currentPropertyValue = _get(fieldConf, key);
      const evaluatedPropertyValue = FormlyExpressions.evaluate(fieldConf.expressionProperties[key], expressionContext);
      if (currentPropertyValue !== evaluatedPropertyValue) {
        evaluatedConf = Utils.setPropertyValueImmutable(fieldConf, key, evaluatedPropertyValue);
      }
    }
    return evaluatedConf;
  }
};




export default class FieldsRenderer {

  //////////////////////////////////////////rendering functions//////////////////////////////////////////////////
  static renderField(props) {
    return <Field {...props} />;
  }

  static renderFieldGroup(props) {
    let { config } = props;

    return (
      <FieldGroup  {...props} >
        {config.fieldGroup.map(function (field, index) {
          return FieldsRenderer.generateFieldTag({ index, config: field });
        })}
      </FieldGroup>
    );
  }



  static generateFieldTag(formlyComponentProps) {
    //return wrapped component
    return (
      <FormContext.Consumer>
        {(context) => {
          //config aliased to field makes config more readable
          let { config: field } = formlyComponentProps;
          const { viewValues, model, formState } = context;

          if (this.shouldHide(field, viewValues, model, formState)) {
            return null;
          }
          if (field.fieldGroup) {
            let fieldGroupProps = PropsManipulator.propsToFieldGroup({ ...context, ...formlyComponentProps });
            const fieldComponent = this.renderFieldGroup(fieldGroupProps);
            return (
              <FormContext.Provider value={{
                ...context,
                viewValues: fieldGroupProps.viewValues,
                model: fieldGroupProps.model,
                fieldsValidation: fieldGroupProps.fieldsValidation,
                onValueUpdate: fieldGroupProps.onValueUpdate,
              }}>
                {this.wrapComponent(field, fieldComponent, fieldGroupProps)}
              </FormContext.Provider>
            )


          }
          else {
            let fieldProps = PropsManipulator.propsToField({ ...context, ...formlyComponentProps });
            const fieldComponent = this.renderField(fieldProps);
            return this.wrapComponent(field, fieldComponent, fieldProps)
          }
        }}
      </FormContext.Consumer>);
  }



  //////////////////////////////////////////hide functions//////////////////////////////////////////////////
  static shouldHide(field, viewValues, model, formState) {
    var hide = field.hide || this.isOrInvoke(field, 'hideExpression', viewValues, model, formState);
    return hide && hide !== null;
  }

  static isOrInvoke(field, property, viewValues, model, formState) {
    if (!field.hasOwnProperty(property)) {
      return null;
    }
    else {
      // note that field.key could be undefined
      let expressionContext = { "viewValue": viewValues[field.key], "modelValue": model[field.key], "model": model, "formState": formState };
      return FormlyExpressions.evaluate(field[property], expressionContext)
    }
  }

  //////////////////////////////////////////Wrapping functions//////////////////////////////////////////////////
  static wrapComponent(fieldObject, fieldComponent, componentProps) {
    var wrappers = this.getWrappers(fieldObject);

    wrappers.forEach(function (wrapper) {
      fieldComponent = this.wrapComponentWith(fieldComponent, wrapper, componentProps);
    }, this);
    return fieldComponent;

  }
  static getWrappers(fieldObject) {
    var wrappers = fieldObject.wrapper;
    // explicit null means no wrapper
    if (wrappers === null) {
      return [];
    }

    // nothing specified means use the default wrapper for the type
    if (!wrappers) {
      // get all wrappers that specify they apply to this type
      wrappers = Utils.arrayify(WrappersConfig.getWrappersComponentsByType(fieldObject.type));
    }
    else {
      wrappers = Utils.arrayify(wrappers).map(wrapperName => WrappersConfig.getWrapperComponent(wrapperName))
    }
    // get all wrappers for that the type specified that it uses.
    const type = FieldsConfig.getTypes()[fieldObject.type];
    if (type && type.wrapper) {
      const typeWrappers = Utils.arrayify(type.wrapper).map(wrapperName => WrappersConfig.getWrapperComponent(wrapperName))
      wrappers = wrappers.concat(typeWrappers);
    }


    // add the default wrapper last
    const defaultWrapper = WrappersConfig.getWrapperComponent();
    if (defaultWrapper) {
      wrappers.push(defaultWrapper)
    }
    return wrappers;
  }
  static wrapComponentWith(component, wrapperComponent, componentProps) {
    var WrapperComponent = wrapperComponent;
    if (!componentProps.key)
      delete componentProps.key;

    return <WrapperComponent {...componentProps}>{component}</WrapperComponent>
  }

}




