import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useTranslation } from "react-i18next"

import styles from './styles';

const useStyles = makeStyles(styles, { name: "ErrorPage" })

const ErrorPage = () => {
    const classes = useStyles();
    const { t } = useTranslation("logoPicker");

    return (
        <Grid // error page message
            item
            container
            alignItems="center"
            justify="center"
            className={classes.errorOccurred}
        >
            <ErrorOutlineIcon className={classes.errorIcon} />
            <Typography variant="h6" noWrap> {t("eventPage:errorOccurred")}</Typography>
        </Grid>
    );
};

export default ErrorPage;
