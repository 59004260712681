import React, { useState } from "react";

import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import makeStyles from "@material-ui/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

import _includes from "lodash/includes";

import addOptionsInputStyles from "./styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(addOptionsInputStyles);

const AddOptionsInput = ({ collection, onAdd, error }) => {
  const [textInput, setTextInput] = useState("");
  const classes = useStyles();
  const { t } = useTranslation("createForm");
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Box marginTop={3}>
          <Typography
            gutterBottom
            variant="subtitle1"
            className={classes.centerAligned}
          >
            {t("createForm:options")}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <TextField
            placeholder={t("createForm:addOption")}
            margin="normal"
            value={textInput}
            onChange={evt => setTextInput(evt.target.value)}
            classes={{
              root: classes.noMarginInput
            }}
            style={{
              width: "70%"
            }}
            error={error}
            helperText={error && t(`createForm:${error}`)}
          />
          <IconButton
            onClick={() => {
              onAdd(
                Array.isArray(collection)
                  ? [...collection, textInput]
                  : [textInput]
              );
              setTextInput("");
            }}
            disabled={!textInput || _includes(collection, textInput)}
          >
            <AddIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <Box marginTop={1}>
        <List dense>
          {Array.isArray(collection) &&
            collection.map((item, index) => (
              <ListItem key={index}>
                <ListItemText primary={item} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() =>
                      onAdd(
                        collection.filter(
                          (item, itemIndex) => itemIndex !== index
                        )
                      )
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
      </Box>
    </>
  );
};

export default AddOptionsInput;
