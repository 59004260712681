import React, { useState, useEffect, Fragment, useContext, useMemo } from "react"
import hexToRgba from "hex-rgba";
import classNames from "classnames";
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-apollo";
import { useParams } from "react-router-dom";
import _get from "lodash/get"
import { useTheme } from "@material-ui/styles";
// material-ui components
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ToggleButton from "@material-ui/lab/ToggleButton";

// material-ui icons
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import queryCreators from "../../../apollo/queryCreators";

import AutomatedMessagesSetting from "./AutomatedMessagesSetting"
import SingleAnnouncement from "./SingleAnnouncement"

import { numberFormatter } from "../../../modules/utils"

import AnnouncementsIcon from "../../../components/Icons/AnnouncementsIcon";
import MessengerIcon from "../../../components/Icons/MessengerIcon";
import DividerWithTitle from '../../../components/DividerWithTitle';
import { MentionsField, Mention } from "../../../components/MentionsField";
import { showGlobalSnackbar } from "../../../components/GlobalSnackbar";
import ConnectFacebookModal from "../../../components/ConnectFacebookModal";

import TemplateBuilderContext from "../../../contexts/TemplateBuilderContext";
import { templatesConfig, parser } from "../../../views/EventPage/TemplateBuilder/core/templateUtils";
import TranslationPreviewModal from "./TranslationPreviewModal";
import ErrorPage from "../../EventPage/ErrorPage"
import { targetChannelType, automatedMessageType, usersType } from "../../../enums";
import useAuth from 'contexts/AuthenticationContext/useAuth';
import FacebookMessageTypes from "./FacebookMessageTypes";
import styles from "./styles"

const useStyles = makeStyles(styles, { name: "EventEngagementView" });
const { REGISTRATION_CONFIRMATION, CHECKIN_GREETING, EXHIBITOR_CONTACTS, EVENT_SUMMARY } = automatedMessageType;
const { ALL_USERS, CHECKED_IN_USERS } = usersType;

const EventEngagementView = () => {
    const theme = useTheme();
    const { placeholderKeys } = useContext(TemplateBuilderContext);
    const classes = useStyles();
    const { t } = useTranslation("eventEngagementView");
    const [textAreaMaxLength, setTextAreaMaxLength] = useState(1000);
    const [textAreaMessage, setTextAreaMessage] = useState("");
    const [textAreaError, setTextAreaError] = useState("");
    const [isMessengerButtonClicked, setIsMessengerButtonClicked] = useState(false);
    const [isEmailButtonClicked, setIsEmailButtonClicked] = useState(true);
    const [checked, setChecked] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConnectFacebookModalOpen, setIsConnectFacebookModalOpen] = useState(false);
    const [announcementMessageType, setAnnouncementMessageType] = useState("");
    const [fbPageConnectionStatus, setFbPageConnectionStatus] = useState(undefined);
    const { userInfo } = useAuth();

    const whiteListedExbibitorContactEmails = [
        "imohamedh14@gmail.com",
        "assem.a.hafez@gmail.com",
        "demo@event-square.net",
        "waeltrade@hotmail.com",
    ]
    const isLoggedInUserWhiteListed = !!whiteListedExbibitorContactEmails.find(email => userInfo.email.toLowerCase() === email.toLowerCase());



    useEffect(() => {
        setTextAreaMaxLength(isMessengerButtonClicked ? 1000 : 1000)
    }, [isMessengerButtonClicked])

    const { eventID } = useParams(); // the eventID gets passed from the currentURL

    const { loading: announcementsLoading, error: announcementsError, data: announcementsData } = useQuery(
        queryCreators.GET_EVENT_ANNOUNCEMENT_MESSAGES.query,
        {
            variables: {
                eventID: eventID,
            },
        }
    );
    const { loading: automatedMessagesLoading, error: automatedMessagesError, data: automatedMessagesData } = useQuery(
        queryCreators.GET_EVENT_AUTOMATED_MESSAGES.query,
        {
            variables: {
                eventID: eventID,
            },
        }
    );

    const { loading: fetchConnectedFbPageLoading, error: connectedFbPageError, data: connectedFbPageData } = useQuery(
        queryCreators.GET_CONNECTED_FB_PAGE.query,
        {
            variables: {
                eventId: eventID,
            },
            onCompleted: (data) => {
                const connectedFbPage = _get(data, "event.connectedFbPage", {});
                if (!_get(connectedFbPage, "pageId") || _get(connectedFbPage, "connectionStatus") === "NEEDS_RECONNECT") {
                    const connectionStatus = _get(data, "event.connectedFbPage.connectionStatus");
                    setFbPageConnectionStatus(connectionStatus);
                }
            }
        }
    );

    const announcementMessages = _get(announcementsData, "event.announcementMessage", []);
    const maxAllowedAnnouncements = _get(announcementsData, "event.maxAllowedAnnouncements", 0);
    const eventLanguages = _get(announcementsData, "event.languages", []);
    const automatedMessage = _get(automatedMessagesData, "event.automatedMessage", []);
    const connectedFbPage = _get(connectedFbPageData, "event.connectedFbPage", {});
    const announcementsLeft = maxAllowedAnnouncements - announcementMessages.length;

    const disableNewAnnouncementTooltip = announcementsLeft.toString() === numberFormatter(announcementsLeft);


    const [addAnnouncementMessage] = useMutation(
        queryCreators.ADD_ANNOUNCEMENT_MESSAGE.mutation
    );

    const onAnnouncementMessageAdd = async (messageTranslations) => {
        let sendTotargetChannels = [];
        if (isEmailButtonClicked)
            sendTotargetChannels.push(targetChannelType.EMAIL);
        if (isMessengerButtonClicked)
            sendTotargetChannels.push(targetChannelType.FB_MESSENGER);

        if (sendTotargetChannels.length <= 0)
            return setTextAreaError(t("chooseOneTarget"));
        if (textAreaMessage.length > textAreaMaxLength)
            return setTextAreaError(t("limitExceeded"));
        else
            setTextAreaError("");

        try {
            await queryCreators.ADD_ANNOUNCEMENT_MESSAGE.mutate(addAnnouncementMessage)({
                announcement: {
                    msgTxt: textAreaMessage,
                    targetChannels: sendTotargetChannels,
                    messageTranslations,
                    eventID,
                    targetUsers: checked ? CHECKED_IN_USERS : ALL_USERS,
                    fbMessageType: announcementMessageType.value,
                }
            },
                {
                    eventID
                }
            );
        } catch (error) {
            const errorCode = _get(error, "graphQLErrors[0].extensions.code.errorCode");
            switch (errorCode) {
                case "CANNOT_CONNECT":
                    showGlobalSnackbar("Can't connect to Facebook page. Try reconnecting", "error")
                    break;

                default:
                    showGlobalSnackbar(t("faildCreateError"), "error")
                    break;
            }
        }

        setTextAreaMessage("");
        setIsEmailButtonClicked(true);
        setIsMessengerButtonClicked(false);
    }
    const fbMessengerToggleButton = (<ToggleButton
        disabled={announcementsLeft <= 0}
        classes={{
            root: classes.sentToToggle,
            selected: classes.selected,
        }}
        value="messenger"
        selected={isMessengerButtonClicked}
        onChange={() => {
            if (fbPageConnectionStatus === "NEEDS_RECONNECT" || !_get(connectedFbPage, "pageId")) {
                setIsConnectFacebookModalOpen(true);
                return;
            }
            setIsMessengerButtonClicked(!isMessengerButtonClicked);
        }}
    >
        <MessengerIcon />
        {
            (isMessengerButtonClicked && announcementsLeft > 0) &&
            <CheckCircleIcon
                className={classes.toggleCheckIcon}
            />
        }

    </ToggleButton>)

    return (
        <Fragment>
            <div className={classes.titleBar}>
                <Grid container alignItems="center">
                    <Grid container item xs={6}>
                        <Typography variant="h5" noWrap>
                            {t("engagement")}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            {
                announcementsError || automatedMessagesError || connectedFbPageError ? <ErrorPage /> :
                    announcementsLoading || automatedMessagesLoading || fetchConnectedFbPageLoading ?
                        <Grid
                            item
                            container
                            alignItems="center"
                            justify="center"
                            className={classes.spinnerContainer}
                        >
                            <CircularProgress thickness={7} />
                        </Grid>
                        :
                        <Fragment>

                            <Grid
                                className={classes.headerContainer}
                            >
                                <DividerWithTitle title={t("automatedMessages")} />
                            </Grid>

                            <Grid container spacing={6} className={classes.container}>
                                <AutomatedMessagesSetting
                                    title={t("registrationConfirmation")}
                                    automatedMessage={automatedMessage}
                                    isAlwaysOn
                                    messageType={REGISTRATION_CONFIRMATION}
                                    eventID={eventID}
                                    tooltipMessage={t("sendRegistrationMessage")}
                                    isfbPageNotConnected={fbPageConnectionStatus === "NEEDS_RECONNECT" || !_get(connectedFbPage, "pageId")}
                                />
                                <AutomatedMessagesSetting
                                    title={t("checkinGreeting")}
                                    automatedMessage={automatedMessage}
                                    messageType={CHECKIN_GREETING}
                                    eventID={eventID}
                                    tooltipMessage={t("sendCheckInMessage")}
                                    enableCustomizeMessage
                                    languages={eventLanguages}
                                    isfbPageNotConnected={fbPageConnectionStatus === "NEEDS_RECONNECT" || !_get(connectedFbPage, "pageId")}
                                />
                                {isLoggedInUserWhiteListed && (
                                    <AutomatedMessagesSetting
                                        title={`${t("exhibitorContacts")}`}
                                        isMessengerOnly
                                        automatedMessage={automatedMessage}
                                        messageType={EXHIBITOR_CONTACTS}
                                        eventID={eventID}
                                        tooltipMessage={t("sendVisitorScanMessage")}
                                        isfbPageNotConnected={fbPageConnectionStatus === "NEEDS_RECONNECT" || !_get(connectedFbPage, "pageId")}
                                    />)}
                                <AutomatedMessagesSetting
                                    title={t("eventSummary")}
                                    isEmailOnly
                                    automatedMessage={automatedMessage}
                                    messageType={EVENT_SUMMARY}
                                    eventID={eventID}
                                    tooltipMessage={t("sendEventSummaryMessage")}
                                    isfbPageNotConnected={fbPageConnectionStatus === "NEEDS_RECONNECT" || !_get(connectedFbPage, "pageId")}
                                />
                            </Grid>

                            <Grid
                                className={classes.headerContainer}
                            >
                                <DividerWithTitle title={t("announcements")} />
                            </Grid>

                            <Grid className={classes.announcementsHistory} >
                                {
                                    announcementMessages.length <= 0 ?
                                        <Paper elevation={0} className={classes.emptyAnnouncementsHistory}>
                                            <AnnouncementsIcon className={classes.emptyAnnounceIcon} size={36} />
                                            <Typography noWrap variant="body2">
                                                {t("emptyHistory")}
                                            </Typography>
                                        </Paper> :

                                        <Fragment>
                                            {
                                                announcementMessages.map(singleMessage => {
                                                    return (
                                                        <SingleAnnouncement
                                                            key={singleMessage.id}
                                                            id={singleMessage.id}
                                                            announcementMessage={singleMessage.msgTxt}
                                                            isEmailOnly={singleMessage.targetChannels.length === 1
                                                                && singleMessage.targetChannels[0] === "EMAIL"}
                                                            isMessengerOnly={singleMessage.targetChannels.length === 1 &&
                                                                singleMessage.targetChannels[0] === "FB_MESSENGER"}
                                                            fbMessageDeliveredCount={singleMessage.fbMessageDeliveredCount}
                                                            fbMessageReadCount={singleMessage.fbMessageReadCount}
                                                            emailDeliveredCount={singleMessage.emailDeliveredCount}
                                                            emailReadCount={singleMessage.emailReadCount}
                                                            createdAt={singleMessage.createdAt}
                                                        />
                                                    )
                                                })
                                            }
                                        </Fragment>
                                }

                            </Grid>

                            <Grid container className={classes.newAnnouncementsContainer}>
                                <Paper elevation={3} className={classes.newAnnouncementsSection}>
                                    <div className={classes.newAnnouncementsUpperSection}>
                                        <Typography noWrap className={classes.newAnnouncementsTitle}>
                                            {t("newAnnouncement")}
                                        </Typography>
                                        <div className={classes.announcementsLeft}>

                                            <Tooltip
                                                title={announcementsLeft}
                                                placement="top"
                                                enterTouchDelay={200}
                                                disableHoverListener={disableNewAnnouncementTooltip}
                                                disableFocusListener={disableNewAnnouncementTooltip}
                                                disableTouchListener={disableNewAnnouncementTooltip}
                                            >
                                                <Badge
                                                    max={1000000}
                                                    badgeContent={numberFormatter(announcementsLeft)}
                                                    color="primary"
                                                    classes={{
                                                        badge: classes.notificationBadge,
                                                        root: classes.badgeRoot
                                                    }}
                                                />
                                            </Tooltip>

                                            <Typography
                                                className={
                                                    classNames({
                                                        [classes.textAreaErrorMessage]: announcementsLeft <= 0,
                                                        [classes.smallTitle]: true,
                                                    })
                                                }
                                                noWrap
                                            >
                                                {t("announcementsLeft")}
                                            </Typography>
                                        </div>
                                    </div>
                                    <MentionsField
                                        className={classes.newAnnouncementsTextarea}
                                        disabled={announcementsLeft <= 0}
                                        multiline
                                        variant="outlined"
                                        rows={5}
                                        maxTextLength={textAreaMaxLength} // TODO: we need to research the exact max text length needed
                                        value={textAreaMessage}
                                        onChange={text => {
                                            setTextAreaMessage(text)
                                        }}
                                        InputProps={{
                                            style: {
                                                fontSize: 13,
                                                lineHeight: 'inherit',
                                            }
                                        }}
                                        fullWidth
                                    >
                                        <Mention
                                            markup={`${templatesConfig.PARSING_CONSTANTS.MARKER_START}__id__${templatesConfig.PARSING_CONSTANTS.MARKER_END}`}
                                            trigger={templatesConfig.PARSING_CONSTANTS.TRIGGER}
                                            style={{
                                                fontSize: 'inherit',
                                                lineHeight: 'inherit',
                                                backgroundColor: hexToRgba(theme.palette.primary.main, 15),
                                                borderRadius: 2,
                                                fontWeight: 'inherit'
                                            }}
                                            data={placeholderKeys.map(key => ({
                                                display: parser.getLocalizedContextKey(key, t, false, false),
                                                id: key
                                            }))}
                                            displayTransform={display => parser.getLocalizedContextKey(display, t, false)}
                                            renderSuggestion={({ id }) => parser.getLocalizedContextKey(id, t, false, false)}
                                        />
                                    </MentionsField>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>
                                            <Typography
                                                className={classes.textAreaErrorMessage}
                                            >
                                                {textAreaError}
                                            </Typography>
                                            <FormControlLabel
                                                classes={{
                                                    label: classes.checkedInRegistrantsLabel
                                                }}
                                                control={
                                                    <Checkbox
                                                        checked={checked}
                                                        onChange={() => setChecked(!checked)}
                                                        color="primary"
                                                    />
                                                }
                                                label={t("checkedInOnly")}
                                            />
                                        </div>
                                        <div
                                            className={classes.textAreaHelperText}
                                        >
                                            <Typography
                                                className={
                                                    classNames({
                                                        [classes.textAreaLimit]: true,
                                                        [classes.textAreaErrorMessage]: textAreaMessage.length > textAreaMaxLength,
                                                    })
                                                }
                                            >
                                                {textAreaMessage.length}/{textAreaMaxLength}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className={classes.selectMessageTypeContainer}>
                                        {
                                            isMessengerButtonClicked &&
                                            <FacebookMessageTypes
                                                onMessageTypeChange={setAnnouncementMessageType}
                                                messageType={announcementMessageType}
                                            />
                                        }
                                    </div>

                                    <div className={classes.newAnnouncementsLowerSection}>
                                        <div className={classes.sentTo}>
                                            <Typography className={classes.smallTitle} noWrap>
                                                {t("sendTo")}:
                                            </Typography>
                                            {
                                                fbPageConnectionStatus === "NEEDS_RECONNECT" || !_get(connectedFbPage, "pageId") ?
                                                    <Tooltip
                                                        placement="top"
                                                        title={t("fbIsNotConnected")}
                                                        enterTouchDelay={200}
                                                    >
                                                        {fbMessengerToggleButton}
                                                    </Tooltip>
                                                    :
                                                    fbMessengerToggleButton
                                            }

                                            <ToggleButton
                                                disabled={announcementsLeft <= 0}
                                                classes={{
                                                    root: classes.sentToToggle,
                                                    selected: classes.selected,
                                                }}
                                                value="mail"
                                                selected={isEmailButtonClicked}
                                                onChange={
                                                    () =>
                                                        setIsEmailButtonClicked(!isEmailButtonClicked)
                                                }
                                            >
                                                <MailOutlineIcon />
                                                {
                                                    (isEmailButtonClicked && announcementsLeft > 0) &&
                                                    <CheckCircleIcon
                                                        className={classes.toggleCheckIcon}
                                                    />
                                                }
                                            </ToggleButton>
                                        </div>

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={textAreaMessage.length <= 0 || announcementsLeft <= 0 || (isMessengerButtonClicked && !announcementMessageType.value)}
                                            onClick={() => {
                                                if (eventLanguages.length > 1) {
                                                    setIsModalOpen(true);
                                                } else {
                                                    onAnnouncementMessageAdd()
                                                }
                                            }}
                                        >
                                            {
                                                eventLanguages.length > 1 ?
                                                    <Typography variant="button" noWrap>{t("checkTranslation")}</Typography>
                                                    :
                                                    <Fragment>
                                                        <Typography variant="button" noWrap>{t("announce")}</Typography>
                                                        <AnnouncementsIcon className={classes.announceButtonIcon} size={36} />
                                                    </Fragment>
                                            }
                                        </Button>
                                    </div>
                                </Paper>
                            </Grid>
                            <TranslationPreviewModal
                                isOpen={isModalOpen}
                                onClose={() => setIsModalOpen(false)}
                                languages={eventLanguages}
                                message={textAreaMessage}
                                onAnnouncementMessageAdd={onAnnouncementMessageAdd}
                            />
                        </Fragment >
            }
            <ConnectFacebookModal
                open={isConnectFacebookModalOpen}
                onClose={() => { setIsConnectFacebookModalOpen(false) }}
            />
        </Fragment>
    )
}

const ContextProvider = () => {
    const templateContextVal = useMemo(() => ({
        placeholderKeys: templatesConfig.ENGAGEMENT_DATA_KEYS,
    }), []);
    return (
        <TemplateBuilderContext.Provider
            value={templateContextVal}
        >
            <EventEngagementView />
        </TemplateBuilderContext.Provider>
    );
}

export default ContextProvider;