import React, { useContext, useMemo } from "react";
import TemplateBuilderContext from "../../../../contexts/TemplateBuilderContext";
import textDataParsing from "../../../../modules/textDataParsing";

export default function withParsedValues(TemplateComponent) {
  return React.forwardRef(function ({ movableConfig, ...rest }, ref) {
    const { contentConfig, layoutConfig, parsables } = movableConfig;
    const { contextData } = useContext(TemplateBuilderContext);
    const parsedContentConfig = useMemo(
      () => textDataParsing.parse(parsables, contextData),
      [parsables, contextData]
    );
    return (
      <TemplateComponent
        {...rest}
        ref={ref}
        layoutConfig={layoutConfig}
        contentConfig={{ ...contentConfig, ...parsedContentConfig }}
      />
    );
  });
}
