import { Component } from 'react';
import PropTypes from 'prop-types';


export default class FieldGroup extends Component {
  render() {
    return this.props.children
  }
}

FieldGroup.propTypes = {
  model: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  viewValues: PropTypes.object.isRequired,
  fieldsValidation: PropTypes.object.isRequired,
  onValueUpdate: PropTypes.func.isRequired
}

FieldGroup.defaultProps = {
  model: {}
}
