import React, { useMemo, useState, useEffect } from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";

import TemplateDataPreview from '../TemplateBuilder/TemplateDataPreview';
import CardModal from '../TemplateBuilder/CardModal';
import CustomButton from '../../../components/CustomButtons/Button';
import queryCreators from '../../../apollo/queryCreators';
import TemplateBuilderContext from '../../../contexts/TemplateBuilderContext';
import PrintTemplate from '../BadgeTemplatesView/PrintTemplate';
import { templatesConfig } from '../TemplateBuilder/core/templateUtils';
import textDataParsing from '../../../modules/textDataParsing';
import styles from './styles';

import { useParams } from 'react-router-dom';
import { useLazyQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

const areDependenciesResolved = (dependencies, contextOverrides) => {
    if (!dependencies || !contextOverrides)
        return false;
    for (let i = 0; i < dependencies.length; i++)
        if (!contextOverrides[dependencies[i]])
            return false;
    return true;
};

const useStyles = makeStyles(styles, { name: 'PrintingPreviewModal' });

const PrintingPreviewModal = ({ template, context, open, onClose, target, ...rest }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const { t } = useTranslation('printingPreviewModal');
    const classes = useStyles();
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [contextOverrides, setContextOverrides] = useState(templatesConfig.getContextProps(context, target));
    const [getBadgeTemplates, { data }] =
        useLazyQuery(queryCreators.GET_EVENT_BADGE_TEMPLATES.query, {
            onCompleted: ({ event }) => {
                const { badgeTemplates } = event;
                if (Array.isArray(badgeTemplates))
                    setCurrentlySelectedTemplate(badgeTemplates[0]);
            }
        });
    const { event } = data || {};
    let { badgeTemplates } = event || {};
    badgeTemplates = Array.isArray(badgeTemplates) && badgeTemplates.filter(template => template.movableTemplates.length > 0);
    const { eventID } = useParams();
    const isTemplateExists = Boolean(template);
    useEffect(() => {
        if (!isTemplateExists)
            getBadgeTemplates({ variables: { eventID } });
    }, [isTemplateExists, eventID, getBadgeTemplates]);
    const [currentlySelectedTemplate, setCurrentlySelectedTemplate] = useState();

    const dependencies = useMemo(() => {
        if (template)
            return textDataParsing.getDependencies(template.movableTemplates)
        else
            if (currentlySelectedTemplate)
                return textDataParsing.getDependencies(currentlySelectedTemplate.movableTemplates);
    }, [template, currentlySelectedTemplate]);
    const [isTemplateLoading, setIsTemplateLoading] = useState();
    const canSubmit = () => !isTemplateLoading && areDependenciesResolved(dependencies, contextOverrides);
    return (
        <CardModal
            classes={{
                cardBody: classes.modalCardBody,
            }}
            open={open}
            onClose={onClose}
            title={t('printingPreviewModal:printNameBadge')}
            fullScreen={isMobile}
        >
            <TemplateBuilderContext.Provider
                value={{
                    contextData: contextOverrides,
                    isPreviewing: true
                }}
            >
                {Array.isArray(badgeTemplates) && currentlySelectedTemplate &&
                    <div className={classes.selectContainer}>
                        <TextField
                            fullWidth
                            select
                            label={t('printingPreviewModal:pickTemplate')}
                            value={currentlySelectedTemplate && currentlySelectedTemplate.id}
                            onChange={({ target: { value: id } }) => setCurrentlySelectedTemplate(badgeTemplates.find(template => template.id === id))}
                            InputLabelProps={{
                                shrink: true
                            }}
                        >
                            {badgeTemplates.map(template => (
                                <MenuItem key={template.id} value={template.id}>
                                    {template.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                }
                <TemplateDataPreview
                    open={open}
                    template={template || currentlySelectedTemplate}
                    contextOverrides={contextOverrides}
                    dependencies={dependencies}
                    onChange={change => setContextOverrides({ ...contextOverrides, ...change })}
                    viewerProps={{
                        onLoadingChange: isLoading => setIsTemplateLoading(isLoading)
                    }}
                    showLoadingIndicator={isTemplateLoading}
                    {...rest}
                />
                <div className={classes.btnContainer}>
                    {canSubmit() ?
                        <PrintTemplate template={template || currentlySelectedTemplate} onBeforePrint={onClose}>
                            <CustomButton
                                size="lg"
                                fullWidth
                                color="primary"
                            >
                                {t('printingPreviewModal:print')}
                            </CustomButton>
                        </PrintTemplate>
                        : <CustomButton
                            size="lg"
                            fullWidth
                            color="primary"
                            onClick={() => setShowConfirmationDialog(true)}
                            disabled={isTemplateLoading}
                        >
                            {t('printingPreviewModal:print')}
                        </CustomButton>
                    }
                </div>
                {
                    isMobile && <div className={classes.btnContainer}>
                        <CustomButton
                            fullWidth
                            simple
                            color="primary"
                            size="lg"
                            onClick={onClose}
                        >
                            {t('printingPreviewModal:cancel')}
                        </CustomButton>
                    </div>
                }
                <Dialog
                    open={showConfirmationDialog}
                    onClose={() => setShowConfirmationDialog(false)}
                >
                    <DialogTitle>{t('printingPreviewModal:confirmationDialogTitle')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('printingPreviewModal:confirmationDialogText')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={() => setShowConfirmationDialog(false)}>
                            {t('printingPreviewModal:cancel')}
                        </Button>
                        <PrintTemplate template={template || currentlySelectedTemplate} onBeforePrint={onClose}>
                            <Button color="primary" autoFocus variant="contained">
                                {t('printingPreviewModal:print')}
                            </Button>
                        </PrintTemplate>
                    </DialogActions>
                </Dialog>
            </TemplateBuilderContext.Provider>
        </CardModal>
    );
};

export default PrintingPreviewModal;