import React, { useState } from "react"
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-apollo";
import { withRouter } from "react-router";
import { useParams } from "react-router-dom";
import queryString from "query-string";
import _get from "lodash/get";
// material-ui components
import { useTheme } from "@material-ui/styles";
import makeStyles from "@material-ui/core/styles/makeStyles"
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from '@material-ui/lab/Alert';

import FacebookIcon from '@material-ui/icons/Facebook';

import CardBody from "../../../../components/Card/CardBody.jsx";
import CardHeader from "../../../../components/Card/CardHeader.jsx";
import Card from "../../../../components/Card/Card.jsx";
import CustomButton from "../../../../components/CustomButtons/Button.jsx";
import { showGlobalSnackbar } from "../../../../components/GlobalSnackbar"
import queryCreators from "../../../../apollo/queryCreators";
import styles from "./styles"

const useStyles = makeStyles(styles, { name: "FbLoginModal" });

const FbLoginModal = ({
    history,
    isOpen = true,
    onClose,
}) => {
    const { t } = useTranslation("FbLoginModal");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const classes = useStyles();
    const { eventID } = useParams(); // the eventID gets passed from the currentURL
    const [connectingPageId, setConnectingPageId] = useState("");
    const { access_token: shortLivedAccessToken } = queryString.parse(history.location.hash);

    const { loading: fetchFbPagesLoading, error, data } = useQuery(
        queryCreators.GET_FB_ACCOUNT_PAGES.query,
        {
            skip: !shortLivedAccessToken,
            variables: {
                eventId: eventID,
                token: shortLivedAccessToken,
            },
            onError: (error) => {
                const errorCode = _get(error, "graphQLErrors[0].extensions.code.errorCode");
                switch (errorCode) {
                    case "ALREADY_CONNECTED_TO_FB_PAGE":
                        showGlobalSnackbar(t("fbPageAlreadyConnected"), "error")
                        onClose(true);
                        break;

                    default:
                        showGlobalSnackbar(t("errorAtListing"), "error");
                        break;
                }
            }
        }
    );

    const fbPages = _get(data, "listAccountFbPages", []);

    const [connectFbPage, { loading: connectingPageLoading }] = useMutation(
        queryCreators.CONNECT_FB_PAGE.mutation,
        {
            onCompleted: () => {
                onClose(true);
                showGlobalSnackbar(t("connectedSuccessfully"))
            },
            onError: async (error) => {
                const errorCode = _get(error, "graphQLErrors[0].extensions.code.errorCode");
                switch (errorCode) {
                    case "REQUEST_LIMIT_EXCEEDED":
                        showGlobalSnackbar(t("tryLater"), "error");
                        break;
                    default:
                        showGlobalSnackbar(t("errorAtConnection"), "error");
                        break;
                }
            }
        }
    );
    const connectFbPageHandler = async (pageId) => {
        try {
            await queryCreators.CONNECT_FB_PAGE.mutate(connectFbPage)({
                token: shortLivedAccessToken,
                pageId: pageId,
                eventId: eventID
            },
                {
                    eventId: eventID,
                }
            );
        } catch (error) {

        }
    }

    return (
        <Dialog
            open={isOpen && !error}
            onClose={onClose}
            classes={{
                paper: fullScreen ? classes.paperFullScreen : classes.paper
            }}
            fullScreen={fullScreen}
        >
            <Card className={fullScreen ? classes.cardFullScreen : classes.card}>

                <CardHeader className={classes.cardHeader} color="primary">
                    <Typography variant="subtitle1">
                        {t("fbPagesList")}
                    </Typography>
                </CardHeader>
                <Card className={classes.cardParent} >
                    <CardBody className={classes.cardBody}>
                        {
                            fetchFbPagesLoading ?
                                <CircularProgress
                                    thickness={7}
                                    size={24}
                                    className={classes.circularProgress}
                                />
                                :
                                (fbPages.length > 0 ?
                                    fbPages.map(page => {
                                        return (
                                            <div
                                                key={page.pageId}
                                                className={classes.fbPageContainerWithError}
                                            >
                                                <div className={classes.fbPageContainer}>
                                                    <img
                                                        className={classes.fbPageImage}
                                                        src={`https://graph.facebook.com/${page.pageId}/picture?height=100&width=100`}
                                                        alt="page"
                                                    />
                                                    <Typography noWrap className={classes.fbPageName}>
                                                        {page.name}
                                                    </Typography>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={connectingPageLoading || page.missingPermissions.length > 0}
                                                        onClick={() => {
                                                            connectFbPageHandler(page.pageId)
                                                            setConnectingPageId(page.pageId)
                                                        }}
                                                    >
                                                        {t("connect")}
                                                        {
                                                            (connectingPageLoading && page.pageId === connectingPageId) &&
                                                            <CircularProgress
                                                                thickness={7}
                                                                size={24}
                                                                className={classes.circularProgressWithColor}
                                                            />
                                                        }
                                                    </Button>
                                                </div>
                                                {
                                                    page.missingPermissions.length > 0 &&
                                                    <div>
                                                        <Alert severity="warning">{t("contactPageAdmin")}</Alert>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                    :
                                    <div // no pages to list message
                                        className={classes.noPagesFound}
                                    >
                                        <FacebookIcon className={classes.fbIcon} />
                                        <Typography variant="h6">{t("noPagesToList")}</Typography>
                                    </div>
                                )
                        }
                    </CardBody>
                </Card>
                {fullScreen && (
                    <CustomButton
                        fullWidth={true}
                        simple
                        color="primary"
                        size="lg"
                        onClick={onClose}
                        className={classes.noMargins}
                    >
                        {t("cancel")}
                    </CustomButton>
                )}
            </Card>
        </Dialog >
    )
}
export default withRouter(FbLoginModal);