import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

import queryCreators from '../../apollo/queryCreators';
import TemplateBuilderIcon from 'components/Icons/TemplateBuilderIcon';
import PrintingPreviewModal from '../EventPage/PrintingPreviewModal';
import styles from './exhibitorsActionsMenuStyles';

import { useMutation, useQuery } from 'react-apollo';
import { useParams } from 'react-router-dom';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { errorTypes, invitationType } from "../../enums";

const { INVITATION_ALREADY_ACCEPTED } = errorTypes;
const { ORGANIZER_TO_EXHIBITOR } = invitationType;

const useStyles = makeStyles(styles, { name: "ExhibitorsActionsMenu" });

const ExhibitorsActionsMenu = ({ exhibitor, onError, objType, onDeleteFailure }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState();
    const [open, setOpen] = useState(false);
    const { eventID } = useParams();
    const [showPrintingModal, setShowPrintingModal] = useState();
    const { t } = useTranslation('exhibitorsView');
    const isDeleteable = !exhibitor.isActivated;

    const [deleteExhibitor, { loading: deleteExhibitorLoading }] = useMutation(
        queryCreators.DELETE_EXHIBITOR.mutation,
        {
            onError: () => onError("cantDeleteExhibitor")
        }
    );

    const [deleteInvitation, { loading: deleteInvitationLoading }] = useMutation(
        queryCreators.DELETE_INVITATION.mutation,
        {
            onError: (error) => {
                const errorCode = _get(error, "graphQLErrors[0].extensions.exception.errorCode");
                if (errorCode === INVITATION_ALREADY_ACCEPTED) {
                    onError("invAlreadyAccepted")
                    onDeleteFailure();
                } else
                    onError("cantDeleteInvitation")
            }
        }
    );

    const { loading: eventDataFetchLoading, data } = useQuery(
        queryCreators.EXHIBITORS_PRINTING_CONTEXT_DATA.query,
        {
            variables: {
                eventID
            }
        }
    );

    const badgeTemplates = _get(data, 'event.badgeTemplates');
    const nonEmptyBadgeTemplates = badgeTemplates && badgeTemplates.filter(template => template.movableTemplates.length > 0);
    const hasNonEmptyBadgeTemplates = nonEmptyBadgeTemplates && nonEmptyBadgeTemplates.length > 0;

    const handleExhibitorDelete = () => {
        queryCreators.DELETE_EXHIBITOR.mutate(deleteExhibitor)(
            {
                id: exhibitor.id,
                eventID: eventID,
            },
            {
                eventID
            }
        );
    };

    const handleInvitationDelete = () => {
        queryCreators.DELETE_INVITATION.mutate(deleteInvitation)(
            { id: exhibitor.id },
            {
                eventID,
                type: ORGANIZER_TO_EXHIBITOR
            }
        );
    };

    return (
        <div>
            <IconButton
                className={classes.button}
                onClick={evt => { setOpen(true); setAnchorEl(evt.currentTarget); }}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setOpen(false)}
                PaperProps={{
                    className: classes.paper
                }}
            >
                <div className={classes.menuItemContainer}>
                    <MenuItem
                        className={classes.deleteMenuItem}
                        onClick={() =>
                            objType === "Exhibitor" ?
                                handleExhibitorDelete() :
                                handleInvitationDelete()
                        }
                        disabled={!isDeleteable || deleteExhibitorLoading || deleteInvitationLoading}
                    >
                        <ListItemIcon >
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit">
                            {t('exhibitorsView:delete')}
                        </Typography>
                    </MenuItem>
                    {isDeleteable || <ListItemIcon classes={{
                        root: classes.infoIconRoot
                    }}>
                        <Tooltip
                            title={t('exhibitorsView:cantDeleteActivatedExhibitor')}
                            enterTouchDelay={200}
                        >
                            <InfoIcon fontSize="small" />
                        </Tooltip>
                    </ListItemIcon>}
                    {(deleteExhibitorLoading || deleteInvitationLoading) &&
                        <div className={classes.menuItemProgress}>
                            <CircularProgress size={24} />
                        </div>
                    }
                </div>
                <div className={classes.menuItemContainer}>
                    <MenuItem
                        onClick={() => { setShowPrintingModal(true); setOpen(false); }}
                        disabled={eventDataFetchLoading || !hasNonEmptyBadgeTemplates}
                    >
                        <ListItemIcon>
                            <TemplateBuilderIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit" className={hasNonEmptyBadgeTemplates ? classes.printText : ''}>
                            {t('exhibitorsView:printBadge')}
                        </Typography>
                    </MenuItem>
                    {!eventDataFetchLoading && !hasNonEmptyBadgeTemplates &&
                        <ListItemIcon classes={{
                            root: classes.infoIconRoot
                        }}>
                            <Tooltip
                                title={t('exhibitorsView:noBadgesToPrint')}
                                enterTouchDelay={200}
                            >
                                <InfoIcon fontSize="small" />
                            </Tooltip>
                        </ListItemIcon>
                    }
                    {eventDataFetchLoading && <CircularProgress size={24} className={classes.menuItemProgress} />}
                </div>
            </Menu>
            {showPrintingModal && <PrintingPreviewModal
                target="exhibitor"
                open={showPrintingModal}
                onClose={() => {
                    setShowPrintingModal(false);
                    setOpen(false);
                }}
                context={{
                    event: data.event,
                    exhibitor
                }}
            />}
        </div>
    );
};

export default ExhibitorsActionsMenu;