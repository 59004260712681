import React, { Fragment, useContext } from "react";
import { get as _get } from "lodash";
import { useTranslation } from "react-i18next";
import classNames from "classnames";


import { useQuery } from "react-apollo";
import queryCreators from "../../../apollo/queryCreators";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import EventIcon from "@material-ui/icons/Event";

import EventCard from "views/EventsPage/EventCard.jsx";
import AuthenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext";
import ErrorPage from "../../EventPage/ErrorPage";
import styles from './styles';

const useStyles = makeStyles(styles, { name: 'Events' })

function Events() {
  const classes = useStyles();
  const { t } = useTranslation("eventsPage");
  const { userInfo } = useContext(AuthenticationContext);
  const { loading, error, data } = useQuery(queryCreators.GET_ACC_WITH_EVENTS, {
    variables: {
      email: userInfo && userInfo.email
    }
  });

  if (loading)
    return (
      <Grid
        item
        container
        alignItems="center"
        justify="center"
        className={classes.spinnerContainer}
      >
        <CircularProgress thickness={7} />
      </Grid>
    );
  if (error) {
    return <ErrorPage />;
  }
  return (
    <div className={classes.body}>
      <main className={classNames(classes.content)}>
        <Grid container spacing={3} className={classNames({ [classes.container]: data.account.events.length <= 0 })}>
          <Fragment>
            {(data &&
              data.account &&
              Array.isArray(data.account.events) &&
              data.account.events.length > 0) || (
                <div className={classes.eventsNoData}>
                  <EventIcon style={{ fontSize: 60 }} />
                  <Typography color="inherit" variant="h6" noWrap>
                    {t("eventsPage:noEvents")}
                  </Typography>
                </div>
              )}
            {_get(data, "account.events", []).map(eventProps => (
              <Grid key={eventProps.id} item xs={12} sm={6} md={4} lg={3} xl={2}>
                <EventCard {...eventProps} />
              </Grid>
            ))}
          </Fragment>
        </Grid>
      </main>
    </div>
  );
};

export default Events;
