const drawerWidth = 240;

const styles = theme => ({
  container: {
    height: "100%",
    background: theme.palette.background.paper,
    paddingTop: theme.spacing(2)
  },
  inputContainer: {
    marginBottom: theme.spacing(1.5),
    cursor: "pointer",
    background: theme.palette.background.paper
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  titleBar: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    borderBottom: `solid 1px ${theme.palette.divider}`,
    color: theme.palette.text.primary
  },
  notParseableForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.grey[300],
    flexGrow: 1,
    width: "80%"
  },
  saveButton: {
    marginLeft: theme.spacing(1)
  },
  translationsButton: {
    marginRight: theme.spacing(1),
  },
  translateIcon: {
    height: 17,
    marginRight: theme.spacing(0.5),
  },
});

export default styles;
