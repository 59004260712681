const styles = theme => ({
    paper: {
        overflowY: "inherit",
    },
    paperFullScreen: {
        overflowY: "inherit",
    },
    cardFullScreen: {
        marginBottom: 0,
        paddingTop: 50,
        boxShadow: "none",
        overflowY: "auto",
        overflowX: "hidden",
    },
    card: {
        boxShadow: "none",
        width: 500,
        marginTop: 0,
        maxHeight: "calc(100vh - 64px)",
        overflowY: "inherit",
        marginBottom: -34,
    },
    cardHeader: {
        width: "auto",
        textAlign: "center",
        marginLeft: 20,
        marginRight: 20,
        marginTop: -34,
        padding: "20px 0",
        marginBottom: 15,
    },
    cardParent: {
        minHeight: 280,
        marginTop: 0,
        overflowY: "auto",
        marginBottom: 0,
        boxShadow: "unset",
    },
    cardBody: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    noPagesFound: {
        flexGrow: 1,
        flexDirection: "column",
        color: theme.palette.divider,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    circularProgress: {
        marginLeft: theme.spacing(1),
    },
    circularProgressWithColor: {
        color: theme.palette.primary.contrastText,
        marginLeft: theme.spacing(1),
    },
    fbPageContainerWithError: {
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(2),
        width: "100%",
    },
    fbPageContainer: {
        display: "flex",
        alignItems: "center",
        marginBottom: 8,
        color: theme.palette.text.primary,
        justifyContent: "center",
    },
    fbPageImage: {
        marginRight: theme.spacing(1),
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 10,
        height: 100,
        width: 100,
    },
    fbPageName: {
        marginRight: theme.spacing(1),
        maxWidth: 120,
        width: "100%",
    },
    fbIcon: {
        fontSize: 60,
    },
    noMargins: {
        margin: 0,
    },
})

export default styles;
