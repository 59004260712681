import React, { Fragment, useState } from "react";

import compose from "recompose/compose";
import { withTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/ar";

import withStyles from "@material-ui/core/styles/withStyles";
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";

import Autocomplete from '@material-ui/lab/Autocomplete';

import { DateTimePicker } from "@material-ui/pickers";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomButton from "components/CustomButtons/Button.jsx";
import LogoPicker from "components/LogoPicker";
import withAuth from "contexts/AuthenticationContext/withAuth";

import _get from "lodash/get";

const styles = theme => ({
  imageUploadContainer: {
    width: 150,
    height: 150,
    margin: "0 auto 30px auto",
  },
  datePicker: {
    marginTop: 16
  },
  datePickerRoot: {
    fontSize: theme.typography.body2.fontSize,
    "&::before": {
      height: "1px !important",
      backgroundColor: "#D2D2D2"
    }
  },
  form: {
    marginBottom: theme.spacing(4)
  },
  languageSelect: {
    margin: "11px 0 0 0",
  },
  noOptionsText: {
    display: "flex",
    justifyContent: "flex-start",
  },
  defaultLanguageText: {
    fontSize: "smaller",
    fontStyle: "italic",
    marginLeft: 4,
  },
  chipTextContainer: {
    display: "flex",
    alignItems: "baseline"
  },
});

const CreateEventForm = ({ classes, t, onSubmit, userInfo, supportedLanguages }) => {

  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [isSelectLanguagesBlur, setIsSelectLanguagesBlur] = useState(false);
  const [logo, setLogo] = useState({
    file: null,
    name: null,
    type: null
  });

  const isSelectedLanguagesValid = Object.keys(selectedLanguages).length <= 3 && Object.keys(selectedLanguages).length > 0;

  const isFormValid = () => {
    const dataUrl = _get(logo, "file");
    if (!dataUrl || !name || !startDate || !endDate || (!isSelectedLanguagesValid && supportedLanguages.length > 0)) return false;
    return true;
  };

  const handleInputChange = event => {
    setName(event.target.value);
  };

  const handleLogoChange = logo => {
    if (logo)
      setLogo({
        file: logo.file,
        name: logo.name,
        type: logo.type
      })
  }

  const handleDateChange = (date, inputName) => {
    if (inputName === "startDate") {
      setStartDate(date)

    } else {
      setEndDate(date)
    }
  };

  const dataUrl = _get(logo, "file");

  return (
    <>
      <form
        noValidate // because we rely on our own validation
        onSubmit={e => {
          e.preventDefault(); // so the app doesn't reload after each submit
          if (isFormValid()) {
            onSubmit({
              name,
              logo,
              startDate: startDate,
              endDate: endDate,
              ownerID: userInfo && userInfo.id,
              languages: selectedLanguages
            })
            return true;
          }
          return false;
        }}
      >
        <div className={classes.form}>
          <div className={classes.imageUploadContainer}>
            <LogoPicker url={dataUrl} onChange={handleLogoChange} />
          </div>
          <CustomInput
            labelText={t("createEventForm:name")}
            id="name"
            formControlProps={{
              fullWidth: true,
              required: true
            }}
            InputProps={{
              type: "text",
              value: name,
              onChange: handleInputChange,
              name: "name"
            }}
            labelProps={{
              shrink: true
            }}
          />
          <CustomInput
            labelText={t("createEventForm:startDate")}
            id="startDate"
            formControlProps={{
              fullWidth: true,
              required: true
            }}
            labelProps={{
              shrink: true
            }}
            renderInput={
              <DateTimePicker
                cancelLabel={t("createEventForm:cancel")}
                okLabel={t("createEventForm:ok")}
                className={classes.datePicker}
                value={startDate}
                onChange={startDate =>
                  handleDateChange(startDate, "startDate")
                }
                InputProps={{
                  classes: {
                    root: classes.datePickerRoot
                  }
                }}
                format="DD/MM/YY - hh:mm A"
                disablePast
                maxDate={endDate ? moment(endDate) : undefined}
              />
            }
          />
          <CustomInput
            labelText={t("createEventForm:endDate")}
            id="endDate"
            formControlProps={{
              fullWidth: true,
              required: true
            }}
            labelProps={{
              shrink: true
            }}
            renderInput={
              <DateTimePicker
                cancelLabel={t("createEventForm:cancel")}
                okLabel={t("createEventForm:ok")}
                className={classes.datePicker}
                value={endDate}
                onChange={endDate => handleDateChange(endDate, "endDate")}
                strictCompareDates
                InputProps={{
                  classes: {
                    root: classes.datePickerRoot
                  }
                }}
                minDate={startDate ? moment(startDate) : undefined}
                format="DD/MM/YY - hh:mm A"
                disablePast
              />
            }
          />

          <Autocomplete
            noOptionsText={t("createEventForm:noOptions")}
            multiple
            disableCloseOnSelect
            size="small"
            options={supportedLanguages}
            getOptionLabel={(option) => option.name}
            classes={{
              root: classes.languageSelect,
              noOptions: classes.noOptionsText
            }}
            onChange={(event, value) => {
              const languagesValues = value.map(value => {
                return value.language;
              })
              setSelectedLanguages(languagesValues)
            }}
            renderOption={(option, { selected }) => (
              <Fragment>
                <Checkbox
                  color="primary"
                  checked={selected}
                />
                {option.name}
              </Fragment>
            )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  size="small"
                  label={
                    <div className={classes.chipTextContainer}>
                      {option.name}
                      {
                        index === 0 ?
                          <Typography className={classes.defaultLanguageText}>
                            ({t("createEventForm:defaultLanguage")})
                          </Typography>
                          :
                          undefined
                      }
                    </div>
                  }
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                required
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
                label={t("createEventForm:eventLanguages")}
                placeholder={t("createEventForm:chooseThreeLanguages")}
                onBlur={() => setIsSelectLanguagesBlur(true)}
                error={(!isSelectedLanguagesValid && supportedLanguages.length > 0) && isSelectLanguagesBlur}
              />
            )}
          />
          {
            ((!isSelectedLanguagesValid && supportedLanguages.length > 0) && isSelectLanguagesBlur) &&
            <FormHelperText error>{t("createEventForm:betweenThreeAndOne")}</FormHelperText>
          }
        </div>
        <CustomButton
          fullWidth={true}
          color="primary"
          size="lg"
          disabled={!isFormValid()}
          type="submit"
        >
          {t("createEventForm:create")}
        </CustomButton>
      </form >
    </>
  );
}

export default compose(
  withAuth,
  withTranslation("createEventsForm"),
  withStyles(styles)
)(CreateEventForm);
