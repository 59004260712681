const styles = theme => ({
    inputLabel: {
        marginTop: theme.spacing(2),
        fontSize: 12,
    },
    slider: {
        maxWidth: 200
    },
});

export default styles;
