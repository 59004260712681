const styles = theme => ({
    divider: {
        flex: 1
    },
    dividerContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginLeft: theme.spacing(0.75),
        marginRight: theme.spacing(0.75)
    }
});

export default styles;
