import TextTemplate from "../MovableTemplate/templates/TextTemplate";
import ImageTemplate from "../MovableTemplate/templates/ImageTemplate";
import QRCodeTemplate from "../MovableTemplate/templates/QRCodeTemplate";
import ShapeTemplate from "../MovableTemplate/templates/ShapeTemplate";
import QRCodeTemplateSettings from "../../utils/ElementSettingsPanel/QRCodeTemplateSettings";
import ImageTemplateSettings from "../../utils/ElementSettingsPanel/ImageTemplateSettings";
import TextTemplateSettings from "../../utils/ElementSettingsPanel/TextTemplateSettings";
import ShapesTemplateSettings from "../../utils/ElementSettingsPanel/ShapesTemplateSettings";

import _forEach from "lodash/forEach";
import _includes from "lodash/includes";
import _get from "lodash/get";
import flatten from "flat";

export const TEMPLATE_TYPES = {
  TEXT: "text",
  IMAGE: "image",
  QRCODE: "qrcode",
  SHAPE: "shape"
};

export const PARSING_CONSTANTS = {
  MARKER_START: "@[",
  MARKER_END: "]",
  PARSING_SEPARATOR: ".",
  PLACEHOLDER_SEPARATOR: "-",
  PARSABLES_FIELD_NAME: "parsables",
  TRIGGER: '@'
};

export const CONTEXT_DATA_KEYS = [
  "target.id",
  "target.name",
  "event.id",
  "event.name",
  "event.logoURL",
  "organizer.firstName",
  "organizer.lastName",
  "registrant.id",
];

export const ENGAGEMENT_DATA_KEYS = [
  "target.id",
  "target.name",
  "event.id",
  "event.name",
]

/**
 * Map data object 
 * @param  {Object} data           [Object containing data of event, exhibitor, registrant, and organizer (can contain one or more)]
 * @param  {String} target         [Target object that needs the data]
 * @return {Object}                [Object containing data flattened]
 */
export function getContextProps(data, target) {
  let targetedId = data[target] && data[target].id;
  let targetedName;

  if (data[target])
    switch (target) {
      case "registrant":
        targetedId = data[target].eventRegistrationID
        targetedName = data[target].name || data[target].invitedName;
        break;
      case "organizer":
        targetedName = data[target].fullName || data[target].invitedName;
        break;
      case "exhibitor":
        targetedName = data[target].name || data[target].invitedName;
        break;
      default:
        break;
    }
  const res = {};
  const flattenedContext = flatten(data);
  const targetedFlattenedContext = { "target.name": targetedName, "target.id": targetedId, ...flattenedContext, "registrant.id": _get(data, "registrant.eventRegistrationID"), }
  _forEach(targetedFlattenedContext, (value, key) => {
    if (_includes(CONTEXT_DATA_KEYS, key))
      res[key] = value;
  });
  return res;
};

export const templates = {
  [TEMPLATE_TYPES.TEXT]: {
    defaults: {
      layoutConfig: {
        dimensions: {
          height: 70,
          width: 300
        },
        origin: {
          left: 0,
          top: 0
        }
      },
      contentConfig: {
        fontSize: 12
      },
      parsables: {}
    },
    templateComponent: TextTemplate,
    settingsComponent: TextTemplateSettings
  },
  [TEMPLATE_TYPES.IMAGE]: {
    defaults: {
      layoutConfig: {
        dimensions: {
          height: 100,
          width: 100
        },
        origin: {
          left: 0,
          top: 0
        }
      },
      contentConfig: {
        objectFit: "contain",
      },
      parsables: {}
    },
    templateComponent: ImageTemplate,
    settingsComponent: ImageTemplateSettings
  },
  [TEMPLATE_TYPES.QRCODE]: {
    defaults: {
      layoutConfig: {
        dimensions: {
          height: 100,
          width: 100
        },
        origin: {
          left: 0,
          top: 0
        }
      },
      keepDimensionsRatio: true,
      contentConfig: {},
      parsables: {}
    },
    templateComponent: QRCodeTemplate,
    settingsComponent: QRCodeTemplateSettings
  },
  [TEMPLATE_TYPES.SHAPE]: {
    defaults: {
      layoutConfig: {
        dimensions: {
          height: 100,
          width: 200
        },
        origin: {
          left: 0,
          top: 0
        }
      },
      keepDimensionsRatio: false,
      contentConfig: {
        backgroundColor: "#F2F2F2",
        borderColor: "#e2e2e2",
        borderRadius: { topLeft: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 },
      },
      parsables: {}
    },
    templateComponent: ShapeTemplate,
    settingsComponent: ShapesTemplateSettings
  }

};
