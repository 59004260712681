const styles = theme => ({
    cardHeader: {
        width: "auto",
        textAlign: "center",
        marginLeft: 20,
        marginRight: 20,
        marginTop: -55,
        padding: "20px 0",
        marginBottom: 15
    },
    paper: {
        overflowY: "inherit",
        width: 440,
    },
    paperFullScreen: {
        overflowY: "inherit",
    },
    cardFullScreen: {
        marginBottom: 0,
        paddingTop: 50,
        boxShadow: "none",
        overflowY: "auto"
    },
    card: {
        marginBottom: 0,
        boxShadow: "none"
    },
    bodyContainer: {
        display: "flex",
        alignItems: "flex-end",
        padding: 20,
        flexWrap: "wrap",
        justifyContent: "center",
    },
    formFields: {
        display: "flex",
        flexDirection: "column",
    },
    selectContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginTop: theme.spacing(1),
    },
    circularProgressContainer: {
        display: "flex",
        justifyContent: "center",
    },
    circularProgress: {
        marginLeft: theme.spacing(1),
    },
});

export default styles;
