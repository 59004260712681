const styles = theme => ({
    link: {
        "&:hover": {
            color: "#FFFFFF"
        },
        color: "#FFFFFF"
    },
    centerText: {
        textAlign: "center"
    }
});

export default styles;
