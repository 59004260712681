const styles = theme => ({
    mobileRow: {
        height: 200,
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 12,
        overflow: "hidden",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: theme.palette.action.hover,
        },
    },
    basicInfoList: {
        wordBreak: "break-all",
    },
    actionsSection: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        flexDirection: "row-reverse",
        marginBottom: theme.spacing(2),
    },
    addedByText: {
        position: "absolute",
        right: 8,
        left: 8,
        bottom: 4,
        fontSize: 12,
        color: theme.palette.text.disabled,
        textAlign: "right",
        maxWidth: "fit-content",
        background: theme.palette.action.selected,
        padding: "2px 8px",
        borderRadius: 50,
        marginLeft: "auto",
    },
});

export default styles;
