import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function LogoIcon(props) {
    return (
        <SvgIcon
            {...props}
            style={{
                ...(props.style || {}),
                fill: 'none',
            }}
            fill="none"
            viewBox="0.1 1 29.5 29.5" >
            <path d="M1.45714 23V6.2C1.45714 3.99086 3.248 2.2 5.45714 2.2H24.6C26.8091 2.2 28.6 3.99086 28.6 6.2V25.4C28.6 27.6091 26.8091 29.4 24.6 29.4H0.100006" stroke="currentcolor" strokeWidth="2.7" />
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
        </SvgIcon>
    );
}

