const styles = theme => {
    const appBarHeight = 64;
    const cardHeaderHeight = 68;
    return {
        paper: {
            overflowY: "inherit",
            direction: theme.direction,
            flip: false,
        },
        cardFullScreen: {
            marginBottom: 0,
            paddingTop: (cardHeaderHeight / 2) + theme.spacing(2.5),
            marginTop: 0,
            boxShadow: "none",
            overflowY: "auto"
        },
        card: {
            maxHeight: `calc(100vh - ${appBarHeight}px);`,
            marginTop: 0,
            overflowY: 'inherit',
            width: 500,
            marginBottom: -(cardHeaderHeight / 2),
        },
        cardHeader: {
            width: "auto",
            textAlign: "center",
            marginLeft: "20px",
            marginRight: "20px",
            padding: "20px 0",
            marginBottom: "15px",
            marginTop: -(cardHeaderHeight / 2),
        },
        cardHeaderTitle: {
            color: theme.palette.primary.contrastText,
        },
        noMargins: {
            margin: 0
        },
        cardBody: {
            overflowY: 'auto',
        }
    }
};

export default styles;
