import hexToRgba from "hex-rgba";

const styles = theme => ({
    mainElement: {
        marginTop: theme.spacing(2.25),
        width: "100%",
    },
    toggleButtonGroup: {
        marginTop: theme.spacing(3.75),
        justifyContent: "flex-end"
    },
    modeDropdown: {
        marginTop: theme.spacing(3.75)
    },
    uploadImagePanel: {
        width: '90%',
        height: 100,
        margin: `${theme.spacing(3.75)}px auto`,
    },
    tabTextColor: {
        "&$selected": {
            color: theme.palette.primary.main,
            background: hexToRgba(theme.palette.primary.main, 15)
        }
    },
    tabLeft: {
        minWidth: 45,
        minHeight: 45,
        borderRadius: "4px 0px 0px 4px"
    },
    tabRight: {
        minWidth: 45,
        minHeight: 45,
        borderRadius: "0px 4px 4px 0px"
    },
    bodyContainer: {
        padding: "0 20px"
    },
    selected: {}
});

export default styles;
