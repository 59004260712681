import React, { useState, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';

import SharedSettings from "../SharedSettings";
import UplodImageIcon from '../UplodImageIcon';
import LinkIcon from "../LinkIcon";
import LogoPicker from '../../../../../../components/LogoPicker';
import { MentionsField, Mention } from '../../../../../../components/MentionsField';
import { templatesConfig, parser } from '../../../core/templateUtils';
import TemplateBuilderContext from '../../../../../../contexts/TemplateBuilderContext';

import validDataUrl from "valid-data-url";
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useTheme from "@material-ui/core/styles/useTheme";
import { useTranslation } from "react-i18next";
import styles from "./styles";
import mentionsInputStyles from '../mentionsInputStyles';

const tabs = {
    URL_TAB: 0,
    IMAGE_PICKER_TAB: 1,
}

const useStyles = makeStyles(styles, { name: "ImageTemplateSettings" });

const ImageTemplateSettings = ({ onMoveForward, onMoveBackward, onDelete, onEdit, uri, objectFit = "fill", isInternalFile }) => {
    const isExternalLink = !validDataUrl(uri) && !isInternalFile;
    const [buttonSelected, setButtonSelected] = useState(isExternalLink ? tabs.URL_TAB : tabs.IMAGE_PICKER_TAB);
    const { placeholderKeys } = useContext(TemplateBuilderContext);
    const { t } = useTranslation(["elementSettingsPanel", "contextData"]);

    const objectFitList = [
        {
            value: 'fill', label: `${t("fill")}`,
        },
        {
            value: 'contain', label: `${t("contain")}`,
        },
        {
            value: 'cover', label: `${t("cover")}`,
        }
    ];
    const theme = useTheme();
    const classes = useStyles();

    const handleTabSwitch = (event, newValue) => {
        if (newValue != null) // so the button can't be unselected unless another one's selected
            setButtonSelected(newValue);
    };

    return (
        <div className={classes.mainElement}>
            <SharedSettings onMoveForward={onMoveForward} onMoveBackward={onMoveBackward} onDelete={onDelete} />
            <div className={classes.bodyContainer}>
                <TextField
                    className={classes.modeDropdown}
                    label={t(`imageMode`)}
                    select
                    value={objectFit}
                    fullWidth
                    onChange={evt => onEdit({ objectFit: evt.target.value })}
                >
                    {objectFitList.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>

                <div >
                    <Tabs
                        value={buttonSelected}
                        onChange={handleTabSwitch}
                        textColor="primary"
                        classes={{
                            flexContainer: classes.toggleButtonGroup
                        }}
                        TabIndicatorProps={{
                            style: { display: "none" } // to remove the indicator
                        }}
                    >
                        <Tab
                            classes={{
                                root: classes.tabLeft,
                                textColorPrimary: classes.tabTextColor,
                                selected: classes.selected
                            }}
                            icon={<LinkIcon />} />
                        <Tab
                            classes={{
                                root: classes.tabRight,
                                textColorPrimary: classes.tabTextColor,
                                selected: classes.selected
                            }}
                            icon={<UplodImageIcon />} />
                    </Tabs>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={buttonSelected}
                        onChangeIndex={handleTabSwitch}
                        style={{ overflow: 'unset' }}
                        slideStyle={{ overflow: 'unset' }}
                    >
                        <div style={{ display: buttonSelected !== tabs.URL_TAB ? 'none' : undefined }} value={buttonSelected} index={tabs.URL_TAB} dir={theme.direction} >
                            <MentionsField
                                label={t(`imageUrl`)}
                                placeholder={t(`textPlaceHolder`)}
                                InputProps={{
                                    style: { fontSize: 13, lineHeight: 'inherit' }
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                size="small"
                                onChange={url => {
                                    onEdit({ uri: url, isInternalFile: false });
                                    setButtonSelected(tabs.URL_TAB);
                                }}
                                value={isExternalLink ? uri : ""}
                                fullWidth
                            >
                                <Mention
                                    markup={`${templatesConfig.PARSING_CONSTANTS.MARKER_START}__id__${templatesConfig.PARSING_CONSTANTS.MARKER_END}`} trigger={templatesConfig.PARSING_CONSTANTS.TRIGGER}
                                    style={mentionsInputStyles(theme)}
                                    data={placeholderKeys.map(key => ({
                                        display: parser.getLocalizedContextKey(key, t, false, false),
                                        id: key
                                    }))}
                                    displayTransform={display => parser.getLocalizedContextKey(display, t, false)}
                                    renderSuggestion={({ id }) => parser.getLocalizedContextKey(id, t, false, false)}
                                />
                            </MentionsField>
                        </div>
                        <div
                            style={{ display: buttonSelected !== tabs.IMAGE_PICKER_TAB ? 'none' : undefined }}
                            className={classes.uploadImagePanel}
                            value={buttonSelected}
                            index={tabs.IMAGE_PICKER_TAB}
                            dir={theme.direction}
                        >
                            <LogoPicker
                                key={uri} // to reset the logo picker so that the same prev image could be selected
                                url={isExternalLink ? "" : uri}
                                onChange={(data) => {
                                    if (data) {
                                        onEdit({ uri: data.file, isInternalFile: true }, true);
                                        setButtonSelected(tabs.IMAGE_PICKER_TAB);
                                    }
                                }
                                }
                                UploadIconComponent={UplodImageIcon} />
                        </div>
                    </SwipeableViews>
                </div>
            </div>
        </div >
    );
}

export default ImageTemplateSettings;