const styles = () => ({
    printContainer: {
        position: 'absolute',
        visibility: 'hidden',
        width: 0,
        height: 0,
        overflow: 'hidden'
    }
});

export default styles;
