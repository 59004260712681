import { container } from "assets/jss/material-kit-react.jsx";

const styles = theme => ({
    container: {
        ...container,
        zIndex: "2",
        position: "relative",
        paddingTop: "20vh",
        color: "#FFFFFF",
        flex: 1
    },
    cardHidden: {
        opacity: "0",
        transform: "translate3d(0, -60px, 0)"
    },
    form: {
        margin: "0"
    },
    cardHeader: {
        width: "auto",
        textAlign: "center",
        marginLeft: "20px",
        marginRight: "20px",
        marginTop: "-40px",
        padding: "20px 0",
        marginBottom: "15px",
    },
    cardHeaderTitle: {
        color: "#ffffff"
    },
    cardFooter: {
        paddingTop: "0rem",
        border: "0",
        borderRadius: "6px",
        justifyContent: "center !important"
    },
    inputIconsColor: {
        color: theme.palette.action.active,
    },
    countrySelectContainer: {
        flex: 1,
        marginRight: 16,
        marginTop: 11,
    },
    countrySelect: {
        display: "flex"
    },
    countryFlag: {
        alignSelf: "center",
        width: 25,
        marginRight: 8
    },
    link: {
        "&:hover": {
            color: "#FFFFFF",
        },
        color: "#FFFFFF",
    },
    centerText: {
        textAlign: "center",
    },
    registerPageContainer: {
        position: "fixed",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
    },
});

export default styles;
