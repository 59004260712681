import React from "react";
import backgroundStyles from "assets/jss/Background";
import withStyles from "@material-ui/core/styles/withStyles";
import image from "assets/img/bg8.jpg";

const Background = ({ classes, children }) => {
  return (
    <div
      className={classes.pageHeader}
      style={{
        backgroundImage: "url(" + image + ")",
        backgroundSize: "cover",
        backgroundPosition: "top center"
      }}
    >
      {children}
    </div>
  );
};

export default withStyles(backgroundStyles)(Background);
