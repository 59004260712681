import React, { useState, useReducer, useRef, useEffect, useMemo } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles"
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import ErrorIcon from "@material-ui/icons/ErrorOutline";

import useWindowSize from 'hooks/useWindowSize';
import zoomCalculator from './utils/zoomCalculator';
import TemplateBuilderContext from 'contexts/TemplateBuilderContext';
import { templatesConfig, parser } from './core/templateUtils';
import { getUniqueItemKey } from 'modules/utils';
import AddMovable from './AddMovable';
import ZoomSliderToggle from '../ZoomSliderToggle';
import useZoomTransformation from './utils/useZoomTransformation';

import TemplateSettingsWrapper from './utils/ElementSettingsPanel/TemplateSettingsWrapper';
import TemplateViewer from './TemplateViewer';

import produce from 'immer';
import { useParams } from 'react-router-dom';
import _get from 'lodash/get';
import _forOwn from 'lodash/forOwn';
import _throttle from 'lodash/throttle';
import _isNull from 'lodash/isNull';
import { useMutation, useQuery } from 'react-apollo';
import { useTranslation } from "react-i18next";
import queryCreators from '../../../apollo/queryCreators';
import OverlappingIcons from "../../../components/OverlappingIcons/OverlappingIcons";
import TemplateBuilderIcon from "components/Icons/TemplateBuilderIcon"
import styles from "./styles"

const useStyles = makeStyles(styles, { name: "TemplateBuilder" });

const ACTION_TYPES = {
  SET_ORIGIN: 'SET_ORIGIN',
  SET_DIMENSIONS: 'SET_DIMENSIONS',
  SET_TRANSFORM: 'SET_TRANSFORM',
  ADD_MOVABLE: 'ADD_MOVABLE',
  EDIT_MOVABLE_CONTENT: 'EDIT_MOVABLE_CONTENT',
  MOVE_MOVABLE_TO_FRONT: 'MOVE_MOVABLE_TO_FRONT',
  MOVE_MOVABLE_TO_BACK: 'MOVE_MOVABLE_TO_BACK',
  DELETE_MOVABLE: 'DELETE_MOVABLE',
  SET_MOVABLES: 'SET_MOVABLES'
};

const saveWrapperFn = fn => fn();
const throttledSave = _throttle(saveWrapperFn, 5000);

const TemplateBuilder = () => {
  useWindowSize(); // used to rerender component on window size changes
  const { t } = useTranslation("templateBuilder");
  const classes = useStyles();
  const theme = useTheme();
  // any screen width below xs "600px" will set smallScreen to true
  const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const selectedMovableRef = useRef(); // reference for the currently selected element
  const [isZoomSliderExpanded, setIsZoomSliderExpanded] = useState(false);
  const [builderContainerNode, setBuilderContainerNode] = useState(null);
  const [isSettingsPanelExpanded, setIsSettingsPanelExpanded] = useState(true);
  const [isDraggingTemplate, setIsDraggingTemplate] = useState(true);
  const [builderZoom, setBuilderZoom] = useState(1);
  const [builderNode, setBuilderNode] = useState(null);
  const [movableLoaded, setMovableLoaded] = useState(false);
  const [isBuilderUIBlocked, setIsBuilderUIBlocked] = useState(false);

  const { eventID } = useParams();
  const { loading: eventContextDataFetchLoading, data: eventContextData } = useQuery(queryCreators.GET_EVENT_CONTEXT_DATA.query, {
    variables: { eventID }
  });

  const [editTemplateBadge] = useMutation(queryCreators.EDIT_BADGE_TEMPLATE.mutation, {
    onCompleted: (data) => {
      const badgeTemplate = _get(data, "editBadgeTemplate");
      if (badgeTemplate && isBuilderUIBlocked) {
        setMovableTemplates({
          type: ACTION_TYPES.SET_MOVABLES,
          payload: badgeTemplate.movableTemplates
        })
      }
      setIsBuilderUIBlocked(false);
    },
  });

  const [currentlySelectedMovableKey, setCurrentlySelectedMovableKey] = useState();
  const [movableTemplates, setMovableTemplates] = useReducer(
    (state, action) => {
      if (action.type === ACTION_TYPES.SET_ORIGIN) {
        return produce(state, draft => {
          const changed = draft.find(
            movable => movable.key === action.payload.key
          );
          if (changed)
            changed.layoutConfig.origin = action.payload.origin || {
              top: 0,
              left: 0
            };
        });
      }
      if (action.type === ACTION_TYPES.SET_DIMENSIONS) {
        return produce(state, draft => {
          const changed = draft.find(
            movable => movable.key === action.payload.key
          );
          if (changed)
            changed.layoutConfig.dimensions = action.payload.dimensions || {
              top: 0,
              left: 0
            };
        });
      }
      if (action.type === ACTION_TYPES.SET_TRANSFORM) {
        return produce(state, draft => {
          const changed = draft.find(
            movable => movable.key === action.payload.key
          );
          if (changed)
            changed.layoutConfig.transform = action.payload.transform;
        });
      }
      if (action.type === ACTION_TYPES.ADD_MOVABLE) {
        return state.concat({
          ...templatesConfig.templates[action.payload].defaults,
          key: action.key,
          type: action.payload
        });
      }
      if (action.type === ACTION_TYPES.EDIT_MOVABLE_CONTENT) {
        const res = produce(state, draft => {
          const changed = draft.find(
            movable => movable.key === action.payload.key
          );
          if (changed) {
            _forOwn(action.payload.changes, (value, key) => {
              const parsables = parser.getParsables(
                value,
                templatesConfig.PARSING_CONSTANTS.MARKER_START,
                templatesConfig.PARSING_CONSTANTS.MARKER_END
              );
              if (Array.isArray(parsables) && parsables.length > 0)
                changed.parsables[key] = value;
              else {
                changed.contentConfig[key] = value;
                delete changed.parsables[key];
              }
            });
          }
        });
        return res;
      }
      if (action.type === ACTION_TYPES.MOVE_MOVABLE_TO_FRONT) {
        return produce(state, draft => {
          const rest = draft.filter(movable => movable.key !== action.payload);
          const reorderedMovable = draft.find(movable => movable.key === action.payload);
          return [...rest, reorderedMovable]
        });
      }
      if (action.type === ACTION_TYPES.MOVE_MOVABLE_TO_BACK) {
        return produce(state, draft => {
          const rest = draft.filter(movable => movable.key !== action.payload);
          const reorderedMovable = draft.find(movable => movable.key === action.payload);
          return [reorderedMovable, ...rest]
        });
      }
      if (action.type === ACTION_TYPES.DELETE_MOVABLE) {
        return state.filter(movable => movable.key !== action.payload);
      }
      if (action.type === ACTION_TYPES.SET_MOVABLES) {
        return action.payload;
      }
    },
    []
  );

  const onOriginChange = (key, newOrigin) => {
    setMovableTemplates({
      type: ACTION_TYPES.SET_ORIGIN,
      payload: {
        key,
        origin: newOrigin
      }
    });
  };

  const onDimensionsChange = (key, newDimensions) => {
    setMovableTemplates({
      type: ACTION_TYPES.SET_DIMENSIONS,
      payload: {
        key,
        dimensions: newDimensions
      }
    });
  };

  const onRotationChange = (key, transform) => {
    setMovableTemplates({
      type: ACTION_TYPES.SET_TRANSFORM,
      payload: {
        key,
        transform
      }
    });
  };

  const handleClickAwayMovable = (e) => {
    // unselect movable if user clicks in the builder container
    // but the clicked area is not another movable
    if ((builderContainerNode && builderContainerNode.contains(e.target))
      && (builderNode.isSameNode(e.target) || !builderNode.contains(e.target))) {
      selectedMovableRef.current = null;
      setCurrentlySelectedMovableKey(undefined);
    }
  }

  const handleSliderExpandState = () => {
    if (isZoomSliderExpanded) setIsZoomSliderExpanded(false);
    else setIsZoomSliderExpanded(true);
  }

  let { fitZoom, fillZoom } = zoomCalculator(builderNode, builderContainerNode);
  const currentBuilderZoom = builderZoom === undefined ? fitZoom : builderZoom;
  const zoomTransformation = useZoomTransformation(builderNode, builderContainerNode, currentBuilderZoom);
  const currentSelectedMovable = movableTemplates.find((movable) => movable.key === currentlySelectedMovableKey)

  const { templateID } = useParams();

  const { loading: templateFetchLoading, error: badgeFetchingError, data } = useQuery(queryCreators.GET_BADGE_TEMPLATE.query, {
    variables: {
      templateID
    },
    onCompleted: ({ badgeTemplate }) => {
      if (badgeTemplate) {
        setMovableTemplates({
          type: ACTION_TYPES.SET_MOVABLES,
          payload: badgeTemplate.movableTemplates
        })
        setMovableLoaded(true)
      }
    }
  });
  const { badgeTemplate } = data || {};
  const { width, height, unit } = badgeTemplate || {};


  useEffect(() => {
    if (movableLoaded && movableTemplates !== _get(data, 'badgeTemplate.movableTemplates')) {
      throttledSave(() => {
        editTemplateBadge({
          variables: {
            badge: {
              id: templateID,
              movableTemplates
            }
          }
        });
      });
    }
    // eslint-disable-next-line
  }, [movableTemplates]);

  const templateContextVal = useMemo(() => ({
    placeholderKeys: templatesConfig.CONTEXT_DATA_KEYS, contextData: eventContextData ? templatesConfig.getContextProps(eventContextData) : undefined
  }), [eventContextData]);

  if (_isNull(badgeTemplate) || badgeFetchingError)
    return <div className={classes.badgeNotFound}>
      <OverlappingIcons parentIcon={TemplateBuilderIcon} childIcon={ErrorIcon} defaultBgColor />
      <Typography color="inherit" variant="h6" noWrap>
        {t("badgeNotFound")}
      </Typography>
    </div>

  return (
    templateFetchLoading || eventContextDataFetchLoading ?
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <CircularProgress thickness={7} />
      </div> : <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <TemplateBuilderContext.Provider
          value={templateContextVal}>
          <div
            style={{
              display: 'flex',
              flex: 1,
              overflow: 'auto',
              zIndex: 0,
              position: 'relative',
              maxHeight: 'calc(100vh - 64px)' // we need to replace 64 with the actual react menu size from mui
            }}
          >
            {
              currentlySelectedMovableKey &&
              <div>
                <TemplateSettingsWrapper
                  contentConfig={currentSelectedMovable && currentSelectedMovable.contentConfig}
                  isExpanded={isSettingsPanelExpanded && !isDraggingTemplate}
                  handleExpand={() => setIsSettingsPanelExpanded(true)}
                  handleCollapse={() => setIsSettingsPanelExpanded(false)}
                  onMoveBackward={() => {
                    setMovableTemplates({
                      type: ACTION_TYPES.MOVE_MOVABLE_TO_BACK,
                      payload: currentlySelectedMovableKey // assigning the element's key to the payload
                    })
                  }}
                  onMoveForward={() => {
                    setMovableTemplates({
                      type: ACTION_TYPES.MOVE_MOVABLE_TO_FRONT,
                      payload: currentlySelectedMovableKey
                    })
                  }}
                  parsables={currentSelectedMovable && currentSelectedMovable.parsables}
                  type={currentSelectedMovable && currentSelectedMovable.type}
                  onEdit={(changes, isUIBlocked) => {
                    setIsBuilderUIBlocked(isUIBlocked);
                    setMovableTemplates({
                      type: ACTION_TYPES.EDIT_MOVABLE_CONTENT,
                      payload: {
                        key: currentlySelectedMovableKey,
                        changes: changes,
                      }
                    })
                  }}
                  onDelete={() => {
                    setMovableTemplates({
                      type: ACTION_TYPES.DELETE_MOVABLE,
                      payload: currentlySelectedMovableKey
                    });
                    setCurrentlySelectedMovableKey(undefined);
                  }}
                  buttonSize={
                    smallScreen ? "medium" : "large"
                  }
                />
              </div>
            }
            <div
              ref={ref => setBuilderContainerNode(ref)}
              style={{
                display: 'flex',
                flex: 1,
                zIndex: 0,
                position: 'relative',
                maxHeight: 'calc(100vh - 64px)' // we need to replace 64 with the actual react menu size from mui
              }}
            >
              <TemplateViewer
                movableTemplates={movableTemplates}
                zoomTransformation={zoomTransformation}
                height={height}
                width={width}
                sizeUnit={unit}
                showMovables
                containerNode={builderNode}
                isMovableActive={movableKey => movableKey === currentlySelectedMovableKey}
                movableProps={{
                  onDimensionsChange,
                  onOriginChange,
                  onRotationChange,
                  onClickAway: handleClickAwayMovable,
                  onMouseDown: (event, movableKey) => {
                    selectedMovableRef.current = event.currentTarget
                    setCurrentlySelectedMovableKey(movableKey);
                  },
                  onTouchStart: (event, movableKey) => {
                    selectedMovableRef.current = event.currentTarget
                    setCurrentlySelectedMovableKey(movableKey);
                  },
                }}
                ref={ref => setBuilderNode(ref)}
                isDragging={(isCurrentlyDragging) => {
                  if (isDraggingTemplate && isCurrentlyDragging) return; // don't set the dragging state to true if already dragging
                  setIsDraggingTemplate(isCurrentlyDragging)
                }}
                isBuilderUIBlocked={isBuilderUIBlocked}
              />
            </div>
          </div>
          {
            (!currentlySelectedMovableKey && !isBuilderUIBlocked) &&
            <React.Fragment>
              <AddMovable
                onMovableAdd={type => {
                  const addedMovableKey = `${type}-${getUniqueItemKey(movableTemplates)}`
                  setMovableTemplates({
                    type: ACTION_TYPES.ADD_MOVABLE,
                    key: addedMovableKey,
                    payload: type
                  })
                  setCurrentlySelectedMovableKey(addedMovableKey); // setting the template to be active once added
                }}
              />

              <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={() => setIsZoomSliderExpanded(false)}>
                <div>
                  <ZoomSliderToggle
                    fill={fillZoom}
                    fit={fitZoom}
                    max={fillZoom > 3 ? fillZoom : 3} // setting the max equals to the fillzoom val if it's above 3
                    min={fillZoom < 0.1 ? fillZoom : 0.1} // setting the min equals to the fillzoom val if it's lower than 0.1 (10%)
                    sliding={(event, currVal) => setBuilderZoom(currVal)}
                    initVal={currentBuilderZoom} // the current value of the slider 
                    isExpanded={isZoomSliderExpanded}
                    handleSliderState={handleSliderExpandState}
                  />
                </div>
              </ClickAwayListener>
            </React.Fragment>
          }
        </TemplateBuilderContext.Provider >
      </div >
  );
};


export default TemplateBuilder;