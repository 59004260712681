const styles = theme => {

    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            color: theme.palette.text.primary
        },
        content: {
            padding: theme.spacing(3)
        },
        chartResponsivenessFix: {
            direction: "ltr", //prevent tooltip and chart resize issues in rtl 
            color: theme.palette.common.black,
            "& .recharts-wrapper": {
                position: "absolute"
            },
            flip: false
        },
        spinnerContainer: {
            flexGrow: 1
        },
        chartNoData: {
            display: "flex",
            height: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: theme.palette.divider
        },
        titleBar: {
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(3),
            borderBottom: `solid 1px ${theme.palette.divider}`
        },
        analytics: {
            marginTop: 16,
        },
        averageVisitTimeTextContainer: {
            display: "flex",
            alignItems: "center",
        },
        averageVisitTimeTextUnit: {
            marginLeft: theme.spacing(1),
        },
        rating: {
            display: "flex",
            fontSize: 41,
        },
        cardHeaderContent: {
            width: "100%"
        }
    }
};

export default styles;
