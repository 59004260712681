
import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/styles/useTheme';
import CircularProgress from '@material-ui/core/CircularProgress';

import TemplateViewer from '../TemplateViewer';
import useZoomTransformation from '../utils/useZoomTransformation';
import { parser } from '../core/templateUtils';
import DividerWithTitle from '../../../../components/DividerWithTitle';
import styles from './styles';

import { useTranslation } from 'react-i18next';
import _isString from 'lodash/isString';

function capitalizeFirstLetter(str) {
    if (!_isString(str)) return;
    return str.replace(/^\w/, c => c.toUpperCase())
};

const useStyles = makeStyles(styles, { name: "TemplateDataPreview" });

const TemplateDataPreview = ({
    template,
    dependencies,
    contextOverrides,
    onChange,
    viewerProps,
    showLoadingIndicator
}) => {
    const [builderNode, setBuilderNode] = useState();
    const [builderContainerNode, setBuilderContainerNode] = useState();
    const zoomTransformation = useZoomTransformation(builderNode, builderContainerNode, undefined, "fit");
    const { width, height, unit, movableTemplates } = template || {};
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const { t } = useTranslation(['printingPreviewModal', 'contextData']);

    return (
        <>
            {Array.isArray(dependencies) && dependencies.length > 0 &&
                <>
                    <DividerWithTitle title={t('printingPreviewModal:badgeInfo')} className={classes.infoDivider} />
                    <div className={classes.form}>
                        <Grid container spacing={3}>
                            {
                                dependencies.map(dependency => {
                                    const isSingle = dependencies.length === 1;
                                    return (
                                        <Grid item key={dependency} xs={isSingle ? 12 : isMobile ? 12 : 6}>
                                            <TextField
                                                fullWidth
                                                label={capitalizeFirstLetter(parser.getLocalizedContextKey(dependency, t, false, false))}
                                                value={contextOverrides[dependency]}
                                                onChange={evt => onChange({ [dependency]: evt.target.value })}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    </div>
                </>
            }
            <DividerWithTitle title={t('printingPreviewModal:preview')} className={classes.previewDivider} />
            <div className={classes.spinnerContainer}>
                {showLoadingIndicator && <CircularProgress size={16} />}
            </div>
            <div
                className={classes.builderContainer}
                ref={ref => setBuilderContainerNode(ref)}
            >

                <TemplateViewer
                    key={template && template.id}
                    movableTemplates={movableTemplates}
                    width={width}
                    height={height}
                    sizeUnit={unit}
                    ref={ref => setBuilderNode(ref)}
                    zoomTransformation={zoomTransformation}
                    {...viewerProps}
                />
            </div>
        </>
    );
};

export default TemplateDataPreview;