import React from 'react';
import compose from 'recompose/compose';
import {
    get as _get,
    reduce as _reduce,
    uniqBy as _uniqBy,
    uniqWith as _uniqWith,
    map as _map,
    groupBy as _groupBy,
    orderBy as _orderBy,
    uniq as _uniq,
    round as _round,
} from 'lodash';
import moment from 'moment';
import memoize from 'memoize-one';

import withStyles from "@material-ui/core/styles/withStyles";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CircularProgress from '@material-ui/core/CircularProgress';
import MUITooltip from '@material-ui/core/Tooltip';
import Grow from '@material-ui/core/Grow';

import ShowChartIcon from '@material-ui/icons/ShowChart';

import red from '@material-ui/core/colors/red';
import teal from '@material-ui/core/colors/teal';
import indigo from '@material-ui/core/colors/indigo';
import orange from '@material-ui/core/colors/orange';
import purple from '@material-ui/core/colors/purple';
import Rating from '@material-ui/lab/Rating';

//graphql
import { Query, useQuery } from "react-apollo";
import queryCreators from "apollo/queryCreators";


//i18n
import { useTranslation } from 'react-i18next';

import { ResponsiveContainer, CartesianGrid, Tooltip, XAxis, PieChart, Pie, Area, AreaChart, Cell } from 'recharts';
import AnimatedNumber from 'react-animated-number';
import ExhibitorsIcon from 'components/Icons/ExhibitorsIcon';
import RegistrationsIcon from 'components/Icons/RegistrationsIcon';
import AttendIcon from 'components/Icons/AttendIcon';
import MobileSocialNetworkIcon from 'components/Icons/MobileSocialNetworkIcon';
import { showGlobalSnackbar } from "../../../components/GlobalSnackbar";
import { checkType } from "../../../enums"
import ErrorPage from "../ErrorPage";
import styles from "./styles";

const REPORTS_ANIM_DURATION = 1500;
const DATE_TIME_TICKS_TYPE = {
    'DAY': 'day',
    'HOUR': 'hour',
}
const { IN, OUT } = checkType;

const DashboardView = props => {

    const { t, i18n } = useTranslation("dashboardView");

    const { data: supportedLanguagesData, loading: supportedLanguagesLoading, error: supportedLanguagesError } = useQuery(queryCreators.GET_SUPPORTED_LANGUAGES,
        {
            variables: {
                language: i18n.language,
            },
            onError: () => {
                showGlobalSnackbar(t("couldntFetchLanguages"), "error");
            }
        },
    );

    const supportedLanguages = _get(supportedLanguagesData, "supportedLanguages", [])

    const getRegistrantsChart = memoize((fetchedRegistrants, eventStartDate, eventEndDate) => {
        const sortedRegistrationsArray = _orderBy(fetchedRegistrants, ['registrationDateTime']);
        let registrationGraphTick = DATE_TIME_TICKS_TYPE.DAY
        let registrationPeriodStart = sortedRegistrationsArray[0] && getChartStartDate(sortedRegistrationsArray[0]['registrationDateTime'], eventStartDate);
        let registrationPeriodEnd = sortedRegistrationsArray[sortedRegistrationsArray.length - 1] && getChartEndDate(sortedRegistrationsArray[sortedRegistrationsArray.length - 1]['registrationDateTime'], eventEndDate);

        if (sortedRegistrationsArray.length) {
            if (moment(registrationPeriodStart).isSame(moment(registrationPeriodEnd), DATE_TIME_TICKS_TYPE.DAY)) {
                registrationGraphTick = DATE_TIME_TICKS_TYPE.HOUR
            }
        }

        return {
            data: groupByDateUtil(fetchedRegistrants, 'registrationDateTime', registrationGraphTick, registrationPeriodStart, registrationPeriodEnd),
            tick: registrationGraphTick
        }
    });

    const getVisitsChart = memoize((fetchedChecks, eventStartDate, eventEndDate) => {
        const uniqueVisitsByDay = _uniqWith(fetchedChecks, (one, two) => {
            return (one.registrantID === two.registrantID && moment(one.createdAt).isSame(moment(two.createdAt), DATE_TIME_TICKS_TYPE.DAY));
        })
        const sortedVisitsArray = _orderBy(uniqueVisitsByDay, ['createdAt']);
        let visitsGraphTick = DATE_TIME_TICKS_TYPE.DAY

        let visitPeriodStart = sortedVisitsArray[0] && getChartStartDate(sortedVisitsArray[0]['createdAt'], eventStartDate);
        let visitPeriodEnd = sortedVisitsArray[sortedVisitsArray.length - 1] && getChartEndDate(sortedVisitsArray[sortedVisitsArray.length - 1]['createdAt'], eventEndDate);

        if (sortedVisitsArray.length) {
            if (moment(visitPeriodStart).isSame(moment(visitPeriodEnd), DATE_TIME_TICKS_TYPE.DAY)) {
                visitsGraphTick = DATE_TIME_TICKS_TYPE.HOUR
            }
        }

        return {
            data: groupByDateUtil(uniqueVisitsByDay, 'createdAt', visitsGraphTick, visitPeriodStart, visitPeriodEnd),
            tick: visitsGraphTick
        }
    });

    const getLeadCapturedForEachCaptorChartData = memoize((fetchedLeadCaptors) => {
        return _map(fetchedLeadCaptors, function (leadCaptor) {
            const leadsCapturedForCaptor = _get(leadCaptor, 'leads') || [];
            return { captorName: leadCaptor.name || leadCaptor.email, capturedLeadsCount: leadsCapturedForCaptor.length };
        });

    });
    const getCapturedLeadsCount = memoize((fetchedLeadCaptors) => {
        //The total count of leads is not for distinct registarnts 
        //ex. if the same registrant is captured multiple exhibitors or even the same will be counted multiple times
        return _reduce(fetchedLeadCaptors, function (sum, leadCaptor) {
            const leadsCapturedForCaptor = _get(leadCaptor, 'leads') || [];
            return sum + leadsCapturedForCaptor.length;
        }, 0);
    });

    const getVisitsCount = memoize((fetchedChecks) => {
        const uniqueVisits = _uniqBy(fetchedChecks, 'registrantID');
        return uniqueVisits.length;
    });

    const getCurrentChecksCount = memoize((fetchedChecks = []) => {
        const registrantsChecksGroup = _groupBy(fetchedChecks, "registrantID");
        const lastCheckedinRegistrants = [];
        for (const registrantID in registrantsChecksGroup) {
            // to get the obj with the latest createdAt
            const lastCheck = registrantsChecksGroup[registrantID].reduce((prev, current) => {
                return (prev.createdAt > current.createdAt) ? prev : current
            });
            if (lastCheck.type === IN) {
                lastCheckedinRegistrants.push(lastCheck);
            }
        }
        return lastCheckedinRegistrants.length;
    });

    const getRegistrantsPerLanguage = memoize((fetchedRegistrants = [], supportedLanguages = []) => {
        if (supportedLanguagesError) {
            return []
        }
        const registrantsWithLanguageName = fetchedRegistrants.map(registrant => {
            const registrantLanguage = supportedLanguages.find(object => object.language === registrant.language);
            return {
                ...registrant,
                language: _get(registrantLanguage, 'name'),
            }
        })
        const registrantsPerLanguageGroup = _groupBy(registrantsWithLanguageName, "language");
        const registrantsPerLanguageCounts = [];
        for (const key in registrantsPerLanguageGroup) {
            registrantsPerLanguageCounts.push({ language: key, registrantsCount: registrantsPerLanguageGroup[key].length })
        }
        return registrantsPerLanguageCounts;
    });

    const getAverageVisitorsScans = memoize((totalCupturedLeadsCount, fetchedLeadCaptors) => {
        const uniqueRegistrantsIds = [];
        fetchedLeadCaptors.forEach(exhibitor => {
            const exhibitorsIds = _uniq(exhibitor.leads.map(lead => lead.registrantID));
            if (exhibitorsIds.length) uniqueRegistrantsIds.push(...exhibitorsIds);
        })
        if (uniqueRegistrantsIds.length < 1) {
            return 0; // to prevent division by zero
        }
        const averageVisitorsScans = totalCupturedLeadsCount / uniqueRegistrantsIds.length
        return Math.round(averageVisitorsScans);
    });

    const getAverageVisitTime = memoize((fetchedChecks, numberOfVisitors) => {
        if (numberOfVisitors < 1) {
            return 0; // to prevent division by zero
        }
        const registrantsChecksGroup = _groupBy(fetchedChecks, "registrantID");
        let totalTime = 0; // total time of visits in milliseconds
        for (const registrantID in registrantsChecksGroup) {
            let currentCheckInTime;
            let currentCheckOutTime;
            for (const [index, check] of registrantsChecksGroup[registrantID].entries()) {
                if (check.type === IN && !currentCheckInTime) {
                    currentCheckInTime = check.createdAt;
                } else if (check.type === OUT && !currentCheckOutTime && currentCheckInTime) {
                    currentCheckOutTime = check.createdAt;
                }

                if (currentCheckInTime && currentCheckOutTime) {
                    totalTime += moment(currentCheckOutTime).diff(moment(currentCheckInTime));
                    currentCheckInTime = undefined;
                    currentCheckOutTime = undefined;
                }

                if (index >= registrantsChecksGroup[registrantID].length - 1 && currentCheckInTime) {
                    totalTime += moment().diff(moment(currentCheckInTime));
                }
            }
        }

        var totalHoursSpent = moment.duration(totalTime).asHours();
        return Math.round(totalHoursSpent / numberOfVisitors)
    });


    const getAverageLeadRating = memoize((fetchedLeadCaptors) => {
        const ratings = [];
        fetchedLeadCaptors.forEach(exhibitor => {
            const exhibRatings = exhibitor.leads.map(lead => lead.rating).filter(Boolean);
            if (exhibRatings.length) ratings.push(...exhibRatings);
        })
        const ratingsSum = ratings.reduce((count, currentElement) => count + currentElement, 0)
        return _round(ratingsSum / (ratings.length || 1), 1);
    });

    const { classes, match } = props;
    const eventID = match.params.eventID;
    return (
        <div className={classes.container}>
            <div className={classes.titleBar}>
                <Grid container direction="row" alignItems="center">
                    <Grid container item xs={12}  >
                        <Typography variant="h5" noWrap>{t("dashboardView:dashboard")}</Typography>
                    </Grid>
                </Grid>
            </div>
            <Query query={queryCreators.GET_EVENT_DASHBOARD_DETAILS.query} variables={{ eventID }}
            >
                {({ loading, error, data }) => {
                    if (error) return <ErrorPage />;
                    if (loading || supportedLanguagesLoading) return <Grid container alignItems="center" justify="center" className={classes.spinnerContainer}><CircularProgress thickness={7} /></Grid>;

                    const fetchedRegistrants = _get(data, 'event.registrants') || [];
                    const fetchedLeadCaptors = _get(data, 'event.exhibitors') || [];
                    const fetchedChecks = _get(data, 'event.checks') || [];
                    const eventStartDate = _get(data, 'event.startDate');
                    const eventEndDate = _get(data, 'event.endDate');
                    const eventLanguages = _get(data, 'event.languages');

                    const { data: registrantsChartData, tick: registrationGraphTick } = getRegistrantsChart(fetchedRegistrants, eventStartDate, eventEndDate)
                    const { data: visitsChartData, tick: visitsGraphTick } = getVisitsChart(fetchedChecks, eventStartDate, eventEndDate)
                    const leadCapturedForEachCaptorChartData = getLeadCapturedForEachCaptorChartData(fetchedLeadCaptors);
                    const numberOfVisitors = getVisitsCount(fetchedChecks);
                    const totalCupturedLeadsCount = getCapturedLeadsCount(fetchedLeadCaptors);
                    const numberOfCurrentVisitors = getCurrentChecksCount(fetchedChecks);
                    const registrantsLanguages = eventLanguages.length > 1 ? getRegistrantsPerLanguage(fetchedRegistrants, supportedLanguages) : [];
                    const averageVisitorsScans = getAverageVisitorsScans(totalCupturedLeadsCount, fetchedLeadCaptors);
                    const averageVisitTime = getAverageVisitTime(fetchedChecks, numberOfVisitors);
                    const averageVisitorsRating = getAverageLeadRating(fetchedLeadCaptors);

                    return (
                        <Grid className={classes.content} container spacing={2}>
                            <Grid item container spacing={2} xs={12}>
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <Card style={{ background: `linear-gradient(60deg, ${teal[400]}, ${teal[600]})` }}>
                                        <CardContent>
                                            <div style={{ flexDirection: "column", display: "flex" }}>
                                                <div style={{ flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <AttendIcon style={{ color: "white", fontSize: 25 }} />
                                                    <Typography style={{ color: "white", fontWeight: 100 }} variant="h5" noWrap>{t("dashboardView:visitors")}</Typography>
                                                </div>
                                                <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                    <Typography style={{ color: "white" }} variant="h4" noWrap>
                                                        <AnimatedNumber
                                                            stepPrecision={0}
                                                            value={numberOfVisitors}
                                                            duration={REPORTS_ANIM_DURATION} />
                                                    </Typography>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <Card style={{ background: `linear-gradient(60deg, ${indigo[400]}, ${indigo[600]})` }}>
                                        <CardContent>
                                            <div style={{ flexDirection: "column", display: "flex" }}>
                                                <div style={{ flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <RegistrationsIcon style={{ color: "white", fontSize: 25 }} />
                                                    <Typography style={{ color: "white", fontWeight: 100 }} variant="h5" noWrap>{t("dashboardView:registrations")}</Typography>
                                                </div>
                                                <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                    <Typography style={{ color: "white" }} variant="h4" noWrap>
                                                        <AnimatedNumber
                                                            stepPrecision={0}
                                                            value={fetchedRegistrants.length}
                                                            duration={REPORTS_ANIM_DURATION} />
                                                    </Typography>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <Card style={{ background: `linear-gradient(60deg, ${orange[400]}, ${orange[600]})` }}>
                                        <CardContent>
                                            <div style={{ flexDirection: "column", display: "flex" }}>
                                                <div style={{ flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <MobileSocialNetworkIcon style={{ color: "white", fontSize: 25 }} />
                                                    <Typography style={{ color: "white", fontWeight: 100 }} variant="h5" noWrap>{t("dashboardView:leadCaptures")}</Typography>
                                                </div>
                                                <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                    <Typography style={{ color: "white" }} variant="h4" noWrap>
                                                        <AnimatedNumber
                                                            stepPrecision={0}
                                                            value={totalCupturedLeadsCount}
                                                            duration={REPORTS_ANIM_DURATION} />
                                                    </Typography>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <Card style={{ background: `linear-gradient(60deg, ${red[400]}, ${red[600]})` }}>
                                        <CardContent>
                                            <div style={{ flexDirection: "column", display: "flex" }}>
                                                <div style={{ flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <ExhibitorsIcon style={{ color: "white", fontSize: 25 }} />
                                                    <Typography style={{ color: "white", fontWeight: 100 }} variant="h5" noWrap>{t("dashboardView:exhibitors")}</Typography>
                                                </div>
                                                <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                    <Typography style={{ color: "white" }} variant="h4" noWrap>
                                                        <AnimatedNumber
                                                            stepPrecision={0}
                                                            value={fetchedLeadCaptors.length}
                                                            duration={REPORTS_ANIM_DURATION} />
                                                    </Typography>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid item container spacing={2} xs={12} className={classes.analytics}>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <Card>
                                        <CardContent>
                                            <div style={{ flexDirection: "column", display: "flex" }}>
                                                <div style={{ flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <ShowChartIcon style={{ fontSize: 25 }} />
                                                    <Typography style={{ fontWeight: 100 }} variant="h5" noWrap>{t("dashboardView:currentVisitors")}</Typography>
                                                </div>
                                                <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                    <Typography variant="h4" noWrap>
                                                        <AnimatedNumber
                                                            stepPrecision={0}
                                                            value={numberOfCurrentVisitors}
                                                            duration={REPORTS_ANIM_DURATION} />
                                                    </Typography>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <Card>
                                        <CardContent>
                                            <div style={{ flexDirection: "column", display: "flex" }}>
                                                <div style={{ flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <ShowChartIcon style={{ fontSize: 25 }} />
                                                    <Typography style={{ fontWeight: 100 }} variant="h5" noWrap>{t("dashboardView:averageVisitTime")}</Typography>
                                                </div>
                                                <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                    <Typography variant="h4" noWrap className={classes.averageVisitTimeTextContainer}>
                                                        <AnimatedNumber
                                                            stepPrecision={0}
                                                            value={averageVisitTime}
                                                            duration={REPORTS_ANIM_DURATION}
                                                        />
                                                        <Typography className={classes.averageVisitTimeTextUnit}>
                                                            {t("dashboardView:hours")}
                                                        </Typography>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <Card>
                                        <CardContent>
                                            <div style={{ flexDirection: "column", display: "flex" }}>
                                                <div style={{ flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <ShowChartIcon style={{ fontSize: 25 }} />
                                                    <Typography style={{ fontWeight: 100 }} variant="h5" noWrap>{t("dashboardView:averageVisitorScans")}</Typography>
                                                </div>
                                                <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                    <Typography variant="h4" noWrap>
                                                        <AnimatedNumber
                                                            stepPrecision={0}
                                                            value={averageVisitorsScans}
                                                            duration={REPORTS_ANIM_DURATION} />
                                                    </Typography>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <Card>
                                        <CardContent>
                                            <div style={{ flexDirection: "column", display: "flex" }}>
                                                <div style={{ flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <ShowChartIcon style={{ fontSize: 25 }} />
                                                    <Typography style={{ fontWeight: 100 }} variant="h5" noWrap>{t("dashboardView:averageVisitorsRating")}</Typography>
                                                    <MUITooltip
                                                        title={averageVisitorsRating}
                                                        placement="right"
                                                        enterTouchDelay={0}
                                                    >
                                                        <div>
                                                            <Rating //TODO: add a tooltip showing the number and animate the stars
                                                                classes={{
                                                                    root: classes.rating,
                                                                }}
                                                                value={averageVisitorsRating}
                                                                readOnly
                                                                precision={0.1}
                                                                IconContainerComponent={({ value, ...other }) => {
                                                                    return (
                                                                        <Grow in={true} timeout={1000 + (value * 100)}>
                                                                            <span
                                                                                {...other}
                                                                            />
                                                                        </Grow>
                                                                    )
                                                                }}
                                                            />
                                                        </div>
                                                    </MUITooltip>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid item container spacing={2} xs={12}>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <Card>
                                        <CardHeader title={<Typography className={classes.cardHeaderTitle} variant="h6" noWrap>{t("dashboardView:registrations")}</Typography>} />
                                        <CardContent>
                                            <ResponsiveContainer className={classes.chartResponsivenessFix} height={300} >
                                                {
                                                    registrantsChartData && registrantsChartData.length ?
                                                        <AreaChart data={registrantsChartData} >
                                                            <Area type="monotone" dataKey="countPerDateTime" name={t("dashboardView:registrations")} stroke={indigo[600]} fill={indigo[500]} />
                                                            <XAxis dataKey="dateTime" hide={true} />
                                                            <CartesianGrid strokeDasharray="5 5" />
                                                            <Tooltip labelFormatter={(val) => registrationGraphTick === DATE_TIME_TICKS_TYPE.DAY ? moment(val).format('DD MMM YYYY') : moment(val).format('DD MMM YYYY  ha')} />
                                                        </AreaChart>
                                                        :
                                                        <div className={classes.chartNoData}>
                                                            <ShowChartIcon style={{ fontSize: 60 }} />
                                                            <Typography color="inherit" variant="h6" noWrap>{t("dashboardView:noRegistrations")}</Typography>
                                                        </div>
                                                }
                                            </ResponsiveContainer>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <Card>
                                        <CardHeader title={<Typography className={classes.cardHeaderTitle} variant="h6" noWrap>{t("dashboardView:visits")}</Typography>} />
                                        <CardContent>

                                            <ResponsiveContainer className={classes.chartResponsivenessFix} height={300} >
                                                {
                                                    visitsChartData && visitsChartData.length ?
                                                        <AreaChart data={visitsChartData}>
                                                            <Area type="monotone" dataKey="countPerDateTime" name={t("dashboardView:visits")} stroke={teal[600]} fill={teal[500]} />
                                                            <XAxis dataKey="dateTime" hide={true} />
                                                            <CartesianGrid strokeDasharray="5 5" />
                                                            <Tooltip labelFormatter={(val) => visitsGraphTick === DATE_TIME_TICKS_TYPE.DAY ? moment(val).format('DD MMM YYYY') : moment(val).format('DD MMM YYYY  ha')} />
                                                        </AreaChart>
                                                        :
                                                        <div className={classes.chartNoData}>
                                                            <ShowChartIcon style={{ fontSize: 60 }} />
                                                            <Typography color="inherit" variant="h6" noWrap>{t("dashboardView:noVisits")}</Typography>
                                                        </div>
                                                }
                                            </ResponsiveContainer>

                                        </CardContent>
                                    </Card>
                                </Grid>
                                {
                                    !!(totalCupturedLeadsCount && fetchedLeadCaptors.length) &&
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Card>
                                            <CardHeader classes={{ content: classes.cardHeaderContent }} title={<Typography variant="h6">{t("dashboardView:leadsCapturedByExhibitors")}</Typography>} />
                                            <CardContent>
                                                <ResponsiveContainer className={classes.chartResponsivenessFix} height={300}>
                                                    <PieChart>
                                                        <Pie animationDuration={REPORTS_ANIM_DURATION} data={leadCapturedForEachCaptorChartData} nameKey="captorName" dataKey="capturedLeadsCount" >
                                                            {
                                                                leadCapturedForEachCaptorChartData.map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={orange[((index % 9) + 1) * 100]}>
                                                                        {`cell-${index}`}
                                                                    </Cell>
                                                                ))
                                                            }
                                                        </Pie>
                                                        <Tooltip />
                                                    </PieChart>
                                                </ResponsiveContainer>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                }
                                {
                                    (eventLanguages.length > 1 && fetchedRegistrants.length > 0 && !supportedLanguagesError) &&
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Card>
                                            <CardHeader classes={{ content: classes.cardHeaderContent }} title={<Typography variant="h6">{t("dashboardView:registrantsLanguages")}</Typography>} />
                                            <CardContent>
                                                <ResponsiveContainer className={classes.chartResponsivenessFix} height={300}>
                                                    <PieChart>
                                                        <Pie animationDuration={REPORTS_ANIM_DURATION} data={registrantsLanguages} nameKey="language" dataKey="registrantsCount" >
                                                            {
                                                                registrantsLanguages.map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={purple[((index % 9) + 1) * 100]}>
                                                                        {`cell-${index}`}
                                                                    </Cell>
                                                                ))
                                                            }
                                                        </Pie>

                                                        <Tooltip />
                                                    </PieChart>
                                                </ResponsiveContainer>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    );
                }}
            </Query>
        </div>
    );
}

function formatDateValue(date, tickType = DATE_TIME_TICKS_TYPE.DAY) {
    return tickType === DATE_TIME_TICKS_TYPE.DAY ? moment(date).startOf('day').valueOf() : moment(date).startOf('hour').valueOf();
}

function groupByDateUtil(data, dateKey, tickType = DATE_TIME_TICKS_TYPE.DAY, startDate, endDate) {

    let dataGroupedByDateTime = _groupBy(data, function (item) {
        return formatDateValue(item[dateKey], tickType);
    });
    // fill between groups 
    let sortedDateTimeGroup = Object.keys(dataGroupedByDateTime).sort();
    let msInterval = tickType === DATE_TIME_TICKS_TYPE.DAY ? 86400000 : 3600000;
    const chartStartTimeStamp = startDate ? formatDateValue(startDate, tickType) : formatDateValue(parseInt(sortedDateTimeGroup[0]), tickType);
    const chartEndTimeStamp = endDate ? formatDateValue(endDate, tickType) : formatDateValue(parseInt(sortedDateTimeGroup[sortedDateTimeGroup.length - 1]), tickType);
    for (let dateTimeStamp = chartStartTimeStamp; dateTimeStamp < chartEndTimeStamp; dateTimeStamp += msInterval) {
        if (!dataGroupedByDateTime[JSON.stringify(dateTimeStamp)]) {
            dataGroupedByDateTime[JSON.stringify(dateTimeStamp)] = []
        }
    }
    const sumGroup = _map(dataGroupedByDateTime, function (group, dateTime) {
        return {
            dateTime: parseInt(dateTime), // key of group is string so convert it back to timestamp
            countPerDateTime: group.length
        }
    });

    return _orderBy(sumGroup, ['dateTime']);

}
function getChartEndDate(lastChartDataDate, eventEndDate, today = moment().toISOString()) {
    // end date should be (last data date) 
    // or (today if today is after the last data date and before event end date)
    // or (event end date if its after last data date and before today)
    if (moment(today).isBetween(lastChartDataDate, eventEndDate)) {
        return today;
    }
    else if (moment(eventEndDate).isBetween(lastChartDataDate, today)) {
        return eventEndDate;
    }

    return lastChartDataDate;
}

function getChartStartDate(firstChartDataDate, eventStartDate, today = moment().toISOString()) {
    // start date should be (first data date) 
    // or (today if today is before the first data date and before event start date)
    // or (event start date if its before first data date and before today)
    if (moment(today).isBefore(firstChartDataDate) && moment(today).isBefore(eventStartDate)) {
        return today;
    }
    else if (moment(eventStartDate).isBefore(firstChartDataDate) && moment(eventStartDate).isBefore(today)) {
        return eventStartDate;
    }

    return firstChartDataDate;

}
export default compose(withStyles(styles, { name: "DashboardView" }))(DashboardView);