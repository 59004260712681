import React, { useEffect } from "react";

export default function withFieldValidation(Component) {

    return (props) => {
        const { required, pattern, type } = props.config.templateOptions;
        const fieldValidator = props.fieldValidator;
        const isFormReadOnly = props.formState.readOnly;

        useEffect(() => {
            if (!isFormReadOnly) {
                required ? fieldValidator.subscribeToValidation("required", required) : fieldValidator.unsubscribeToValidation("required");
                pattern ? fieldValidator.subscribeToValidation("pattern", pattern) : fieldValidator.unsubscribeToValidation("pattern");
                type === "email" ? fieldValidator.subscribeToValidation(type) : fieldValidator.unsubscribeToValidation(type);

                props.onChange();
            }
            // eslint-disable-next-line 
        }, [required, pattern, type])

        return (
            <Component {...props} />
        );
    }
}
