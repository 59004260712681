import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import useTheme from "@material-ui/core/styles/useTheme";

import { useTranslation } from "react-i18next";

import _map from "lodash/map";
import INPUT_TYPES from "./inputTypes";

const ITEM_HEIGHT = 48;

const AddInputMenu = ({ onSelect }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const { t } = useTranslation("createForm");

  const handleClick = event => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <IconButton
        aria-label="add-input"
        aria-controls="add-input-menu"
        aria-haspopup="true"
        onClick={handleClick}
        color="primary"
        style={{
          boxShadow: theme.shadows[2]
        }}
      >
        <AddIcon />
      </IconButton>
      <Menu
        id="add-input-meni"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 200
          }
        }}
      >
        {_map(INPUT_TYPES, input => (
          <MenuItem
            key={input}
            onClick={() => {
              onSelect(input);
              handleClose();
            }}
          >
            {t(`createForm:${input}`)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default AddInputMenu;
