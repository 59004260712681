import React, { useContext, useState, useEffect } from "react";
import withParsedValues from "../../withParsedValues";
import ImageIcon from "@material-ui/icons/Image";
import useTheme from "@material-ui/core/styles/useTheme";

import { parser } from '../../templateUtils';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Image from "./Image";
import TemplateBuilderContext from "../../../../../../contexts/TemplateBuilderContext";

const useStyles = makeStyles(() => ({
  img: {
    width: 'inherit',
    height: 'inherit'
  }
}));

function ImageTemplate({ contentConfig, layoutConfig, onError, ...rest }, ref) {

  const { dimensions, origin, transform } = layoutConfig || {};
  const { uri, objectFit } = contentConfig || {};

  const parsables = parser.getParsables(uri);
  const containsParsables = Array.isArray(parsables) && parsables.length > 0;
  const parsedUrl = parser.removeParsables(uri);

  const [isFaultyUrl, setIsFaultyUrl] = useState(false);
  const { isPreviewing } = useContext(TemplateBuilderContext);
  const theme = useTheme();
  const classes = useStyles();

  useEffect(() => {
    setIsFaultyUrl(false);
  }, [uri]);


  if (!contentConfig || !layoutConfig || (isPreviewing && (isFaultyUrl || !parsedUrl)))
    return null;

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        ...dimensions,
        ...origin,
        transform
      }}
    >
      {(isPreviewing || (uri && !containsParsables)) && (
        <Image
          alt=""
          onError={err => {
            setIsFaultyUrl(true);
            onError && onError(err)
          }}
          src={parsedUrl}
          style={{ objectFit, ...dimensions }}
          className={classes.img}
          {...rest}
        />
      )}
      {!isPreviewing && (!uri || containsParsables) && (
        <ImageIcon style={{ color: containsParsables || theme.palette.grey[500], ...dimensions }} />
      )}
    </div>
  );
}

export default withParsedValues(React.memo(React.forwardRef(ImageTemplate)));
