import LocalStorage from "local-storage";

const storage = {
  set: (name, value) => LocalStorage.set(name, value),
  get: (name) => LocalStorage.get(name),
  remove: (name) => LocalStorage.remove(name),
  clear: () => LocalStorage.clear(),
  on: (name, listener) => LocalStorage.on(name, listener),
  off: (name, listener) => LocalStorage.off(name, listener),
};

export default storage;
