import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import _toNumber from 'lodash/toNumber';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormatAlignLeftTwoToneIcon from '@material-ui/icons/FormatAlignLeftTwoTone';
import FormatAlignRightTwoToneIcon from '@material-ui/icons/FormatAlignRightTwoTone';
import FormatAlignCenterTwoToneIcon from '@material-ui/icons/FormatAlignCenterTwoTone';
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { MentionsField, Mention } from '../../../../../../components/MentionsField';
import { templatesConfig, parser } from '../../../core/templateUtils';
import TemplateBuilderContext from '../../../../../../contexts/TemplateBuilderContext';
import SharedSettings from "../SharedSettings"
import ColorPicker from "../ColorPicker";


import styles from "./styles";
import mentionsInputStyles from '../mentionsInputStyles';

const useStyles = makeStyles(styles, { name: "TextTemplateSettings" });

const TextTemplateSettings = ({
    onMoveForward,
    onMoveBackward,
    text,
    fontSize,
    color,
    textAlign = "left",
    onEdit,
    onDelete,
}) => {
    const classes = useStyles();
    const { placeholderKeys } = useContext(TemplateBuilderContext);
    const { t } = useTranslation("elementSettingsPanel");
    const theme = useTheme();

    return (
        <div className={classes.mainElement}>
            <SharedSettings onMoveForward={onMoveForward} onMoveBackward={onMoveBackward} onDelete={onDelete} />
            <div className={classes.panelContent}>
                <MentionsField
                    value={text}
                    onChange={text => onEdit({ text })}
                    label={t(`textLabel`)}
                    InputProps={{
                        style: {
                            fontSize: 13,
                            lineHeight: 'inherit',
                        }
                    }}
                    placeholder={t(`textPlaceHolder`)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                >
                    <Mention
                        markup={`${templatesConfig.PARSING_CONSTANTS.MARKER_START}__id__${templatesConfig.PARSING_CONSTANTS.MARKER_END}`}
                        trigger={templatesConfig.PARSING_CONSTANTS.TRIGGER}
                        style={mentionsInputStyles(theme)}
                        data={placeholderKeys.map(key => ({
                            display: parser.getLocalizedContextKey(key, t, false, false),
                            id: key
                        }))}
                        displayTransform={display => parser.getLocalizedContextKey(display, t, false)}
                        renderSuggestion={({ id }) => parser.getLocalizedContextKey(id, t, false, false)}
                    />
                </MentionsField>
                {/* <TextField
                label={t(`fontFamily`)}
                select
                className={classes.textField}
                value={currFont}
                onChange={handleFontChange}
            >
                {fontsList.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField> */}
                <TextField
                    label={t(`fontSize`)}
                    type="number"
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={fontSize}
                    onChange={evt =>
                        _toNumber(evt.target.value) > 0 && onEdit({ fontSize: evt.target.value })
                    }
                    fullWidth
                />
                <div className={classes.fontSettingsContainer}>
                    <React.Fragment>
                        <div>
                            <FormControl>
                                <InputLabel shrink>
                                    {t(`alignment`)}
                                </InputLabel>
                                <div className={classes.toggleContainer}>
                                    <ToggleButtonGroup
                                        value={textAlign}
                                        exclusive
                                        onChange={(evt, textAlign) => {
                                            if (textAlign != null)
                                                return onEdit({ textAlign })
                                        }}
                                    >
                                        <ToggleButton
                                            classes={{
                                                root: classes.toggleButton,
                                                selected: classes.selected
                                            }}
                                            value={theme.direction === "ltr" ? "left" : "right"} >
                                            {theme.direction === "ltr" ? <FormatAlignLeftTwoToneIcon /> : <FormatAlignRightTwoToneIcon />}
                                        </ToggleButton>
                                        <ToggleButton
                                            classes={{
                                                root: classes.toggleButton,
                                                selected: classes.selected
                                            }}
                                            value="center" >
                                            <FormatAlignCenterTwoToneIcon />
                                        </ToggleButton>
                                        <ToggleButton
                                            classes={{
                                                root: classes.toggleButton,
                                                selected: classes.selected
                                            }}
                                            value={theme.direction === "ltr" ? "right" : "left"} >
                                            {theme.direction === "ltr" ? <FormatAlignRightTwoToneIcon /> : <FormatAlignLeftTwoToneIcon />}
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                            </FormControl>
                        </div>
                    </React.Fragment>
                    <div className={classes.pickerContainer}>                <ColorPicker title={t("color")} color={color} colorType="rgba" onChange={color => onEdit({ color })} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TextTemplateSettings;