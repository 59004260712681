const styles = theme => ({
    paper: {
        overflowY: "inherit",
    },
    paperFullScreen: {
        overflowY: "inherit",
    },
    cardFullScreen: {
        marginBottom: 0,
        paddingTop: 50,
        boxShadow: "none",
        overflowY: "auto",
    },
    card: {
        boxShadow: "none",
        width: 500,
        marginTop: 0,
        maxHeight: "calc(100vh - 64px)",
        overflowY: "inherit",
        marginBottom: -34,
    },
    cardHeader: {
        width: "auto",
        textAlign: "center",
        marginLeft: 20,
        marginRight: 20,
        marginTop: -34,
        padding: "20px 0",
        marginBottom: 15,
    },
    cardBodyContainer: {
        marginTop: 0,
        overflowY: "auto",
        overflowX: "hidden",
        marginBottom: 0,
        boxShadow: "unset",
    },
    tabTextColor: {
        "&$selected": {
            color: theme.palette.text.primary,
        },
    },
    tab: {
        flex: 1,
        minWidth: 0,
    },
    saveButton: {
        marginTop: theme.spacing(4),
    },
    disabledTitleTabs: {
        color: "red",
        opacity: 1,
    },
    circularProgressContainer: {
        display: "flex",
        justifyContent: "center",
    },
    titlesContainer: {
        display: "flex",
        padding: `${theme.spacing(4)}px 0px`,
    },
    title: {
        flex: 1,
        textAlign: "start",
    },
    translationPreviewContainer: {
        display: "flex",
        padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    },
    verticalDivider: {
        marginRight: 12,
    },
    defaultResourceText: {
        flex: 1,
        textAlign: "start",
    },
    selected: {},
})

export default styles;
