export default function zoomCalculator(builderNode, builderContainerNode) {
  let zoomDetails = { fitWidthZoom: 1, fitHeightZoom: 1 };
  if (builderContainerNode && builderNode) {
    // getComputedStyle is better than getBoundingClientRect as it doesn't take in account transforms
    // also getComputedStyle is better than clientWidth, offsetWidth as it is not rounded
    const builderContainerHeight = parseFloat(getComputedStyle(builderContainerNode).getPropertyValue("height"));
    const builderContainerWidth = parseFloat(getComputedStyle(builderContainerNode).getPropertyValue("width"));
    const builderNodeHeight = parseFloat(getComputedStyle(builderNode).getPropertyValue("height"));
    const builderNodeWidth = parseFloat(getComputedStyle(builderNode).getPropertyValue("width"));

    // we are going to check how to zoom the builderNode to fit the builder container
    const fitHeightZoom = builderContainerHeight / (builderNodeHeight);
    const fitWidthZoom = builderContainerWidth / (builderNodeWidth);
    zoomDetails = { fitWidthZoom, fitHeightZoom };
  }
  const fitZoom =
    zoomDetails.fitHeightZoom < zoomDetails.fitWidthZoom
      ? zoomDetails.fitHeightZoom
      : zoomDetails.fitWidthZoom;

  const fillZoom =
    zoomDetails.fitHeightZoom > zoomDetails.fitWidthZoom
      ? zoomDetails.fitHeightZoom
      : zoomDetails.fitWidthZoom;

  return { ...zoomDetails, fitZoom, fillZoom };
}
