import React from "react";
import Box from "@material-ui/core/Box";
import useTheme from "@material-ui/styles/useTheme";

const OverlappingIcons = ({
  parentIcon: ParentIcon,
  childIcon: ChildIcon,
  renderChildIcon,
  childIconContainerStyles,
  defaultBgColor,
}) => {
  const theme = useTheme();
  return (
    <Box width={60} height={60} position="relative">
      <ParentIcon style={{ fontSize: 60 }} />
      <Box
        position="absolute"
        right="-5%"
        top="53%"
        style={{
          ...childIconContainerStyles
        }}
      >
        {renderChildIcon ? (
          renderChildIcon()
        ) : (
            <ChildIcon
              style={{
                fontSize: 30,
                background: defaultBgColor ? theme.palette.background.default : theme.palette.background.paper,
                borderRadius: "50%"
              }}
            />
          )}
      </Box>
    </Box>
  );
};

export default OverlappingIcons;
