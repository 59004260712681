import React, { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Divider from "@material-ui/core/Divider";
import Popover from '@material-ui/core/Popover';

// core components
import Button from "components/CustomButtons/Button.jsx";

import customDropdownStyle from "assets/jss/material-kit-react/components/customDropdownStyle.jsx";

const CustomDropdown = props => {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleItemClick = (index) => {
    setTimeout(() => {
      if (typeof props.onItemClick === 'function') {
        props.onItemClick(index);
      }
    }, 0);

    handleClose();
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const {
    classes,
    buttonText,
    buttonIcon,
    dropdownList,
    buttonProps,
    dropdownHeader,
    caret,
    hoverColor,
    rtlActive
  } = props;

  const caretClasses = classNames({
    [classes.caret]: true,
    [classes.caretActive]: open,
    [classes.caretRTL]: rtlActive
  });
  const dropdownItem = classNames({
    [classes[hoverColor + "Hover"]]: true,
    [classes.dropdownItemRTL]: rtlActive
  });


  return (
    <>
      <Button
        aria-label="dropdown"
        aria-owns={open ? "menu-list" : null}
        aria-haspopup="true"
        {...buttonProps}
        onClick={handleClick}
        style={{
          padding: "12px 10px",
          minHeight: 60
        }}
      >
        {buttonIcon !== undefined ? (
          <props.buttonIcon className={classes.buttonIcon} />
        ) : null}
        {buttonText !== undefined ? buttonText : null}
        {caret ? <b className={caretClasses} /> : null}
      </Button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
        className={classNames({
          [classes.popperClose]: !open,
          [classes.pooperResponsive]: true
        })}
      >
        <div className={classes.paperContainer}>
          <MenuList role="menu" className={classes.menuList}>
            {dropdownHeader !== undefined ? (
              <MenuItem
                onClick={handleClose}
                className={classes.dropdownHeader}
              >
                {dropdownHeader}
              </MenuItem>
            ) : null}
            {dropdownList.map((prop, key) => {
              if (prop.divider) {
                return (
                  <Divider
                    key={key}
                    onClick={handleClose}
                    className={classes.dropdownDividerItem}
                  />
                );
              }
              return (
                <MenuItem
                  key={key}
                  onClick={() => handleItemClick(key)}
                  className={dropdownItem}
                >
                  {prop}
                </MenuItem>
              );
            })}
          </MenuList>
        </div>
      </Popover>
    </>
  );
}

CustomDropdown.defaultProps = {
  caret: true,
  hoverColor: "primary"
};

CustomDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  hoverColor: PropTypes.oneOf(["primary", "black"]),
  buttonText: PropTypes.node,
  buttonIcon: PropTypes.func,
  dropdownList: PropTypes.array,
  buttonProps: PropTypes.object,
  onItemClick: PropTypes.func,
  dropup: PropTypes.bool,
  dropdownHeader: PropTypes.node,
  rtlActive: PropTypes.bool,
  caret: PropTypes.bool,
  left: PropTypes.bool
};

export default withStyles(customDropdownStyle)(CustomDropdown);
