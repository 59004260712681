import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const SelfRegistrantActionsIcon = ({ color, size, ...rest }) => {
    return (
        <SvgIcon
            width={size}
            height={size}
            viewBox={size && `0 0 ${size} ${size}`}
            fill="none"
            {...rest}
        >
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M 5 4 C 3.346 4 2 5.346 2 7 L 2 21 C 2 22.654 3.346 24 5 24 L 8.7578125 24 L 6.7578125 22 L 5 22 C 4.448 22 4 21.551 4 21 L 4 7 C 4 6.449 4.448 6 5 6 L 27 6 C 27.552 6 28 6.449 28 7 L 28 21 C 28 21.551 27.552 22 27 22 L 25.998047 22 L 25.998047 22.998047 C 25.995047 23.317047 25.96225 23.655 25.90625 24 L 27 24 C 28.654 24 30 22.654 30 21 L 30 7 C 30 5.346 28.654 4 27 4 L 5 4 z M 12.994141 8 L 12.994141 10.996094 L 14.994141 10.996094 L 14.994141 8 L 12.994141 8 z M 17.53125 9.046875 L 15.412109 11.166016 L 16.828125 12.578125 L 18.945312 10.458984 L 17.53125 9.046875 z M 10.458984 9.0546875 L 9.046875 10.46875 L 11.166016 12.587891 L 12.578125 11.171875 L 10.458984 9.0546875 z M 17.003906 12.994141 L 17.003906 14.994141 L 20 14.994141 L 20 12.994141 L 17.003906 12.994141 z M 12.998047 13 L 12.998047 22.583984 L 10.980469 20.566406 L 9.5664062 21.980469 L 14.291016 26.705078 L 14.330078 26.666016 C 15.183588 27.413813 16.537306 27.998047 18.509766 27.998047 C 21.032228 27.998047 22.586428 26.734265 23.291016 25.490234 C 23.995604 24.246204 23.998047 23.007812 23.998047 23.007812 L 23.998047 23.003906 L 23.998047 17.998047 L 21.998047 17.998047 L 21.998047 22.986328 C 21.998047 22.986328 21.978947 23.74989 21.550781 24.505859 C 21.122619 25.261829 20.432303 25.998047 18.509766 25.998047 C 16.511687 25.998047 15.763713 25.392399 15.380859 24.890625 C 14.998006 24.388851 14.998047 23.976565 14.998047 23.976562 L 14.998047 13 L 12.998047 13 z M 8 13.005859 L 8 15.005859 L 10.996094 15.005859 L 10.996094 13.005859 L 8 13.005859 z M 15.998047 15.998047 L 15.998047 20.998047 L 17.998047 20.998047 L 17.998047 15.998047 L 15.998047 15.998047 z M 18.998047 16.998047 L 18.998047 20.998047 L 20.998047 20.998047 L 20.998047 16.998047 L 18.998047 16.998047 z"
                fill={color}
            />
        </SvgIcon>
    );
};

export default SelfRegistrantActionsIcon;
