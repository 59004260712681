import React, { useState } from "react";

import { useTheme } from "@material-ui/styles";
import makeStyles from "@material-ui/core/styles/makeStyles"
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TextField from "@material-ui/core/TextField";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

import EditIcon from "@material-ui/icons/Edit";

import styles from "./styles";

const useStyles = makeStyles(styles, { name: "FormEditField" });

const FormEditField = ({ value, onChange }) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const [mouseOver, setMouseOver] = useState(false);
    const [editMode, setEditMode] = useState(false);

    return (
        <ClickAwayListener onClickAway={() => setEditMode(false)}>
            <TextField
                className={classes.textField}
                defaultValue={value}
                onChange={(event) => onChange(event.target.value)}
                disabled={!editMode}
                onMouseEnter={() => { setMouseOver(true) }}
                onMouseLeave={() => { setMouseOver(false) }}
                InputProps={{
                    classes: {
                        disabled: classes.disabled
                    },
                    endAdornment:
                        (mouseOver && !editMode) || fullScreen ? (
                            <InputAdornment position="end">
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setEditMode(true)
                                        setMouseOver(false);
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            </InputAdornment>
                        ) : null
                }}
            />
        </ClickAwayListener>
    )
}
export default FormEditField;