import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const AnnouncementsIcon = ({ color, size, ...rest }) => {
    return (
        <SvgIcon
            width={size}
            height={size}
            viewBox={size && `0 0 ${size} ${size}`}
            fill="none"
            color={color}
            {...rest}
        >
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M32.4 0L30.6 2.28472L7.2 10.6667V21.3333L11.6121 22.9132L11.1023 24.3368C10.7189 25.4053 11.2844 26.5762 12.368 26.9549L16.2879 28.3264C17.3697 28.7051 18.5588 28.1431 18.9422 27.0729L19.4273 25.7153L30.6 29.7153L32.4 32H36V0H32.4ZM32.4 5.42361V26.5764L31.827 26.3715L10.8 18.8403V13.1597L31.827 5.62847L32.4 5.42361ZM0 10.6667V21.3333H3.6V10.6667H0Z"
                fill={color}
            />
        </SvgIcon>

    );
};

export default AnnouncementsIcon;
