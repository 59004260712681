import React from "react";
import _get from "lodash/get";

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";

import CreateEventForm from "views/EventsPage/CreateEventForm.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CustomButton from "components/CustomButtons/Button.jsx";

import compose from "recompose/compose";
import { useTranslation } from "react-i18next";

import { useQuery } from "react-apollo";
import queryCreators from "../../apollo/queryCreators";

const styles = theme => ({
  paper: {
    overflowY: "inherit"
  },
  cardFullScreen: {
    marginBottom: 0,
    paddingTop: 50,
    boxShadow: "none",
    overflowY: "auto"
  },
  card: {
    marginBottom: 0,
    boxShadow: "none"
  },
  cardHeader: {
    width: "auto",
    textAlign: "center",
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "-55px",
    padding: "20px 0",
    marginBottom: "15px"
  },
  cardHeaderTitle: {
    color: theme.palette.primary.contrastText
  },
  noMargins: {
    margin: 0
  }
});

const CreateEventModal = ({
  open,
  onClose,
  transition,
  onSubmit,
  classes,
  fullScreen,
}) => {

  const { t, i18n } = useTranslation("eventsPage");

  const { loading: supportedLanguagesLoading, data: supportedLanguagesData, error: supportedLanguagesError } = useQuery(queryCreators.GET_SUPPORTED_LANGUAGES,
    {
      variables: {
        language: i18n.language,
      },
    },
  );

  const supportedLanguages = _get(supportedLanguagesData, "supportedLanguages", []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={transition}
      classes={{
        paper: classes.paper
      }}
      maxWidth="xs"
      fullScreen={fullScreen}
    >
      <Card className={fullScreen ? classes.cardFullScreen : classes.card}>
        <CardHeader className={classes.cardHeader} color="primary">
          <Typography
            className={classes.cardHeaderTitle}
            variant="subtitle1"
          >
            {t("eventsPage:createEvent")}
          </Typography>
        </CardHeader>
        <CardBody>
          <CreateEventForm
            onSubmit={onSubmit}
            supportedLanguages={supportedLanguages}
            supportedLanguagesLoading={supportedLanguagesLoading}
            supportedLanguagesError={supportedLanguagesError}
          />
          {fullScreen && (
            <CustomButton
              fullWidth={true}
              simple
              color="primary"
              size="lg"
              onClick={onClose}
              className={classes.noMargins}
            >
              {t("eventsPage:cancel")}
            </CustomButton>
          )}
        </CardBody>
      </Card>
    </Dialog>
  );
}

export default compose(
  withStyles(styles)
)(CreateEventModal);
