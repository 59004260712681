import React, { Fragment, useEffect, useState } from "react";
import { useQuery } from 'react-apollo';
import _get from "lodash/get";
import compose from "recompose/compose";
import queryString from 'query-string';
import _isNil from "lodash/isNil"
// material-ui components
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from "@material-ui/core/CircularProgress";
import NoBackgroundButton from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
// icons
import Email from "@material-ui/icons/Email";
import Lock from "@material-ui/icons/Lock";
import ContactPhone from "@material-ui/icons/ContactPhone";
// core components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Snackbar from "../../components/CustomSnackbar/Snackbar.jsx";
//i18n
import { withTranslation } from "react-i18next";

import { Formik, } from 'formik';
import * as Yup from 'yup';
import "yup-phone";

import request from "../../modules/axios";
import { Link } from "react-router-dom";

import { getCountryCallingCode, getCountries, formatPhoneNumberIntl } from 'react-phone-number-input'
import en from 'react-phone-number-input/locale/en.json'
import routePaths from "../../config/routePaths"
import queryCreators from '../../apollo/queryCreators';
import { errorTypes } from "../../enums";
import styles from "./styles";

const useStyles = makeStyles(styles, { name: "RegisterPage" });

const httpRegister = async userData => {
  const response = await request.post(
    "/accounts/registration",
    userData
  );
  return response;
};


const RegisterPage = ({ t, history }) => {
  const classes = useStyles();

  const [cardAnimation, setCardAnimation] = useState("cardHidden");
  const [isLoading, setLoading] = useState(false);
  const [showNotification, toggleNotification] = useState(false);
  const [snackbarVariant, setSnackbarVariant] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const defaultCountryCode = "EG";
  const queryParams = queryString.parse(history.location.search);

  const { loading: invitationLoading, error: invitationError, data } =
    useQuery(queryCreators.GET_ACCOUNT_INVITATION.query, {
      skip: !queryParams.invId,
      variables: {
        id: queryParams.invId
      },
    });

  const invitedEmail = _get(data, 'unauthenticatedUserInvitation.invitedEmail', undefined);
  const invitationData = _get(data, 'unauthenticatedUserInvitation', {});

  useEffect(() => {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(function () {
      setCardAnimation("");
    }, 700);
  });

  const register = async (values) => {
    setLoading(true);
    try {
      await httpRegister({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        mobileNumber: formatPhoneNumberIntl(`+${getCountryCallingCode(values.countryCode)}${values.mobileNumber}`).replace(/ /g, ""),
        invId: queryParams.invId,
      });
      setSnackbarVariant("success");
      setSnackbarMessage("Account was created successfully");
      toggleNotification(true);
      queryParams.invId &&
        history.replace({
          pathname: `${routePaths.INVITATION}`,
          search: `?invId=${queryParams.invId}`,
        })
    } catch (e) {
      setSnackbarVariant("error");
      if (_get(e, 'response.data.errorType') === errorTypes.ACCOUNT_ALREADY_EXISTS) {
        setSnackbarMessage(t("accountAlreadyExist"));
      }
      else {
        setSnackbarMessage(t("errorBoundry:somethingWentWrong"));
      }
      toggleNotification(true);
    }
    setLoading(false);
  };

  const RegistrationFormSchema = Yup.object().shape({
    firstName: Yup.string().required(t("firstNameRequired")),
    lastName: Yup.string().required(t("lastNameRequired")),
    email: Yup.string()
      .email(t("invalidEmail"))
      .required(t("requiredEmail")),
    password: Yup.string()
      .min(6, t("tooShortPassword"))
      .required(t("requiredPassword")),
    verifyPassword: Yup.string().oneOf([Yup.ref("password")], "Passwords doesn't match").required(t("matchPasswordField")),
    countryCode: Yup.string(),
    mobileNumber: Yup.string().required(t("requiredMobileNumber")).test("validateMobileNumber", t("invalidMobileNumber"),
      function (value) {
        return Yup.string().phone(defaultCountryCode).isValidSync(`+${getCountryCallingCode(this.parent.countryCode)}${value}`)
      }),
  });

  if (invitationError || _isNil(invitationData)) {
    return (
      <Grid container justify="center" alignItems="center" className={classes.registerPageContainer}>
        <Grid container direction="column" alignItems="center">
          <Typography variant="h4" gutterBottom className={classes.centerText}>
            {
              _isNil(invitationData) ?
                t("noLongerAvailable")
                : t("errorOccurred")
            }
          </Typography>
          <NoBackgroundButton>
            <Link className={classes.link} to={routePaths.HOME}>
              {t("home")}
            </Link>
          </NoBackgroundButton>
        </Grid>
      </Grid>
    )
  }
  if (!invitationLoading) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Card
          style={{
            maxWidth: 450
          }}
          className={classes[cardAnimation]}
        >
          <Snackbar
            open={showNotification}
            autoHideDuration={6000}
            onClose={() => toggleNotification(false)}
            message={snackbarMessage}
            variant={snackbarVariant}
          />
          <div className={classes.form}>
            <CardHeader color="primary" className={classes.cardHeader}>
              <Typography
                className={classes.cardHeaderTitle}
                variant="subtitle1"
                gutterBottom
              >
                {t("registerPage:register")}
              </Typography>
            </CardHeader>
            <CardBody>
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  email: invitedEmail || "",
                  password: "",
                  mobileNumber: "",
                  countryCode: defaultCountryCode,
                  verifyPassword: "",
                }}
                validationSchema={RegistrationFormSchema}
                onSubmit={values => {
                  register(values);
                }}
                validateOnChange={false}
              >
                {props => {
                  const {
                    errors,
                    values,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  } = props
                  return (
                    <form onSubmit={handleSubmit}>
                      <CustomInput
                        error={!!(touched.firstName && errors.firstName)}
                        errorText={errors.firstName}
                        labelText={t("registerPage:firstName")}
                        id="firstName"
                        formControlProps={{
                          fullWidth: true
                        }}
                        InputProps={{
                          type: "text",
                          value: values.firstName,
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                      />
                      <CustomInput
                        error={!!(touched.lastName && errors.lastName)}
                        errorText={errors.lastName}
                        labelText={t("registerPage:lastName")}
                        id="lastName"
                        formControlProps={{
                          fullWidth: true
                        }}
                        InputProps={{
                          type: "text",
                          value: values.lastName,
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                      />
                      <CustomInput
                        error={!!(touched.email && errors.email)}
                        errorText={errors.email}
                        labelText={t("registerPage:email")}
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        InputProps={{
                          type: "email",
                          value: values.email,
                          disabled: !_isNil(invitedEmail), // if page was redirected from invitation page then disable email field
                          onChange: handleChange,
                          onBlur: handleBlur,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                      />
                      <CustomInput
                        labelText={t("registerPage:password")}
                        error={!!(touched.password && errors.password)}
                        errorText={errors.password}
                        id="password"
                        formControlProps={{
                          fullWidth: true
                        }}
                        InputProps={{
                          type: "password",
                          value: values.password,
                          onChange: handleChange,
                          onBlur: handleBlur,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Lock className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                      />
                      <CustomInput
                        error={!!(touched.verifyPassword && errors.verifyPassword)}
                        errorText={errors.verifyPassword}
                        labelText={t("registerPage:verifyPassword")}
                        id="verifyPassword"
                        formControlProps={{
                          fullWidth: true
                        }}
                        InputProps={{
                          type: "password",
                          value: values.verifyPassword,
                          onChange: handleChange,
                          onBlur: handleBlur,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Lock className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                      />
                      <div style={{
                        display: "flex",
                      }}>
                        <div className={classes.countrySelectContainer}>
                          <InputLabel shrink>{t("country")}</InputLabel>
                          <Select
                            name="countryCode"
                            classes={{
                              select: classes.countrySelect,
                            }}
                            value={values.countryCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            renderValue={() => (
                              <Fragment>
                                <img
                                  className={classes.countryFlag}
                                  alt={en[values.countryCode]}
                                  src={`http://catamphetamine.gitlab.io/country-flag-icons/3x2/${values.countryCode}.svg`}
                                />
                                {`+${getCountryCallingCode(values.countryCode)}`}
                              </Fragment>
                            )}
                          >
                            {getCountries().map(country => (
                              <MenuItem key={country} value={country}>
                                <img
                                  className={classes.countryFlag}
                                  alt={en[country]}
                                  src={`http://catamphetamine.gitlab.io/country-flag-icons/3x2/${country}.svg`}
                                />
                                {`${en[country]} +${getCountryCallingCode(country)}`}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                        <div style={{ flex: 3, }} >
                          <CustomInput
                            error={!!(touched.mobileNumber && errors.mobileNumber)}
                            errorText={errors.mobileNumber}
                            labelText={t("registerPage:mobileNumber")}
                            id="mobileNumber"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            InputProps={{
                              type: "text",
                              value: values.mobileNumber,
                              onChange: handleChange,
                              onBlur: handleBlur,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <ContactPhone className={classes.inputIconsColor} />
                                </InputAdornment>
                              )
                            }}
                          />
                        </div>
                      </div>
                      <Button
                        type="submit"
                        fullWidth={true}
                        color="primary"
                        size="lg"
                      >
                        <div style={{ color: "white" }}>
                          {
                            isLoading ?
                              <CircularProgress thickness={3} size={30} color="inherit" />
                              :
                              t("registerPage:register")
                          }
                        </div>
                      </Button>

                      {!queryParams.invId && (
                        <Button fullWidth={true} simple color="primary" size="lg">
                          <Link
                            style={{
                              width: "100%"
                            }}
                            to={routePaths.LOGIN}
                          >
                            {t("registerPage:login")}
                          </Link>
                        </Button>
                      )}
                    </form>
                  )
                }
                }
              </Formik>
            </CardBody>
          </div>
        </Card >
      </div >
    );
  }
  return null; // show some loading indicator 
};

export default compose(
  withTranslation("registerPage"),
)(RegisterPage);
