const styles = theme => ({
    btnContainer: {
        marginTop: theme.spacing(1)
    },
    selectContainer: {
        marginBottom: theme.spacing(3)
    },
    modalCardBody: {
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
    },
});

export default styles;
