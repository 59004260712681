const styles = theme => ({
    formControl: {
        margin: `${theme.spacing(1)}px 0`
    },
    readOnly: {
        pointerEvents: 'none',
    }
});

export default styles;
