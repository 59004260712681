import React, { useReducer } from "react";
import classNames from "classnames";

import { makeStyles, useTheme } from "@material-ui/styles";
import _get from "lodash/get";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";

import LogoIcon from "components/Icons/Logo";
import GlobeIcon from "components/Icons/GlobeIcon";
import SigoutIcon from "components/Icons/SigoutIcon";
import EventIcon from '@material-ui/icons/Event';
import SettingsIcon from '@material-ui/icons/Settings';

import useMediaQuery from "@material-ui/core/useMediaQuery";

//graphql
import { useQuery } from "react-apollo";
import queryCreators from "./../../apollo/queryCreators";

//i18n
import { useTranslation } from "react-i18next";

import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Snackbar from "../../components/CustomSnackbar/Snackbar";
import useAuth from "../../contexts/AuthenticationContext/useAuth";

import styles from "./styles";

import { useHistory } from "react-router-dom";
import routePaths from "../../config/routePaths";

const useStyles = makeStyles(styles, { name: "EventsPage" });

const langsArray = [
    { label: "عربي", value: "ar" },
    { label: "English", value: "en" }
];

const HomePage = ({ routes = () => { } }) => {
    const history = useHistory();
    const { logout, userInfo } = useAuth();

    /* We needed to use useReducer here because snackbar flickers if it isn't passed
    the previous value for variant*/
    const [snackbarData, setSnackbarData] = useReducer(
        (state, action) => {
            if (action.type === "SHOW_SNACKBAR") {
                return {
                    ...action.payload
                };
            } else {
                return {
                    ...state,
                    show: false
                };
            }
        },
        {
            show: false
        }
    );

    const { loading: accountLoading, error, data: account } = useQuery(
        queryCreators.GET_USER_ACCOUNT.query,
        {
            variables: {
                email: _get(userInfo, "email", ""),
            }
        }
    );
    const accountData = _get(account, "account", {});

    const classes = useStyles();
    const theme = useTheme();
    const showLogoText = useMediaQuery(theme.breakpoints.up(410));
    const { t, i18n } = useTranslation("eventsPage");

    const accountOptionsMenuArray = [
        { label: t("events"), icon: <EventIcon />, path: routePaths.EVENTS },
        { label: t("account"), icon: <SettingsIcon />, path: routePaths.ACCOUNT },
        { label: "divider" },
        { label: t("logout"), icon: <SigoutIcon />, onClick: logout },
    ];

    return (
        <div className={classes.root}>
            <AppBar position="sticky" className={classNames(classes.appBar)}>
                <Toolbar disableGutters={true}>
                    <div
                        className={classes.appBarLogoContainer}
                        onClick={() => {
                            history.replace(routePaths.HOME);
                        }}
                    >
                        <LogoIcon className={classes.appBarLogo} />
                        {showLogoText &&
                            <Typography
                                className={classes.appBarTitle}
                                variant="subtitle1"
                                color="inherit"
                                noWrap
                            >
                                {"Eventsquare"}
                            </Typography>
                        }
                    </div>
                    <div className={classes.appBarRight}>
                        <List className={classes.appBarRightList}>
                            <ListItem className={classes.appBarRightListItem}>
                                <CustomDropdown
                                    buttonProps={{
                                        color: "transparent",
                                    }}
                                    rtlActive={theme.direction === "rtl"}
                                    left
                                    buttonIcon={() =>
                                        (!accountLoading && !error) &&
                                        <Avatar
                                            src={accountData.logoUrl}
                                            className={classes.avatar}
                                        >
                                            {`${_get(accountData, "firstName", "").substring(0, 1)}${_get(accountData, "lastName", "").substring(0, 1)}`}
                                        </Avatar>
                                    }
                                    dropdownList={
                                        accountOptionsMenuArray.map(option =>
                                            option.label === "divider" ?
                                                { divider: true } :
                                                <>
                                                    <ListItemIcon
                                                        classes={{
                                                            root: classes.listItemIcon,
                                                        }}
                                                    >
                                                        {option.icon}
                                                    </ListItemIcon>
                                                    <ListItemText className={classes.listItemText} primary={option.label} />
                                                </>
                                        )}
                                    onItemClick={optionIndex => {
                                        if (accountOptionsMenuArray[optionIndex].onClick) {
                                            accountOptionsMenuArray[optionIndex].onClick();
                                        }
                                        history.replace(accountOptionsMenuArray[optionIndex].path);
                                    }}
                                />
                            </ListItem>
                            <ListItem className={classes.appBarRightListItem}>
                                <CustomDropdown
                                    buttonProps={{
                                        color: "transparent"
                                    }}
                                    rtlActive={theme.direction === "rtl"}
                                    left
                                    buttonIcon={() => <GlobeIcon />}
                                    dropdownList={langsArray.map(lang => <ListItemText primary={lang.label} />)}
                                    onItemClick={langIndex => {
                                        i18n.changeLanguage(langsArray[langIndex].value);
                                    }}
                                />
                            </ListItem>
                        </List>
                    </div>
                </Toolbar>
            </AppBar>
            {routes()}
            <Snackbar
                open={snackbarData.show}
                autoHideDuration={6000}
                onClose={() =>
                    setSnackbarData({
                        show: false
                    })
                }
                message={snackbarData.message}
                variant={snackbarData.variant}
            />
        </div>
    );
};

export default HomePage;