import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const MenuIcon = ({ color, size, ...rest }) => {
  return (
    <SvgIcon
      width={size}
      height={size}
      viewBox={size && `0 0 ${size} ${size}`}
      fill="none"
      {...rest}
    >
      <path d="M6 3.49316C4.62817 3.49316 3.5 4.62134 3.5 5.99316C3.5 7.36499 4.62817 8.49316 6 8.49316H18C19.3718 8.49316 20.5 7.36499 20.5 5.99316C20.5 4.62134 19.3718 3.49316 18 3.49316H6ZM6 4.99316H18C18.5612 4.99316 19 5.43199 19 5.99316C19 6.55434 18.5612 6.99316 18 6.99316H6C5.43883 6.99316 5 6.55434 5 5.99316C5 5.43199 5.43883 4.99316 6 4.99316ZM6 9.49316C4.62817 9.49316 3.5 10.6213 3.5 11.9932C3.5 13.365 4.62817 14.4932 6 14.4932H18C19.3718 14.4932 20.5 13.365 20.5 11.9932C20.5 10.6213 19.3718 9.49316 18 9.49316H6ZM6 10.9932H18C18.5612 10.9932 19 11.432 19 11.9932C19 12.5543 18.5612 12.9932 18 12.9932H6C5.43883 12.9932 5 12.5543 5 11.9932C5 11.432 5.43883 10.9932 6 10.9932ZM6 15.4932C4.62817 15.4932 3.5 16.6213 3.5 17.9932C3.5 19.365 4.62817 20.4932 6 20.4932H18C19.3718 20.4932 20.5 19.365 20.5 17.9932C20.5 16.6213 19.3718 15.4932 18 15.4932H6ZM6 16.9932H18C18.5612 16.9932 19 17.432 19 17.9932C19 18.5543 18.5612 18.9932 18 18.9932H6C5.43883 18.9932 5 18.5543 5 17.9932C5 17.432 5.43883 16.9932 6 16.9932Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default MenuIcon;
