import React, { useEffect, createRef } from "react"
import Grow from "@material-ui/core/Grow";
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import PubSub from 'pubsub-js'
import { SnackbarProvider, useSnackbar } from 'notistack';

const defaultVariant = "success";
const defaultAutoHideDuration = 6000;
const PUB_SUB_EVENT_NAME = "GlobalSnackbar_showSnackbar";

const snackbarRef = createRef();

const SnackbarSubscription = () => {
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        PubSub.subscribe(PUB_SUB_EVENT_NAME, (_, data) => {
            const { message = "", variant = defaultVariant, autoHideDuration = defaultAutoHideDuration } = data;
            enqueueSnackbar(message, { variant, autoHideDuration })
        })
        return () => {
            // unsubscribing on component unmounting so the same instance doesn't get duplicated
            PubSub.unsubscribe(PUB_SUB_EVENT_NAME)
        };
    }, [enqueueSnackbar]);
    return null;
};

const showGlobalSnackbar = (message, variant, autoHideDuration) => {
    PubSub.publish(PUB_SUB_EVENT_NAME, {
        message: message,
        variant: variant,
        autoHideDuration: autoHideDuration
    });
}
const GlobalSnackbar = () => {
    return (
        <SnackbarProvider
            // preventDuplicate
            ref={snackbarRef}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            TransitionComponent={Grow}
            maxSnack={2}
            action={(key) => (
                <IconButton onClick={() => snackbarRef.current.closeSnackbar(key)}>
                    <ClearIcon style={{ color: "white" }} />
                </IconButton>
            )}
        >
            <SnackbarSubscription />
        </SnackbarProvider>
    )
}

export { GlobalSnackbar, showGlobalSnackbar }
