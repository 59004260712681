import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const AttendIcon = ({ color, size, ...rest }) => {
  return (
    <SvgIcon
      width={size}
      height={size}
      viewBox={size && `0 0 ${size} ${size}`}
      fill="none"
      {...rest}
    >
      <path d="M12 2C9.24746 2 7 4.24746 7 7C7 9.75254 9.24746 12 12 12C14.7525 12 17 9.75254 17 7C17 4.24746 14.7525 2 12 2ZM12 3.5C13.9419 3.5 15.5 5.05812 15.5 7C15.5 8.94188 13.9419 10.5 12 10.5C10.0581 10.5 8.5 8.94188 8.5 7C8.5 5.05812 10.0581 3.5 12 3.5ZM17.5 12C14.4625 12 12 14.4625 12 17.5C12 20.5375 14.4625 23 17.5 23C20.5375 23 23 20.5375 23 17.5C23 14.4625 20.5375 12 17.5 12ZM6.25 14C5.0095 14 4 15.0095 4 16.25V16.8496C4 19.7376 7.514 22 12 22C12.2685 22 12.5329 21.9906 12.7939 21.9746C12.3749 21.5341 12.0164 21.0367 11.7334 20.4922C7.8779 20.4092 5.5 18.5651 5.5 16.8496V16.25C5.5 15.8365 5.8365 15.5 6.25 15.5H11.3174C11.4899 14.966 11.7318 14.4635 12.0293 14H6.25ZM20.5 15C20.6279 15 20.7558 15.0487 20.8535 15.1465C21.049 15.342 21.049 15.658 20.8535 15.8535L16.8535 19.8535C16.756 19.951 16.628 20 16.5 20C16.372 20 16.244 19.951 16.1465 19.8535L14.1465 17.8535C13.951 17.658 13.951 17.342 14.1465 17.1465C14.342 16.951 14.658 16.951 14.8535 17.1465L16.5 18.793L20.1465 15.1465C20.2442 15.0487 20.3721 15 20.5 15Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default AttendIcon;
