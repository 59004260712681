import React from "react"
import QrReader from 'react-qr-reader2'
import { useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Dialog from '@material-ui/core/Dialog';
import { useTheme } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CardBody from "../../components/Card/CardBody.jsx";
import CardHeader from "../../components/Card/CardHeader.jsx";
import CustomButton from "../../components/CustomButtons/Button.jsx";
import Card from "../../components/Card/Card.jsx";
import { showGlobalSnackbar } from "../../components/GlobalSnackbar";
import styles from "./styles";

const useStyles = makeStyles(styles, { name: "QRCodeScannerModal" });

const QRCodeScannerModal = ({ open, onClose, onScan }) => {
    const classes = useStyles();
    const { t } = useTranslation("QRCodeScannerModal");
    const theme = useTheme();

    // any screen width below xs "600px" will set fullscreen to true
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (

        <Dialog
            open={open}
            onClose={onClose}
            classes={{
                paper: fullScreen ? classes.paperFullScreen : classes.paper
            }}
            fullScreen={fullScreen}
        >
            <Card className={fullScreen ? classes.cardFullScreen : classes.card}>

                <CardHeader className={classes.cardHeader} color="primary">
                    <Typography variant="subtitle1">
                        {t("searchByQrCode")}
                    </Typography>
                </CardHeader>
                <CardBody>
                    <div className={classes.bodyContainer} >
                        <QrReader
                            delay={1000}
                            onScan={(data) => {
                                if (data) {
                                    onScan(JSON.parse(data).rID);
                                    onClose();
                                }
                            }}
                            onError={() => showGlobalSnackbar(t("scanError"), "error")}
                            style={{ flex: 1 }}
                        />
                    </div>

                    {fullScreen && (
                        <CustomButton
                            fullWidth={true}
                            simple
                            color="primary"
                            size="lg"
                            onClick={onClose}
                            className={classes.noMargins}
                        >
                            {t("templatesPage:cancel")}
                        </CustomButton>
                    )}
                </CardBody>
            </Card>
        </Dialog>
    )
}
export default QRCodeScannerModal;