import React, { useState, Fragment } from "react"
import { useTranslation } from "react-i18next";
import NameBadgeModal from "./NameBadgeModal"
import Grid from "@material-ui/core/Grid";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import BadgeTemplateCard from "./BadgeTemplateCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams } from "react-router-dom";
import _get from "lodash/get";
//graphql
import { useMutation, useQuery } from "react-apollo";
import queryCreators from "apollo/queryCreators";
import Snackbar from "../../../components/CustomSnackbar/Snackbar";
import TemplateBuilderIcon from 'components/Icons/TemplateBuilderIcon'
import ErrorPage from "../ErrorPage";
import styles from "./styles";

const useStyles = makeStyles(styles, { name: "TemplatesPage" });

const TemplatesPage = () => {
    const theme = useTheme();
    const [isModalOpen, setIsModalOpen] = useState(false); // create badge modal state
    const [snackbar, setSnackbar] = useState({
        showSnackbar: false,
        message: "",
        variant: ""
    });
    const { eventID } = useParams(); // the eventID gets passed from the currentURL
    const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const classes = useStyles();
    const { t } = useTranslation("templatesPage");
    const { loading: badgesLoading, error, data } = useQuery(
        queryCreators.GET_EVENT_BADGE_TEMPLATES.query,
        {
            variables: {
                eventID
            }
        }
    );
    const badgeTemplates = _get(data, "event.badgeTemplates", []);
    const [addBadgeTemplate] = useMutation(
        queryCreators.ADD_BADGE_TEMPLATE.mutation
    );
    const onBadgeTemplateAdd = async (badgeObject) => {
        try {
            await queryCreators.ADD_BADGE_TEMPLATE.mutate(addBadgeTemplate)({
                badge: {
                    ...badgeObject,
                    eventID
                }
            },
                {
                    eventID
                }
            );
        } catch (e) {
            setSnackbar({
                showSnackbar: true,
                message: t("templatesPage:faildCreateError"),
                variant: "error"
            })
        }
    }

    return (
        <Fragment>
            <div className={classes.titleBar}>
                <Grid container direction="row" alignItems="center">
                    <Typography variant="h5" noWrap>
                        {t("templatesPage:pageTitle")}
                    </Typography>
                </Grid>
            </div>
            {
                error ? <ErrorPage /> :
                    <Fragment>
                        <Grid
                            className={classes.addButton}
                        >
                            <Fab // modal toggle button
                                color="primary"
                                onClick={() => setIsModalOpen(true)}
                                size={smallScreen ? "medium" : "large"}
                            >
                                <AddIcon />
                            </Fab>
                        </Grid>
                        <NameBadgeModal
                            onTemplateAdd={onBadgeTemplateAdd}
                            isOpen={isModalOpen}
                            onClose={() => setIsModalOpen(false)}
                        />
                        {
                            !badgesLoading ? badgeTemplates.length > 0 ?
                                < Grid container spacing={3} className={classes.container}>
                                    {
                                        badgeTemplates.map((template) =>
                                            <Grid item key={template.id} xs={12} sm={6} md={4} lg={3} xl={2}>
                                                <BadgeTemplateCard
                                                    onError={error =>
                                                        setSnackbar({
                                                            showSnackbar: true,
                                                            message: error,
                                                            variant: "error"
                                                        })}
                                                    template={template}
                                                />
                                            </Grid >
                                        )
                                    }

                                </Grid> :
                                <Grid // empty page message
                                    item
                                    container
                                    alignItems="center"
                                    justify="center"
                                    className={classes.noBadgesFound}
                                >
                                    <TemplateBuilderIcon className={classes.noBadgesIcon} />
                                    <Typography variant="h6"> {t("templatesPage:pleaseAddBadges")}</Typography>
                                </Grid>
                                :
                                <Grid
                                    item
                                    container
                                    alignItems="center"
                                    justify="center"
                                    className={classes.spinnerContainer}
                                >
                                    <CircularProgress thickness={7} />
                                </Grid>
                        }
                        <div style={{ justifyContent: "center" }}>
                            <Snackbar
                                open={snackbar.showSnackbar}
                                autoHideDuration={6000}
                                onClose={() => setSnackbar({
                                    showSnackbar: false,
                                    message: snackbar.message,
                                    variant: snackbar.variant
                                })}
                                message={snackbar.message}
                                variant={snackbar.variant}
                            />
                        </div>
                    </Fragment>
            }

        </Fragment >
    )
}

export default TemplatesPage;