import React from "react";

import ListAltIcon from "@material-ui/icons/ListAlt";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "../../../components/CustomButtons/Button";

import styles from "./styles";
import { useTranslation } from "react-i18next";
import OverlappingIcons from "../../../components/OverlappingIcons/OverlappingIcons";

const useStyles = makeStyles(styles);

const FormErrorView = ({ notParseable, notModifiable, onResetClick }) => {
  const classes = useStyles();
  const { t } = useTranslation("createForm");
  return (
    <div className={classes.notParseableForm}>
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <OverlappingIcons parentIcon={ListAltIcon} childIcon={ErrorIcon} />
        <Typography color="inherit" variant="h6" noWrap>
          {notParseable && t("createForm:notParseable")}
          {notModifiable && t("createForm:notModifiable")}
        </Typography>
        {notParseable && (
          <Button fullWidth color="primary" onClick={onResetClick}>
            {t("createForm:reset")}
          </Button>
        )}
      </Box>
    </div>
  );
};

export default FormErrorView;
