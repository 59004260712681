import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const DashboardIcon = ({ color, size, ...rest }) => {
  return (
    <SvgIcon
      width={size}
      height={size}
      viewBox={size && `0 0 ${size} ${size}`}
      fill="none"
      {...rest}
    >
      <path d="M5.25 3C4.01625 3 3 4.01625 3 5.25V6.75C3 7.98375 4.01625 9 5.25 9H9.25C10.4838 9 11.5 7.98375 11.5 6.75V5.25C11.5 4.01625 10.4838 3 9.25 3H5.25ZM14.75 3C13.5162 3 12.5 4.01625 12.5 5.25V11.75C12.5 12.9838 13.5162 14 14.75 14H18.75C19.9838 14 21 12.9838 21 11.75V5.25C21 4.01625 19.9838 3 18.75 3H14.75ZM5.25 4.5H9.25C9.67325 4.5 10 4.82675 10 5.25V6.75C10 7.17325 9.67325 7.5 9.25 7.5H5.25C4.82675 7.5 4.5 7.17325 4.5 6.75V5.25C4.5 4.82675 4.82675 4.5 5.25 4.5ZM14.75 4.5H18.75C19.1732 4.5 19.5 4.82675 19.5 5.25V11.75C19.5 12.1732 19.1732 12.5 18.75 12.5H14.75C14.3268 12.5 14 12.1732 14 11.75V5.25C14 4.82675 14.3268 4.5 14.75 4.5ZM5.25 10C4.01625 10 3 11.0162 3 12.25V18.75C3 19.9838 4.01625 21 5.25 21H9.25C10.4838 21 11.5 19.9838 11.5 18.75V12.25C11.5 11.0162 10.4838 10 9.25 10H5.25ZM5.25 11.5H9.25C9.67325 11.5 10 11.8268 10 12.25V18.75C10 19.1732 9.67325 19.5 9.25 19.5H5.25C4.82675 19.5 4.5 19.1732 4.5 18.75V12.25C4.5 11.8268 4.82675 11.5 5.25 11.5ZM14.75 15C13.5162 15 12.5 16.0162 12.5 17.25V18.75C12.5 19.9838 13.5162 21 14.75 21H18.75C19.9838 21 21 19.9838 21 18.75V17.25C21 16.0162 19.9838 15 18.75 15H14.75ZM14.75 16.5H18.75C19.1732 16.5 19.5 16.8268 19.5 17.25V18.75C19.5 19.1732 19.1732 19.5 18.75 19.5H14.75C14.3268 19.5 14 19.1732 14 18.75V17.25C14 16.8268 14.3268 16.5 14.75 16.5Z"
        fill={color}
      />

    </SvgIcon>
  );
};

export default DashboardIcon;
