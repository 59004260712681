const styles = theme => ({
    selectMessageTypeTitle: {
        marginBottom: theme.spacing(1),
        fontSize: 14,
        color: theme.palette.text.primary,
        marginTop: theme.spacing(2),
    },
    selectFbMessageTypeButton: {
        width: 215,
        justifyContent: "space-between",
    },
    selectFbMessageTypeContainer: {
        display: "flex",
    },
    descriptionContainer: {
        width: 320,
        padding: 12,
        height: 300,
        overflowY: "auto",
        borderLeft: `1px solid ${theme.palette.divider}`
    },
})

export default styles;
