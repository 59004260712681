import React from "react";

import ListAltIcon from "@material-ui/icons/ListAlt";
import CreateIcon from "@material-ui/icons/Create";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "../../components/CustomButtons/Button";
import OverlappingIcons from "../../components/OverlappingIcons/OverlappingIcons";

import { useTranslation } from "react-i18next";
import useTheme from "@material-ui/styles/useTheme";

const useStyles = makeStyles(theme => ({
  noForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.divider,
    flex: 1,
    marginTop: `-${theme.spacing(10)}px`,
    padding: theme.spacing(3),
    width: "100%"
  }
}));

const NoFormView = ({ onAddClick }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation("createForm");
  return (
    <div className={classes.noForm}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <OverlappingIcons
          parentIcon={ListAltIcon}
          renderChildIcon={() => (
            <CreateIcon
              style={{
                fontSize: 26,
                border: `2px solid ${theme.palette.divider}`,
                borderRadius: "50%",
                background: theme.palette.background.paper,
                padding: theme.spacing(0.25)
              }}
            />
          )}
        />
        <Typography color="inherit" variant="h6" align="center">
          {t("createForm:noForm")}
        </Typography>
        <Button fullWidth color="primary" onClick={onAddClick}>
          {t("createForm:addForm")}
        </Button>
      </Box>
    </div>
  );
};

export default NoFormView;
