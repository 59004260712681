import React, { useState } from "react";

import Box from "@material-ui/core/Box";
import LinkIcon from "@material-ui/icons/Link";
import useTheme from "@material-ui/styles/useTheme";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

const getRegistrationUrl = (registrationFormId, language) =>
  `${window.location.origin}/registrationForms?id=${registrationFormId}&lang=${language}`;

const CopyRegistrationUrl = ({ registrationFormId, language }) => {
  const [copied, setCopied] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation("registrationsView");

  if (!registrationFormId) return null;
  return (
    <Box
      display="inline-block"
      paddingTop={2}
      paddingRight={1}
    >
      <Tooltip
        title={t("registrationsView:copied")}
        open={copied}
        leaveDelay={2000}
        onClose={() => setCopied(false)}
      >
        <CopyToClipboard
          text={getRegistrationUrl(registrationFormId, language)}
          onCopy={() => setCopied(true)}
        >
          <Button
            style={{
              background: theme.palette.background.paper,
              paddingTop: theme.spacing(1),
              paddingBottom: theme.spacing(1),
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
              boxShadow: theme.shadows[1],
              color: theme.palette.text.primary
            }}
          >
            <Typography noWrap>
              {t("registrationsView:registrationLink")}
            </Typography>
            <Box
              marginLeft={1}
              marginRight={1}
              display="flex"
              alignItems="center"
            >
              <LinkIcon />
            </Box>
          </Button>
        </CopyToClipboard>
      </Tooltip>
    </Box>
  );
};

export default CopyRegistrationUrl;
