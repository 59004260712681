import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const MinimizeIcon = ({ color, size, ...rest }) => {
    return (
        <SvgIcon
            width={size}
            height={size}
            viewBox={size && `0 0 ${size} ${size}`}
            fill="none"
            {...rest}
        >
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M20,14H4V10H20"
                fill={color}
            />
        </SvgIcon>
    );
};

export default MinimizeIcon;
