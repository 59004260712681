import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const GlobeIcon = ({ color, size, ...rest }) => {
  return (
    <SvgIcon
      width={size}
      height={size}
      viewBox={size && `0 0 ${size} ${size}`}
      fill="none"
      {...rest}
    >
      <path d="M12 2C6.48603 2 2 6.48604 2 12C2 17.514 6.48603 22 12 22C17.514 22 22 17.514 22 12C22 6.48604 17.514 2 12 2ZM12 3.5C12.3663 3.5 12.7382 3.65246 13.1465 4.02832C13.5548 4.40418 13.9689 5.00125 14.3232 5.77246C14.6082 6.39276 14.8184 7.18813 15.0107 8H8.98926C9.18155 7.18813 9.39175 6.39276 9.67676 5.77246C10.0311 5.00125 10.4452 4.40418 10.8535 4.02832C11.2618 3.65246 11.6337 3.5 12 3.5ZM8.88867 4.09375C8.68188 4.41868 8.48809 4.76644 8.31348 5.14648C7.93297 5.97464 7.64747 6.95491 7.42578 8H4.49805C5.44372 6.22596 7.00017 4.83523 8.88867 4.09375ZM15.1113 4.09375C16.9998 4.83523 18.5563 6.22596 19.502 8H16.5742C16.3525 6.95491 16.067 5.97464 15.6865 5.14648C15.5119 4.76644 15.3181 4.41868 15.1113 4.09375ZM3.87402 9.5H7.23047C7.12772 10.3161 7 11.1159 7 12C7 12.8841 7.12771 13.6839 7.23047 14.5H3.87402C3.63216 13.7098 3.5 12.8706 3.5 12C3.5 11.1294 3.63216 10.2902 3.87402 9.5ZM8.7334 9.5H15.2666C15.3781 10.3067 15.5 11.1103 15.5 12C15.5 12.8897 15.3781 13.6933 15.2666 14.5H8.7334C8.62189 13.6933 8.5 12.8897 8.5 12C8.5 11.1103 8.62189 10.3067 8.7334 9.5ZM16.7695 9.5H20.126C20.3678 10.2902 20.5 11.1294 20.5 12C20.5 12.8706 20.3678 13.7098 20.126 14.5H16.7695C16.8723 13.6839 17 12.8841 17 12C17 11.1159 16.8723 10.3161 16.7695 9.5ZM4.49805 16H7.42578C7.64747 17.0451 7.93297 18.0254 8.31348 18.8535C8.48809 19.2336 8.68188 19.5813 8.88867 19.9062C7.00017 19.1648 5.44372 17.774 4.49805 16ZM8.98926 16H15.0107C14.8184 16.8119 14.6082 17.6072 14.3232 18.2275C13.9689 18.9987 13.5548 19.5958 13.1465 19.9717C12.7382 20.3475 12.3663 20.5 12 20.5C11.6337 20.5 11.2618 20.3475 10.8535 19.9717C10.4452 19.5958 10.0311 18.9987 9.67676 18.2275C9.39175 17.6072 9.18155 16.8119 8.98926 16ZM16.5742 16H19.502C18.5563 17.774 16.9998 19.1648 15.1113 19.9062C15.3181 19.5813 15.5119 19.2336 15.6865 18.8535C16.067 18.0254 16.3525 17.0451 16.5742 16Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default GlobeIcon;
