import React from "react";
// material-ui components
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

//i18n
import { useTranslation } from "react-i18next";

import pageNotFoundStyle from "assets/jss/material-kit-react/views/PageNotFound.jsx";
import Background from "../Background/Background";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  const classes = makeStyles(pageNotFoundStyle)();
  const { t } = useTranslation("pageNotFound");
  return (
    <div>
      <Background>
        <div className={classes.container}>
          <Grid container justify="center" alignItems="center">
            <Grid container direction="column" alignItems="center">
              <Typography
                color="inherit"
                variant="h1"
                className={classes.textOne}
                gutterBottom
              >
                404
              </Typography>
              <Typography color="inherit" variant="h4" gutterBottom>
                {t("pageNotFound:pageNotFound")}
              </Typography>
              <Button color="inherit">
                <Link
                  to="/"
                  style={{
                    color: "#ffffff"
                  }}
                >
                  {t("backToHome")}
                </Link>
              </Button>
            </Grid>
          </Grid>
        </div>
      </Background>
    </div>
  );
};

export default PageNotFound;
