import React from "react";
import FlipToBackIcon from "@material-ui/icons/FlipToBack";
import FlipToFrontIcon from "@material-ui/icons/FlipToFront";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineSharpIcon from '@material-ui/icons/DeleteOutlineSharp';
import makeStyles from "@material-ui/core/styles/makeStyles";
import styles from "./styles";

const useStyles = makeStyles(styles, { name: "SharedSettings" });

const SharedSettings = ({ onMoveForward, onMoveBackward, onDelete }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.flipsContainer}>
                <IconButton className={classes.flipButton}
                    onClick={onMoveForward}
                    size="small"
                >
                    <FlipToFrontIcon />
                </IconButton>
                <IconButton className={classes.flipButton}
                    onClick={onMoveBackward}
                    size="small"
                >
                    <FlipToBackIcon />
                </IconButton>
            </div>


            <IconButton className={classes.deleteButton}
                onClick={onDelete}
                size="small"
            >
                <DeleteOutlineSharpIcon />
            </IconButton>
        </div>
    );

}
export default SharedSettings;