import React, { useState } from "react";
import _get from "lodash/get";
import InputLabel from "@material-ui/core/InputLabel";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next"
import Button from '@material-ui/core/Button';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import PostPurchaseUpdate from "./PostPurchaseUpdate";
import ConfirmedEventUpdate from "./ConfirmedEventUpdate";
import AccountUpdate from "./AccountUpdate";
import Default from "./Default";
import styles from "./styles";

const useStyles = makeStyles(styles, { name: "FacebookMessageTypes" });

const FacebookMessageTypes = ({ onMessageTypeChange, messageType }) => {
    const classes = useStyles();
    const { t } = useTranslation("facebookMessageTypes");
    const [anchorEl, setAnchorEl] = useState(null);
    const [announcementMessageType, setAnnouncementMessageType] = useState(messageType);
    const [currentDescription, setCurrentDescription] = useState({ value: "default" });

    const selectMessageTypeList = [
        {
            value: "POST_PURCHASE_UPDATE", label: t("postPurchaseUpdate")
        },
        {
            value: "CONFIRMED_EVENT_UPDATE", label: t("confirmedEventUpdate")
        },
        {
            value: "ACCOUNT_UPDATE", label: t("accountUpdate")
        },
    ]
    const selectLabelText = selectMessageTypeList.find(messageType => messageType.value === announcementMessageType.value)
    return (
        <div>
            <InputLabel className={classes.selectMessageTypeTitle}>{t("fbMessageType")}</InputLabel>
            <Button
                variant="outlined"
                onClick={(event) => setAnchorEl(event.currentTarget)}
                className={classes.selectFbMessageTypeButton}
            >
                <Typography noWrap variant="button">
                    {_get(selectLabelText, "label") || t("selectType")}
                </Typography>
                <ArrowDropDownIcon />
            </Button>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <div
                    onMouseLeave={() => setCurrentDescription({ value: "default" })}
                    className={classes.selectFbMessageTypeContainer}
                >
                    <div>
                        {
                            selectMessageTypeList.map(item => (
                                <MenuItem
                                    onClick={() => {
                                        setAnnouncementMessageType(item);
                                        onMessageTypeChange(item)
                                        setAnchorEl(null);
                                    }}
                                    onMouseEnter={() => setCurrentDescription(item)}
                                    key={item.value}
                                    value={item.value}
                                >
                                    {item.label}
                                </MenuItem>
                            ))
                        }
                    </div>
                    <div className={classes.descriptionContainer}>
                        {
                            {
                                default: <Default />,
                                POST_PURCHASE_UPDATE: <PostPurchaseUpdate />,
                                CONFIRMED_EVENT_UPDATE: <ConfirmedEventUpdate />,
                                ACCOUNT_UPDATE: <AccountUpdate />,
                            }[currentDescription.value]
                        }
                    </div>
                </div>
            </Popover>
        </div>

    );
}
export default FacebookMessageTypes;