const styles = theme => ({
    iconContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        color: theme.palette.grey['500']
    },
    hiddenImage: {
        display: 'none'
    }
});

export default styles;
