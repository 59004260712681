
const styles = theme => ({
    color: {
        position: "absolute",
        width: 36,
        height: 27,
        borderRadius: 4,
        top: theme.spacing(1),
        left: theme.spacing(0.5)
    },
    swatch: {
        width: 70,
        height: 45,
        borderRadius: 3,
        border: `1px solid ${theme.palette.grey[300]}`,
        cursor: 'pointer',
        marginTop: theme.spacing(1),
        justifySelf: "center"
    },
    pickersPos: {
        display: "grid"
    },
    expandMoreIcon: {
        position: "absolute",
        right: theme.spacing(0.25),
        top: theme.spacing(1.5)
    },
    title: {
        fontSize: "0.75rem",
        textAlign: "start",
        width: "max-content",
    }
});

export default styles;
