import React from 'react';

import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import styles from './styles';

import classNames from 'classnames';

const useStyles = makeStyles(styles);

const DividerWithTitle = ({ title, className, style }) => {
    const classes = useStyles();
    return (
        <div
            style={style}
            className={className ?
                classNames(classes.dividerContainer, className) : classes.dividerContainer
            }>
            <Divider
                className={classes.divider}
            />
            <div className={classes.titleContainer}><Typography color="textPrimary">{title}</Typography></div>
            <Divider
                className={classes.divider}
            />
        </div>
    );
};

export default DividerWithTitle;