import React, { Component } from "react";
import { cloneDeep as _cloneDeep } from "lodash";
import moment from "moment";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router";
import PrivateRoute from "./components/Routing/PrivateRoute";
import AuthenticationRoute from "./components/Routing/AuthenticationRoute";
import { GlobalSnackbar } from "./components/GlobalSnackbar"
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import muiThemeOptions from "config/muiTheme.js";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";

//graphql
import { ApolloProvider } from "react-apollo";
import client from "./apollo/client";

// i18n
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { I18nextProvider } from "react-i18next";
import { ar, en } from "config/localization.js";
import langModule from "./modules/language";

import "./App.css";
import routePaths from "config/routePaths";

// routes
import EventsPage from "views/EventsPage/index";
import HomePage from "views/HomePage";
import AccountSettings from "views/AccountSettings";
import EventPage from "views/EventPage/EventPage.jsx";
import BrandEventPage from "views/EventPage/BrandEventPage";
import DashboardView from "views/EventPage/DashboardView";
import RegistrationsView from "views/EventPage/RegistrationsView";
import RegistrationView from "views/EventPage/RegistrationView";
import RegistrationsScansView from "views/EventPage/RegistrationsScansView";
import EventSettingsView from "views/EventPage/EventSettingsView";
import FbLoginModal from "views/EventPage/EventSettingsView/FbLoginModal";
import EventEngagementView from "views/EventPage/EventEngagementView";
import PageNotFound from "views/PageNotFound/PageNotFound.jsx";
import Authenticate from "./contexts/AuthenticationContext/Authenticate";
import LandingContainer from "./views/LandingContainer/LandingContainer";
import AuthenticationContext from "./contexts/AuthenticationContext/AuthenticationContext";
import VerifyPage from "./views/VerifyPage/VerifyPage";
import InvitationPage from "./views/InvitationPage";
import AfterFbLogin from "./views/AfterFbLogin";
import LoginPage from "./views/LoginPage/LoginPage";
import OrganizerTab from "./views/LoginPage/OrganizerTab";
import ExhibitorTab from "./views/LoginPage/ExhibitorTab";
import RegisterPage from "./views/RegisterPage/RegisterPage";
import ExhibitorsView from "./views/ExhibitorsView";
import EventMembersView from "./views/EventPage/EventMembersView";
import CreateFormView from "./views/CreateFormView";
import CreateEventModal from "./views/EventsPage/CreateEventModal";
import BadgeTemplatesView from "./views/EventPage/BadgeTemplatesView"
import TemplateBuilder from "./views/EventPage/TemplateBuilder";

import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/styles';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

var hist = createBrowserHistory();

var i18nextInstance = i18next.use(LanguageDetector).init({
  fallbackLng: "en",
  //lng: "en", // if enabled it resets the app's language on refresh
  debug: process.env.NODE_ENV === "development",
  fallbackNS: ["translation"],
  resources: {
    en: en,
    ar: ar
  }
});
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { theme: createMuiTheme(muiThemeOptions) };

    this.onLanguageChange = this.onLanguageChange.bind(this);

    i18next.on("languageChanged", this.onLanguageChange);
  }

  componentWillMount() {
    // init the theme for lang
    this.onLanguageChange(i18nextInstance.language);
  }

  onLanguageChange(lang) {
    langModule.setLang(lang);
    const newMUIOptions = _cloneDeep(muiThemeOptions);
    if (lang === "ar") {
      newMUIOptions.typography.fontFamily =
        '"Droid Arabic Kufi","Roboto", "Helvetica", "Arial", "sans-serif"';
    }
    newMUIOptions.direction = i18nextInstance.dir();
    document.getElementsByTagName("BODY")[0].setAttribute("dir", newMUIOptions.direction);
    moment.locale(lang);

    this.setState({ theme: createMuiTheme(newMUIOptions) });
  }

  render() {
    return (
      <ApolloProvider client={client}>
        <I18nextProvider i18n={i18nextInstance}>
          <StylesProvider jss={jss}>
            <MuiThemeProvider theme={this.state.theme}>
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                locale={i18nextInstance.language}
              >
                <Authenticate>
                  <GlobalSnackbar />
                  <AuthenticationContext.Consumer>
                    {({ isAuthenticated }) => (
                      <Router history={hist}>
                        <Switch>
                          <Redirect
                            from={routePaths.HOME}
                            to={
                              isAuthenticated
                                ? routePaths.EVENTS
                                : routePaths.LOGIN
                            }
                            push={false}
                            exact
                          />
                          <PrivateRoute
                            path={routePaths.EVENT_CREATE}
                            exact
                            render={props => (
                              <HomePage
                                {...props}
                                routes={props => (
                                  <EventsPage
                                    {...props}
                                    routes={(props) => (
                                      <CreateEventModal {...props} open />
                                    )}
                                  />
                                )}
                              />
                            )}
                          />

                          <PrivateRoute
                            path={routePaths.REGISTRATIONS_SCANS}
                            render={props => (
                              <BrandEventPage>
                                <EventPage
                                  {...props}
                                  hideSidebar
                                  routes={
                                    <PrivateRoute
                                      component={RegistrationsScansView}
                                    />
                                  }
                                />
                              </BrandEventPage>
                            )}
                          />

                          <PrivateRoute
                            path={routePaths.EVENT}
                            render={props => (
                              <BrandEventPage>
                                <EventPage
                                  {...props}
                                  routes={
                                    <Switch>
                                      <Redirect
                                        from={routePaths.EVENT}
                                        to={routePaths.DASHBOARD_REDIRECT(
                                          props.match.params.eventID
                                        )}
                                        push={false}
                                        exact={true}
                                      />
                                      <PrivateRoute
                                        path={routePaths.DASHBOARD}
                                        component={DashboardView}
                                        exact={true}
                                      />
                                      <PrivateRoute
                                        path={routePaths.REGISTRATIONS}
                                        component={RegistrationsView}
                                        exact={true}
                                      />
                                      <PrivateRoute
                                        path={routePaths.REGISTRATION}
                                        component={RegistrationView}
                                        exact={true}
                                      />
                                      <PrivateRoute
                                        path={routePaths.EXHIBITORS}
                                        component={ExhibitorsView}
                                        exact={true}
                                      />
                                      <PrivateRoute
                                        path={routePaths.EVENT_MEMBERS}
                                        component={EventMembersView}
                                        exact={true}
                                      />
                                      <PrivateRoute
                                        path={routePaths.REGISTRATIONS_FORM}
                                        component={CreateFormView}
                                        exact={true}
                                      />
                                      <PrivateRoute
                                        path={routePaths.ENGAGEMENT}
                                        component={EventEngagementView}
                                        exact={true}
                                      />
                                      <PrivateRoute
                                        path={routePaths.BADGE_TEMPLATES}
                                        component={BadgeTemplatesView}
                                        exact={true}
                                      />
                                      <PrivateRoute
                                        path={routePaths.BADGE_TEMPLATE_BUILDER}
                                        component={TemplateBuilder}
                                        exact={true}
                                      />
                                      <PrivateRoute
                                        path={routePaths.SETTINGS}
                                        component={EventSettingsView}
                                        exact={true}
                                      />
                                      <PrivateRoute
                                        path={routePaths.FACEBOOK_PAGES_MODAL}
                                        exact
                                        render={props => (
                                          <EventSettingsView
                                            {...props}
                                            routes={props => (
                                              <FbLoginModal {...props} open />
                                            )}
                                          />
                                        )}
                                      />
                                      <Redirect
                                        to={routePaths.DASHBOARD_REDIRECT(
                                          props.match.params.eventID
                                        )}
                                        push={false}
                                      />
                                    </Switch>
                                  }
                                />
                              </BrandEventPage>
                            )}
                          />
                          <PrivateRoute
                            path={routePaths.EVENTS}
                            exact
                            render={props => (
                              <HomePage
                                {...props}
                                routes={props => (
                                  <EventsPage {...props} />
                                )}
                              />
                            )}
                          />
                          <PrivateRoute
                            path={routePaths.ACCOUNT}
                            exact
                            render={props => (
                              <HomePage
                                {...props}
                                routes={props => (
                                  <AccountSettings
                                    {...props}
                                  />
                                )}
                              />
                            )}
                          />
                          <Route
                            path={routePaths.VERIFY_ORGANIZER}
                            component={VerifyPage}
                          />
                          <Route
                            path={routePaths.INVITATION}
                            component={InvitationPage}
                          />
                          <Route
                            path={routePaths.AFTER_FB_LOGIN}
                            component={AfterFbLogin}
                          />
                          <AuthenticationRoute
                            path={routePaths.LOGIN}
                            render={props => (
                              <LandingContainer>
                                <LoginPage
                                  {...props}
                                  routes={
                                    // <Route component={LoginPage} />
                                    <Switch>
                                      <Redirect
                                        from={routePaths.LOGIN}
                                        to={routePaths.ORGANIZER_LOGIN}
                                        push={false}
                                        exact={true}
                                      />
                                      <Route
                                        path={routePaths.ORGANIZER_LOGIN}
                                        component={OrganizerTab}
                                        push={false}
                                        exact={true}
                                      />
                                      <Route
                                        path={routePaths.EXHIBITOR_LOGIN}
                                        component={ExhibitorTab}
                                        push={false}
                                        exact={true}
                                      />
                                      <Redirect
                                        to={routePaths.ORGANIZER_LOGIN}
                                        push={false}
                                      />
                                    </Switch>
                                  }
                                />
                              </LandingContainer>
                            )}
                          />
                          <Route
                            path={routePaths.REGISTER}
                            render={props => (
                              <LandingContainer
                                {...props}
                                routes={<Route component={RegisterPage} />}
                              />
                            )}
                          />
                          <Route component={PageNotFound} />
                        </Switch>
                      </Router>
                    )}
                  </AuthenticationContext.Consumer>
                </Authenticate>
              </MuiPickersUtilsProvider>
            </MuiThemeProvider>
          </StylesProvider>
        </I18nextProvider>
      </ApolloProvider >
    );
  }
}

export default App;