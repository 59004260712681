import _get from "lodash/get";
import _sortBy from "lodash/sortBy";
import { checkType } from "../enums";
const { OUT } = checkType;

function getUniqueItemKey(items) {
  let number = Math.floor(Math.random() * 10000);
  if (items.find(item => item.key === number)) {
    number = getUniqueItemKey(items);
  }
  return number;
}

function numberFormatter(number) { // function to format large notification numbers
  let si = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "K" },
    { value: 1E6, symbol: "M" },
  ];
  let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (number >= si[i].value) {
      break;
    }
  }
  return (number / si[i].value).toFixed(0).replace(rx, "$1") + si[i].symbol;
}

function getDefaultBrowserTheme() {
  const isDark = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
  return isDark ? "dark" : "light";
}

/**
 * check if registrant's last check state is the same as current one or not
 * @param  {Array} registrantChecksList  [already existing registrant's checklist]
 * @param  {String} checkType           [type of check being processed now]
 */
function canRegistrantCheck(registrantChecksList, checkType, isRestrictCheckingModeOn = false) {
  const checksList = _sortBy(registrantChecksList, ["createdAt"]) || [];
  const lastCheckType = _get(checksList, `${[checksList.length - 1]}.type`, "");

  if (isRestrictCheckingModeOn) {
    if (checksList.length > 0) {
      return lastCheckType !== checkType;
    } else if (checksList.length <= 0 && checkType === OUT) {
      return false;
    }
    return true;
  }
  return true;
}

/**
 * returns the i18next key for checking result
 * @param  {String} checkType [IN or OUT]
 * @param  {String} messageType [success, failure or duplicate]
 * @return {String} [the i18next key] 
 */
function getRegistrantCheckLocaleKey(checkType = "", messageType = "") {
  const snackbarMessages = {
    IN: {
      success: "checkinSuccess",
      failure: "checkinFailure",
      duplicate: "alreadyCheckedin",
    },
    OUT: {
      success: "checkoutSuccess",
      failure: "checkoutFailure",
      duplicate: "alreadyCheckedout",
    }
  }
  return _get(snackbarMessages, `${[checkType]}.${[messageType]}`);
}

export { getUniqueItemKey, numberFormatter, getDefaultBrowserTheme, canRegistrantCheck, getRegistrantCheckLocaleKey };
