import React, { useReducer, useEffect } from "react";

import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";

import INPUT_TYPES from "./inputTypes";
import AddOptionsInput from "./AddOptionsInput";
import CommonInputSettings from "./CommonInputSettings";
import ImagePickerInputSettings from "./ImagePickerInputSettings";

import { useTranslation } from "react-i18next";
import _includes from "lodash/includes";

const actionTypes = {
  CHANGE: "CHANGE",
  SET: "SET",
  SET_ERROR: "SET_ERROR",
  CLEAR_ERRORS: "CLEAR_ERRORS"
};

const errorMessages = {
  LABEL_REQUIRED: "labelRequired",
  LABEL_EXISTS: "labelExists",
  OPTIONS_REQUIRED: "optionsRequired"
};

function getErrors({ key, value }, selectedInputKey, inputs) {
  if (key === "label") {
    if (!value) return errorMessages.LABEL_REQUIRED;
    if (
      _includes(
        inputs
          .filter(input => input.key !== selectedInputKey)
          .map(input => input.label),
        value
      )
    )
      return errorMessages.LABEL_EXISTS;
  }
  if (key === "options") {
    if (!Array.isArray(value) || value.length === 0)
      return errorMessages.OPTIONS_REQUIRED;
  }
}

function errorsReducer(state, action) {
  if (action.type === actionTypes.SET_ERROR) {
    return {
      ...state,
      [action.payload.key]: (() => {
        if (Array.isArray(state[action.payload.key])) {
          if (_includes(state[action.payload.key], action.payload.error))
            return state[action.payload.key];
          return [...state[action.payload.key], action.payload.error];
        } else return [action.payload.error];
      })()
    };
  }
  if (action.type === actionTypes.CLEAR_ERRORS) {
    return {
      ...state,
      [action.payload]: null
    };
  }
  return {};
}

function inputSettingsReducer(state, action) {
  if (action.type === actionTypes.CHANGE)
    return {
      ...state,
      [action.payload.key]: action.payload.value
    };
  if (action.type === actionTypes.SET)
    return {
      ...action.payload
    };
}

const SettingsView = ({
  input,
  onSettingsChange,
  defaultInputKeys,
  inputs
}) => {

  const { t } = useTranslation("createForm");

  const [inputSettings, setInputSettings] = useReducer(inputSettingsReducer, {
    ...input
  });

  const [errors, setErrors] = useReducer(errorsReducer, {});

  useEffect(() => {
    //Rerender component when input reference changes
    //Adding to the watch list of this effect will change the intended behavior
    setInputSettings({ type: actionTypes.SET, payload: input });
  }, [input]);

  if (!input) return null;
  return (
    <Box display="flex" justifyContent="center">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        flexGrow={1}
      >
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="100%"
        >
          <Box margin={2}>
            <TextField
              name="label"
              label={t("createForm:label")}
              margin="normal"
              value={inputSettings.label}
              onChange={evt => {
                setInputSettings({
                  type: actionTypes.CHANGE,
                  payload: { key: "label", value: evt.target.value }
                });
                const error = getErrors(
                  {
                    key: "label",
                    value: evt.target.value
                  },
                  input.key,
                  inputs
                );
                if (!error) {
                  onSettingsChange({ label: evt.target.value });
                  setErrors({
                    type: actionTypes.CLEAR_ERRORS,
                    payload: "label"
                  });
                } else {
                  setErrors({
                    type: actionTypes.SET_ERROR,
                    payload: {
                      key: "label",
                      error
                    }
                  });
                }
              }}
              disabled={_includes(defaultInputKeys, input.key)}
              fullWidth
              error={errors.label}
              helperText={errors.label && t(`createForm:${errors.label}`)}
            />
            <Box display="flex" justifyContent="flex-start" marginTop={1}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={inputSettings.key === "name" || inputSettings.key === "email" || inputSettings.key === "phone"}
                      checked={inputSettings.required || false}
                      color="primary"
                      onChange={evt =>
                        onSettingsChange({ required: evt.target.checked })
                      }
                    />
                  }
                  label={t("createForm:required")}
                />
              </FormGroup>
            </Box>
            {/* Commented out until it's fully implemented */}
            {/* {!_includes(
              [inputTypes.CHECKBOX, inputTypes.MULTI_CHECKBOX],
              inputSettings.type
            ) && (
              <Box display="flex" justifyContent="flex-start" marginTop={1}>
                <FormGroup row>
                  <FormControlLabel
                    className={
                      theme.direction === "rtl"
                        ? classes.multiCheckboxLabelRtl
                        : ""
                    }
                    control={
                      <Checkbox
                        checked={inputSettings.unique || false}
                        color="primary"
                        onChange={evt =>
                          onSettingsChange({
                            unique: evt.target.checked
                          })
                        }
                      />
                    }
                    label={t("createForm:unique")}
                  />
                </FormGroup>
              </Box>
            )} */}
            {_includes(
              [INPUT_TYPES.TEXT, INPUT_TYPES.TEXT_AREA, INPUT_TYPES.SELECT],
              inputSettings.type
            ) && (
                <CommonInputSettings
                  inputSettings={inputSettings}
                  onSettingsChange={onSettingsChange}
                />
              )}
            {_includes(
              [INPUT_TYPES.IMAGE_PICKER],
              inputSettings.type
            ) && (
                <ImagePickerInputSettings
                  inputSettings={inputSettings}
                  onSettingsChange={onSettingsChange}
                />
              )}
          </Box>
          {_includes(
            [INPUT_TYPES.SELECT, INPUT_TYPES.MULTI_CHECKBOX],
            inputSettings.type
          ) && (
              <Box marginTop={2}>
                <Divider />
                <AddOptionsInput
                  collection={inputSettings.options}
                  onAdd={inputOptions => {
                    setInputSettings({
                      type: actionTypes.CHANGE,
                      payload: {
                        key: "options",
                        value: inputOptions
                      }
                    });
                    const error = getErrors({
                      key: "options",
                      value: inputOptions
                    });
                    if (!error) {
                      setErrors({
                        type: actionTypes.CLEAR_ERRORS,
                        payload: "options"
                      });
                      onSettingsChange({
                        options: inputOptions
                      });
                    } else
                      setErrors({
                        type: actionTypes.SET_ERROR,
                        payload: {
                          key: "options",
                          error
                        }
                      });
                  }}
                  error={errors.options}
                />
              </Box>
            )}
        </Box>
      </Box>
    </Box>
  );
};

export default SettingsView;
