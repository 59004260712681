const styles = theme => ({
    container: {
        minHeight: 200,
        position: "relative",
    },
    tabTextColor: {
        "&$selected": {
            color: theme.palette.text.primary,
        },
    },
    tab: {
        flex: 1,
        minWidth: 0,
    },
    announcementsTextarea: {
        marginTop: theme.spacing(2),
    },
    circularProgress: {
        minHeight: 200,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    translationMessageLoading: {
        position: "absolute",
        right: 10,
        top: 25,
    },
    translationTitle: {
        marginBottom: 32,
        textAlign: "start",
    },
    translationSectionContainer: {
        position: "relative",
    },
    selected: {}
});

export default styles;
