const errorTypes = {
    INVALID_INVITATION: "INVALID_INVITATION",
    ACCOUNT_NOT_FOUND: "ACCOUNT_NOT_FOUND",
    INVITATION_ALREADY_ACCEPTED: "INVITATION_ALREADY_ACCEPTED",
    EXHIBITOR_ALREADY_EXISTS: "EXHIBITOR_ALREADY_EXISTS",
    MEMBER_ALREADY_EXISTS: "MEMBER_ALREADY_EXISTS",
    INVITATION_ALREADY_EXISTS: "INVITATION_ALREADY_EXISTS",
    EMAIL_NOT_VERIFIED: "EMAIL_NOT_VERIFIED",
    NOT_FOUND: "NOT_FOUND",
    PERMISSION_DENIED: "PERMISSION_DENIED",
    UNAUTHENTICATED: "UNAUTHENTICATED",
    TOKEN_EXPIRED: "TOKEN_EXPIRED",
    INCORRECT_CURRENT_PASSWORD: "INCORRECT_CURRENT_PASSWORD",
    CURRENT_PASSWORD_NOT_PROVIDED: "CURRENT_PASSWORD_NOT_PROVIDED",
    PASSWORD_TOO_SHORT: "PASSWORD_TOO_SHORT",
    ATTACHMENT_LIMIT_EXCEEDED: "ATTACHMENT_LIMIT_EXCEEDED",
    ACCOUNT_ALREADY_EXISTS: "ACCOUNT_ALREADY_EXISTS",
    DUPLICATE_CHECKS: "DUPLICATE_CHECKS",
}

export default errorTypes;
