const styles = theme => ({
    paper: {
        overflowY: "inherit",
    },
    paperFullScreen: {
        overflowY: "inherit",
    },
    cardFullScreen: {
        marginBottom: 0,
        paddingTop: 50,
        boxShadow: "none",
        overflowY: "auto",
    },
    card: {
        boxShadow: "none",
        width: 500,
        marginTop: 0,
        maxHeight: "calc(100vh - 64px)",
        overflowY: "inherit",
        marginBottom: -34,
    },
    cardHeader: {
        width: "auto",
        textAlign: "center",
        marginLeft: 20,
        marginRight: 20,
        marginTop: -34,
        padding: "20px 0",
        marginBottom: 15,
    },
    messageTextInput: {
        margin: 8,
    },
    addButton: {
        flex: 1,
    },
    resetButton: {
        width: 115,
        color: theme.palette.text.primary,
        "&:hover,&:focus": {
            color: theme.palette.text.secondary,
        },
    },
    customMessageArea: {
        resize: "none",
        background: theme.palette.background.paper,
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.divider}`,
        minWidth: "100%",
        borderRadius: 4,
        padding: 12,
        "&:focus": {
            border: `1px solid ${theme.palette.primary.main}`,
        },
    },
    cardBodyContainer: {
        marginTop: 0,
        overflowY: "auto",
        overflowX: "hidden",
        marginBottom: 0,
        boxShadow: "unset",
    },
    showFbCheckRoot: {
        margin: 0,
        marginLeft: theme.spacing(1),
    },
    toggleCheckIcon: {
        color: theme.palette.success[700],
        position: "absolute",
        top: 15,
        left: 15,
        fontSize: 20,
        backgroundColor: theme.palette.background.paper,
        borderRadius: "50%",
    },
    sentToToggle: {
        border: 0,
        marginRight: 10,
        padding: 3,
        borderRadius: "50%",
        height: "unset",
        opacity: 0.6,
        backgroundColor: "transparent",
        "&$selected": {
            backgroundColor: "transparent",
            opacity: 1,
        },
        "&:hover": {
            padding: 3,
            borderRadius: "50%",
            height: "unset",
        },
    },
    smallTitle: {
        fontSize: 10,
        marginRight: 10,
        color: theme.palette.action.active,
    },
    facebookSection: {
        paddingBottom: 24,
        alignItems: "flex-end",
        display: "flex",
    },
    swipeableViews: {
        overflow: "hidden",
    },
    sentTo: {
        display: "flex",
        alignItems: "center",
        marginRight: theme.spacing(8)
    },
    fbConnectionError: {
        marginTop: 20,
        textAlign: "start",
    },
    selectMessageTypeContainer: {
        marginBottom: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    selected: {},
})

export default styles;
