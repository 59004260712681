import _forOwn from "lodash/forOwn";
import _uniq from 'lodash/uniq';

import { parser } from "../views/EventPage/TemplateBuilder/core/templateUtils"

const parsablesRegex = /@\[(.*?)\]/g;

function parse(parsables, context) {
    if (!context) return parsables;
    const res = {};
    _forOwn(parsables, (value, key) => {
        const parsedValue = parser.getParsedValues(context, value);
        if (parsedValue) res[key] = parsedValue;
    });
    return res;
};

function getDependencies(movableTemplates) {
    let res = [];
    if (Array.isArray(movableTemplates)) {
        movableTemplates.forEach(movable => {
            if (movable.parsables) {
                _forOwn(movable.parsables, (value, key) => {
                    res = res.concat(parser.getParsables(value));
                });
            }
        })
    }
    return _uniq(res);
};

const textDataParsingFunctions = {
    parsablesRegex,
    parse,
    getDependencies,
}

export default textDataParsingFunctions;
