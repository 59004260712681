import React, { useState, useMemo, useEffect } from "react";
import _get from "lodash/get";

// mui
import makeStyles from "@material-ui/core/styles/makeStyles"
import Grid from "@material-ui/core/Grid";
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import ImageIcon from '@material-ui/icons/Image';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import moment from "moment";
// eslint-disable-next-line
import momentDurationFormatSetup from "moment-duration-format";
import { useParams } from "react-router-dom";
//graphql
import { useQuery, useMutation } from "react-apollo";
//i18n
import { useTranslation } from "react-i18next";
// others
import { Formly } from "components/FormlyForm";
import ErrorBoundary from "components/ErrorBoundary";
import queryCreators from "../../../apollo/queryCreators";
import CheckInIcon from "components/Icons/CheckInIcon";
import CheckOutIcon from "components/Icons/CheckOutIcon";
import TemplateBuilderIcon from "components/Icons/TemplateBuilderIcon"
import OverlappingIcons from "../../../components/OverlappingIcons/OverlappingIcons";
import { showGlobalSnackbar } from "../../../components/GlobalSnackbar";
import { canRegistrantCheck, getRegistrantCheckLocaleKey } from "../../../modules/utils";
import { checkType, errorTypes } from "../../../enums";
import PrintingPreviewModal from "../../EventPage/PrintingPreviewModal"
import styles from "./styles"

const useStyles = makeStyles(styles, { name: "RegistrationView" });
const { DUPLICATE_CHECKS } = errorTypes;

function RegistrationView() {
    const classes = useStyles();
    const [showPrintingModal, setShowPrintingModal] = useState(false);
    const { t, i18n } = useTranslation("registrationView");
    const { eventID, eventRegistrationID } = useParams(); // the eventID gets passed from the currentURL

    const { loading, error, data, subscribeToMore: subscribeToMoreChecks } = useQuery(queryCreators.GET_REGISTRATION_DETAILS.query, {
        variables: {
            eventRegistrationID,
            eventID,
        },
        fetchPolicy: "cache-and-network",
    });

    const { loading: isFormLoading, data: formResourcesData } = useQuery(queryCreators.FETCH_EVENT_REGISTRATION_FORM.query, {
        variables: {
            eventID,
        }
    });

    const formFields = _get(formResourcesData, "eventRegistrationForm", []);
    const formModel = _get(data, 'event.registrants[0].formData', {});
    const registrantData = _get(data, 'event.registrants[0]', {});
    const capturedBy = _get(data, 'event.registrants[0].capturesByExhibitors', []);
    const registrantID = _get(data, 'event.registrants[0].id');
    const isRestrictCheckingModeOn = _get(data, 'event.isRestrictCheckingModeOn');
    const eventLanguages = Object.keys(formFields) || [];
    const formConfig = useMemo(() => ({
        fields: _get(formFields, i18n.language, _get(formFields, eventLanguages[0], [])).filter(field => field.type)

        // eslint-disable-next-line
    }), [formFields, i18n.language]);
    const formState = useMemo(() => ({
        readOnly: true
    }), []);

    const [checkUser] = useMutation(queryCreators.CHECK_REGISTRANT.mutation);
    const fullDateFormat = "hh:mm A - MMM DD, YYYY";

    const { loading: eventContextDataFetchLoading, error: eventContextDataError, data: eventContextData } = useQuery(queryCreators.GET_CONTEXT_DATA.query, {
        skip: !registrantID,
        variables: {
            registrantID: registrantID,
            eventID,
        }
    });

    const printingPreviewContext = {
        ...eventContextData,
        registrant: registrantData,
    };
    const checkRegistrant = async (checkType) => {
        const registrantChecks = registrantData.checks || [];
        if (canRegistrantCheck(registrantChecks, checkType, isRestrictCheckingModeOn)) {
            try {
                await queryCreators.CHECK_REGISTRANT.mutate(checkUser)({
                    check: {
                        eventID,
                        registrantID,
                        type: checkType
                    }
                });
                showGlobalSnackbar(t(getRegistrantCheckLocaleKey(checkType, "success")));
            } catch (error) {
                const errorCode = _get(error, "graphQLErrors[0].extensions.code.errorCode");
                switch (errorCode) {
                    case DUPLICATE_CHECKS:
                        showGlobalSnackbar(t(getRegistrantCheckLocaleKey(checkType, "duplicate")), "error");
                        break;

                    default:
                        showGlobalSnackbar(t(getRegistrantCheckLocaleKey(checkType, "failure")), "error");
                        break;
                }
            }
        } else {
            showGlobalSnackbar(t(getRegistrantCheckLocaleKey(checkType, "duplicate")), "error");
        }
    }

    const getTotalVisitTime = () => {
        const registrantChecks = registrantData.checks || [];
        let currentCheckInTime;
        let currentCheckOutTime;
        let totalTime = 0; // total time of visits in milliseconds

        for (const [index, check] of registrantChecks.entries()) {
            if (check.type === checkType.IN && !currentCheckInTime) {
                currentCheckInTime = check.createdAt;
            } else if (check.type === checkType.OUT && !currentCheckOutTime && currentCheckInTime) {
                currentCheckOutTime = check.createdAt;
            }

            if (currentCheckInTime && currentCheckOutTime) {
                totalTime += moment(currentCheckOutTime).diff(moment(currentCheckInTime));
                currentCheckInTime = undefined;
                currentCheckOutTime = undefined;
            }

            if (index >= registrantChecks.length - 1 && currentCheckInTime) {
                totalTime += moment().diff(moment(currentCheckInTime));
            }
        }
        const totalTimeDuration = moment.duration(totalTime);
        const totalMinutes = totalTimeDuration.asMinutes();
        if (!totalMinutes) {
            return 0;
        }
        return moment.duration(totalMinutes, "minutes").format(`h [${t("hours")}], m [${t("minutes")}]`)
    }

    useEffect(() => {
        subscribeToMoreChecks({
            document: queryCreators.CHECK_SUBSCRIPTION,
            variables: { eventId: eventID },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                const { newCheck } = subscriptionData.data;
                const currentRegistrant = prev.event.registrants.find(({ id }) => newCheck.registrantID === id);

                if (currentRegistrant && (!currentRegistrant.checks || !currentRegistrant.checks.find(({ id }) => newCheck.id.toString() === id.toString()))) {
                    currentRegistrant.checks = [...(currentRegistrant.checks || []), newCheck];
                    return {
                        ...prev,
                        event: {
                            ...prev.event,
                            registrants: [
                                currentRegistrant
                            ],
                        }
                    }
                }
                return prev;
            }
        });
    }, [subscribeToMoreChecks, eventID])

    if (isFormLoading || eventContextDataFetchLoading || (loading && !registrantID)) return (
        <Grid
            item
            container
            alignItems="center"
            justify="center"
            className={classes.spinnerContainer}
        >
            <CircularProgress thickness={7} />
        </Grid>
    );

    if ((error || eventContextDataError || !registrantID) && !loading) {
        return (
            <div className={classes.badgeNotFound}>
                <OverlappingIcons parentIcon={HowToVoteIcon} childIcon={ErrorIcon} defaultBgColor />
                <Typography color="inherit" variant="h6" noWrap>
                    {t("registrantNotFound")}
                </Typography>
            </div>
        );
    }
    return (
        <div className={classes.root}>
            <Grid item xs={6} sm={12} md={7} lg={6} xl={6} className={classes.takeActionContainer}>
                <Button
                    variant="contained"
                    className={classes.takeActionButton}
                    onClick={() =>
                        setShowPrintingModal(true)
                    }
                >
                    <TemplateBuilderIcon className={classes.subElement} />
                    <Typography
                        className={classes.buttonsText}
                        noWrap
                    >
                        {t("printBadge")}
                    </Typography>
                </Button>

                <Button
                    variant="contained"
                    className={classes.takeActionButton}
                    onClick={() => checkRegistrant(checkType.IN)}
                >
                    <CheckInIcon className={classes.subElement} />
                    <Typography
                        className={classes.buttonsText}
                        noWrap
                    >
                        {t("checkIn")}
                    </Typography>
                </Button>

                <Button
                    variant="contained"
                    className={classes.takeActionButton}
                    onClick={() => checkRegistrant(checkType.OUT)}
                >
                    <CheckOutIcon className={classes.subElement} />
                    <Typography
                        className={classes.buttonsText}
                        noWrap
                    >
                        {t("checkOut")}
                    </Typography>
                </Button>
            </Grid>

            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} >
                        <Card>
                            <CardHeader title={<Typography className={classes.cardHeaderTitle} variant="h6" noWrap>{t("basicInfo")}</Typography>} />
                            <CardContent
                                classes={{
                                    root: classes.cardBody,
                                }}
                            >
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>{registrantData.name.substring(0, 1).toUpperCase()}</Avatar>
                                        </ListItemAvatar>
                                        {
                                            registrantData.name ?
                                                <Typography>{registrantData.name}</Typography> :
                                                <Typography className={classes.notProvidedData}>{t("notProvided")}</Typography>
                                        }
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar classes={{ root: classes.avatarRoot }}><PhoneIcon /></Avatar>
                                        </ListItemAvatar>
                                        {
                                            registrantData.phone ?
                                                <Typography>{registrantData.phone}</Typography> :
                                                <Typography className={classes.notProvidedData}>{t("notProvided")}</Typography>
                                        }
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar classes={{ root: classes.avatarRoot }}><EmailIcon /></Avatar>
                                        </ListItemAvatar>
                                        {
                                            registrantData.email ?
                                                <Typography>{registrantData.email}</Typography> :
                                                <Typography className={classes.notProvidedData}>{t("notProvided")}</Typography>
                                        }
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar classes={{ root: classes.avatarRoot }}><CalendarTodayIcon /></Avatar>
                                        </ListItemAvatar>
                                        {
                                            registrantData.registrationDateTime ?
                                                <Typography>{
                                                    moment(registrantData.registrationDateTime).format("MMM DD, YYYY")}
                                                </Typography> :
                                                <Typography className={classes.notProvidedData}>{t("notProvided")}</Typography>
                                        }
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardHeader
                                title={(
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Typography
                                            className={classes.cardHeaderTitle}
                                            variant="h6"
                                            noWrap>
                                            {t("checksHistory")}
                                        </Typography>
                                        {
                                            getTotalVisitTime() !== 0 &&
                                            <Typography noWrap>
                                                {getTotalVisitTime()}
                                            </Typography>
                                        }
                                    </div>
                                )}
                            />

                            <CardContent classes={{
                                root: classes.cardBody,
                            }}>
                                {
                                    registrantData.checks ?
                                        <List disablePadding>
                                            {
                                                registrantData.checks.map(check =>
                                                    <ListItem key={check.id} dense>
                                                        <ListItemAvatar>
                                                            {
                                                                check.type === checkType.IN ?
                                                                    <CheckInIcon
                                                                        color="primary"
                                                                    /> :
                                                                    <CheckOutIcon />
                                                            }
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={
                                                                `${t("checked")}  
                                                        ${t(`${check.type.toLowerCase()}`)}
                                                    `}
                                                            secondary={moment(check.createdAt).format(fullDateFormat)}
                                                        />
                                                    </ListItem>
                                                )
                                            }
                                        </List> :
                                        <Typography noWrap className={classes.emptyChecksText}>
                                            {t("emptyChecks")}
                                        </Typography>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                    {
                        capturedBy.length > 0 &&
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardHeader
                                    title={<Typography className={classes.cardHeaderTitle}
                                        variant="h6" noWrap>{t("exhibitorsInteractions")}</Typography>} />
                                <CardContent classes={{
                                    root: classes.largeCardBody,
                                }}>
                                    <List>
                                        {
                                            capturedBy.map(captor =>
                                                < ListItem key={captor.id} >
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            src={captor.exhibitor[0].logoURL}
                                                            className={classes.exhibitorsAvatar}
                                                            variant="rounded"
                                                        >
                                                            <ImageIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={captor.exhibitor[0].name}
                                                        secondary={moment(captor.captionDate).format(fullDateFormat)}
                                                    />
                                                </ListItem>
                                            )
                                        }
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>
                    }
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardHeader
                                title={<Typography
                                    className={classes.cardHeaderTitle}
                                    variant="h6"
                                    noWrap>{t("registrationDetails")}
                                </Typography>} />
                            <CardContent className={classes.paddingRemoval}>
                                <ErrorBoundary className={classes.formErrorContainer}>
                                    <div className={classes.formContainer}>
                                        <Formly
                                            model={formModel}
                                            config={formConfig}
                                            formState={formState}
                                            onFormlyUpdate={(m) => { }}
                                            onFormlyStateChange={(m) => { }}
                                        />
                                    </div>
                                </ErrorBoundary>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div >
            {
                <PrintingPreviewModal
                    target="registrant"
                    open={showPrintingModal}
                    onClose={() => {
                        setShowPrintingModal(false);
                    }}
                    context={printingPreviewContext}
                />
            }
        </div >

    );
}


export default RegistrationView;
