import React from "react"
import { useTranslation } from "react-i18next"

// material-ui components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import makeStyles from '@material-ui/core/styles/makeStyles';

import styles from './CheckOutVisitorsModal.styles';

const useStyles = makeStyles(styles, { name: "CheckOutVisitorsModal" });

const CheckOutVisitorsModal = ({ open, onClose, onCheckOut }) => {
    const classes = useStyles();
    const { t } = useTranslation("checkOutAllVisitorsModal");

    return (
        <Dialog
            open={open}
            onClose={onClose}
            className={classes.dialog}
        >
            <DialogTitle>{t("checkOutAllVisitors")}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("wouldYouLikeToProceed")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t("no")}
                </Button>
                <Button onClick={onCheckOut} color="primary">
                    {t("yes")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CheckOutVisitorsModal;
