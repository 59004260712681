const styles = theme => ({
    main: {
        padding: "5px 12px 12px",
        overflow: "hidden",
    },
    subMiddleTitle: {
        display: "flex",
        marginBottom: 10,
    },
    subMiddleTitleIcon: {
        color: theme.palette.action.disabled,
        marginRight: 6,
    },
    subMiddleTitleText: {
        color: theme.palette.text.secondary,
        fontSize: 12,
    },
    alwaysOnSwitch: {
        opacity: 0.5,
    },
    cardUpperSection: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    cardTitle: {
        display: "flex",
        alignItems: "center",
        flex: 1,
    },
    title: {
        marginRight: theme.spacing(0.5),
        fontSize: 14,
    },
    cardMiddleSection: {
        display: "flex",
        justifyContent: "center",
        paddingBottom: 24,
        alignItems: "center",
    },
    subMiddleSection: {
        flex: 1,
    },
    divider: {
        marginRight: 30,
    },
    subMiddleBody: {
        display: "flex",
        minHeight: 37,
    },
    badgeIcon: {
        marginRight: 30,
        display: "block"
    },
    emailIcon: {
        fontSize: 30,
    },
    cardLowerSection: {
        fontSize: 10,
        textAlign: "start",
        color: theme.palette.text.secondary,
    },
    infoIcon: {
        width: 10,
    },
    notificationBadge: {
        zIndex: 5,
        fontWeight: 400,
        fontSize: 10,
    },
    parentGrid: {},
    '@media (max-width: 960px)': {
        parentGrid: {
            flexBasis: "50%",
            minWidth: "50%",
        },
    },
    '@media (max-width: 750px)': {
        parentGrid: {
            flexBasis: "100%",
            minWidth: "100%",
        },
    },
    fbNotConnected: {
        opacity: 0.6,
    },
    tooltipPopper: {
        textAlign: "start",
    },
})

export default styles;
