import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-apollo";
import { Formik } from 'formik';
import * as Yup from 'yup';
import _get from "lodash/get";

import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import DoneIcon from "@material-ui/icons/Done";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import queryCreators from "../../apollo/queryCreators";
import useAuth from 'contexts/AuthenticationContext/useAuth';
import { showGlobalSnackbar } from "../../components/GlobalSnackbar";
import DividerWithTitle from "../../components/DividerWithTitle";
import LogoPicker from "../../components/LogoPicker";
import { errorTypes } from "../../enums";
import ErrorPage from "../EventPage/ErrorPage";

import styles from "./styles";

const useStyles = makeStyles(styles, { name: "AccountSettings" });

const { INCORRECT_CURRENT_PASSWORD, CURRENT_PASSWORD_NOT_PROVIDED, PASSWORD_TOO_SHORT } = errorTypes;

const AccountSettings = () => {
    const { t } = useTranslation("accountSettings");
    const theme = useTheme();
    const classes = useStyles();
    const [isPasswordSectionShown, setIsPasswordSectionShown] = useState(false);
    const [isUpdateButtonShown, setIsUpdateButtonShown] = useState(false);
    const [resetForm, setRestForm] = useState(0);
    const [isShowPassword, setIsShowPassword] = useState({
        currentPassword: false,
        newPassword: false,
    });
    const [logo, setLogo] = useState({
        file: null,
        name: null,
        type: null
    });
    const { userInfo } = useAuth();
    const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const { loading: accountLoading, error: accountFetchError, data: account } = useQuery(
        queryCreators.GET_USER_ACCOUNT.query,
        {
            variables: {
                email: _get(userInfo, "email", ""),
            }
        }
    );
    const accountData = _get(account, "account", {});

    const [updateAccount, { loading: updateAccountLoading }] = useMutation(queryCreators.UPDATE_USER_ACCOUNT.mutation, {
        onCompleted: () => {
            showGlobalSnackbar(t("successfulUpdate"));
            setIsUpdateButtonShown(false);
            setIsPasswordSectionShown(false);
            setRestForm(c => c + 1);
        },
        onError: (error) => {
            const errorCode = _get(error, "graphQLErrors[0].extensions.exception.errorCode");
            switch (errorCode) {
                case INCORRECT_CURRENT_PASSWORD:
                    showGlobalSnackbar(t("incorrectCurrentPassword"), "error");
                    break;
                case CURRENT_PASSWORD_NOT_PROVIDED:
                    showGlobalSnackbar(t("currentPasswordNotProvided"), "error");
                    break;
                case PASSWORD_TOO_SHORT:
                    showGlobalSnackbar(t("passwordTooShort"), "error");
                    break;
                default:
                    showGlobalSnackbar(t("cantUpdateAccount"), "error");
                    break;
            }
        }
    });

    const UpdateAccountFormSchema = Yup.object().shape({
        firstName: Yup.string(),
        lastName: Yup.string(),
        currentPassword: Yup.string(),
        newPassword: Yup.string().min(6, t("tooShortPassword"))
            .when("currentPassword", {
                is: (currentPassword) => _get(currentPassword, "length", 0) > 0,
                then: Yup.string().required(t("newPasswordCantBeEmpty"))
            }),
    });

    const handleLogoChange = logo => {
        if (logo) {
            setLogo({
                file: logo.file,
                name: logo.name,
                type: logo.type
            })
            setIsUpdateButtonShown(true);
            return;
        }

        setIsUpdateButtonShown(false);
        return;
    }

    const updateAccountData = (formValues) => {
        for (const key in formValues) {
            if (key === "logo" && formValues.logo && !formValues.logo.name)
                delete formValues[key];
            else if (!formValues[key] || formValues[key] === accountData[key])
                delete formValues[key];
        }

        if (Object.keys(formValues).length > 0) {
            queryCreators.UPDATE_USER_ACCOUNT.mutate(updateAccount)({
                accountUpdateInput: {
                    ...formValues,
                    id: accountData.id,
                },
            },
                {
                    email: accountData.email
                }
            )
        }
    }

    if (accountFetchError) {
        return <ErrorPage />;
    }

    return (
        <div className={classes.body}>
            <div className={classes.titleBar}>
                <Grid container direction="row" alignItems="center">
                    <Grid container item xs={4}>
                        <Typography variant="h6" noWrap>
                            {t("account")}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            <DividerWithTitle title={t("basicInfo")} className={classes.divider} />
            <main className={classes.content}>
                <Grid
                    container
                    item
                    xs={12}
                    sm={9}
                    md={9}
                    className={classes.bodyContainer}
                >
                    {
                        accountLoading ?
                            <Grid
                                container
                                alignItems="center"
                                justify="center"
                                className={classes.spinnerContainer}
                            >
                                <CircularProgress thickness={7} />
                            </Grid>
                            :
                            <Paper className={classes.root}>
                                <Paper elevation={0} className={classes.settingsContainer}>
                                    <div className={classes.form}>
                                        <div className={classes.logoPickerContainer}>
                                            <div className={classes.logoPicker}>
                                                <LogoPicker
                                                    url={logo.file ? logo.file : accountData.logoUrl}
                                                    onChange={handleLogoChange}
                                                />
                                            </div>
                                        </div>
                                        <Formik
                                            key={resetForm}
                                            initialValues={{
                                                firstName: accountData.firstName,
                                                lastName: accountData.lastName,
                                                currentPassword: "",
                                                newPassword: "",
                                            }}
                                            validationSchema={UpdateAccountFormSchema}
                                            onSubmit={async values => {
                                                updateAccountData({ ...values, logo })
                                            }}
                                            validateOnChange={true}
                                        >
                                            {props => {
                                                const {
                                                    errors,
                                                    values,
                                                    touched,
                                                    handleChange,
                                                    handleSubmit,
                                                    setValues,
                                                    dirty,
                                                } = props

                                                return (
                                                    <form
                                                        className={classes.formContainer}
                                                        onSubmit={(values) => {
                                                            handleSubmit(values)
                                                        }}
                                                    >
                                                        {
                                                            Object.keys(values).map(key => {
                                                                if (!key.includes("Password"))
                                                                    return (<TextField
                                                                        className={classes.textField}
                                                                        fullWidth
                                                                        label={t(`${key}`)}
                                                                        id={key}
                                                                        key={key}
                                                                        type="text"
                                                                        value={values[key]}
                                                                        onChange={handleChange}
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                        error={!!(touched[key] && errors[key])}
                                                                        helperText={errors[key]}
                                                                    />)
                                                                return undefined;
                                                            }

                                                            )
                                                        }
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                placeItems: "center",
                                                                marginBottom: 16,
                                                                width: "100%",
                                                            }}
                                                        >

                                                            <Typography variant="subtitle2">
                                                                {`${t("password")}:`}
                                                            </Typography>
                                                            <Button
                                                                className={classes.passwordSectionToggleButton}
                                                                color="primary"
                                                                variant="contained"
                                                                onClick={() => {
                                                                    setValues({
                                                                        ...values,
                                                                        currentPassword: "",
                                                                        newPassword: "",
                                                                    })
                                                                    setIsPasswordSectionShown(!isPasswordSectionShown)
                                                                }}
                                                            >
                                                                {
                                                                    isPasswordSectionShown ?
                                                                        t("cancel")
                                                                        :
                                                                        t("change")
                                                                }
                                                            </Button>
                                                        </div>

                                                        {
                                                            isPasswordSectionShown &&
                                                            Object.keys(values).map(key => {
                                                                if (key.includes("Password"))
                                                                    return (<TextField
                                                                        className={classes.textField}
                                                                        fullWidth
                                                                        label={t(`${key}`)}
                                                                        id={key}
                                                                        key={key}
                                                                        type={isShowPassword[key] ? "text" : "password"}
                                                                        value={values[key]}
                                                                        onChange={handleChange}
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        onClick={() => setIsShowPassword({
                                                                                            ...isShowPassword,
                                                                                            [key]: !isShowPassword[key]
                                                                                        })}
                                                                                    >
                                                                                        {isShowPassword[key] ? <Visibility /> : <VisibilityOff />}
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                        error={!!(errors[key])}
                                                                        helperText={errors[key]}
                                                                    />)
                                                                return undefined;
                                                            }

                                                            )
                                                        }

                                                        {
                                                            ((isUpdateButtonShown || dirty) && Object.keys(errors).length <= 0) &&
                                                            <Grid className={classes.submitButtonContainer}>
                                                                <Fab
                                                                    size={smallScreen ? "medium" : "large"}
                                                                    color="primary"
                                                                    type="submit"
                                                                >
                                                                    {
                                                                        updateAccountLoading ?
                                                                            <CircularProgress size={60} className={classes.circularProgress} /> :
                                                                            <DoneIcon />
                                                                    }
                                                                </Fab>
                                                            </Grid>
                                                        }
                                                    </form>
                                                )
                                            }
                                            }
                                        </Formik>
                                    </div>
                                </Paper>
                            </Paper>
                    }
                </Grid>
            </main >
        </div >
    );
}

export default AccountSettings;