import React, { useState, useContext, useEffect, Fragment } from "react"
import { useTranslation } from "react-i18next";
import hexToRgba from "hex-rgba";
import SwipeableViews from 'react-swipeable-views';
// material-ui components
import { useTheme } from "@material-ui/styles";
import makeStyles from "@material-ui/core/styles/makeStyles"
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography"
import Tooltip from '@material-ui/core/Tooltip';
import ToggleButton from "@material-ui/lab/ToggleButton";

// material-ui icons
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { MentionsField, Mention } from '../../../../components/MentionsField';

import MessengerIcon from "../../../../components/Icons/MessengerIcon";
import { templatesConfig, parser } from "../../../../views/EventPage/TemplateBuilder/core/templateUtils";
import CardBody from "../../../../components/Card/CardBody.jsx";
import CardHeader from "../../../../components/Card/CardHeader.jsx";
import Card from "../../../../components/Card/Card.jsx";
import CustomButton from "../../../../components/CustomButtons/Button.jsx";
import TranslationPreview from "../../../../components/TranslationPreview";
import ConnectFacebookModal from "../../../../components/ConnectFacebookModal";
import TemplateBuilderContext from '../../../../contexts/TemplateBuilderContext';
import MultiImagePicker from "./MultiImagePicker";
import FacebookMessageTypes from "../FacebookMessageTypes";
import { targetChannelType } from "../../../../enums";
import styles from "./styles"

const { EMAIL, FB_MESSENGER } = targetChannelType;

const useStyles = makeStyles(styles, { name: "CustomizeMessagesModal" });

const CustomizeMessagesModal = ({
    isOpen,
    onClose,
    message,
    messageTranslations,
    attachedFileUrls,
    onMessageSubmit,
    languages = [],
    fbMessageTag,
    isfbPageNotConnected,
    targetChannels,
}) => {
    const { t } = useTranslation("eventEngagementView");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const { placeholderKeys } = useContext(TemplateBuilderContext);
    const classes = useStyles();
    const [customMessage, setCustomMessage] = useState(message);
    const [attachments, setAttachments] = useState(attachedFileUrls || []);
    const [translationMessages, setTranslationMessages] = useState([]);
    const [buttonSelected, setButtonSelected] = useState(0);
    const [fbMessageType, setFbMessageType] = useState(fbMessageTag.value ? fbMessageTag : { value: "CONFIRMED_EVENT_UPDATE" });
    const [isMessengerButtonClicked, setIsMessengerButtonClicked] = useState(targetChannels.includes(FB_MESSENGER) && !isfbPageNotConnected);
    const [isEmailButtonClicked, setIsEmailButtonClicked] = useState(targetChannels.includes(EMAIL) || !!targetChannels.length <= 0);
    const [isConnectFacebookModalOpen, setIsConnectFacebookModalOpen] = useState(false);

    const newTargetChannles = [isMessengerButtonClicked ? FB_MESSENGER : null, isEmailButtonClicked ? EMAIL : null].filter(Boolean)
    const fbMessengerToggleButton = (<ToggleButton
        classes={{
            root: classes.sentToToggle,
            selected: classes.selected,
        }}
        value="messenger"
        selected={isMessengerButtonClicked}
        onChange={() => {
            if (isfbPageNotConnected) {
                setIsConnectFacebookModalOpen(true);
                return;
            }
            setIsMessengerButtonClicked(!isMessengerButtonClicked);
        }}
    >
        <MessengerIcon />
        {
            isMessengerButtonClicked &&
            <CheckCircleIcon
                className={classes.toggleCheckIcon}
            />
        }

    </ToggleButton>)

    useEffect(() => {
        setIsEmailButtonClicked(targetChannels.includes(EMAIL) || !!targetChannels.length <= 0)
        setIsMessengerButtonClicked(targetChannels.includes(FB_MESSENGER))
    }, [targetChannels])

    const handleTabSwitch = (event, newValue) => {
        if (newValue != null) // so the button can't be unselected unless another one's selected
            setButtonSelected(newValue);
    };

    const resetModal = () => {
        setButtonSelected(0)
    }

    return (
        <Dialog
            onExited={resetModal}
            open={isOpen}
            onClose={onClose}
            classes={{
                paper: fullScreen ? classes.paperFullScreen : classes.paper
            }}
            fullScreen={fullScreen}
        >
            <Card className={fullScreen ? classes.cardFullScreen : classes.card}>

                <CardHeader className={classes.cardHeader} color="primary">
                    <Typography variant="subtitle1">
                        {t("customizeMessages")}
                    </Typography>
                </CardHeader>
                <Card className={classes.cardBodyContainer}>
                    <CardBody>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={buttonSelected}
                            onChangeIndex={handleTabSwitch}
                            className={classes.swipeableViews}
                            slideStyle={{ overflow: 'unset' }}
                        >
                            <div
                                style={{
                                    display: buttonSelected !== 0 ? 'none' : undefined,
                                }}
                                value={buttonSelected}
                                index={0}
                                dir={theme.direction}
                            >
                                <MentionsField
                                    multiline
                                    variant="outlined"
                                    rows={5}
                                    maxTextLength={1000} // TODO: we need to research the exact max text length needed
                                    value={customMessage || ""}
                                    onChange={text => {
                                        setCustomMessage(text);
                                    }}
                                    InputProps={{
                                        style: {
                                            fontSize: 13,
                                            lineHeight: 'inherit',
                                        }
                                    }}
                                    placeholder={t(`customizeGreetingMessage`)}
                                    fullWidth
                                >
                                    <Mention
                                        markup={`${templatesConfig.PARSING_CONSTANTS.MARKER_START}__id__${templatesConfig.PARSING_CONSTANTS.MARKER_END}`}
                                        trigger={templatesConfig.PARSING_CONSTANTS.TRIGGER}
                                        style={{
                                            fontSize: 'inherit',
                                            lineHeight: 'inherit',
                                            backgroundColor: hexToRgba(theme.palette.primary.main, 15),
                                            borderRadius: 2,
                                            fontWeight: 'inherit'
                                        }}
                                        data={placeholderKeys.map(key => ({
                                            display: parser.getLocalizedContextKey(key, t, false, false),
                                            id: key
                                        }))}
                                        displayTransform={display => parser.getLocalizedContextKey(display, t, false)}
                                        renderSuggestion={({ id }) => parser.getLocalizedContextKey(id, t, false, false)}
                                    />
                                </MentionsField>

                                <MultiImagePicker
                                    attachedFiles={attachedFileUrls}
                                    setAttachments={setAttachments}
                                    maxAttachmentsNumber={1}
                                />

                                <div
                                    style={{
                                        paddingBottom: 24,
                                        alignItems: "flex-end",
                                    }}
                                >
                                    <div className={classes.selectMessageTypeContainer}>
                                        {
                                            isMessengerButtonClicked &&
                                            <FacebookMessageTypes
                                                onMessageTypeChange={setFbMessageType}
                                                messageType={fbMessageType}
                                            />
                                        }
                                    </div>

                                    <div className={classes.sentTo}>
                                        <Typography className={classes.smallTitle} noWrap>
                                            {`${t("sendTo")}:`}
                                        </Typography>
                                        {
                                            isfbPageNotConnected ?
                                                <Tooltip
                                                    placement="top"
                                                    title={t("fbIsNotConnected")}
                                                    enterTouchDelay={200}
                                                >
                                                    {fbMessengerToggleButton}
                                                </Tooltip>
                                                :
                                                fbMessengerToggleButton
                                        }


                                        <ToggleButton
                                            classes={{
                                                root: classes.sentToToggle,
                                                selected: classes.selected,
                                            }}
                                            value="mail"
                                            selected={isEmailButtonClicked}
                                            onChange={
                                                () =>
                                                    setIsEmailButtonClicked(!isEmailButtonClicked)
                                            }
                                        >
                                            <MailOutlineIcon />
                                            {
                                                isEmailButtonClicked &&
                                                <CheckCircleIcon
                                                    className={classes.toggleCheckIcon}
                                                />
                                            }
                                        </ToggleButton>

                                    </div>

                                    {
                                        (isfbPageNotConnected && isMessengerButtonClicked) &&
                                        <Typography className={classes.fbConnectionError} color="error">
                                            {t("fbIsNotConnectedErrorMessage")}
                                        </Typography>
                                    }
                                    {
                                        newTargetChannles.length <= 0 &&
                                        <Typography className={classes.fbConnectionError} color="error">
                                            {t("eventEngagementView:chooseOneTarget")}
                                        </Typography>
                                    }
                                </div>

                            </div>
                            {
                                buttonSelected === 1 ?
                                    <div
                                        style={{
                                            display: buttonSelected !== 1 ? 'none' : undefined,
                                        }}
                                        value={buttonSelected}
                                        index={1}
                                        dir={theme.direction}
                                    >
                                        <TranslationPreview
                                            index={1}
                                            dir={theme.direction}
                                            finalTranslations={setTranslationMessages}
                                            messageToTranslate={customMessage}
                                            savedMessageTranslations={message === customMessage ? messageTranslations : []}
                                            languages={languages}
                                        />
                                    </div>
                                    :
                                    <></>
                            }
                        </SwipeableViews>

                        <div style={{
                            display: "flex",
                        }}>
                            {
                                <Fragment>
                                    <CustomButton
                                        simple
                                        className={classes.resetButton}
                                        fullWidth={true}
                                        size="lg"
                                        onClick={(event) => {
                                            if (buttonSelected === 0) {
                                                setCustomMessage("");
                                                setAttachments([]);
                                                onMessageSubmit([], "", isMessengerButtonClicked ? fbMessageType : "", [], [EMAIL]);
                                                onClose();
                                            } else {
                                                handleTabSwitch(event, 0)
                                            }
                                        }}
                                    >
                                        {
                                            buttonSelected === 0 ?
                                                t("reset")
                                                :
                                                t("back")
                                        }
                                    </CustomButton>
                                    <CustomButton
                                        disabled={newTargetChannles.length <= 0}
                                        className={classes.addButton}
                                        fullWidth={true}
                                        color="primary"
                                        size="lg"
                                        onClick={(event) => {
                                            if (buttonSelected === 0 && languages.length > 1) {
                                                handleTabSwitch(event, 1)
                                            } else {
                                                onClose()
                                                onMessageSubmit(attachments, customMessage, isMessengerButtonClicked ? fbMessageType : "", translationMessages, newTargetChannles)
                                            }
                                        }}
                                    >
                                        {
                                            buttonSelected === 0 && languages.length > 1 ?
                                                t("checkTranslation")
                                                :
                                                t("save")
                                        }
                                    </CustomButton>
                                </Fragment>
                            }

                        </div>
                        {fullScreen && (
                            <CustomButton
                                fullWidth={true}
                                simple
                                color="primary"
                                size="lg"
                                onClick={onClose}
                                className={classes.noMargins}
                            >
                                {t("templatesPage:cancel")}
                            </CustomButton>
                        )}

                    </CardBody>
                </Card>
            </Card>
            <ConnectFacebookModal
                open={isConnectFacebookModalOpen}
                onClose={() => { setIsConnectFacebookModalOpen(false) }}
            />
        </Dialog>
    )
}
export default CustomizeMessagesModal;