const styles = theme => ({
    container: {
        padding: 24
    },
    spinnerContainer: {
        flexGrow: 1
    },
    titleBar: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3),
        borderBottom: `solid 1px ${theme.palette.divider}`,
        color: theme.palette.text.primary
    },
    addButton: {
        position: "fixed",
        bottom: theme.spacing(2.5),
        alignSelf: "flex-end",
        paddingRight: 12,
        zIndex: 10
    },
    noBadgesFound: {
        flexGrow: 1,
        flexDirection: "column",
        color: theme.palette.divider,
    },
    noBadgesIcon: {
        fontSize: 60,
    }
});

export default styles;
