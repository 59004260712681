const styles = theme => ({
  container: {
    position: "absolute",
    display: "flex",
    paddingRight: theme.spacing(3.5),
    bottom: theme.spacing(2.5),
    width: "100%",
    justifyContent: "flex-end",
  },
  button: {
    transition: "all 0.6s cubic-bezier(.37,.63,.53,1.3)",
  },
  buttonOn: {
    transform: "rotate(360deg)",
  },
  buttonOff: {
    transform: "rotate(0deg)",
  },
  slider: {
    flex: 1,
    maxWidth: 321,
    padding: "13px 25px 0px 25px",
    transition: "all 0.6s cubic-bezier(.37,.63,.53,1.3)",
  },
  sliderOn: {
    opacity: 1,
    transform: "translateX(-2%)",
  },
  sliderOff: {
    opacity: 0,
    transform: "translateX(15%)",
  },
  zoomButton: {
    background: theme.palette.background.paper,
    lineHeight: 0,
    marginBottom: 20,
  },
  buttonDirection: {
    color: theme.palette.text.primary,
    transform: "scaleX(1)",
  },
  markLabel: {
    color: theme.palette.primary.main,
  }
});

export default styles;
