import React, { useState, useMemo } from "react";

import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from '@material-ui/core/Avatar';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import AddIcon from "@material-ui/icons/Add";
import Button from "../../components/CustomButtons/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Snackbar from "../../components/CustomSnackbar/Snackbar.jsx";

import { useQuery, useMutation } from "react-apollo";
import queryCreators from "../../apollo/queryCreators";
import _get from "lodash/get";
import _map from "lodash/map";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import ExhibitorsIcon from "components/Icons/ExhibitorsIcon";
import ErrorPage from "../EventPage/ErrorPage"
import ExhibitorsActionsMenu from "./ExhibitorsActionsMenu";
import { useParams } from "react-router-dom";
import { errorTypes, invitationType } from "../../enums";

const { EXHIBITOR_ALREADY_EXISTS, INVITATION_ALREADY_EXISTS } = errorTypes;
const { ORGANIZER_TO_EXHIBITOR } = invitationType;

const EXHIBITORS_TABLE_COLUMNS = [
  { dataKey: "name", headerLabelI18nKey: "name" },
  { dataKey: "email", headerLabelI18nKey: "email" },
  { dataKey: "invitation", headerLabelI18nKey: "invitation" },
  {
    dataKey: "activated",
    headerLabelI18nKey: "activated",
    styles: {
      width: 150
    }
  },
  {
    dataKey: "",
    headerLabelI18nKey: "",
    styles: {
      width: 100
    }
  }
];

const useStyles = makeStyles(styles, { name: "ExhibitorsView" });

const isEmail = email => {
  if (!email) return false;
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const ExhibitorsView = () => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showSnackbar, toggleSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { eventID } = useParams();
  const exhibitorToAddIsValid = name.trim() && isEmail(email);

  const { loading: exhibitorsLoading, error: exhibitorsError, data, refetch: getExhibRefetch } = useQuery(
    queryCreators.GET_EVENT_EXHIBITORS.query,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        eventID
      }
    }
  );

  const { loading: invitationsLoading, error: invitationsError, data: invitationsdata, refetch: getInvsRefetch } = useQuery(
    queryCreators.GET_UNACCEPTED_INVITATIONS.query,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        eventID,
        type: ORGANIZER_TO_EXHIBITOR
      }
    }
  );

  const exhibitors = _get(data, "event.exhibitors", []);
  const invitations = _get(invitationsdata, "event.unacceptedInvitationsWithType", []);

  const invitationsWithFlag = invitations.map((element) => {
    return {
      ...element,
      objType: "Invitation",
    };
  });
  const exhibitorsWithFlag = exhibitors.map((element) => {
    return {
      ...element,
      objType: "Exhibitor",
    };
  });
  const allExhibitors = useMemo(
    () =>
      exhibitorsWithFlag.concat(invitationsWithFlag)
    , [exhibitorsWithFlag, invitationsWithFlag]);

  const [addInvitation, { loading: addExhibitorLoading }] = useMutation(
    queryCreators.ADD_INVITATION.mutation,
    {
      onCompleted: () => {
        setName("");
        setEmail("");
      },
      onError: (error) => {
        const errorCode = _get(error, "graphQLErrors[0].extensions.exception.errorCode");
        switch (errorCode) {
          case INVITATION_ALREADY_EXISTS:
            setSnackbarMessage("alreadySentInv");
            break;
          case EXHIBITOR_ALREADY_EXISTS:
            setSnackbarMessage("alreadyAddedExhibitor");
            break;
          default:
            setSnackbarMessage("cantAddExhibitor");
            break;
        }
        toggleSnackbar(true);
      }
    }
  );

  const onInvitationAdd = () => {

    const existingExhibitor = allExhibitors.find( // to not allow adding duplicate invs or exhibitors
      element => (element.email === email || element.invitedEmail === email)
    )

    if (existingExhibitor) {
      if (existingExhibitor.email)
        setSnackbarMessage("alreadyAddedExhibitor");
      else if (existingExhibitor.invitedEmail)
        setSnackbarMessage("alreadySentInv");
      toggleSnackbar(true);
      return;
    }
    queryCreators.ADD_INVITATION.mutate(addInvitation)(
      {
        invitation: {
          name: name.trim(),
          email,
          eventID,
          type: ORGANIZER_TO_EXHIBITOR,
        }
      },
      {
        eventID,
        type: ORGANIZER_TO_EXHIBITOR,
      }
    );
  };

  const { t } = useTranslation("exhibitorsView");

  return (
    <>
      <div className={classes.titleBar}>
        <Grid container direction="row" alignItems="center">
          <Grid container item xs={12}>
            <Typography variant="h5" noWrap>
              {t("exhibitors")}
            </Typography>
          </Grid>
        </Grid>
      </div>
      {exhibitorsError || invitationsError ?
        <ErrorPage /> :

        <Grid
          container
          item
          xs={12}
          sm={9}
          md={9}
          style={{
            alignSelf: "center"
          }}
        >
          <Box width="100%" display="flex" justifyContent="center">
            <Box width="90%" display="flex" flexDirection="column">
              <Paper className={classes.root}>
                <Box
                  display="flex"
                  padding={1}
                  paddingLeft={2}
                  paddingRight={2}
                  alignItems="center"
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent="center"
                >
                  <form
                    noValidate // because we rely on our own validation
                    onSubmit={e => {
                      e.preventDefault(); // so the app doesn't reload after each submit
                      if (exhibitorToAddIsValid) {
                        onInvitationAdd();
                        return true;
                      }
                      return false;
                    }}
                  >
                    <TextField
                      className={isMobile ? "" : classes.nameField}
                      label={t("name")}
                      variant="outlined"
                      margin="dense"
                      value={name}
                      onChange={evt => setName(evt.target.value)}
                      fullWidth={isMobile}
                      style={{
                        flex: 0.3
                      }}
                    />
                    <TextField
                      className={isMobile ? "" : classes.emailField}
                      label={t("email")}
                      variant="outlined"
                      margin="dense"
                      value={email}
                      onChange={evt => setEmail(evt.target.value.trim())}
                      fullWidth={isMobile}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      className={
                        classNames({
                          [classes.button]: !isMobile,
                          [classes.mobileButton]: isMobile
                        })}
                      type="submit"
                      disabled={!exhibitorToAddIsValid || addExhibitorLoading}
                      fullWidth={isMobile}
                      size="sm"
                    >
                      {addExhibitorLoading ? (
                        <Box
                          color={theme.palette.primary.contrastText}
                          className={classes.icon}
                        >
                          <CircularProgress color="inherit" size={18} />
                        </Box>
                      ) : (
                        <AddIcon className={classes.icon} />
                      )}
                      {t("add")}
                    </Button>
                  </form>
                </Box>
                {(exhibitorsLoading || invitationsLoading) && (
                  <LinearProgress style={{ width: "100%" }} />
                )}
                {exhibitorsLoading || invitationsLoading ? ( // if loading just show the table's head
                  <Box display="flex" justifyContent="center">
                    {!isMobile && (
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            {_map(EXHIBITORS_TABLE_COLUMNS, column => (
                              <TableCell
                                key={column.dataKey}
                                style={{
                                  ...column.styles
                                }}
                              >
                                {t(`${column.headerLabelI18nKey}`)}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                      </Table>
                    )}
                  </Box>
                ) : allExhibitors.length > 0 ? (
                  <Box display="flex" justifyContent="center">
                    {!isMobile && (
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            {_map(EXHIBITORS_TABLE_COLUMNS, column => (
                              <TableCell
                                key={column.dataKey}
                                style={{
                                  ...column.styles
                                }}
                              >
                                {t(`${column.headerLabelI18nKey}`)}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {allExhibitors.map(exhibitor => (
                            <Tooltip
                              key={exhibitor.id}
                              title={
                                exhibitor.members && exhibitor.members.length > 0 ?
                                  t("membersCount", { count: exhibitor.members.length }) :
                                  t("noMembers")
                              }
                              arrow
                              placement={theme.direction === "ltr" ? "left" : "right"}
                              enterTouchDelay={200}
                            >
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  <Avatar
                                    variant="rounded"
                                    src={exhibitor.logoURL}
                                  />
                                </TableCell>
                                <TableCell className={classes.breakText} component="th" scope="row">
                                  {exhibitor.invitedName || exhibitor.name}
                                </TableCell>
                                <TableCell className={classes.breakText}>
                                  {exhibitor.invitedEmail || exhibitor.email}
                                </TableCell>
                                <TableCell>
                                  <Box display="flex">
                                    <div
                                      className={classNames(classes.activity, {
                                        [classes.pending]: !exhibitor.accepted,
                                        [classes.active]: (exhibitor.objType === "Exhibitor" && exhibitor.id) || exhibitor.accepted
                                      })}
                                    >
                                      {(exhibitor.objType === "Exhibitor" && exhibitor.id) || exhibitor.accepted
                                        ? t("accepted")
                                        : t("pending")}
                                    </div>
                                  </Box>
                                </TableCell>
                                <TableCell>
                                  <Box display="flex">
                                    <div
                                      className={classNames(classes.activity, {
                                        [classes.inactive]: !exhibitor.isActivated,
                                        [classes.active]: exhibitor.isActivated
                                      })}
                                    >
                                      {exhibitor.isActivated
                                        ? t("active")
                                        : t("inactive")}
                                    </div>
                                  </Box>
                                </TableCell>
                                <TableCell>
                                  <ExhibitorsActionsMenu
                                    onError={error => {
                                      setSnackbarMessage(error);
                                      toggleSnackbar(true);
                                    }}
                                    onDeleteFailure={() => {
                                      getExhibRefetch()
                                      getInvsRefetch()
                                    }}
                                    exhibitor={exhibitor}
                                    objType={exhibitor.objType}
                                  />
                                </TableCell>
                              </TableRow>
                            </Tooltip>
                          ))}
                        </TableBody>
                      </Table>
                    )}
                    {isMobile && (
                      <List className={classes.fullWidth}>
                        {allExhibitors.length > 0 &&
                          allExhibitors.map(exhibitor => (
                            <Tooltip
                              key={exhibitor.id}
                              title={
                                exhibitor.members && exhibitor.members.length > 0 ?
                                  t("membersCount", { count: exhibitor.members.length }) :
                                  t("noMembers")
                              }
                              arrow
                              placement={theme.direction === "rtl" ? "left" : "right"}
                              enterTouchDelay={0}
                            >
                              <ListItem alignItems="flex-start">
                                <Box display="flex" alignItems="center" className={classes.fullWidth}>
                                  <Box marginRight={2}>
                                    <ListItemAvatar>
                                      <Box
                                        display="flex"
                                        justifyContent="center"
                                        style={{
                                          fontSize: 35
                                        }}
                                        marginBottom={0.5}
                                      >
                                        <Avatar
                                          variant="rounded"
                                          src={exhibitor.logoURL}
                                        />
                                      </Box>
                                    </ListItemAvatar>
                                    <Box display="flex" justifyContent="center">
                                      <div
                                        className={classNames(
                                          classes.activity,
                                          classes.listActivity,
                                          {
                                            [classes.inactive]: !exhibitor.isActivated,
                                            [classes.active]: exhibitor.isActivated
                                          }
                                        )}
                                      >
                                        {exhibitor.isActivated
                                          ? t("active")
                                          : t("inactive")}
                                      </div>
                                    </Box>
                                    <Box display="flex" justifyContent="center">
                                      <div
                                        className={classNames(
                                          classes.activity,
                                          classes.listActivity,
                                          {
                                            [classes.pending]: !exhibitor.accepted,
                                            [classes.active]: (exhibitor.objType === "Exhibitor" && exhibitor.id) || exhibitor.accepted
                                          })}
                                      >
                                        {(exhibitor.objType === "Exhibitor" && exhibitor.id) || exhibitor.accepted
                                          ? t("accepted")
                                          : t("pending")}
                                      </div>
                                    </Box>
                                  </Box>
                                  <ListItemText
                                    primary={exhibitor.name || exhibitor.invitedName}
                                    secondary={exhibitor.invitedEmail || exhibitor.email}

                                    primaryTypographyProps={{
                                      noWrap: true,
                                    }}
                                    secondaryTypographyProps={{
                                      variant: "body2",
                                      className: classes.listEmail,
                                      color: "textPrimary",
                                      noWrap: true,
                                    }}
                                  />
                                  <ExhibitorsActionsMenu
                                    onError={error => {
                                      setSnackbarMessage(error);
                                      toggleSnackbar(true);
                                    }}
                                    exhibitor={exhibitor}
                                    objType={exhibitor.objType}
                                    onDeleteFailure={() => {
                                      getExhibRefetch()
                                      getInvsRefetch()
                                    }}
                                  />
                                </Box>
                              </ListItem>
                            </Tooltip>
                          ))}
                      </List>
                    )}
                  </Box>
                ) : // rendering an empty placeholder if no exhibitors exist
                  !exhibitorsLoading || !invitationsLoading ? (
                    <div className={classes.cardNoData}>
                      <ExhibitorsIcon style={{ fontSize: 60 }} />
                      <Typography color="inherit" variant="h6" className={classes.noExhibitorText}>
                        {t("pleaseAddExhibitor")}
                      </Typography>
                    </div>
                  ) : null}
              </Paper>
            </Box>
          </Box>
          <Snackbar
            open={showSnackbar}
            autoHideDuration={6000}
            onClose={() => toggleSnackbar(false)}
            message={t(`${snackbarMessage}`)}
            variant="error"
          />
        </Grid>
      }
    </>
  );
};

export default ExhibitorsView;