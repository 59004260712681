const styles = theme => ({
    errorOccurred: {
        flexGrow: 1,
        flexDirection: "column",
        color: theme.palette.divider,
        backgroundColor: theme.palette.background.default,
    },
    errorIcon: {
        fontSize: 60,
        justifySelf: "center",
    }
});

export default styles;
