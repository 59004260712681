import axios from "axios";
import * as authModule from "modules/auth";
import lang from "./language";

const instance = axios.create({
  baseURL: process.env.REACT_APP_RESTAPI_URL
});

instance.interceptors.request.use(config => {
  const token = authModule.getToken();
  const currentLang = lang.getLang();
  let resultingConfig = {
    ...config,
    headers: {
      ...config.headers,
      "app-language": currentLang
    }
  };
  if (token) {
    resultingConfig.headers.Authorization = `Bearer ${token}`;
  }
  return resultingConfig;
});

export default instance;
