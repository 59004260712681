import React, { useState, memo } from "react";

import cn from "classnames";

import _map from "lodash/map";
import _get from "lodash/get";

import { useParams } from "react-router-dom";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import Typography from "@material-ui/core/Typography";
import KeyIcon from "@material-ui/icons/VpnKey";
import Tooltip from "@material-ui/core/Tooltip";
import makeStyles from "@material-ui/core/styles/makeStyles";

//i18n
import { useTranslation } from "react-i18next";

import RegistrantActionsMenu from "../../RegistrantActionsMenu";

import routePaths from "config/routePaths";

import styles from "./styles";

const useStyles = makeStyles(styles);

const LargeScreenRegistrant = ({
    registrant,
    registrantsTableColumns,
    event,
    isRestrictCheckingModeOn,
    renderRegistrantChecksIcon,
    isNewRegistrantAnimOn,
    setIsNewRegistrantAnimOn,
}) => {
    const { eventID } = useParams();

    const { t } = useTranslation("registrationsView");
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRegistrant, setCurrentRegistrant] = useState(null);

    const open = Boolean(anchorEl);

    const isActionMenuOpen = open && currentRegistrant === registrant.id;


    const handleActionsMenuClick = (event, registrantId) => {
        setAnchorEl(event.currentTarget);
        setCurrentRegistrant(registrantId);
    };

    const handleActionsMenuClose = () => {
        setAnchorEl(null);
        setCurrentRegistrant(null);
    };

    return (
        <TableRow
            onAnimationEnd={() => setIsNewRegistrantAnimOn("")}
            className={cn({
                [classes.row]: true,
                [classes.newRegistrantAnimation]: registrant.id === isNewRegistrantAnimOn,
            })}
            hover
            onClick={(e) => {
                window.open(routePaths.REGISTRATION_REDIRECT(eventID, registrant.eventRegistrationID), '_blank')
                /* if (e.ctrlKey) {
                } else {
                  props.history.push(routePaths.REGISTRATION_REDIRECT(eventID, registrant.eventRegistrationID))
                } */
            }}
        >
            <TableCell size="small">
                {renderRegistrantChecksIcon(registrant)}
            </TableCell>

            <TableCell size="small">
                <Tooltip
                    title={_get(registrant, "eventRegistrationID", "")}
                >
                    <KeyIcon
                        style={{
                            color: "#d6b818",
                            transform: "scaleY(-1)",
                            width: 24
                        }}
                    />
                </Tooltip>
            </TableCell>
            {_map(
                registrantsTableColumns,
                column => {
                    if (!column.csvOnly)
                        return column.component ? (
                            <TableCell
                                key={column.dataKey}
                                style={{ width: 24 }}
                            >
                                <column.component
                                    data={
                                        registrant[column.dataKey]
                                    }
                                />
                            </TableCell>
                        ) : (
                            <TableCell key={column.dataKey}>
                                {registrant[column.dataKey]}
                            </TableCell>
                        );
                }
            )}
            <TableCell onClick={(event) => event.stopPropagation()}>
                <RegistrantActionsMenu
                    onClick={handleActionsMenuClick}
                    onClose={handleActionsMenuClose}
                    open={isActionMenuOpen}
                    anchorEl={isActionMenuOpen ? anchorEl : null}
                    event={event}
                    registrant={registrant}
                    isRestrictCheckingModeOn={isRestrictCheckingModeOn}
                />
                {
                    !!_get(registrant, "addedByAccount[0].fullName") &&
                    <Typography noWrap className={classes.addedByText}>
                        {`${t("addedBy")}: ${_get(registrant, "addedByAccount[0].fullName")}`}
                    </Typography>
                }
            </TableCell>
        </TableRow>
    );
}

export default memo(LargeScreenRegistrant);
