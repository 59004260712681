import React, { useReducer, useState, Fragment } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import ImageIcon from '@material-ui/icons/Image';
import VisibilityOnIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOffOutlined";
import TranslateIcon from '@material-ui/icons/Translate';
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from '@material-ui/core/InputAdornment';

import InputContainer from "./InputContainer";
import AddInputMenu from "./AddInputMenu";
import Button from "../../components/CustomButtons/Button";
import Snackbar from "../../components/CustomSnackbar/Snackbar";

import styles from "./styles.js";
import INPUT_TYPES from "./inputTypes";
import SettingsView from "./SettingsView";

import _includes from "lodash/includes";
import _get from "lodash/get";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useLazyQuery } from "react-apollo";
import queryCreators from "../../apollo/queryCreators";
import FormErrorView from "./FormErrorView";
import NoFormView from "./NoFormView";

import { getUniqueItemKey } from "../../modules/utils";

import FormTranslationsModal from "./FormTranslationsModal";
import ErrorPage from "../EventPage/ErrorPage"
const useStyles = makeStyles(styles);

const defaultOptions = ["firstValue", "secondValue"];

const actionTypes = {
  CHANGE: "CHANGE",
  ADD: "ADD",
  DELETE: "DELETE",
  RESET: "RESET",
  SHOW_SNACKBAR: "SHOW_SNACKBAR",
  ERROR: "ERROR"
};

function generateFormlyJson(inputs) {
  const formlyInputs = inputs.map(input => ({
    type: input.type,
    key: input.key,
    hide: input.hidden,
    templateOptions: {
      label: input.label,
      placeholder: input.placeholder,
      required: input.required,
      type: input.textType,
      unique: input.unique,
      description: input.description,
      options:
        Array.isArray(input.options) &&
        input.options.map(option => ({
          name: option,
          value: option
        })),
      maxFileSize: input.maxFileSize,
    }
  }));
  return formlyInputs;
}

function isParseable(formlyInputs) {
  let isParseable = true;
  if (!Array.isArray(formlyInputs)) {
    isParseable = false;
  } else {
    formlyInputs.forEach(input => {
      if (!input.type || !_includes(INPUT_TYPES, input.type)) {
        isParseable = false;
      }
    });
  }
  return isParseable;
}

function parseFormlyJson(formlyInputs) {
  const inputs = formlyInputs.map(formlyInput => ({
    type: formlyInput.type,
    key: formlyInput.key,
    hidden: formlyInput.hide,
    label: formlyInput.templateOptions.label,
    placeholder: formlyInput.templateOptions.placeholder,
    required: formlyInput.templateOptions.required,
    textType: formlyInput.templateOptions.type,
    unique: formlyInput.templateOptions.unique,
    description: formlyInput.templateOptions.description,
    options:
      Array.isArray(formlyInput.templateOptions.options) &&
      formlyInput.templateOptions.options.map(({ label, value }) => value),
    maxFileSize: formlyInput.templateOptions.maxFileSize,
  }));
  return inputs;
}

function getPlaceholderInputLabel(inputs, inputType, defaultInputs, t) {
  const defaultFieldsCount = defaultInputs.filter(
    input => input.type === inputType
  ).length;
  const currentFieldsCount = inputs.filter(input => input.type === inputType)
    .length;
  let currentFieldNumber;
  if (defaultFieldsCount > 0) {
    currentFieldNumber = currentFieldsCount - defaultFieldsCount + 1;
  } else {
    currentFieldNumber = currentFieldsCount + 1;
  }
  return `${t(`createForm:${inputType}`)} ${currentFieldNumber}`;
}

function snackbarDataReducer(state, action) {
  if (action.type === "SHOW_SNACKBAR") {
    return {
      ...action.payload
    };
  } else {
    return {
      ...state,
      show: false
    };
  }
}

function inputsReducer(
  state,
  action,
  { selectInput, selectedInput, defaultInputs, t, setModifiedTag }
) {
  if (action.type === actionTypes.ADD) {
    let input;
    if (action.payload.inputType === INPUT_TYPES.IMAGE_PICKER) {
      input = {
        type: INPUT_TYPES.IMAGE_PICKER,
        label: getPlaceholderInputLabel(
          state,
          action.payload.inputType,
          defaultInputs,
          t
        ),
        key: `${action.payload.inputType}-${getUniqueItemKey(state)}`,
        maxFileSize: 1 * 1000000, // default max size in bytes
        dataType: "base64"
      };
    }
    else {
      input = {
        type: _includes(
          [INPUT_TYPES.EMAIL, INPUT_TYPES.PHONE],
          action.payload.inputType
        )
          ? INPUT_TYPES.TEXT
          : action.payload.inputType,
        label: getPlaceholderInputLabel(
          state,
          action.payload.inputType,
          defaultInputs,
          t
        ),
        key: `${action.payload.inputType}-${getUniqueItemKey(state)}`,
        textType:
          _includes(
            [INPUT_TYPES.TEXT, INPUT_TYPES.EMAIL, INPUT_TYPES.PHONE],
            action.payload.inputType
          ) && action.payload.inputType,
        options:
          _includes(
            [INPUT_TYPES.SELECT, INPUT_TYPES.MULTI_CHECKBOX],
            action.payload.inputType
          ) && defaultOptions.map(option => t(`createForm:${option}`))
      };
    }
    selectInput(input.key);
    setModifiedTag(true);
    return [...state, input];
  }
  if (action.type === actionTypes.CHANGE) {
    const modifiedInputs = state.map(input => {
      if (input.key !== action.payload.key) return input;
      if (action.payload.hidden && input.key === selectedInput) selectInput("");
      return {
        ...input,
        ...action.payload
      };
    });
    setModifiedTag(true);
    return modifiedInputs;
  }
  if (action.type === actionTypes.DELETE) {
    setModifiedTag(true);
    return state.filter(input => input.key !== action.payload);
  }
  if (action.type === actionTypes.RESET) {
    if (action.payload.isFormDirty) setModifiedTag(true);
    return action.payload.inputs;
  }
  if (action.type === actionTypes.ERROR) {
    const inputs = [...state];
    inputs[action.payload] = true;
    return inputs;
  }
}

//TODO -Console errors
const CreateFormView = ({ match }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isSettingsDrawerOpen, toggleSettingsDrawer] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDraggingField, setIsDraggingField] = useState(false);
  const { t } = useTranslation("createForm");
  const [snackbarData, setSnackbarData] = useReducer(snackbarDataReducer, {
    show: false
  });
  const [doesFormExist, setFormExistence] = useState(true);
  const [selectedInputKey, selectInput] = useState();
  const [isModified, setModifiedTag] = useState(false);
  const [upsertRegistrationForm, { loading: addFormLoading }] = useMutation(
    queryCreators.ADD_REGISTRATION_FORM.mutation,
    {
      onCompleted: () => {
        setSnackbarData({
          type: actionTypes.SHOW_SNACKBAR,
          payload: {
            show: true,
            message: doesFormExist
              ? t("createForm:formUpdated")
              : t("createForm:formCreated"),
            variant: "success"
          }
        });
        setModifiedTag(false);
        setFormExistence(true);
      },
      onError: () => {
        setSnackbarData({
          type: actionTypes.SHOW_SNACKBAR,
          payload: {
            show: true,
            message: doesFormExist
              ? t("createForm:formUpdateFailed")
              : t("createForm:formCreateFailed"),
            variant: "error"
          }
        });
      }
    }
  );

  const [getDefaultForm, { loading: defaultFormLoading, error: defaultFormError, data: defaultFormData }] = useLazyQuery(
    queryCreators.FETCH_EVENT_DEFAULT_FORM.query,
    {
      fetchPolicy: "no-cache", // used because loading state doesn't change if data is loaded and cached
      variables: {
        eventId: match.params.eventID
      },
      onCompleted: (data) => {
        inputsHandler({
          type: actionTypes.RESET,
          payload: {
            inputs: data.initialRegistrationFormFields,
            isFormDirty: false
          }
        });
        setModifiedTag(true);
        selectInput(_get(data, "initialRegistrationFormFields[0].key"));
      }
    }
  );
  const defaultInputs = _get(defaultFormData, "initialRegistrationFormFields", [])

  const [inputs, inputsHandler] = useReducer(
    (state, action) =>
      inputsReducer(state, action, {
        selectInput,
        defaultInputs,
        t,
        selectedInputKey,
        setModifiedTag
      }),
    []
  );
  const selectedInput =
    Array.isArray(inputs) &&
    inputs.find(input => input.key === selectedInputKey);

  const { loading: fetchFormLoading, error, data: eventData } = useQuery(
    queryCreators.FETCH_EVENT_FORM.query,
    {
      variables: {
        eventID: match.params.eventID
      },
      onCompleted: (data) => { // data is the event data object returned after query completion
        if (data && data.event) {
          if (data.event.registrationForm) {
            if (data.event.registrationForm.notModifiable)
              return inputsHandler({
                type: actionTypes.ERROR,
                payload: "notModifiable"
              });
            if (!isParseable(data.event.registrationForm.form))
              return inputsHandler({
                type: actionTypes.ERROR,
                payload: "notParseable"
              });
            if (Array.isArray(data.event.registrationForm.form) && data.event.registrationForm.form.length > 0) {
              const parseForm = parseFormlyJson(eventData.event.registrationForm.form);
              selectInput(_get(parseForm[0], "key"));
              return inputsHandler({
                type: actionTypes.RESET,
                payload: {
                  inputs: parseForm,
                  isFormDirty: false
                }
              });
            }
          }
        }
        inputsHandler({
          type: actionTypes.RESET,
          payload: {
            inputs: defaultInputs,
            isFormDirty: false
          }
        });
        setFormExistence(false);
      }
    }
  );

  const onDragEnd = result => {
    // reorder the list
    const { destination, source } = result;
    setIsDraggingField(false);
    if (!destination) return;
    if (destination.index === source.index && destination.droppableId === source.droppableId) return // if field was dragged and dropped in the same place

    const draggedElement = inputs[source.index]; // saving the currently dragged element
    inputs.splice(source.index, 1); // removing it from the inputs array
    inputs.splice(destination.index, 0, draggedElement); // adding it back in the dropped location
    setModifiedTag(true) // showing the save button
  }
  const eventLanguages = _get(eventData, "event.languages", [])

  return (
    <>
      <div className={classes.titleBar}>
        <Grid container direction="row" alignItems="center">
          <Grid container item xs={12}>
            <Typography variant="h5" noWrap>
              {t("createForm:createForm")}
            </Typography>
          </Grid>
        </Grid>
      </div>
      {error || defaultFormError ?
        <ErrorPage /> :
        <Grid
          className={classes.container}
          container
          direction="column"
          alignItems={isMediumScreen ? "center" : "flex-start"}
        >
          {(fetchFormLoading || defaultFormLoading) ? (
            <Box
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="80%"
            >
              <CircularProgress thickness={7} />
            </Box>
          ) : !doesFormExist ? (
            <NoFormView onAddClick={() => {
              getDefaultForm();
              setFormExistence(true)
            }} />
          ) : inputs.notParseable || inputs.notModifiable ? (
            <FormErrorView
              notParseable={inputs.notParseable}
              notModifiable={inputs.notModifiable}
              onResetClick={() => {
                getDefaultForm();
                inputsHandler({
                  type: actionTypes.RESET,
                  payload: {
                    inputs: defaultInputs,
                    isFormDirty: false
                  }
                })
              }}
            />
          ) : (
            <Grid container item sm={8} xs={10} justify="center">
              <Grid container item md={9} sm={12} xs={12} justify="flex-end">
                <Box marginTop={1}>
                  {
                    (eventLanguages.length > 1 && !isModified) &&
                    <Button
                      style={{
                        background: theme.palette.background.paper,
                        boxShadow: theme.shadows["2"],
                        color: theme.palette.text.primary,
                      }}
                      className={classes.translationsButton}
                      onClick={() => {
                        setIsModalOpen(true);
                      }}
                    >
                      <TranslateIcon className={classes.translateIcon} />
                      {t("createForm:translations")}
                    </Button>
                  }
                  {!isModified && (
                    <Fragment>

                      <Button
                        style={{
                          background: theme.palette.background.paper,
                          boxShadow: theme.shadows["2"],
                          color: theme.palette.text.primary
                        }}
                        onClick={() => {
                          getDefaultForm();
                          inputsHandler({
                            type: actionTypes.RESET,
                            payload: {
                              inputs: defaultInputs,
                              isFormDirty: true
                            }
                          })
                        }}
                      >
                        {t("createForm:reset")}
                      </Button>
                    </Fragment>
                  )}
                  {isModified && (
                    <Button
                      color="primary"
                      className={classes.saveButton}
                      onClick={() =>
                        upsertRegistrationForm({
                          variables: {
                            formSettings: {
                              eventID: match.params.eventID,
                              form: generateFormlyJson(inputs)
                            }
                          }
                        })
                      }
                      disabled={addFormLoading}
                    >
                      <Box
                        color={theme.palette.primary.contrastText}
                        display="flex"
                        alignItems="center"
                      >
                        <Box marginRight={addFormLoading ? 1 : 0}>
                          {t("createForm:save")}
                        </Box>
                        {addFormLoading && (
                          <CircularProgress
                            color="inherit"
                            size={12}
                            thickness={10}
                          />
                        )}
                      </Box>
                    </Button>
                  )}
                </Box>
              </Grid>
              <DragDropContext
                onDragStart={() => setIsDraggingField(true)}
                onDragEnd={onDragEnd}
              >
                <Grid item md={9} sm={12} xs={12}>
                  <Droppable droppableId={"regForm"}>
                    {(provided) => (
                      <Fragment>
                        <Box
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          marginTop={1}>
                          {inputs.map((input, index) => {
                            const showDeleteAction = !_includes(
                              defaultInputs.map(input => input.key),
                              input.key
                            );
                            let disableHideAction = false;
                            if (input.key === "name") disableHideAction = true;
                            if (input.key === "email") disableHideAction = true;
                            if (input.key === "phone") disableHideAction = true;

                            let endAdornment;

                            switch (input.textType) {
                              case "phone":
                                // adding phone icon to its text fields
                                endAdornment = <InputAdornment position="end"><PhoneIcon /></InputAdornment>
                                break;

                              case "email":
                                endAdornment = <InputAdornment position="end"><EmailIcon /></InputAdornment>
                                break;

                              default:
                                break;
                            }
                            if (input.type === "imagePicker") {
                              endAdornment = <InputAdornment position="end"><ImageIcon /></InputAdornment>;
                            }
                            return (
                              <Draggable
                                key={input.key}
                                draggableId={input.key}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                  >
                                    <InputContainer
                                      style={{
                                        background: theme.palette.background.default
                                      }}
                                      inputProps={input}
                                      className={classes.inputContainer}
                                      isBeingDragged={isDraggingField}
                                      selected={
                                        !isMediumScreen && input.key === selectedInputKey
                                      }
                                      onClick={() => {
                                        if (!input.hidden) {
                                          selectInput(input.key);
                                          if (isMediumScreen) toggleSettingsDrawer(true);
                                        }
                                      }}
                                      endAdornment={endAdornment}
                                      renderActions={() => (
                                        <>
                                          {input.hidden || (
                                            <Tooltip
                                              title={
                                                disableHideAction
                                                  ? input.key === "name"
                                                    ? t("createForm:cantHideName")
                                                    : t("createForm:cantHideEmailAndPhone")
                                                  : t("createForm:hide")
                                              }
                                            >
                                              <span>
                                                <IconButton
                                                  aria-label="hide"
                                                  onClick={() =>
                                                    inputsHandler({
                                                      type: actionTypes.CHANGE,
                                                      payload: {
                                                        key: input.key,
                                                        hidden: true
                                                      }
                                                    })
                                                  }
                                                  disabled={disableHideAction}
                                                >
                                                  <VisibilityOnIcon fontSize="small" />
                                                </IconButton>
                                              </span>
                                            </Tooltip>
                                          )}
                                          {input.hidden && (
                                            <Tooltip title={t("createForm:show")}>
                                              <IconButton
                                                aria-label={t("createForm:show")}
                                                onClick={() =>
                                                  inputsHandler({
                                                    type: actionTypes.CHANGE,
                                                    payload: {
                                                      key: input.key,
                                                      hidden: false
                                                    }
                                                  })
                                                }
                                              >
                                                <VisibilityOffIcon fontSize="small" />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                          {showDeleteAction && (
                                            <Tooltip title={t("createForm:delete")}>
                                              <IconButton
                                                aria-label={t("createForm:delete")}
                                                onClick={() =>
                                                  inputsHandler({
                                                    type: actionTypes.DELETE,
                                                    payload: input.key
                                                  })
                                                }
                                              >
                                                <DeleteIcon fontSize="small" />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                        </>
                                      )}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}

                          {provided.placeholder}
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="center"
                          marginTop={2}
                          marginBottom={1}
                        >
                          <AddInputMenu
                            onSelect={inputType =>
                              inputsHandler({
                                type: actionTypes.ADD,
                                payload: { inputType }
                              })
                            }
                          />
                        </Box>
                      </Fragment>
                    )}
                  </Droppable>
                </Grid>
              </DragDropContext>
            </Grid>
          )}
          {!fetchFormLoading && doesFormExist && (
            <Drawer
              className={classes.drawer}
              variant={isMediumScreen ? "temporary" : "permanent"}
              classes={{
                paper: classes.drawerPaper
              }}
              anchor="right"
              open={isSettingsDrawerOpen}
              onClose={() => toggleSettingsDrawer(false)}
            >
              <div className={classes.toolbar} />
              {Array.isArray(inputs) && (
                <SettingsView
                  input={selectedInput}
                  onSettingsChange={input => {
                    inputsHandler({
                      type: actionTypes.CHANGE,
                      payload: {
                        ...input,
                        key: selectedInput.key
                      }
                    });
                  }}
                  defaultInputKeys={defaultInputs.map(input => input.key)}
                  inputs={inputs}
                />
              )}
            </Drawer>
          )}
          {
            (eventLanguages.length > 1 && !isModified) &&
            <FormTranslationsModal
              isOpen={isModalOpen}
              onClose={setIsModalOpen}
              eventLanguages={eventLanguages.filter((language, index) => index !== 0)}
              inputsToTranslate={inputs}
            />
          }
          <Snackbar
            open={snackbarData.show}
            autoHideDuration={6000}
            onClose={() =>
              setSnackbarData({
                show: false
              })
            }
            message={snackbarData.message}
            variant={snackbarData.variant}
          />
        </Grid>
      }
    </>
  );
};

export default CreateFormView;
