import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const MobileSocialNetworkIcon = ({ color, size, ...rest }) => {
  return (
    <SvgIcon
      width={size}
      height={size}
      viewBox={size && `0 0 ${size} ${size}`}
      fill="none"
      {...rest}
    >
      <path d="M8.25 2C7.01625 2 6 3.01625 6 4.25V19.75C6 20.9838 7.01625 22 8.25 22H15.75C16.9838 22 18 20.9838 18 19.75V4.25C18 3.01625 16.9838 2 15.75 2H8.25ZM8.25 3.5H15.75C16.1732 3.5 16.5 3.82675 16.5 4.25V19.75C16.5 20.1733 16.1732 20.5 15.75 20.5H8.25C7.82675 20.5 7.5 20.1733 7.5 19.75V4.25C7.5 3.82675 7.82675 3.5 8.25 3.5ZM10 6.5C9.60218 6.5 9.22064 6.65804 8.93934 6.93934C8.65804 7.22064 8.5 7.60218 8.5 8C8.5 8.39782 8.65804 8.77936 8.93934 9.06066C9.22064 9.34196 9.60218 9.5 10 9.5C10.3978 9.5 10.7794 9.34196 11.0607 9.06066C11.342 8.77936 11.5 8.39782 11.5 8C11.5 7.60218 11.342 7.22064 11.0607 6.93934C10.7794 6.65804 10.3978 6.5 10 6.5ZM12.2881 7C12.4231 7.307 12.5 7.644 12.5 8C12.5 8.171 12.4822 8.3385 12.4492 8.5H13.5V11.5H10.5V10.4492C10.3385 10.4822 10.171 10.5 10 10.5C9.644 10.5 9.307 10.4231 9 10.2881V12.25C9 12.664 9.3355 13 9.75 13H14.25C14.6645 13 15 12.664 15 12.25V7.75C15 7.336 14.6645 7 14.25 7H12.2881ZM10.75 17.5C10.6506 17.4986 10.5519 17.517 10.4597 17.554C10.3675 17.5911 10.2836 17.6461 10.2128 17.7159C10.142 17.7857 10.0858 17.8688 10.0474 17.9605C10.0091 18.0522 9.98932 18.1506 9.98932 18.25C9.98932 18.3494 10.0091 18.4478 10.0474 18.5395C10.0858 18.6312 10.142 18.7143 10.2128 18.7841C10.2836 18.8539 10.3675 18.9089 10.4597 18.946C10.5519 18.983 10.6506 19.0014 10.75 19H13.25C13.3494 19.0014 13.4481 18.983 13.5403 18.946C13.6325 18.9089 13.7164 18.8539 13.7872 18.7841C13.858 18.7143 13.9142 18.6312 13.9526 18.5395C13.9909 18.4478 14.0107 18.3494 14.0107 18.25C14.0107 18.1506 13.9909 18.0522 13.9526 17.9605C13.9142 17.8688 13.858 17.7857 13.7872 17.7159C13.7164 17.6461 13.6325 17.5911 13.5403 17.554C13.4481 17.517 13.3494 17.4986 13.25 17.5H10.75Z"
        fill={color}
      />

    </SvgIcon>

  );
};

export default MobileSocialNetworkIcon;
