const styles = theme => ({
    row: {
        position: "relative",
        "&:hover": {
            cursor: "pointer",
        }
    },
    newRegistrantAnimation: {
        animation: "$newRegAnim 2s",
    },
    "@keyframes newRegAnim": {
        from: {
            opacity: 0.3
        },
        to: {
            opacity: 1
        }
    },
    addedByText: {
        position: "absolute",
        right: 8,
        left: 8,
        bottom: 4,
        fontSize: 12,
        color: theme.palette.text.disabled,
        textAlign: "right",
        maxWidth: "fit-content",
        background: theme.palette.action.selected,
        padding: "2px 8px",
        borderRadius: 50,
        marginLeft: "auto",
    },
});

export default styles;
