import React, { useRef } from 'react';
import { Mention, MUIMentionsInput } from './MUIMentionsInput';
import TextField from '@material-ui/core/TextField';
import makeStyles from "@material-ui/core/styles/makeStyles";
import styles from './styles';

const useStyles = makeStyles(styles);

const MentionsField = ({
    children,
    fullWidth,
    InputProps,
    variant,
    rows,
    multiline = false,
    maxTextLength,
    ...props
}) => {
    const classes = useStyles();
    //useRef was used here to maintain a reference to this component that doesn't change for every rerender
    const MUIMentionsInputWithChildren = useRef(mentionInputProps => (
        <MUIMentionsInput
            maxTextLength={maxTextLength}
            multiline={multiline}
            {...InputProps}
            {...mentionInputProps}
        >
            {children}
        </MUIMentionsInput>
    ));
    return (
        <TextField
            variant={variant}
            multiline={multiline}
            rows={rows}
            rowsMax={rows}
            style={{
                width: fullWidth ? '100%' : 200,
                height: 133,
            }}
            fullWidth={fullWidth}
            {...props}
            InputProps={{
                inputComponent: MUIMentionsInputWithChildren.current,
                rowsMax: rows,
                rows: rows,
                multiline: multiline,
                classes: {
                    multiline: classes.multiline,
                }
            }}
        />
    )
}

export { MentionsField, Mention }