import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import routePaths from "../../../config/routePaths";
import styles from "./styles";

const useStyles = makeStyles(styles, { name: "InvitationPage" });

const InvitationErrorMessage = ({ message, buttonText, onClick }) => {

    const classes = useStyles();

    return (
        <Fragment>
            <Typography variant="h4" gutterBottom className={classes.centerText}>
                {message}
            </Typography>
            <Button onClick={onClick}>
                <Link className={classes.link} to={routePaths.HOME}>
                    {buttonText}
                </Link>
            </Button>
        </Fragment>
    );
}

export default InvitationErrorMessage;