import React from "react"
import compose from "recompose/compose";
import _get from "lodash/get";

import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from "@material-ui/core/styles/withTheme";
import DialogTitle from "@material-ui/core/DialogTitle";
import LockOutline from "@material-ui/icons/LockOutlined";
import Email from "@material-ui/icons/Email";

import { withTranslation } from "react-i18next";

import withAuth from "../../contexts/AuthenticationContext/withAuth";
import CardBody from "../../components/Card/CardBody.jsx";
import CardFooter from "../../components/Card/CardFooter.jsx";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import { showGlobalSnackbar } from "../../components/GlobalSnackbar";
import loginPageStyle from "../../assets/jss/material-kit-react/views/loginPage"
import errorTypes from "../../enums/errorTypes";

class OrganizerTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            organizerEmail: "",
            organizerPassword: "",
            createAccountDialogOpened: false,
            error: "",
            loading: false,
        };
    }

    handleCreateAccountDialogOpen = () => {
        this.setState({ createAccountDialogOpened: true });
    };

    handleCreateAccountDialogClose = () => {
        this.setState({ createAccountDialogOpened: false });
    };

    render() {
        const { t, classes, theme, fullScreen, login } = this.props;

        return (
            <form
                noValidate
                onSubmit={async e => {
                    try {
                        e.preventDefault();
                        this.setState({
                            loading: true,
                            error: ""
                        });
                        await login(
                            this.state.organizerEmail,
                            this.state.organizerPassword
                        );
                    } catch (e) {
                        const errorType = _get(e, "response.data.errorType");

                        if (_get(e, "response.status") === 401 && !errorType) { // unauthorized 
                            this.setState({
                                error: "invalidData",
                            });
                        } else if (_get(e, "response.status") && !errorType) {
                            showGlobalSnackbar(t("errorOccurred"), "error")
                        } else if (errorType) {
                            switch (errorType) {
                                case errorTypes.EMAIL_NOT_VERIFIED:
                                    showGlobalSnackbar(t("notVerifiedEmail"), "error")
                                    break;
                                default:
                                    showGlobalSnackbar(t("errorOccurred"), "error")
                                    break;
                            }
                        } else {
                            showGlobalSnackbar(t("networkError"), "error")
                        }
                    }
                    this.setState({
                        loading: false
                    });
                }}
            >
                <CardBody>
                    <CustomInput
                        labelText={t("loginPage:email")}
                        id="email"
                        formControlProps={{
                            fullWidth: true
                        }}
                        InputProps={{
                            type: "email",
                            value: this.state.organizerEmail,
                            onChange: e => {
                                this.setState({
                                    organizerEmail: e.target.value
                                });
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Email
                                        className={classes.inputIconsColor}
                                    />
                                </InputAdornment>
                            )
                        }}
                    />
                    <CustomInput
                        labelText={t("loginPage:password")}
                        id="pass"
                        formControlProps={{
                            fullWidth: true
                        }}
                        InputProps={{
                            type: "password",
                            value: this.state.organizerPassword,
                            onChange: e => {
                                this.setState({
                                    organizerPassword: e.target.value
                                });
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <LockOutline
                                        className={classes.inputIconsColor}
                                    />
                                </InputAdornment>
                            )
                        }}
                    />
                    {this.state.error && (
                        <FormHelperText
                            style={{
                                color: theme.palette.error.dark
                            }}
                        >
                            {t(`loginPage:${this.state.error}`)}
                        </FormHelperText>
                    )}
                </CardBody>
                <CardFooter
                    className={classes.cardFooter}
                    style={{ flexDirection: "column" }}
                >
                    <Button
                        fullWidth={true}
                        color="primary"
                        size="lg"
                        type="submit"
                        disabled={this.state.loading}
                        style={{
                            direction: theme.direction
                        }}
                    >
                        <Box
                            color={theme.palette.primary.contrastText}
                            display="flex"
                            alignItems="center"
                        >
                            <Box marginRight={this.state.loading ? 1 : 0}>
                                {t("loginPage:login")}
                            </Box>
                            {this.state.loading && (
                                <CircularProgress
                                    color="inherit"
                                    size={12}
                                    thickness={10}
                                />
                            )}
                        </Box>
                    </Button>
                    <Button
                        fullWidth={true}
                        simple
                        color="primary"
                        onClick={this.handleCreateAccountDialogOpen}
                        size="lg"
                    >
                        {t("loginPage:createAccount")}
                    </Button>
                    <Dialog
                        fullScreen={fullScreen}
                        open={this.state.createAccountDialogOpened}
                        onClose={this.handleCreateAccountDialogClose}
                        aria-labelledby="create-new-account-dialog"
                    >
                        <DialogTitle id="responsive-dialog-title">
                            {"Create new account"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Contact us on sales@event-square.net or +201093562354
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={this.handleCreateAccountDialogClose}
                                autoFocus
                                color="primary"
                            >
                                Okay
                            </Button>
                        </DialogActions>
                    </Dialog>
                </CardFooter>
            </form >

        );
    }
}
export default compose(
    withTranslation("loginPage"),
    withAuth,
    withTheme,
    withStyles(loginPageStyle)
)(OrganizerTab);
