import React, { Fragment, useMemo, useState, useEffect, useCallback, useRef } from 'react';
import _get from "lodash/get";
import _find from "lodash/find";
import _some from "lodash/some";
import _toLower from "lodash/toLower";
import _debounce from "lodash/debounce";
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import QrReader from "react-qr-reader2";
import { useReactToPrint } from "react-to-print";

import Popper from "@material-ui/core/Popper";
import useTheme from '@material-ui/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from "@material-ui/core/TextField";
import Avatar from '@material-ui/core/Avatar';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import InputAdornment from "@material-ui/core/InputAdornment";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Collapse from '@material-ui/core/Collapse';
import Modal from "@material-ui/core/Modal";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Checkbox from "@material-ui/core/Checkbox";

import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import QRCodeIcon from "components/Icons/QRCodeIcon";
import MultipleCheckInIcon from "components/Icons/MultipleChecksIcon";
import MultipleCheckOutIcon from "components/Icons/MultipleCheckOutIcon";
import CheckInIcon from "components/Icons/CheckInIcon";
import CheckOutIcon from "components/Icons/CheckOutIcon";
import TemplateBuilderIcon from "components/Icons/TemplateBuilderIcon";
import SettingsIcon from "@material-ui/icons/Settings";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import queryCreators from '../../../apollo/queryCreators';
import { showGlobalSnackbar } from "../../../components/GlobalSnackbar";
import { registrantScanActions, errorTypes } from "../../../enums";
import routePaths from "../../../config/routePaths";
import { templatesConfig } from '../TemplateBuilder/core/templateUtils';
import TemplateViewer from '../TemplateBuilder/TemplateViewer';
import TemplateBuilderContext from '../../../contexts/TemplateBuilderContext';
import { registrantFilterTechnique } from "../../../enums";
import { canRegistrantCheck, getRegistrantCheckLocaleKey } from "../../../modules/utils";
import beepSound from "../../../assets/audio/beep.mp3";

import styles from './styles';
import { Slider } from '@material-ui/core';

const { QR_SCAN, SEARCH_REGISTRANT } = registrantFilterTechnique;

const useStyles = makeStyles(styles, { name: 'RegistrationsScansView' });
const { CHECK_IN, CHECK_OUT, PRINT_BADGE, VIEW_DETAILS } = registrantScanActions;
const { DUPLICATE_CHECKS } = errorTypes;

const audio = new Audio(beepSound);

const RegistrationsScansView = () => {
    const [registrantsSearchWord, setRegistrantsSearchWord] = useState("");
    const [qrCodeScanResult, setQrCodeScanResult] = useState("");
    const [exhibitorQrCodeScanResult, setExhibitorQrCodeScanResult] = useState("");
    const [isQRCodeSectionOn, setIsQRCodeSectionOn] = useState(false);
    const [isSettingsModalOn, setIsSettingsModalOn] = useState(true);
    const [qrCodeScannedBeforeModalOn, setQrCodeScannedBeforeModalOn] = useState(false);
    const [searchRegistrantResult, setSearchRegistrantResult] = useState({});
    const [videoFocusMeta, setVideoFocusMeta] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [actions, setActions] = useState(() => [CHECK_IN]);
    const [badgeTemplates, setBadgeTemplates] = useState([]);
    const [currentlySelectedTemplate, setCurrentlySelectedTemplate] = useState();
    const [contextOverrides, setContextOverrides] = useState();
    const searchInputRef = useRef();
    const templatePreviewRef = useRef();
    const noQRCodeScansCount = useRef();
    noQRCodeScansCount.current = 0;
    const theme = useTheme();
    const { t } = useTranslation("registrationsScansView");
    const classes = useStyles();
    const { eventID } = useParams();
    const handlePrint = useReactToPrint({
        content: () => templatePreviewRef.current,
    });

    const [checkUser] = useMutation(queryCreators.CHECK_REGISTRANT.mutation);

    const { loading: getBadgesLoading } = useQuery(queryCreators.GET_EVENT_BADGE_TEMPLATES.query, {
        variables: {
            eventID,
        },
        onCompleted: ({ event }) => {
            const { badgeTemplates } = event;
            if (Array.isArray(badgeTemplates)) {
                setBadgeTemplates(badgeTemplates);
                setCurrentlySelectedTemplate(badgeTemplates[0]);
            }
        }
    });

    const { loading: getEventDataLoading, data: getEventData, subscribeToMore: subscribeToMoreRegistrants, fetchMore: fetchMoreRegistrants } = useQuery(queryCreators.GET_EVENT.query, {
        variables: {
            eventID,
        },
    });
    const fetchedRegistrants = _get(getEventData, "event.registrants", []);
    const relatedEvents = _get(getEventData, "event.relatedEvents", []);
    const eventData = _get(getEventData, "event", {});
    const isRestrictCheckingModeOn = _get(getEventData, 'event.isRestrictCheckingModeOn');
    const errorVibration = () => window.navigator.vibrate([100, 30, 100]);
    const successVibration = () => window.navigator.vibrate(100);

    const actionsList = [
        {
            action: PRINT_BADGE,
            typography: t("printBadge"),
            icon: <TemplateBuilderIcon />,
        },
        {
            action: CHECK_IN,
            typography: t("checkIn"),
            icon: <CheckInIcon />,
        },
        {
            action: CHECK_OUT,
            typography: t("checkOut"),
            icon: <CheckOutIcon />,
        },
        {
            action: VIEW_DETAILS,
            typography: t("viewDetails"),
            icon: <OpenInNewIcon />,
        },

    ]

    const actionsToggleHandler = (newAction) => {
        const currentIndex = actions.indexOf(newAction);
        let newChecked = [...actions];

        if (currentIndex === -1) {
            if (newAction === CHECK_OUT) { // if check_in is selected
                newChecked = newChecked.filter(action => action !== CHECK_IN)
            } else if (newAction === CHECK_IN) {
                newChecked = newChecked.filter(action => action !== CHECK_OUT)
            }
            newChecked.push(newAction);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        if (newChecked.length > 0) {
            setActions(newChecked);
        }
    };

    const checkRegistrant = useCallback(async (checkType, registrant) => {
        const registrantChecks = registrant.checks || [];

        if (canRegistrantCheck(registrantChecks, checkType, isRestrictCheckingModeOn)) {
            try {
                await queryCreators.CHECK_REGISTRANT.mutate(checkUser)({
                    check: {
                        eventID,
                        registrantID: registrant.id,
                        type: checkType
                    }
                });
                successVibration();
                showGlobalSnackbar(t(getRegistrantCheckLocaleKey(checkType, "success")));
            } catch (error) {
                errorVibration();
                const errorCode = _get(error, "graphQLErrors[0].extensions.code.errorCode");
                switch (errorCode) {
                    case DUPLICATE_CHECKS:
                        showGlobalSnackbar(t(getRegistrantCheckLocaleKey(checkType, "duplicate")), "error");
                        break;

                    default:
                        showGlobalSnackbar(t(getRegistrantCheckLocaleKey(checkType, "failure")), "error");
                        break;
                }
            }
        } else {
            errorVibration();
            showGlobalSnackbar(t(getRegistrantCheckLocaleKey(checkType, "duplicate")), "error");
        }
    }, [checkUser, isRestrictCheckingModeOn, t, eventID])

    const renderRegistrantCheckIcon = (registrant) => {

        if (!registrant.checks) {
            return null;
        }

        const max = registrant.checks.reduce((prev, current) => { // to get the obj with the latest createdAt
            return (prev.createdAt > current.createdAt) ? prev : current
        });

        if (registrant.checks.length > 1) { // if the registrant has checked in/out more than once
            if (max.type === "IN") { // if the last check was IN
                return <MultipleCheckInIcon color="primary" />
            } else {
                return <MultipleCheckOutIcon color="action" />
            }
        } else if (registrant.checks.length === 1) {// registrant has checked in/out ONLY once
            if (max.type === "IN") {
                return <CheckInIcon color="primary" />
            } else {
                return <CheckOutIcon color="action" />
            }
        }
    }

    const applyActions = useCallback((registrant) => {
        const checkActions = actions.filter(action => action === CHECK_IN || action === CHECK_OUT);
        checkActions.forEach(action => {
            checkRegistrant(action, registrant);
        })
        if (actions.find(action => action === VIEW_DETAILS)) {
            window.open(routePaths.REGISTRATION_REDIRECT(eventID, registrant.eventRegistrationID), '_blank');
        }

        if (actions.find(action => action === PRINT_BADGE)) {
            setContextOverrides(templatesConfig.getContextProps({
                event: {
                    id: eventData.id,
                    logoURL: eventData.logoURL,
                    name: eventData.name
                },
                registrant: {
                    ...registrant
                }
            }, "registrant"))
            setTimeout(() => handlePrint(), 0); // wait until changes applies to template before printing
        }
        setRegistrantsSearchWord("");
        setAnchorEl(null);
    }, [actions, eventData, eventID, handlePrint, checkRegistrant])

    const filterRegistrants = useCallback((registrants, filterWord, filterTechnique) => {
        if (filterWord) {
            const registrant = _find(registrants, o => {
                return _some(Object.entries(o), ([key, v]) => {
                    if (key !== "name" && _toLower(v) === _toLower(filterWord)) {
                        return o
                    }
                });
            })

            if (filterTechnique === QR_SCAN) {
                audio.play();
                if (!registrant) {
                    fetchMoreRegistrants({
                        variables: {
                            eventID
                        },
                        updateQuery: (prev, { fetchMoreResult }) => {
                            if (!fetchMoreResult) return prev;
                            const newRegistrants = _get(fetchMoreResult, "event.registrants", []);
                            if (!newRegistrants.find(({ eventRegistrationID }) => filterWord === eventRegistrationID)) {
                                errorVibration();
                                showGlobalSnackbar(t("registrantNotFound"), "error");
                            } else {
                                filterRegistrants(newRegistrants, filterWord, QR_SCAN);
                            }
                            return {
                                ...prev,
                                event: {
                                    ..._get(prev, "event", {}),
                                    registrants: newRegistrants,
                                }
                            }
                        },
                    });
                } else {
                    applyActions(registrant);
                }
            } else {
                setAnchorEl(searchInputRef.current);
                setSearchRegistrantResult(registrant);
            }


        }
    }, [fetchMoreRegistrants, applyActions, t, eventID]);

    const filteredRegistrant = useMemo(() => _debounce((registrants, searchWord, filterTechnique) => filterRegistrants(registrants, searchWord, filterTechnique), 500), [filterRegistrants]);

    useEffect(() => {
        subscribeToMoreRegistrants({
            document: queryCreators.REGISTRANT_SUBSCRIPTION,
            variables: { eventId: eventID },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                const { newRegistrant } = subscriptionData.data;
                const existingRegistrant = prev.event.registrants.find(({ id }) => id.toString() === newRegistrant.id.toString());
                if (!existingRegistrant) { // to prevent duplicate registrants
                    return {
                        ...prev,
                        event: {
                            ...prev.event,
                            registrants: [...prev.event.registrants, newRegistrant],
                        }
                    }
                }
                return prev;
            }
        });

        subscribeToMoreRegistrants({
            document: queryCreators.CHECK_SUBSCRIPTION,
            variables: { eventId: eventID },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                const { newCheck } = subscriptionData.data;
                const newRegistrants = (prev.event.registrants || []).map((r) => {
                    const existingCheck = (r.checks || []).find(({ id }) => id.toString() === newCheck.id.toString());
                    if (newCheck.registrantID !== r.id || existingCheck) return r;
                    else return {
                        ...r,
                        checks: [...(r.checks || []), newCheck]
                    }
                })
                return {
                    ...prev,
                    event: {
                        ...prev.event,
                        registrants: newRegistrants,
                    }
                }
            }
        });
    }, [eventID, subscribeToMoreRegistrants]);


    if (getBadgesLoading || getEventDataLoading) {
        return (
            <Grid
                container
                alignItems="center"
                justify="center"
                className={classes.spinnerContainer}
            >
                <CircularProgress thickness={7} />
            </Grid>
        );
    }
    return (
        <>
            <div className={classes.upperSectionContainer}>
                <IconButton
                    className={classes.modalOnButton}
                    onClick={() => {
                        setIsSettingsModalOn(true)
                    }}
                >
                    <SettingsIcon />
                </IconButton>
                <div
                    className={classes.eventData}
                >
                    <img
                        src={eventData.logoURL}
                        alt="event's logo"
                        className={classes.eventLogo}
                    />
                    <Typography variant="h6" color="textPrimary">
                        {eventData.name}
                    </Typography>
                </div>
            </div>
            <Grid
                item
                xs={12}
                sm={9}
                md={9}
                className={classes.bodyContainer}
            >
                <Paper className={classes.settingsContainer}>
                    <Grid item xs={12} sm={12} md={12} lg={12} className={classes.searchContainer}>
                        <TextField
                            ref={searchInputRef}
                            value={registrantsSearchWord}
                            className={classes.searchInput}
                            placeholder={t("search")}
                            onChange={event => {
                                const searchWord = event.target.value.trim()
                                setRegistrantsSearchWord(searchWord);
                                filteredRegistrant(fetchedRegistrants, searchWord, SEARCH_REGISTRANT);
                            }}
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Zoom
                                            timeout={{
                                                enter:
                                                    theme.transitions.duration
                                                        .enteringScreen,
                                                exit: 0
                                            }}
                                            in={!!registrantsSearchWord}
                                            mountOnEnter
                                            unmountOnExit
                                        >
                                            <IconButton
                                                onClick={() => {
                                                    setRegistrantsSearchWord("");
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Zoom>
                                        <Zoom
                                            timeout={{
                                                enter:
                                                    theme.transitions.duration
                                                        .enteringScreen,
                                                exit: 0
                                            }}
                                            in={!registrantsSearchWord}
                                            mountOnEnter
                                            unmountOnExit
                                        >
                                            <IconButton
                                                onClick={() => {
                                                    setRegistrantsSearchWord("");
                                                }}
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </Zoom>
                                    </InputAdornment>
                                )
                            }}
                        />

                        <ClickAwayListener
                            onClickAway={() => {
                                setAnchorEl(null);
                            }}
                        >
                            <Popper
                                placement="bottom"
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                            >
                                <Paper style={{ width: _get(searchInputRef, "current.offsetWidth", 0) }}>
                                    {
                                        searchRegistrantResult ?
                                            <MenuItem
                                                onClick={() => {
                                                    applyActions(searchRegistrantResult);
                                                }}
                                            >
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <Avatar>{_get(searchRegistrantResult, "name", "").substring(0, 1).toUpperCase()}</Avatar>
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={_get(searchRegistrantResult, "name")}
                                                    secondary={
                                                        <Fragment>
                                                            <Typography variant="body2">{_get(searchRegistrantResult, "email")}</Typography>
                                                            <Typography variant="body2">{_get(searchRegistrantResult, "phone")}</Typography>
                                                        </Fragment>
                                                    }
                                                    secondaryTypographyProps={{
                                                        component: "span"
                                                    }}
                                                />
                                                {renderRegistrantCheckIcon(searchRegistrantResult)}
                                            </MenuItem>
                                            :
                                            <Typography className={classes.noMatchesText}>{t("noMatches")}</Typography>
                                    }
                                </Paper>
                            </Popper>
                        </ClickAwayListener>

                        <Tooltip
                            title={t("searchRegistrantsByQrCode")}
                            placement="top"
                            enterTouchDelay={200}
                        >
                            <IconButton
                                onClick={() => {
                                    setIsQRCodeSectionOn(!isQRCodeSectionOn);
                                }}
                            >
                                <QRCodeIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <FormHelperText>{t("typeCorrectData")}</FormHelperText>
                    <Collapse in={isQRCodeSectionOn} timeout="auto" unmountOnExit className={classes.collapse}>
                        <QrReader
                            delay={500}
                            onLoad={({ streamTrack }) => {
                                const capabilities = streamTrack.getCapabilities();

                                // Check whether focus distance is supported or not.
                                if (!capabilities.focusDistance) {
                                    return;
                                }

                                // Map focus distance to a slider element.
                                setVideoFocusMeta({ min: capabilities.focusDistance.min, max: capabilities.focusDistance.max, step: capabilities.focusDistance.step, value: streamTrack.getSettings().focusDistance, streamTrack });
                            }}
                            onScan={(data) => {
                                if (data) {
                                    const qrCodeRegistrantId = JSON.parse(data).rID;
                                    const qrCodeEventID = JSON.parse(data).eID;
                                    const qrCodeExhibitorID = JSON.parse(data).exhibID;
                                    if (qrCodeExhibitorID) {
                                        if (qrCodeExhibitorID !== exhibitorQrCodeScanResult) {
                                            if (qrCodeEventID === eventID) {
                                                setExhibitorQrCodeScanResult(qrCodeExhibitorID);
                                                showGlobalSnackbar(t("exhibitorChecked"))
                                            } else {
                                                showGlobalSnackbar(t("belongsToAnotherEvent"), "error")
                                            }
                                        } else if (qrCodeExhibitorID === exhibitorQrCodeScanResult && noQRCodeScansCount.current >= 3) {
                                            setQrCodeScannedBeforeModalOn(true);
                                        }
                                    } else {
                                        if (qrCodeRegistrantId !== qrCodeScanResult) {
                                            if (relatedEvents.find((id) => id === qrCodeEventID) || qrCodeEventID === eventID) {
                                                setQrCodeScanResult(qrCodeRegistrantId);
                                                filterRegistrants(fetchedRegistrants, qrCodeRegistrantId, QR_SCAN);
                                            } else {
                                                showGlobalSnackbar(t("belongsToAnotherEvent"), "error")
                                            }
                                        } else if (qrCodeRegistrantId === qrCodeScanResult && noQRCodeScansCount.current >= 3) { // if QR code was removed before re-scanning show alert message. otherwise ignore
                                            setQrCodeScannedBeforeModalOn(true);
                                        }
                                    }
                                    noQRCodeScansCount.current = 0;
                                } else {
                                    noQRCodeScansCount.current += 1;
                                }
                            }}
                            onError={() => showGlobalSnackbar(t("scanError"), "error")}
                            className={classes.qrCode}
                        />
                        {videoFocusMeta && (
                            <Slider
                                defaultValue={videoFocusMeta.value}
                                step={videoFocusMeta.step}
                                min={videoFocusMeta.min}
                                max={videoFocusMeta.max}
                                onChange={(event, currVal) => {
                                    videoFocusMeta.streamTrack.applyConstraints({
                                        advanced: [{
                                            focusMode: "manual",
                                            focusDistance: currVal
                                        }]
                                    });
                                }}
                            />
                        )}
                    </Collapse>
                </Paper>
            </Grid>

            <Modal
                className={classes.modal}
                open={isSettingsModalOn}
                onClose={() => { setIsSettingsModalOn(false) }}
            >
                <Paper className={classes.modalContainer}>
                    <Typography className={classes.actionsTitle}>
                        {t("chooseActionsToPerform")}
                    </Typography>

                    <List>
                        {actionsList.map((value) => {
                            const isRowSelected = !!actions.find(action => value.action === action)
                            return (
                                <ListItem
                                    classes={{
                                        root: classes.listItem,
                                        selected: classes.selectedListItem,
                                    }}
                                    key={value.action}
                                    dense
                                    button
                                    onClick={() => actionsToggleHandler(value.action)}
                                    selected={isRowSelected}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            color="primary"
                                            edge="start"
                                            checked={isRowSelected}
                                        //    tabIndex={-1}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={value.typography} />
                                    {value.icon}
                                </ListItem>
                            );
                        })}
                    </List>
                    {
                        (!getBadgesLoading && actions.find(action => action === PRINT_BADGE)) &&
                        <div className={classes.selectContainer}>
                            {
                                Array.isArray(badgeTemplates) && currentlySelectedTemplate ?
                                    <TextField
                                        fullWidth
                                        select
                                        label={t("pickTemplate")}
                                        value={currentlySelectedTemplate && currentlySelectedTemplate.id}
                                        onChange={({ target: { value: id } }) => setCurrentlySelectedTemplate(badgeTemplates.find(template => template.id === id))}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    >
                                        {
                                            badgeTemplates.map(template => (
                                                <MenuItem key={template.id} value={template.id}>
                                                    {template.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                    :
                                    <Typography>{t("noBadgesToPrint")}</Typography>
                            }
                        </div>
                    }
                    <DialogActions>
                        <Button autoFocus onClick={() => { setIsSettingsModalOn(false) }} color="primary">
                            {t("save")}
                        </Button>
                    </DialogActions>
                </Paper>
            </Modal>


            <Dialog
                open={qrCodeScannedBeforeModalOn}
                onClose={() => { setQrCodeScannedBeforeModalOn(false) }}
                className={classes.dialog}
            >
                <DialogTitle>{t("qrCodeWasScannedBefore")} </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => { setQrCodeScannedBeforeModalOn(false); }}
                        color="primary"
                    >
                        {t("cancel")}
                    </Button>
                    <Button
                        onClick={() => {
                            if (exhibitorQrCodeScanResult) {
                                showGlobalSnackbar(t("exhibitorChecked"));
                            } else {
                                filteredRegistrant(fetchedRegistrants, qrCodeScanResult, QR_SCAN);
                            }
                            setQrCodeScannedBeforeModalOn(false);
                        }}
                        color="primary"
                        autoFocus
                    >
                        {t("yes")}
                    </Button>
                </DialogActions>
            </Dialog>

            <TemplateBuilderContext.Provider
                value={{
                    contextData: contextOverrides,
                    isPreviewing: true
                }}
            >
                <div className={classes.templateViewer}>
                    <TemplateViewer
                        ref={templatePreviewRef}
                        key={_get(currentlySelectedTemplate, "id", "")}
                        height={_get(currentlySelectedTemplate, "height")}
                        width={_get(currentlySelectedTemplate, "width")}
                        sizeUnit={_get(currentlySelectedTemplate, "unit")}
                        movableTemplates={_get(currentlySelectedTemplate, "movableTemplates", [])}
                        showBorder={false}
                        zoomTransformation={{}}
                    />
                </div>

            </TemplateBuilderContext.Provider>
        </ >
    );
};

export default RegistrationsScansView;
