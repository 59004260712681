import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel'
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import ToggleButton from '@material-ui/lab/ToggleButton';
import CropFreeIcon from '@material-ui/icons/CropFree';
import { useTranslation } from "react-i18next";

import SharedSettings from "../SharedSettings"
import ColorPicker from "../ColorPicker";
import styles from "./styles";

const useStyles = makeStyles(styles, { name: "ShapesTemplateSettings" });

const ShapesTemplateSettings = ({
    onMoveForward,
    onMoveBackward,
    borderColor = "#e2e2e2",
    backgroundColor = "#F2F2F2",
    onEdit,
    borderSize = 0,
    borderRadius = { topLeft: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 },
    isSeparateBorders = false,
    onDelete,
}) => {
    const classes = useStyles();
    const { t } = useTranslation("shapeTemplateSettings");

    useEffect(() => {
        onEdit({
            // checking on empty data when settings panel gets maximized
            borderSize: borderSize === "" ? 0 : borderSize,
            borderRadius: {
                topLeft: borderRadius.topLeft === "" ? 0 : borderRadius.topLeft,
                topRight: borderRadius.topRight === "" ? 0 : borderRadius.topRight,
                bottomRight: borderRadius.bottomRight === "" ? 0 : borderRadius.bottomRight,
                bottomLeft: borderRadius.bottomLeft === "" ? 0 : borderRadius.bottomLeft
            }
        })
        // eslint-disable-next-line
    }, [])
    return (
        <div className={classes.mainElement}>

            <SharedSettings onMoveForward={onMoveForward} onMoveBackward={onMoveBackward} onDelete={onDelete} />
            <div className={classes.bodyContainer}>
                <div className={classes.backgroundColorPicker}>
                    <ColorPicker
                        title={t("backgroundFill")}
                        color={backgroundColor}
                        colorType="rgba"
                        onChange={backgroundColor => onEdit({ backgroundColor })}
                    />
                </div>
                <div className={classes.borderSizeColorContainer}>
                    <TextField
                        label={t("borderSize")}
                        type="number"
                        value={borderSize}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(event) => {
                            let fieldValue = event.target.value;
                            if (fieldValue < 0 || fieldValue.length > 6) return;
                            onEdit({ borderSize: fieldValue })
                        }}
                        onBlur={(event) => {
                            if (!event.target.value)
                                onEdit({ borderSize: 0 })
                        }}
                        onFocus={(event) => {
                            if (event.target.value === "0")
                                onEdit({ borderSize: "" })
                        }}
                    />

                    <div className={classes.borderColorPicker}>
                        <ColorPicker
                            title={t("borderColor")}
                            color={borderColor}
                            colorType="rgba"
                            onChange={borderColor => onEdit({ borderColor })}
                        />
                    </div>

                </div>

                <FormLabel className={classes.title}>
                    {t("cornersRadius")}
                </FormLabel>
                <div className={classes.cornerRadiusContainer}>
                    <Fade in={!isSeparateBorders} mountOnEnter unmountOnExit timeout={{ enter: 400, exit: 0, }}>
                        <TextField
                            className={classes.allBorders}
                            type="number"
                            fullWidth
                            value={borderRadius.topLeft}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={isSeparateBorders}
                            onChange={(event) => {
                                let fieldValue = event.target.value;
                                if (fieldValue < 0 || fieldValue.length > 6) return;
                                onEdit({
                                    borderRadius: {
                                        topLeft: fieldValue,
                                        topRight: fieldValue,
                                        bottomRight: fieldValue,
                                        bottomLeft: fieldValue
                                    }
                                })
                            }}
                            onBlur={(event) => {
                                if (!event.target.value)
                                    onEdit({
                                        borderRadius: {
                                            topLeft: 0,
                                            topRight: 0,
                                            bottomRight: 0,
                                            bottomLeft: 0
                                        }
                                    })
                            }}
                            onFocus={(event) => {
                                if (event.target.value === "0")
                                    onEdit({
                                        borderRadius: {
                                            topLeft: "",
                                            topRight: "",
                                            bottomRight: "",
                                            bottomLeft: ""
                                        }
                                    })
                            }}
                        />
                    </Fade>
                    <Fade in={isSeparateBorders} mountOnEnter unmountOnExit timeout={{ enter: 400, exit: 0, }}>
                        <div className={classes.separateBorders}>
                            <TextField
                                type="number"
                                fullWidth
                                value={borderRadius.topLeft}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={!isSeparateBorders}
                                onChange={(event) => {
                                    let fieldValue = event.target.value;
                                    if (fieldValue < 0 || fieldValue.length > 6) return;
                                    onEdit({
                                        borderRadius: {
                                            topLeft: fieldValue,
                                            topRight: borderRadius.topRight,
                                            bottomRight: borderRadius.bottomRight,
                                            bottomLeft: borderRadius.bottomLeft
                                        }
                                    })
                                }}
                                onBlur={(event) => {
                                    if (!event.target.value)
                                        onEdit({
                                            borderRadius: {
                                                topLeft: 0,
                                                topRight: borderRadius.topRight,
                                                bottomRight: borderRadius.bottomRight,
                                                bottomLeft: borderRadius.bottomLeft
                                            }
                                        })
                                }}
                                onFocus={(event) => {
                                    if (event.target.value === "0")
                                        onEdit({
                                            borderRadius: {
                                                topLeft: "",
                                                topRight: borderRadius.topRight,
                                                bottomRight: borderRadius.bottomRight,
                                                bottomLeft: borderRadius.bottomLeft
                                            }
                                        })
                                }}
                            />
                            <TextField
                                className={classes.separateBorder}
                                type="number"
                                fullWidth
                                value={borderRadius.topRight}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={!isSeparateBorders}
                                onChange={(event) => {
                                    let fieldValue = event.target.value;
                                    if (fieldValue < 0 || fieldValue.length > 6) return;
                                    onEdit({
                                        borderRadius: {
                                            topLeft: borderRadius.topLeft,
                                            topRight: fieldValue,
                                            bottomRight: borderRadius.bottomRight,
                                            bottomLeft: borderRadius.bottomLeft
                                        }
                                    })
                                }}
                                onBlur={(event) => {
                                    if (!event.target.value)
                                        onEdit({
                                            borderRadius: {
                                                topLeft: borderRadius.topLeft,
                                                topRight: 0,
                                                bottomRight: borderRadius.bottomRight,
                                                bottomLeft: borderRadius.bottomLeft
                                            }
                                        })
                                }}
                                onFocus={(event) => {
                                    if (event.target.value === "0")
                                        onEdit({
                                            borderRadius: {
                                                topLeft: borderRadius.topLeft,
                                                topRight: "",
                                                bottomRight: borderRadius.bottomRight,
                                                bottomLeft: borderRadius.bottomLeft
                                            }
                                        })
                                }}
                            />
                            <TextField
                                className={classes.separateBorder}
                                type="number"
                                fullWidth
                                value={borderRadius.bottomRight}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={!isSeparateBorders}
                                onChange={(event) => {
                                    let fieldValue = event.target.value;
                                    if (fieldValue < 0 || fieldValue.length > 6) return;
                                    onEdit({
                                        borderRadius: {
                                            topLeft: borderRadius.topLeft,
                                            topRight: borderRadius.topRight,
                                            bottomRight: fieldValue,
                                            bottomLeft: borderRadius.bottomLeft
                                        }
                                    })
                                }}
                                onBlur={(event) => {
                                    if (!event.target.value)
                                        onEdit({
                                            borderRadius: {
                                                topLeft: borderRadius.topLeft,
                                                topRight: borderRadius.topRight,
                                                bottomRight: 0,
                                                bottomLeft: borderRadius.bottomLeft
                                            }
                                        })
                                }}
                                onFocus={(event) => {
                                    if (event.target.value === "0")
                                        onEdit({
                                            borderRadius: {
                                                topLeft: borderRadius.topLeft,
                                                topRight: borderRadius.topRight,
                                                bottomRight: "",
                                                bottomLeft: borderRadius.bottomLeft
                                            }
                                        })
                                }}
                            />
                            <TextField
                                className={classes.separateBorder}
                                type="number"
                                fullWidth
                                value={borderRadius.bottomLeft}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={!isSeparateBorders}
                                onChange={(event) => {
                                    let fieldValue = event.target.value;
                                    if (fieldValue < 0 || fieldValue.length > 6) return;
                                    onEdit({
                                        borderRadius: {
                                            topLeft: borderRadius.topLeft,
                                            topRight: borderRadius.topRight,
                                            bottomRight: borderRadius.bottomRight,
                                            bottomLeft: fieldValue
                                        }
                                    })
                                }}
                                onBlur={(event) => {
                                    if (!event.target.value)
                                        onEdit({
                                            borderRadius: {
                                                topLeft: borderRadius.topLeft,
                                                topRight: borderRadius.topRight,
                                                bottomRight: borderRadius.bottomRight,
                                                bottomLeft: 0
                                            }
                                        })
                                }}
                                onFocus={(event) => {
                                    if (event.target.value === "0")
                                        onEdit({
                                            borderRadius: {
                                                topLeft: borderRadius.topLeft,
                                                topRight: borderRadius.topRight,
                                                bottomRight: borderRadius.bottomRight,
                                                bottomLeft: ""
                                            }
                                        })
                                }}
                            />
                        </div>
                    </Fade>
                    <ToggleButton
                        className={classes.toggleSeparateBorders}
                        value="check"
                        size="small"
                        selected={isSeparateBorders}
                        onChange={() => {
                            onEdit({
                                isSeparateBorders: !isSeparateBorders
                            })
                            if (isSeparateBorders)
                                onEdit({
                                    borderRadius: {
                                        topLeft: borderRadius.topLeft,
                                        topRight: borderRadius.topLeft,
                                        bottomRight: borderRadius.topLeft,
                                        bottomLeft: borderRadius.topLeft
                                    }
                                })
                        }}
                    >
                        <CropFreeIcon />
                    </ToggleButton>
                </div>


            </div>
        </div >
    );
}

export default ShapesTemplateSettings;