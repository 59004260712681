import React, { useState, useCallback } from "react";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import MenuList from "@material-ui/core/MenuList";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import _get from "lodash/get";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-apollo";

import CheckOutIcon from "components/Icons/CheckOutIcon";
import CheckInIcon from "components/Icons/CheckInIcon";
import TemplateBuilderIcon from 'components/Icons/TemplateBuilderIcon';
import { showGlobalSnackbar } from "../../components/GlobalSnackbar";

import queryCreators from "../../apollo/queryCreators";
import PrintingPreviewModal from "./PrintingPreviewModal";
import { checkType, errorTypes } from "../../enums";
import { canRegistrantCheck, getRegistrantCheckLocaleKey } from "../../modules/utils";


import styles from './registrantActionsMenuStyles';
// import { parser } from "./TemplateBuilder/core/templateUtils";
// import PrintTemplate from "./BadgeTemplatesView/PrintTemplate";

const useStyles = makeStyles(styles);

const { IN, OUT } = checkType;
const { DUPLICATE_CHECKS } = errorTypes;

const MENU_ITEM_HEIGHT = 48;

const RegistrantActionsMenu = React.memo(
  ({
    onClick,
    onClose,
    open,
    anchorEl,
    registrant,
    event,
    isRestrictCheckingModeOn,
  }) => {
    const { t } = useTranslation("registrationsView");
    const classes = useStyles();
    const context = {
      event, registrant: {
        ...registrant,
        id: registrant.eventRegistrationID
      }
    };
    const [showPrintingModal, setShowPrintingModal] = useState(false);
    const [checkUser] = useMutation(queryCreators.CHECK_REGISTRANT.mutation);
    const nonEmptyBadgeTemplates = Array.isArray(event.badgeTemplates) && event.badgeTemplates.filter(template => template.movableTemplates.length > 0);
    const hasNonEmptyBadgeTemplates = nonEmptyBadgeTemplates && nonEmptyBadgeTemplates.length > 0;
    // const hasOneBadgeTemplate = nonEmptyBadgeTemplates && nonEmptyBadgeTemplates.length === 1;
    // const hasNoDependencies = hasOneBadgeTemplate && parser.getDependencies(nonEmptyBadgeTemplates && nonEmptyBadgeTemplates[0].movableTemplates).length === 0;
    const anchorElCallback = useCallback(() => anchorEl, [anchorEl]);
    const options = [
      {
        icon: CheckInIcon,
        label: "checkIn",
        checkType: IN,
      },
      {
        icon: CheckOutIcon,
        label: "checkout",
        checkType: OUT,
      }
    ];

    const clickHandler = async (checkType) => {
      const registrantChecks = registrant.checks || [];
      onClose();
      if (canRegistrantCheck(registrantChecks, checkType, isRestrictCheckingModeOn)) {
        try {
          await queryCreators.CHECK_REGISTRANT.mutate(
            checkUser
          )({
            check: {
              eventID: event.id,
              registrantID: registrant.id,
              type: checkType
            }
          });
          showGlobalSnackbar(t(getRegistrantCheckLocaleKey(checkType, "success")));
        } catch (error) {
          const errorCode = _get(error, "graphQLErrors[0].extensions.code.errorCode");
          switch (errorCode) {
            case DUPLICATE_CHECKS:
              showGlobalSnackbar(t(getRegistrantCheckLocaleKey(checkType, "duplicate")), "error");
              break;

            default:
              showGlobalSnackbar(t(getRegistrantCheckLocaleKey(checkType, "failure")), "error");
              break;
          }
        }
      } else {
        showGlobalSnackbar(t(getRegistrantCheckLocaleKey(checkType, "duplicate")), "error");
      }
    }


    return (
      <>
        <IconButton
          classes={{
            root: classes.buttonRoot,
          }}
          aria-label="more"
          aria-controls={`rigistran-actions-menu-${registrant.eventRegistrationID}`}
          aria-haspopup="true"
          onClick={async event => {
            onClick(event, registrant.id);
          }}
        >
          <MoreVertIcon />
        </IconButton>
        {open && (
          <Popper
            open={open}
            anchorEl={anchorElCallback}
            transition
            placement="bottom-start"
          >
            <Paper style={{
              maxHeight: MENU_ITEM_HEIGHT * 4.5,
              width: 200
            }}>
              <ClickAwayListener onClickAway={onClose}>
                <MenuList
                  id={`rigistrant-actions-menu-${registrant.eventRegistrationID}`}
                >
                  {options.map(option => (
                    <MenuItem key={option.label} onClick={() => clickHandler(option.checkType)}>
                      {option.component ? (
                        <option.component />
                      ) : (
                        <>
                          <ListItemIcon classes={{
                            root: classes.iconRoot
                          }}>
                            <option.icon fontSize="small" />
                          </ListItemIcon>
                          <Typography variant="inherit">
                            {t(`registrationsView:${option.label}`)}
                          </Typography>
                        </>
                      )}
                    </MenuItem>
                  ))}
                  <div className={classes.printBadgeItemContainer}>
                    <MenuItem
                      onClick={() => {
                        // if (!hasNoDependencies)
                        setShowPrintingModal(true);
                        onClose();
                      }}
                      disabled={!hasNonEmptyBadgeTemplates}
                      className={classes.printBadgeItem}
                    >
                      {
                        // hasNoDependencies ?
                        //   <PrintTemplate
                        //     template={nonEmptyBadgeTemplates && nonEmptyBadgeTemplates[0]}
                        //   >
                        //     <div>
                        //       <ListItemIcon classes={{
                        //         root: classes.iconRoot
                        //       }}>
                        //         <TemplateBuilderIcon fontSize="small" />
                        //       </ListItemIcon>
                        //       <Typography noWrap variant="inherit">
                        //         {t('registrationsView:printRegistrantsBadge')}
                        //       </Typography>
                        //     </div>
                        //   </PrintTemplate> :
                        <>
                          <ListItemIcon classes={{
                            root: classes.iconRoot
                          }}>
                            <TemplateBuilderIcon fontSize="small" />
                          </ListItemIcon>
                          <Typography noWrap variant="inherit">
                            {t('registrationsView:printRegistrantsBadge')}
                          </Typography>
                        </>
                      }
                    </MenuItem>
                    {!hasNonEmptyBadgeTemplates &&
                      <ListItemIcon classes={{
                        root: classes.infoIconRoot
                      }}>
                        <Tooltip
                          title={t('registrationsView:noBadgesToPrint')}
                          enterTouchDelay={200}
                        >
                          <InfoIcon fontSize="small" />
                        </Tooltip>
                      </ListItemIcon>
                    }
                  </div>
                </MenuList>

              </ClickAwayListener>
            </Paper>
          </Popper>
        )}
        {showPrintingModal && <PrintingPreviewModal
          target="registrant"
          open={showPrintingModal}
          onClose={() => {
            setShowPrintingModal(false);
            onClose();
          }}
          context={context}
        />}
      </>
    );
  }
);

export default RegistrantActionsMenu;
