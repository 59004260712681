import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from 'apollo-link-ws';
import { onError } from "apollo-link-error";
import { getMainDefinition } from 'apollo-utilities';
import { ApolloLink, Observable, split } from "apollo-link";
import i18n from 'i18next';

import { logout, getToken, subscribeToTokenChange } from "../modules/auth";
import errorCode from "../enums/errorTypes";
import { showGlobalSnackbar } from "../components/GlobalSnackbar"

const { PERMISSION_DENIED, TOKEN_EXPIRED, UNAUTHENTICATED } = errorCode;

const request = async operation => {
  const token = getToken();
  operation.setContext({
    headers: {
      ...(token ? { authorization: token } : {}),
      "app-language": i18n.language,
    }
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_GRAPHQL_WS_URL,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => ({
      ...(getToken() ? { authorization: getToken() } : {}),
    }),
  }
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  credentials: "same-origin"
})

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const linkSplit = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
        const errorCode = graphQLErrors[0].extensions.code;
        switch (errorCode) {
          case TOKEN_EXPIRED:
            showGlobalSnackbar(i18n.t("AuthErrors:sessionTimedOut"), "error")
            logout();
            break;

          case UNAUTHENTICATED:
            showGlobalSnackbar(i18n.t("AuthErrors:provideToken"), "error")
            logout();
            break;

          case PERMISSION_DENIED:
            showGlobalSnackbar(i18n.t("AuthErrors:permissionDenied"), "error")
            break;

          default:
            break;
        }
      }

      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    requestLink,
    linkSplit,
  ]),
  cache: new InMemoryCache()
});

if (process.env.NODE_ENV === "development") {
  window.apolloClient = client;
}

subscribeToTokenChange((newToken) => {
  if (!newToken) {
    wsLink.subscriptionClient.close(); // close connection on logging out
  }
})

export default client;
