import React, { Fragment } from "react";
import { withStyles, FormControl, InputLabel, TextField } from "@material-ui/core";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

import customInputStyle from "assets/jss/material-kit-react/components/customInputStyle.jsx";
import { compose } from "recompose";
import withTheme from "@material-ui/core/styles/withTheme";

function CustomInput({ ...props }) {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    InputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    theme,
    renderInput,
    errorText,
    disabled,
    validate,
    required,
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white
  });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined,
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white
  });
  const formControlClasses = classNames({
    [classes.formControl]: true,
    [classes.formControlLabel]: labelText !== undefined,
    [formControlProps.className]: formControlProps.className !== undefined
  });
  let inputWithProps;
  if (renderInput) {
    inputWithProps = React.Children.map(renderInput, child =>
      React.cloneElement(child, {
        inputProps: {
          classes: {
            input: inputClasses,
            root: marginTop,
            disabled: classes.disabled,
            underline: underlineClasses
          }
        },
        id: id
      })
    );
  }
  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {renderInput ? (
        <Fragment>
          <InputLabel
            className={classes.labelRoot + " " + labelClasses}
            htmlFor={id}
            {...labelProps}
            style={{
              left: theme.direction === "rtl" && "auto",
              transformOrigin: theme.direction === "rtl" && "top right"
            }}
          >
            {labelText}
          </InputLabel>
          {inputWithProps[0]}
        </Fragment>
      ) : (
          <TextField
            required={required}
            validate={validate}
            label={labelText}
            helperText={error ? errorText : undefined}
            disabled={disabled}
            classes={{
              root: marginTop,
            }}
            id={id}
            InputProps={InputProps}
            InputLabelProps={labelProps}
            error={error}
          />
        )}
    </FormControl>
  );
}

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  InputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool
};

export default compose(
  withTheme,
  withStyles(customInputStyle)
)(CustomInput);
