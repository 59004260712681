import React from 'react';
import cn from 'classnames';

import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import styles from './styles';
const useStyles = makeStyles(styles, { name: 'CardModal' });

const CardModal = ({
    open,
    onClose,
    fullScreen,
    children,
    maxWidth,
    transition,
    title,
    classes: classesOverrides = {},
}) => {
    const classes = useStyles();
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={maxWidth}
            fullScreen={fullScreen}
            classes={{
                paper: cn(classes.paper, classesOverrides.dialogPaper),
            }}
            TransitionComponent={transition}
        >
            <Card className={cn({
                [classes.cardFullScreen]: !!fullScreen,
                [classes.card]: !fullScreen,
                [classesOverrides.card]: !!classesOverrides.card,
            })}>
                <CardHeader className={cn(classes.cardHeader, classesOverrides.cardHeader)} color="primary">
                    <Typography
                        className={cn(classes.cardHeaderTitle, classesOverrides.cardHeaderTitle)}
                        variant="subtitle1"
                    >
                        {title}
                    </Typography>
                </CardHeader>
                <CardBody className={cn(classes.cardBody, classesOverrides.cardBody)}>
                    {children}
                </CardBody>
            </Card>
        </Dialog>
    );
}

export default CardModal;