import React, { useState } from 'react';

import { withRouter } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import queryCreators from '../../../../apollo/queryCreators';
import TemplateBuilderIcon from 'components/Icons/TemplateBuilderIcon';
import PrintingPreviewModal from '../../../EventPage/PrintingPreviewModal';
import styles from './styles';

import { useMutation, useQuery } from 'react-apollo';
import { useParams } from 'react-router-dom';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { errorTypes, invitationType } from "../../../../enums";
import routePaths from "../../../../config/routePaths";

const { INVITATION_ALREADY_ACCEPTED, NOT_FOUND } = errorTypes;
const { ORGANIZER_TO_ORGANIZER } = invitationType;

const useStyles = makeStyles(styles, { name: "MembersActionsMenu" });

const MembersActionsMenu = ({ currentItem, onError, onDeleteFailure, loggedInUserEmail, history }) => {
    const isLoggedInUser = loggedInUserEmail === currentItem.email;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState();
    const [open, setOpen] = useState(false);
    const { eventID } = useParams();
    const [showPrintingModal, setShowPrintingModal] = useState();
    const { t } = useTranslation('eventMembersView');

    const [deleteMember, { loading: deleteMemberLoading }] = useMutation(
        queryCreators.DELETE_EVENT_MEMBER.mutation,
        {
            onCompleted: () => { if (isLoggedInUser) history.replace(routePaths.HOME) },
            onError: (error) => {
                const errorCode = _get(error, "graphQLErrors[0].extensions.exception.errorCode");
                if (errorCode === NOT_FOUND) {
                    onDeleteFailure();
                    return onError("noLongerMember")
                }

                return onError("cantDeleteMember")
            }
        }
    );

    const [deleteInvitation, { loading: deleteInvitationLoading }] = useMutation(
        queryCreators.DELETE_INVITATION.mutation,
        {
            onCompleted: () => { if (isLoggedInUser) history.replace(routePaths.HOME) },
            onError: (error) => {
                const errorCode = _get(error, "graphQLErrors[0].extensions.exception.errorCode");
                if (errorCode === INVITATION_ALREADY_ACCEPTED) {
                    onError("invAlreadyAccepted")
                    onDeleteFailure();
                } else
                    onError("cantDeleteInvitation")
            }
        }
    );

    const { loading: eventDataFetchLoading, data } = useQuery(
        queryCreators.EXHIBITORS_PRINTING_CONTEXT_DATA.query,
        {
            variables: {
                eventID
            }
        }
    );

    const badgeTemplates = _get(data, 'event.badgeTemplates');
    const nonEmptyBadgeTemplates = badgeTemplates && badgeTemplates.filter(template => template.movableTemplates.length > 0);
    const hasNonEmptyBadgeTemplates = nonEmptyBadgeTemplates && nonEmptyBadgeTemplates.length > 0;

    const typeConfig = {
        deleteHandler: () => currentItem.objType === "Member" || isLoggedInUser ?
            handleMemberDelete() :
            handleInvitationDelete(),
        icon: isLoggedInUser ? <ExitToAppIcon fontSize="small" /> : <DeleteIcon fontSize="small" />,
        text: isLoggedInUser ? t('leave') : t('delete')
    }

    const handleMemberDelete = () => {
        queryCreators.DELETE_EVENT_MEMBER.mutate(deleteMember)(
            {
                id: currentItem.id,
                eventID: eventID,
            },
            {
                eventID,
                accountEmail: currentItem.email // to fetch and delete current event from cache
            }
        );
    };

    const handleInvitationDelete = () => {
        queryCreators.DELETE_INVITATION.mutate(deleteInvitation)(
            { id: currentItem.id },
            {
                eventID,
                type: ORGANIZER_TO_ORGANIZER
            }
        );
    };

    return (
        <div>
            <IconButton
                className={classes.button}
                onClick={evt => { setOpen(true); setAnchorEl(evt.currentTarget); }}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setOpen(false)}
                PaperProps={{
                    className: classes.paper
                }}
            >

                <div className={classes.menuItemContainer}>
                    <MenuItem
                        className={classes.deleteMenuItem}
                        onClick={() => {
                            typeConfig.deleteHandler()
                        }}
                        disabled={deleteMemberLoading || deleteInvitationLoading || currentItem.objType === "Owner"}
                    >
                        <ListItemIcon>
                            {typeConfig.icon}
                        </ListItemIcon>
                        <Typography variant="inherit">
                            {typeConfig.text}
                        </Typography>
                    </MenuItem>
                    {
                        currentItem.objType === "Owner" &&
                        <ListItemIcon classes={{
                            root: classes.infoIconRoot
                        }}>
                            <Tooltip
                                title={isLoggedInUser ? t("ownerCantLeave") : t('cantDeleteOwner')}
                                enterTouchDelay={200}
                            >
                                <InfoIcon fontSize="small" />
                            </Tooltip>
                        </ListItemIcon>
                    }

                    {(deleteMemberLoading || deleteInvitationLoading) &&
                        <div className={classes.menuItemProgress}>
                            <CircularProgress size={24} />
                        </div>
                    }
                </div>
                <div className={classes.menuItemContainer}>
                    <MenuItem
                        onClick={() => { setShowPrintingModal(true); setOpen(false); }}
                        disabled={eventDataFetchLoading || !hasNonEmptyBadgeTemplates || currentItem.objType === "Invitation"}
                    >
                        <ListItemIcon>
                            <TemplateBuilderIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit" className={hasNonEmptyBadgeTemplates ? classes.printText : ''}>
                            {t('printBadge')}
                        </Typography>
                    </MenuItem>
                    {!eventDataFetchLoading && !hasNonEmptyBadgeTemplates ?
                        <ListItemIcon classes={{
                            root: classes.infoIconRoot
                        }}>
                            <Tooltip
                                title={t('noBadgesToPrint')}
                                enterTouchDelay={200}
                            >
                                <InfoIcon fontSize="small" />
                            </Tooltip>
                        </ListItemIcon>
                        :
                        currentItem.objType === "Invitation" &&
                        <ListItemIcon classes={{
                            root: classes.infoIconRoot
                        }}>
                            <Tooltip
                                title={t('invitationNotAccepted')}
                                enterTouchDelay={200}
                            >
                                <InfoIcon fontSize="small" />
                            </Tooltip>
                        </ListItemIcon>
                    }
                    {eventDataFetchLoading && <CircularProgress size={24} className={classes.menuItemProgress} />}
                </div>
            </Menu>
            {
                showPrintingModal && <PrintingPreviewModal
                    target="organizer"
                    open={showPrintingModal}
                    onClose={() => {
                        setShowPrintingModal(false);
                        setOpen(false);
                    }}
                    context={{
                        event: data.event,
                        organizer: currentItem
                    }}
                />
            }
        </div >
    );
};

export default withRouter(MembersActionsMenu);