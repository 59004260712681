const styles = theme => ({
    badgeNotFound: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.divider,
    },
})

export default styles;
