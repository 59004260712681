import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import { List, ListItem, withStyles } from "@material-ui/core";

import footerStyle from "assets/jss/material-kit-react/components/footerStyle.jsx";

function Footer({ ...props }) {
  const { classes, whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          {false && (
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="https://twitter.com/CreativeTim"
                  className={classes.block}
                >
                  <i className={classes.socialIcons + " fab fa-twitter"} />
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="https://www.facebook.com/CreativeTim"
                  className={classes.block}
                >
                  <i className={classes.socialIcons + " fab fa-facebook"} />
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="https://www.instagram.com/CreativeTimOfficial"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.block}
                >
                  <i className={classes.socialIcons + " fab fa-instagram"} />
                </a>
              </ListItem>
            </List>
          )}
        </div>
        <div className={classes.right}>
          &copy; {1900 + new Date().getYear()},
          <a href={"/"} className={aClasses}>
            {" "}
            Eventsquare
          </a>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  whiteFont: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
