import React, { useState, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import useTheme from "@material-ui/core/styles/useTheme";
import ContentLoader from "react-content-loader";
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';
import CircularProgress from "@material-ui/core/CircularProgress";
import { useMutation, useQuery } from "react-apollo";
import classNames from "classnames";
import queryCreators from "apollo/queryCreators";
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import useRouter from 'hooks/useRouter';
import TemplateViewer from '../../TemplateBuilder/TemplateViewer';
import useZoomTransformation from '../../TemplateBuilder/utils/useZoomTransformation';
import routePaths from "../../../../config/routePaths"
import PrintingPreviewModal from '../../PrintingPreviewModal';
import TemplateBuilderContext from '../../../../contexts/TemplateBuilderContext';
import { templatesConfig } from '../../TemplateBuilder/core/templateUtils';

import styles from "./styles";

import { useParams } from 'react-router-dom';
// import { parser } from '../../TemplateBuilder/core/templateUtils';
// import PrintTemplate from '../PrintTemplate';

const useStyles = makeStyles(styles, { name: "TemplatesPage" });

const BadgeTemplateCard = ({ template, onError }) => {
    const { id, name, width, height, unit, movableTemplates } = template || {};
    const [builderNode, setBuilderNode] = useState();
    const [builderContainerNode, setBuilderContainerNode] = useState();
    const zoomTransformation = useZoomTransformation(builderNode, builderContainerNode, undefined, "fill");
    const classes = useStyles();
    const router = useRouter();
    const { t } = useTranslation("templatesPage");
    const { eventID } = useParams();
    const [showPrintingModal, setShowPrintingModal] = useState();
    const theme = useTheme();
    // const dependencies = parser.getDependencies(movableTemplates);

    const [deleteBadgeTemplate, { loading: deleteBadgeLoading }] = useMutation(
        queryCreators.DELETE_BADGE_TEMPLATE.mutation
    );

    const deleteBadge = async badgeID => {
        try {
            await queryCreators.DELETE_BADGE_TEMPLATE.mutate(deleteBadgeTemplate)({
                badge: badgeID
            },
                {
                    eventID
                }
            );
        } catch (e) {
            onError && onError(t("templatesPage:failedDeleteError"));
        }
    }

    const { loading: eventDataFetchLoading, data: eventData } =
        useQuery(
            queryCreators.GET_EVENT_CONTEXT_DATA.query,
            {
                variables: {
                    eventID
                },
                onError: () => {
                    onError && onError(t("templatesPage:failedToLoadData"));
                }
            }
        );
    const { event } = eventData || {};

    const contextOverrides = useMemo(() => {
        if (eventData)
            return templatesConfig.getContextProps(eventData);
    }, [eventData])

    return (
        <>
            {showPrintingModal && event &&
                <PrintingPreviewModal
                    template={template}
                    context={eventData}
                    open
                    onClose={() => setShowPrintingModal(false)}
                />
            }
            <div className={
                classNames({
                    [classes.container]: id && !deleteBadgeLoading,
                    [classes.containerDisabled]: (id && deleteBadgeLoading) || !id,
                })
            } onClick={() => {
                if (deleteBadgeLoading) return;
                router.push(routePaths.BADGE_TEMPLATE_BUILDER_REDIRECT(eventID, id))
            }}>
                <Card className={classes.card}>
                    <div className={classes.cardHeader}>
                        <Typography noWrap className={classes.cardTitle}>
                            {name}
                        </Typography>
                        <IconButton disabled={deleteBadgeLoading || !id} className={classes.deleteButton} size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                deleteBadge(id);
                            }}
                        >
                            {deleteBadgeLoading && (
                                // loading around delete button appears while deleting
                                <CircularProgress size={35} className={classNames(classes.iconButtonLoading, classes.deleteButtonLoading)} />
                            )}
                            <DeleteIcon />
                        </IconButton>
                        {movableTemplates.length > 0 &&
                            // Array.isArray(dependencies) && dependencies.length > 0 ?
                            <IconButton
                                className={classes.printButton}
                                size="small"
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    setShowPrintingModal(true);
                                }}
                            >
                                {eventDataFetchLoading && (
                                    <CircularProgress size={35} className={classes.iconButtonLoading} />
                                )}
                                <PrintIcon />
                            </IconButton>
                            // :
                            // <IconButton
                            //     className={classes.printButton}
                            //     size="small"
                            //     onClick={e => e.stopPropagation()}
                            // >

                            //     <PrintTemplate
                            //         template={template}
                            //     >
                            //         <PrintIcon />
                            //     </PrintTemplate>
                            // </IconButton>
                        }
                    </div>
                    {
                        !id ?
                            <CardBody className={classes.cardBody}>
                                <ContentLoader
                                    primaryColor={theme.palette.divider}
                                    secondaryColor={theme.palette.action.selected}
                                    className={classes.placeHolderArea}
                                />
                            </CardBody> :
                            <CardBody className={classes.cardBody}>

                                {(!movableTemplates || !movableTemplates.length) && (
                                    <div className={classes.placeHolderArea}>
                                        <Typography>
                                            {t("templatesPage:emptyBadge")}
                                        </Typography>
                                    </div>
                                )}
                                {movableTemplates && !!movableTemplates.length && (
                                    <div className={classes.badgeTemplateViewContainer}
                                        ref={ref => { if (builderContainerNode !== ref) setBuilderContainerNode(ref); }}
                                    >
                                        <TemplateBuilderContext.Provider
                                            value={{
                                                contextData: contextOverrides,
                                            }}
                                        >
                                            <TemplateViewer
                                                movableTemplates={movableTemplates}
                                                width={width}
                                                height={height}
                                                sizeUnit={unit}
                                                ref={ref => { if (builderNode !== ref) setBuilderNode(ref); }}
                                                zoomTransformation={zoomTransformation}
                                                showBorder={false}
                                            />
                                        </TemplateBuilderContext.Provider>
                                    </div>
                                )}
                            </CardBody>
                    }
                </Card>
            </div>
        </>
    );
}

export default BadgeTemplateCard
