import React, { Fragment, useEffect, useState } from "react"
import { useMutation, useQuery, useLazyQuery } from "react-apollo";
import _get from "lodash/get";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import queryCreators from "../../../apollo/queryCreators";

// material-ui components
import { useTheme } from "@material-ui/styles";
import makeStyles from "@material-ui/core/styles/makeStyles"
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import CircularProgress from "@material-ui/core/CircularProgress";

import CardBody from "../../../components/Card/CardBody.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import Card from "../../../components/Card/Card.jsx";
import CustomButton from "../../../components/CustomButtons/Button.jsx";
import { showGlobalSnackbar } from "../../../components/GlobalSnackbar";
import FormEditField from "./FormEditField"
import styles from "./styles"

const useStyles = makeStyles(styles, { name: "FormTranslationsModal" });

const FormTranslationsModal = ({
    isOpen,
    onClose,
    eventLanguages = [],
    inputsToTranslate = [],
}) => {
    const { t, i18n } = useTranslation("formTranslations");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const classes = useStyles();
    const [buttonSelected, setButtonSelected] = useState(0);
    const [translatedResources, setTranslatedResources] = useState({});
    const [titles, setTitles] = useState([]);
    const { eventID } = useParams();

    const { loading: supportedLanguagesLoading } = useQuery(queryCreators.GET_SUPPORTED_LANGUAGES, {
        variables: {
            language: i18n.language,
        },
        onCompleted: (data) => {
            const supportedLanguages = _get(data, "supportedLanguages", [])
            setTitles(eventLanguages.map(language => {
                const title = supportedLanguages.find(object => object.language === language);
                if (title) {
                    return title.name;
                }
                return language;
            }))
        },
    });

    const [getFormTranslations, { data: formTranslation, loading: formTranslationLoading }] = useLazyQuery(queryCreators.GET_FORM_TRANSLATION.query, {
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
            setTranslatedResources({
                ...translatedResources,
                [eventLanguages[buttonSelected]]: {
                    ..._get(data, `translatedFormResources.${eventLanguages[buttonSelected]}`)
                }
            })
        }
    });

    useEffect(() => {
        if (inputsToTranslate.length > 0) {
            getFormTranslations({
                variables: {
                    eventId: eventID,
                }
            });
        }
    }, [getFormTranslations, inputsToTranslate, eventID])

    const [updateFormTranslations] = useMutation(queryCreators.UPDATE_FORM_TRANSLATIONS.mutation, {
        onCompleted: () => {
            onClose(false);
            showGlobalSnackbar(t("formTranslationsUpdated"));
        },
        onError: () => {
            onClose(false);
            showGlobalSnackbar(t("errorOccurred"), "error");
        },
    });

    const handleTabSwitch = (event, newValue) => {
        if (newValue != null) {// so the button can't be unselected unless another one is selected
            setButtonSelected(newValue);
            if (!translatedResources[eventLanguages[newValue]])
                setTranslatedResources({
                    ...translatedResources,
                    [eventLanguages[newValue]]: {
                        ..._get(formTranslation, `translatedFormResources.${eventLanguages[newValue]}`)
                    }
                })
        }
    }

    const updateTranslations = () => {
        updateFormTranslations({
            variables: {
                eventId: eventID,
                customFormTranslations: translatedResources,
            }
        })
    }

    const handleInputChange = (text, resource) => {
        setTranslatedResources({
            ...translatedResources,
            [eventLanguages[buttonSelected]]: {
                ...translatedResources[eventLanguages[buttonSelected]],
                [resource]: text,
            }
        })
    }

    return (
        <Dialog
            open={isOpen}
            onClose={() => onClose(false)}
            classes={{
                paper: fullScreen ? classes.paperFullScreen : classes.paper
            }}
            fullScreen={fullScreen}
        >
            <Card className={fullScreen ? classes.cardFullScreen : classes.card}>

                <CardHeader className={classes.cardHeader} color="primary">
                    <Typography variant="subtitle1">
                        {t("formTranslations")}
                    </Typography>
                </CardHeader>
                <Card className={classes.cardBodyContainer}>
                    <CardBody>
                        <Tabs
                            style={{ marginBottom: theme.spacing(2) }}
                            value={buttonSelected}
                            onChange={handleTabSwitch}
                            textColor="primary"
                            TabIndicatorProps={{
                                style: { background: theme.palette.text.primary }
                            }}
                        >
                            {
                                titles.map(language => {
                                    return (
                                        <Tab
                                            key={language}
                                            classes={{
                                                root: classes.tab,
                                                textColorPrimary: classes.tabTextColor,
                                                selected: classes.selected
                                            }}
                                            label={language}
                                        />
                                    )
                                })
                            }

                        </Tabs>
                        <div className={classes.titlesContainer}>
                            <Typography
                                color="textPrimary"
                                className={classes.title}
                            >
                                {t("defaultLanguage").toUpperCase()}
                            </Typography>
                            <Typography
                                color="textPrimary"
                                className={classes.title}
                            >
                                {t("translations", { language: titles[buttonSelected] || "" }).toUpperCase()}
                            </Typography>
                        </div>

                        <Grid>
                            {
                                formTranslationLoading || supportedLanguagesLoading || Object.keys(translatedResources).length <= 0 ?
                                    <div className={classes.circularProgressContainer}>
                                        <CircularProgress />
                                    </div>
                                    :
                                    Object.keys(translatedResources[eventLanguages[buttonSelected]]).map((key, index) =>
                                        key &&
                                        <Fragment key={index}>
                                            <Divider />
                                            <div className={classes.translationPreviewContainer}>
                                                <Typography
                                                    className={classes.defaultResourceText}
                                                    noWrap
                                                    color="textPrimary"
                                                >
                                                    {key}
                                                </Typography>

                                                <Divider
                                                    className={classes.verticalDivider}
                                                    flexItem
                                                    orientation="vertical"
                                                />

                                                <FormEditField
                                                    onChange={(text) => handleInputChange(text, key)}
                                                    key={`${formTranslation.translatedFormResources[eventLanguages[buttonSelected]][key]}${eventLanguages[buttonSelected]}`}
                                                    value={translatedResources[eventLanguages[buttonSelected]][key]}
                                                />
                                            </div>
                                        </Fragment>
                                    )
                            }
                        </Grid>

                        <CustomButton
                            className={classes.saveButton}
                            fullWidth={true}
                            color="primary"
                            size="lg"
                            onClick={updateTranslations}
                        >
                            {t("save")}
                        </CustomButton>

                        {fullScreen && (
                            <CustomButton
                                fullWidth={true}
                                simple
                                color="primary"
                                size="lg"
                                onClick={() => onClose(false)}
                            >
                                {t("templatesPage:cancel")}
                            </CustomButton>
                        )}

                    </CardBody>
                </Card>
            </Card>
        </Dialog >
    )
}
export default FormTranslationsModal;