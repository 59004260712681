import React, { useEffect, useState, useContext } from "react";
import QRCode from "qrcode";
import withParsedValues from "../../withParsedValues";
import QRCodeIcon from "../../../../../../components/Icons/QRCodeIcon";
import useTheme from "@material-ui/core/styles/useTheme";
import TemplateBuilderContext from "../../../../../../contexts/TemplateBuilderContext";

function QRCodeTemplate({ contentConfig, layoutConfig }, ref) {
  const { qrcode } = contentConfig || {};
  const { dimensions, origin, transform } = layoutConfig || {};
  const { isPreviewing } = useContext(TemplateBuilderContext);
  const [qrcodeUrl, setQrcodeUrl] = useState("");
  const theme = useTheme();

  useEffect(() => {
    (async function () {
      let res = null;
      if (qrcode)
        res = await QRCode.toDataURL(qrcode, {
          errorCorrectionLevel: 'Q'
        });
      setQrcodeUrl(res);
    })();
  }, [qrcode]);

  if (!contentConfig || !layoutConfig || (isPreviewing && !qrcode))
    return null;

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        ...dimensions,
        ...origin,
        transform
      }}
    >
      {qrcode ? (
        <img
          src={qrcodeUrl}
          alt="QrCode"
          style={{ width: "inherit", height: "inherit" }}
        />
      ) : (
        <QRCodeIcon style={{ color: theme.palette.grey[500], ...dimensions }} />
      )}
    </div>
  );
}

export default withParsedValues(React.memo(React.forwardRef(QRCodeTemplate)));
