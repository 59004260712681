import React, { useMemo, useContext } from "react";
import { Textfit } from "react-textfit";
import Box from "@material-ui/core/Box";


import { useTranslation } from "react-i18next";
import withParsedValues from "../../withParsedValues";
import { parser, templatesConfig } from '../../templateUtils';
import TemplateBuilderContext from "../../../../../../contexts/TemplateBuilderContext";

function TextTemplate({ layoutConfig, contentConfig }, ref) {

  const { dimensions, origin, transform } = layoutConfig || {};
  const {
    text,
    color,
    fontSize,
    fontFamily,
    textAlign
  } = contentConfig || {};
  const { isPreviewing } = useContext(TemplateBuilderContext);
  const { t } = useTranslation(["templateBuilder", "contextData"]);
  const parsedText = useMemo(() => {
    const parsables = parser.getParsables(text);
    if (Array.isArray(parsables) && parsables.length > 0) {
      const { PARSING_CONSTANTS: { MARKER_START, MARKER_END } } = templatesConfig;
      let res = text;
      parsables.forEach(parsable => res = res.replace(MARKER_START + parsable + MARKER_END, isPreviewing ? '' : parser.getLocalizedContextKey(
        parsable,
        t,
        isPreviewing ? false : undefined,
        isPreviewing ? false : undefined
      )));
      return res;
    }
    return text;
  }, [text, isPreviewing, t]);

  if (!layoutConfig || !contentConfig || (!parsedText && isPreviewing) || (isPreviewing && !text))
    return null;

  return (
    <div
      style={{
        position: "absolute",
        ...dimensions,
        ...origin,
        transform,
        color,
        fontFamily,
        textAlign,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        lineHeight: 1,
        overflow: "hidden"
      }}
      ref={ref}
    >
      <Textfit
        max={parsedText && fontSize ? Number(fontSize) : undefined}
        key={`${dimensions.width}${dimensions.height}`}
        style={{
          ...dimensions
        }}
        mode="multi"
      >
        {parsedText ? (
          parsedText
        ) : (
          <Box color="rgba(0, 0, 0, 0.12)">
            {t("templateBuilder:addText")}
          </Box>
        )}
      </Textfit>
    </div>
  );
}

export default withParsedValues(React.memo(React.forwardRef(TextTemplate)));
