const inputTypes = {
  TEXT: "text",
  TEXT_AREA: "textarea",
  CHECKBOX: "checkbox",
  SELECT: "select",
  MULTI_CHECKBOX: "multiCheckbox",
  EMAIL: "email",
  PHONE: "phone",
  IMAGE_PICKER: "imagePicker",
};

export default inputTypes;
