import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import classNames from "classnames";
import tinycolor from "tinycolor2";
import hexToRgba from "hex-rgba";
import { NavLink, useHistory } from "react-router-dom";
import { get as _get } from "lodash";

import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from "@material-ui/core/styles/withTheme";
import withWidth from "@material-ui/core/withWidth";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import LinearProgress from "@material-ui/core/LinearProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LogoIcon from "components/Icons/Logo";
import GlobeIcon from "components/Icons/GlobeIcon";
import Avatar from "@material-ui/core/Avatar";

import Slide from "@material-ui/core/Slide";
import Zoom from "@material-ui/core/Zoom";
import Collapse from "@material-ui/core/Collapse";

//i18n
import { withTranslation } from "react-i18next";

//graphql
import { Query, useQuery } from "react-apollo";
import gql from "graphql-tag";
import queryCreators from "./../../apollo/queryCreators";

import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import EngagementIcon from "components/Icons/EngagementIcon"
import MenuIcon from "components/Icons/MenuIcon";
import SigoutIcon from "components/Icons/SigoutIcon";
import EventIcon from '@material-ui/icons/Event';
import ExhibitorsIcon from "components/Icons/ExhibitorsIcon";
import DashboardIcon from "components/Icons/DashboardIcon";
import TeamIcon from "components/Icons/TeamIcon";
import FormIcon from "components/Icons/FormIcon";
import SettingsIcon from "components/Icons/SettingsIcon";
import RegistrationsIcon from "components/Icons/RegistrationsIcon";
import TemplateBuilderIcon from 'components/Icons/TemplateBuilderIcon';

import useAuth from 'contexts/AuthenticationContext/useAuth';
import routePaths from "../../config/routePaths"
import ErrorPage from "./ErrorPage"

// The usage of React.forwardRef will no longer be required for react-router-dom v6.
// see https://github.com/ReactTraining/react-router/issues/6056
const MUINavLink = React.forwardRef((props, ref) => (
  <NavLink innerRef={ref} {...props} />
));

const langsArray = [
  { label: "عربي", value: "ar" },
  { label: "English", value: "en" }
];
const styles = theme => {
  const appBarHeight = 64;
  const openedDrawerWidth = 240;
  const closedDrawerWidth = theme.spacing(9);

  return {
    root: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column"
    },
    body: {
      display: "flex",
      flex: 1,
      flexDirection: "row"
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      height: appBarHeight
    },
    errorAppBar: {
      paddingLeft: 24,
    },
    appBarLeft: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      overflow: "hidden"
    },
    appBarRight: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
      marginRight: 20,
    },
    appBarRightList: {
      display: "flex"
    },
    appBarRightListItem: {
      padding: 0
    },
    appBarLeftDrawerOpened: {
      borderStyle: "solid",
      borderColor: theme.palette.divider,
      borderWidth: "0px 1px 0px 0px",
      minWidth: openedDrawerWidth,
      padding: theme.spacing(0, 0, 0, 3)
    },
    appBarLogo: {
      height: 30,
      width: 30,
      margin: theme.spacing(0, 2, 0, 0)
    },
    appBarTitle: {
      fontWeight: 400,
      fontSize: 20
    },
    appBarButton: {
      marginLeft: theme.spacing(0.5)
    },
    appBarLogoDrawerClosed: {
      margin: theme.spacing(0, 2, 0, 1)
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 12
    },
    homeButton: {
      "&:hover,&:focus": {
        color: "inherit"
      }
    },
    hide: {
      display: "none"
    },
    drawerPaper: {
      width: openedDrawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      borderStyle: "solid",
      borderWidth: "0px 1px 0px 0px",
      borderColor: theme.palette.divider,
      [theme.breakpoints.up("sm")]: {
        top: appBarHeight,
        height: `calc(100vh - ${appBarHeight || 0}px)`,
        overflowX: "hidden",
      }
    },
    drawerPaperClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: closedDrawerWidth
    },
    content: {
      position: "relative",
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.background.default,
      overflow: "hidden"
    },
    contentPermenantDrawerOpened: {
      margin: `0px 0px 0px ${openedDrawerWidth}px`,
      transition: theme.transitions.create(["margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    contentPermenantDrawerClosed: {
      margin: `0px 0px 0px ${closedDrawerWidth}px`,
      transition: theme.transitions.create(["margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    drawerMenuItemIcon: {
      color: "inherit",
      margin: theme.spacing(0, 3, 0, 1),
      minWidth: "unset" // override default minWidth
    },
    drawerMenuItemText: {
      display: "flex", // aligns the text to the right on rtl and left on ltr
      color: "inherit"
    },
    drawerEventNameContainer: {
      margin: `4px 0px`,
      padding: theme.spacing(1),
      wordWrap: "break-word",
      backgroundColor: theme.palette.background.default
    },
    drawerEventLogoContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    drawerEventLogo: {
      objectFit: "contain",
      margin: theme.spacing(1),
      borderRadius: 4,
      transition: theme.transitions.create(["width", "height"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    eventLogoDrawerClosed: {
      height: closedDrawerWidth - theme.spacing(2),
      width: closedDrawerWidth - theme.spacing(2)
    },
    eventLogoDrawerOpened: {
      height: openedDrawerWidth / 2,
      width: openedDrawerWidth / 2
    },
    eventLogoDrawerError: {
      paddingTop: 50,
    },
    eventLogoDrawerLoadingOpened: {
      height: openedDrawerWidth / 2,
      width: "100%",
      padding: `0 ${theme.spacing(2)}px`
    },
    activeDrawerMenuItem: {
      background: hexToRgba(theme.palette.primary.main, 8),
      color: theme.palette.primary.main,
      "&:hover,&:focus": {
        color: theme.palette.primary.main
      }
    },
    inactiveDrawerMenuItem: {
      "&:hover,&:focus": {
        color: theme.palette.primary.main
      }
    },
    errorMessageContainer: {
      padding: "5px 0 0 5px",
      textAlign: "center",
    },
    avatar: {
      width: 35,
      height: 35,
      fontSize: 13,
      backgroundColor: tinycolor(theme.palette.primary.contrastText).setAlpha(.2).toRgbString(),
      color: theme.palette.primary.contrastText,
    },
    listItemText: {
      textAlign: "start",
    },
    listItemIcon: {
      color: "unset",
    },
    planBadge: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderRadius: 16,
      padding: "3px 6px",
      fontSize: "0.6rem",
      marginBottom: 8,
      maxWidth: 60,
    },
    appBarLogoContainer: {
      "&:hover": {
        cursor: "pointer",
      },
      display: "flex",
    },
    appBarOnSidebarHidden: {
      paddingLeft: 24,
    },
  };
};

const EventPage = ({ t, i18n, classes, theme, width, routes, match, errorOnLoad, hideSidebar }) => {
  const eventID = match.params.eventID;
  const history = useHistory();

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const handleDrawerOpen = () => {
    setDrawerIsOpen(true);
  };
  const { userInfo, logout } = useAuth();

  const { loading: accountLoading, error, data: account } = useQuery(
    queryCreators.GET_USER_ACCOUNT.query,
    {
      variables: {
        email: _get(userInfo, "email", ""),
      }
    }
  );

  const accountData = _get(account, "account", {});

  const accountOptionsMenuArray = [
    { label: t("eventsPage:events"), icon: <EventIcon />, path: routePaths.EVENTS },
    { label: t("eventsPage:account"), icon: <SettingsIcon />, path: routePaths.ACCOUNT },
    { label: "divider" },
    { label: t("eventsPage:logout"), icon: <SigoutIcon />, onClick: logout },
  ];


  const handleDrawerClose = (drawerType) => {
    if (drawerType !== "permanent") { // if on mobile ONLY close drawer on selection
      setDrawerIsOpen(false);
    }
  };
  const drawerType = width === "xs" ? "temporary" : "permanent";
  const slideDirection = theme.direction === "ltr" ? "right" : "left";
  const drawerDirection = "left";
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const showLogoText = useMediaQuery(theme.breakpoints.up(410));
  const eventAppBar = (
    <AppBar position="sticky"
      className={classNames(classes.appBar,
        errorOnLoad && classes.errorAppBar
      )}>
      <Toolbar disableGutters={true}>
        <div
          className={classNames({
            [classes.appBarLeft]: true,
            [classes.appBarLeftDrawerOpened]: drawerIsOpen,
            [classes.appBarOnSidebarHidden]: hideSidebar,
          })}
        >
          {
            (!errorOnLoad && !hideSidebar) &&
            <Slide
              direction={slideDirection}
              in={!drawerIsOpen}
              timeout={{
                enter: theme.transitions.duration.enteringScreen,
                exit: 0
              }}
              mountOnEnter
              unmountOnExit
            >
              <IconButton
                aria-label="open drawer"
                color="inherit"
                onClick={handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  drawerIsOpen && classes.hide
                )}
              >
                <MenuIcon />
              </IconButton>
            </Slide>
          }
          <div
            className={classes.appBarLogoContainer}
            onClick={() => {
              if (!hideSidebar) {
                history.push(routePaths.HOME);
              }
            }}
          >
            <LogoIcon
              className={classNames(
                classes.appBarLogo,
                !drawerIsOpen && !errorOnLoad && classes.appBarLogoDrawerClosed
              )}
            />
            {showLogoText &&
              <Typography
                className={classes.appBarTitle}
                variant="subtitle1"
                color="inherit"
                noWrap
              >
                Eventsquare
              </Typography>
            }
          </div>
          <Slide
            direction={slideDirection}
            in={drawerIsOpen}
            timeout={{
              enter: theme.transitions.duration.enteringScreen,
              exit: 0
            }}
            mountOnEnter
            unmountOnExit
          >
            <IconButton color="inherit" onClick={handleDrawerClose} className={classes.appBarButton}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </Slide>
        </div>
        <div className={classes.appBarRight}>
          <List className={classes.appBarRightList}>
            {
              !hideSidebar &&
              <ListItem className={classes.appBarRightListItem}>
                <CustomDropdown
                  buttonProps={{
                    color: "transparent",
                  }}
                  rtlActive={theme.direction === "rtl"}
                  left
                  buttonIcon={() =>
                    (!accountLoading && !error) &&
                    <Avatar
                      src={accountData.logoUrl}
                      className={classes.avatar}
                    >
                      {`${_get(accountData, "firstName", "").substring(0, 1)}${_get(accountData, "lastName", "").substring(0, 1)}`}
                    </Avatar>
                  }
                  dropdownList={
                    accountOptionsMenuArray.map(option =>
                      option.label === "divider" ?
                        { divider: true } :
                        <>
                          <ListItemIcon
                            classes={{
                              root: classes.listItemIcon,
                            }}
                          >
                            {option.icon}
                          </ListItemIcon>
                          <ListItemText className={classes.listItemText} primary={option.label} />
                        </>
                    )}
                  onItemClick={optionIndex => {
                    if (accountOptionsMenuArray[optionIndex].onClick) {
                      accountOptionsMenuArray[optionIndex].onClick();
                    }
                    history.replace(accountOptionsMenuArray[optionIndex].path);
                  }}
                />
              </ListItem>
            }
            <ListItem className={classes.appBarRightListItem}>
              <CustomDropdown
                buttonProps={{
                  color: "transparent"
                }}
                rtlActive={theme.direction === "rtl"}
                left
                buttonIcon={() => <GlobeIcon />}
                dropdownList={langsArray.map(lang => lang.label)}
                onItemClick={langIndex => {
                  i18n.changeLanguage(langsArray[langIndex].value);
                }}
              />
            </ListItem>
          </List>
        </div>
      </Toolbar>
    </AppBar>
  )

  return (
    <div className={classes.root}>
      {
        errorOnLoad ?
          <Fragment>
            {eventAppBar}
            <ErrorPage />
          </Fragment>
          :
          <Fragment>
            {eventAppBar}
            <div className={classes.body}>
              {
                !hideSidebar &&
                <SwipeableDrawer disableBackdropTransition={!iOS} disableDiscovery={iOS}
                  variant={drawerType}
                  anchor={drawerDirection}
                  classes={{
                    paper: classNames(
                      classes.drawerPaper,
                      !drawerIsOpen &&
                      drawerType === "permanent" &&
                      classes.drawerPaperClose
                    )
                  }}
                  onClose={handleDrawerClose}
                  onOpen={handleDrawerOpen}
                  open={drawerIsOpen}
                  disableSwipeToOpen={true}
                >
                  <div>
                    <Query
                      query={
                        gql`{
                            event(id:"${eventID}"){
                                id
                                name
                                logoURL
                                plan
                              }
                          }`
                      }>
                      {({ loading, error, data }) => {
                        return (
                          <Fragment>
                            <div className={classes.drawerEventLogoContainer}>
                              {
                                loading ?
                                  <Fragment>
                                    <div
                                      className={classNames(
                                        classes.drawerEventLogo,
                                        drawerIsOpen
                                          ? classes.eventLogoDrawerLoadingOpened
                                          : classes.eventLogoDrawerClosed
                                      )}
                                    >
                                      <LinearProgress />
                                    </div>
                                  </Fragment>
                                  :
                                  <Fragment>
                                    <img
                                      alt="event logo"
                                      className={classNames(
                                        classes.drawerEventLogo,
                                        drawerIsOpen
                                          ? classes.eventLogoDrawerOpened
                                          : classes.eventLogoDrawerClosed,
                                        drawerIsOpen && error && classes.eventLogoDrawerError
                                      )}
                                      src={_get(data, "event.logoURL")}
                                    />
                                    <Typography
                                      align="center"
                                      className={classes.planBadge}
                                      noWrap
                                    >
                                      {data.event.plan || "BASIC"}
                                    </Typography>
                                  </Fragment>
                              }
                            </div>
                            <Collapse
                              in={drawerIsOpen}
                              timeout={{
                                enter: theme.transitions.duration.enteringScreen / 2,
                                exit: theme.transitions.duration.leavingScreen / 2
                              }}
                            >
                              {!loading && (
                                <div className={classes.drawerEventNameContainer}>
                                  <Typography variant="subtitle1" align="center">
                                    {_get(data, "event.name")}
                                  </Typography>
                                </div>
                              )}
                            </Collapse>
                          </Fragment>
                        );
                      }}
                    </Query>
                    <List component="nav">
                      <Tooltip
                        TransitionComponent={Zoom}
                        disableFocusListener={drawerType !== "permanent" || drawerIsOpen}
                        disableHoverListener={drawerType !== "permanent" || drawerIsOpen}
                        disableTouchListener={drawerType !== "permanent" || drawerIsOpen}
                        title={t("dashboardView:dashboard")}
                        placement="right"
                      >
                        <ListItem
                          button
                          replace
                          component={MUINavLink}
                          activeClassName={classes.activeDrawerMenuItem}
                          className={classes.inactiveDrawerMenuItem}
                          to={`/events/${eventID}/dashboard`}
                          onClick={() => handleDrawerClose(drawerType)}
                        >
                          <ListItemIcon className={classes.drawerMenuItemIcon}>
                            <DashboardIcon size={24} />
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{
                              noWrap: true,
                              className: classes.drawerMenuItemText
                            }}
                            primary={t("dashboardView:dashboard")}
                          />
                        </ListItem>
                      </Tooltip>
                      <Tooltip
                        TransitionComponent={Zoom}
                        disableFocusListener={drawerType !== "permanent" || drawerIsOpen}
                        disableHoverListener={drawerType !== "permanent" || drawerIsOpen}
                        disableTouchListener={drawerType !== "permanent" || drawerIsOpen}
                        title={t("dashboardView:registrations")}
                        placement="right"
                      >
                        <ListItem
                          button
                          replace
                          component={MUINavLink}
                          activeClassName={classes.activeDrawerMenuItem}
                          className={classes.inactiveDrawerMenuItem}
                          to={`/events/${eventID}/registrations`}
                          onClick={() => handleDrawerClose(drawerType)}
                        >
                          <ListItemIcon className={classes.drawerMenuItemIcon}>
                            <RegistrationsIcon size={24} />
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{
                              noWrap: true,
                              className: classes.drawerMenuItemText
                            }}
                            primary={t("dashboardView:registrations")}
                          />
                        </ListItem>
                      </Tooltip>
                      <Tooltip
                        TransitionComponent={Zoom}
                        disableFocusListener={drawerType !== "permanent" || drawerIsOpen}
                        disableHoverListener={drawerType !== "permanent" || drawerIsOpen}
                        disableTouchListener={drawerType !== "permanent" || drawerIsOpen}
                        title={t("dashboardView:registrationForm")}
                        placement="right"
                      >
                        <ListItem
                          button
                          replace
                          component={MUINavLink}
                          activeClassName={classes.activeDrawerMenuItem}
                          className={classes.inactiveDrawerMenuItem}
                          to={`/events/${eventID}/buildForm`}
                          onClick={() => handleDrawerClose(drawerType)}
                        >
                          <ListItemIcon className={classes.drawerMenuItemIcon}>
                            <FormIcon size={24} />
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{
                              noWrap: true,
                              className: classes.drawerMenuItemText
                            }}
                            primary={t("dashboardView:registrationForm")}
                          />
                        </ListItem>
                      </Tooltip>
                      <Tooltip
                        TransitionComponent={Zoom}
                        disableFocusListener={drawerType !== "permanent" || drawerIsOpen}
                        disableHoverListener={drawerType !== "permanent" || drawerIsOpen}
                        disableTouchListener={drawerType !== "permanent" || drawerIsOpen}
                        title={t("dashboardView:exhibitors")}
                        placement="right"
                      >
                        <ListItem
                          button
                          replace
                          component={MUINavLink}
                          activeClassName={classes.activeDrawerMenuItem}
                          className={classes.inactiveDrawerMenuItem}
                          to={`/events/${eventID}/exhibitors`}
                          onClick={() => handleDrawerClose(drawerType)}
                        >
                          <ListItemIcon className={classes.drawerMenuItemIcon}>
                            <ExhibitorsIcon size={24} />
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{
                              noWrap: true,
                              className: classes.drawerMenuItemText
                            }}
                            primary={t("dashboardView:exhibitors")}
                          />
                        </ListItem>
                      </Tooltip>
                      <Tooltip
                        TransitionComponent={Zoom}
                        disableFocusListener={drawerType !== "permanent" || drawerIsOpen}
                        disableHoverListener={drawerType !== "permanent" || drawerIsOpen}
                        disableTouchListener={drawerType !== "permanent" || drawerIsOpen}
                        title={t("dashboardView:team")}
                        placement="right"
                      >
                        <ListItem
                          button
                          replace
                          component={MUINavLink}
                          activeClassName={classes.activeDrawerMenuItem}
                          className={classes.inactiveDrawerMenuItem}
                          to={routePaths.EVENT_MEMBERS_REDIRECT(eventID)}
                          onClick={() => handleDrawerClose(drawerType)}
                        >
                          <ListItemIcon className={classes.drawerMenuItemIcon}>
                            <TeamIcon size={24} />
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{
                              noWrap: true,
                              className: classes.drawerMenuItemText
                            }}
                            primary={t("dashboardView:team")}
                          />
                        </ListItem>
                      </Tooltip>
                      <Tooltip
                        TransitionComponent={Zoom}
                        disableFocusListener={drawerType !== "permanent" || drawerIsOpen}
                        disableHoverListener={drawerType !== "permanent" || drawerIsOpen}
                        disableTouchListener={drawerType !== "permanent" || drawerIsOpen}
                        title={t("eventPage:nameBadges")}
                        placement="right"
                      >
                        <ListItem
                          button
                          replace
                          component={MUINavLink}
                          activeClassName={classes.activeDrawerMenuItem}
                          className={classes.inactiveDrawerMenuItem}
                          to={routePaths.BADGE_TEMPLATES_REDIRECT(eventID)}
                        >
                          <ListItemIcon className={classes.drawerMenuItemIcon}>
                            <TemplateBuilderIcon size={24} />
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{
                              noWrap: true,
                              className: classes.drawerMenuItemText
                            }}
                            primary={t("eventPage:nameBadges")}
                            onClick={() => handleDrawerClose(drawerType)}
                          />
                        </ListItem>
                      </Tooltip>
                      <Tooltip
                        TransitionComponent={Zoom}
                        disableFocusListener={drawerType !== "permanent" || drawerIsOpen}
                        disableHoverListener={drawerType !== "permanent" || drawerIsOpen}
                        disableTouchListener={drawerType !== "permanent" || drawerIsOpen}
                        title={t("eventEngagementView:engagement")}
                        placement="right"
                      >
                        <ListItem
                          button
                          replace
                          component={MUINavLink}
                          activeClassName={classes.activeDrawerMenuItem}
                          className={classes.inactiveDrawerMenuItem}
                          to={`/events/${eventID}/engagement`}
                          onClick={() => handleDrawerClose(drawerType)}
                        >
                          <ListItemIcon className={classes.drawerMenuItemIcon}>
                            <EngagementIcon size={24} />
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{
                              noWrap: true,
                              className: classes.drawerMenuItemText
                            }}
                            primary={t("eventEngagementView:engagement")}
                          />
                        </ListItem>
                      </Tooltip>
                      <Tooltip
                        TransitionComponent={Zoom}
                        disableFocusListener={drawerType !== "permanent" || drawerIsOpen}
                        disableHoverListener={drawerType !== "permanent" || drawerIsOpen}
                        disableTouchListener={drawerType !== "permanent" || drawerIsOpen}
                        title={t("eventSettingsView:settings")}
                        placement="right"
                      >
                        <ListItem
                          button
                          replace
                          component={MUINavLink}
                          activeClassName={classes.activeDrawerMenuItem}
                          className={classes.inactiveDrawerMenuItem}
                          to={routePaths.SETTINGS_REDIRECT(eventID)}
                          onClick={() => handleDrawerClose(drawerType)}
                        >
                          <ListItemIcon className={classes.drawerMenuItemIcon}>
                            <SettingsIcon size={24} />
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{
                              noWrap: true,
                              className: classes.drawerMenuItemText
                            }}
                            primary={t("eventSettingsView:settings")}
                          />
                        </ListItem>
                      </Tooltip>
                    </List>
                  </div>
                </SwipeableDrawer>
              }
              <main
                className={classNames(
                  classes.content,
                  (drawerType === "permanent" && !hideSidebar) &&
                  (
                    drawerIsOpen
                      ? classes.contentPermenantDrawerOpened
                      : classes.contentPermenantDrawerClosed
                  )
                )}
              >
                {routes}
              </main>
            </div>
          </Fragment>
      }
    </div >
  );
}

EventPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default compose(
  withTranslation("eventPage"),
  withWidth(),
  withTheme,
  withStyles(styles)
)(EventPage);
