import deepPurple from "@material-ui/core/colors/deepPurple";
import { green } from "@material-ui/core/colors";
// import { getDefaultBrowserTheme } from "../modules/utils";

const muiTheme = {
  direction: "ltr",
  useNextVariants: true,
  palette: {
    primary: deepPurple,
    success: green,
    // type: getDefaultBrowserTheme(),
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"'
  },

  // component styles overrides
  overrides: {
    MuiFormLabel: {
      root: {
        textAlign: "start"
      },
    },
    MuiCardHeader: {
      content: {
        textAlign: "start"
      }
    },
    MuiSelect: {
      select: {
        textAlign: "start"
      }
    }
  },
};

export default muiTheme;
