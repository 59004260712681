import React from "react";

import makeStyles from "@material-ui/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";

import classNames from "classnames";
import _isFunction from "lodash/isFunction";

import styles from "./styles";
import INPUT_TYPES from "../inputTypes";

const useStyles = makeStyles(styles);

const InputContainer = ({
  className,
  selected,
  onClick,
  renderActions,
  inputProps,
  endAdornment,
  isBeingDragged,
}) => {
  const classes = useStyles();
  const getInput = props => {
    if (props.type === INPUT_TYPES.CHECKBOX)
      return (
        <Box display="flex">
          <FormControl
            component="fieldset"
            className={classNames(classes.formControl, classes.noPointerEvents)}
          >
            <FormControlLabel control={<Checkbox />} label={props.label} />
          </FormControl>
        </Box>
      );
    if (props.type === INPUT_TYPES.MULTI_CHECKBOX) {
      const { label, options } = props;
      return (
        <Box display="flex">
          <FormControl
            component="fieldset"
            className={classNames(classes.formControl, classes.noPointerEvents)}
          >
            <FormLabel
              component="label"
              classes={{
                root: classes.mutliChecboxLabelRoot
              }}
            >
              {label}
            </FormLabel>
            <FormGroup>
              {options &&
                options.map((option, index) => (
                  <FormControlLabel
                    control={<Checkbox />}
                    label={option}
                    key={index}
                  />
                ))}
            </FormGroup>
          </FormControl>
        </Box>
      );
    }

    return (
      <FormControl className={classes.formControl} margin="normal" fullWidth>
        <InputLabel shrink>{props.label}</InputLabel>
        <Input
          aria-describedby="component-error-text"
          multiline={props.type === INPUT_TYPES.TEXT_AREA}
          rows={props.type === INPUT_TYPES.TEXT_AREA ? 3 : 0}
          placeholder={props.placeholder}
          margin="none"
          classes={{
            root: classNames(classes.inputRoot, classes.noPointerEvents)
          }}
          endAdornment={endAdornment}
        />
        {props.description && (
          <FormHelperText>{props.description}</FormHelperText>
        )}
      </FormControl>
    );
  };

  return (
    <Box position="relative">
      <Box
        border={1}
        p={1}
        className={classNames(classes.inputContainer, className, {
          [classes.hiddenInput]: inputProps.hidden,
          [classes.inputContainerSelected]: selected,
          [classes.fieldDragging]: isBeingDragged,
        })}
        borderRadius={5}
        paddingRight={2}
        paddingLeft={2}
        onClick={onClick}
      >
        {getInput(inputProps)}
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        position="absolute"
        className={classes.actionButtonsContainer}
      >
        {_isFunction(renderActions) && renderActions(classes.iconContainer)}
      </Box>
    </Box>
  );
};

export default InputContainer;
