import React, { useMemo, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography"
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddPhotoAlternate from "@material-ui/icons/AddPhotoAlternateOutlined";

import { useTranslation } from "react-i18next"

import classNames from "classnames";
import styles from "./styles"

const useStyles = makeStyles(styles, { name: "LogoPicker" });

const LogoPicker = ({ url, onChange, UploadIconComponent, onError, maxFileSize = 900000 }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const classes = useStyles();

  const { t } = useTranslation("logoPicker");

  const pickerID = useMemo(() => `image-picker-${Math.floor(Math.random() * 10000)}`, [])

  const handleImageChange = event => {
    const file = event.target.files[0];

    if (file) {
      //Checking that uploaded file is an image
      if (!file.type.match("image.*")) {
        setError("unsupportedFile");
        onError && onError(true);
        onChange && onChange(null);
      } else {
        const base64Reader = new FileReader();
        base64Reader.onloadend = () => {
          if (file.size > maxFileSize) {
            setError(true);
            onError && onError(true);
            onChange && onChange(null);
          }
          else {
            setError(false);
            onError && onError(false);
            onChange &&
              onChange({
                name: file.name,
                type: file.type,
                file: base64Reader.result
              });
          }
          setLoading(false);

        };
        base64Reader.readAsDataURL(file);
      }
    }
  };

  return (
    <>
      <input
        accept="image/*"
        className={classes.input}
        id={pickerID}
        name="logo"
        type="file"
        onChange={handleImageChange}
      />
      <label className={classes.imageBox} htmlFor={pickerID}>
        <div className={classes.relativePositioning}>
          {url && (
            <div className={classes.overlayContainer}>
              <div className={classes.overlay} />
              {UploadIconComponent ? (
                <UploadIconComponent className={classes.overlayIcon} />
              ) : (
                <AddPhotoAlternate className={classes.overlayIcon} />
              )}
            </div>
          )}
          <Avatar
            alt="Your logo"
            className={classNames(classes.bigAvatar, error && "error")}
            src={loading || error ? "" : url}
            classes={{
              img: classes.img
            }}
          >
            {loading && !error ? (
              <CircularProgress thickness={3} size={30} />
            ) : UploadIconComponent ? (
              <UploadIconComponent className={classes.logoIcon} />
            ) : (
              <AddPhotoAlternate className={classes.logoIcon} />
            )}
          </Avatar>
        </div>
      </label>
      {
        error && error === "unsupportedFile" ?
          < Typography className={classes.logoError}>
            {t("unsupportedFile")}
          </Typography> :
          error &&
          < Typography className={classes.logoError}>
            {t("tooLargeImage", { maxMBSize: (maxFileSize / (1024 * 1024)).toFixed(1) })}
          </Typography>
      }
    </>
  );
};

export default LogoPicker;
