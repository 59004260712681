import React, { memo } from "react";
import _get from "lodash/get"
import cls from "classnames";
import InputLabel from "@material-ui/core/InputLabel";
import Avatar from "@material-ui/core/Avatar";
import { Typography, FormHelperText } from "@material-ui/core";

import makeStyles from "@material-ui/styles/makeStyles";

import styles from "./styles";

const useStyles = makeStyles(styles, { name: "ImagePicker" });
const ImagePicker = (props) => {
    const classes = useStyles();
    const { viewValue = [], config, fieldValidation } = props;
    const { templateOptions } = config;
    const { label, description, required } = templateOptions || {};

    const hasError = fieldValidation.isValid === false;
    const errorMsg = Object.values(_get(fieldValidation, 'messages', {}))[0];
    return (
        <>
            <InputLabel required={required} className={classes.inputLabel}>{label}</InputLabel>
            {
                viewValue.length > 0 ?
                    <div className={classes.input} style={{ display: "flex" }}>
                        {
                            viewValue.map((uri => {
                                const url = uri.startsWith("https://") ? uri : `data:image/png;base64,${uri}`;
                                return (
                                    <div className={cls({ [classes.imageBox]: url })}>
                                        <Avatar
                                            alt="image picker file"
                                            className={cls(classes.bigAvatar, hasError && "error")}
                                            src={hasError ? "" : url}
                                            classes={{
                                                img: classes.img
                                            }}
                                            onClick={() => {
                                                if (uri.startsWith("https://")) {
                                                    window.open(url);
                                                } else {
                                                    let image = new Image();
                                                    image.src = url;

                                                    let w = window.open("");
                                                    w.document.write(image.outerHTML);
                                                }
                                            }}
                                        />
                                    </div>
                                );
                            }

                            ))
                        }
                    </div>
                    :
                    <Typography variant="overline" color="textSecondary">No images added</Typography>

            }
            {(errorMsg || description) && <FormHelperText>{errorMsg || description}</FormHelperText>}

        </>
    );
}

export default memo(ImagePicker);