import { PARSING_CONSTANTS } from "./templatesConfig";
import _isString from 'lodash/isString';
import textDataParsing from "../../../../../modules/textDataParsing";

const {
  MARKER_START,
  MARKER_END,
} = PARSING_CONSTANTS;

function getParsables(str) {
  if (!_isString(str)) return;
  const parsables = [];
  let match = textDataParsing.parsablesRegex.exec(str);
  while (match != null) {
    parsables.push(match[1]);
    match = textDataParsing.parsablesRegex.exec(str);
  }
  return parsables;
};

function getParsedValues(context, str) {
  const parsables = getParsables(str);
  let res = str;
  parsables.forEach(parsable => {
    if (context[parsable])
      res = res.replace(MARKER_START + parsable + MARKER_END, context[parsable])
  });
  return res;
};

function removeParsables(str) {
  if (!_isString(str)) return;
  return str.replace(textDataParsing.parsablesRegex, '');
};

function getLocalizedContextKey(key, t, withQuotes = true, withTrigger = true) {
  if (!key || !t || !_isString(key)) return;
  return `${withTrigger ? '@' : ''}${withQuotes ? '"' : ''}${t(`contextData:${key.replace('.', ':')}`)}${withQuotes ? '"' : ''}`;
};

const parserFunctions = {
  getParsables,
  getParsedValues,
  getLocalizedContextKey,
  removeParsables,
};

export default parserFunctions;
