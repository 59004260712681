import { container } from "assets/jss/material-kit-react.jsx";

const styles = theme => ({
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    paddingTop: "20vh",
    color: "#FFFFFF",
    flex: 1
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)"
  },
  pageHeader: {
    minHeight: "100vh",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    "&:before": {
      background: "rgba(0, 0, 0, 0.5)"
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""'
    }
  },
  form: {
    margin: "0"
  },
  cardHeader: {
    width: "auto",
    textAlign: "center",
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "-40px",
    padding: "20px 0",
    marginBottom: "15px",
  },
  cardHeaderTitle: {
    color: "#ffffff"
  },
  socialIcons: {
    maxWidth: "24px",
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px"
  },
  divider: {
    marginTop: "30px",
    marginBottom: "0px",
    textAlign: "center"
  },
  cardFooter: {
    paddingTop: "0rem",
    border: "0",
    borderRadius: "6px",
    justifyContent: "center !important"
  },
  socialLine: {
    marginTop: "1rem",
    textAlign: "center",
    padding: "0",
  },
  inputIconsColor: {
    color: theme.palette.action.active,
  },
  storeBadgesContainer: {
    display: "flex",
  },
  storeBadgeLink: {
    width: "50%"
  },
  googlePlayImage: {
    width: "100%"
  },
  loginContent: {
    minHeight: 365,
  },
  loginContentMobile: {
    minHeight: 480,
  },
  link: {
    "&:hover": {
      color: "#FFFFFF",
    },
    color: "#FFFFFF",
  },
  centerText: {
    textAlign: "center",
  },
});

export default styles;
