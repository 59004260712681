import React from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
// material-ui components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import makeStyles from '@material-ui/core/styles/makeStyles';

import routePaths from "../../config/routePaths";

import styles from './styles';

const useStyles = makeStyles(styles, { name: "ConnectFacebookModal" });

const ConnectFacebookModal = ({ open, onClose }) => {
    const classes = useStyles();
    const { t } = useTranslation("connectFacebookModal");
    const { eventID } = useParams(); // the eventID gets passed from the currentURL

    return (
        <Dialog
            open={open}
            onClose={onClose}
            className={classes.dialog}
        >
            <DialogTitle>{t("connectFacebookPage")}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("noFacebookPageConnected")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t("no")}
                </Button>
                <HashLink
                    to={routePaths.FACEBOOK_CONNECTION_SECTION_REDIRECT(eventID)}
                    smooth
                >
                    <Button color="primary" autoFocus>
                        {t("yes")}
                    </Button>
                </HashLink>
            </DialogActions>
        </Dialog>
    )
}

export default ConnectFacebookModal;
