import hexToRgba from "hex-rgba";
import { darken } from '@material-ui/core/styles';

const styles = theme => ({
    toggleButtonGroup: {
        marginTop: theme.spacing(2.5),
    },
    lightModeButton: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        "&:hover": {
            backgroundColor: darken(theme.palette.common.white, 0.05),
        },
        "&$selected:hover": {
            backgroundColor: darken(theme.palette.common.white, 0.05),
        },
        "&$selected": {
            color: theme.palette.common.black,
            backgroundColor: theme.palette.common.white,
            background: `linear-gradient(to top, ${hexToRgba(theme.palette.primary.main, 30)}, ${hexToRgba(theme.palette.primary.main, 30)})`,
            fallbacks: [{
                background: `-webkit-linear-gradient(to top, ${hexToRgba(theme.palette.primary.main, 30)}, ${hexToRgba(theme.palette.primary.main, 30)})`,
            }, {
                background: `-o-linear-gradient(to top, ${hexToRgba(theme.palette.primary.main, 30)}, ${hexToRgba(theme.palette.primary.main, 30)})`,
            }]
        }
    },
    darkModeButton: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.grey[800],
        "&:hover": {
            backgroundColor: darken(theme.palette.grey[800], 0.05),
        },
        "&$selected:hover": {
            backgroundColor: darken(theme.palette.grey[800], 0.05),
        },
        "&$selected": {
            backgroundColor: theme.palette.grey[800],
            color: theme.palette.common.white,
            background: `linear-gradient(to top, ${hexToRgba(theme.palette.primary.main, 30)}, ${hexToRgba(theme.palette.primary.main, 30)})`,
            fallbacks: [{
                background: `-webkit-linear-gradient(to top, ${hexToRgba(theme.palette.primary.main, 30)}, ${hexToRgba(theme.palette.primary.main, 30)})`,
            }, {
                background: `-o-linear-gradient(to top, ${hexToRgba(theme.palette.primary.main, 30)}, ${hexToRgba(theme.palette.primary.main, 30)})`,
            }]
        }
    },
    selected: {}
})

export default styles;
