const styles = theme => {
    return {
        builderContainer: {
            height: 400,
            width: "100%",
            maxHeight: 400,
            overflow: "hidden",
            marginTop: theme.spacing(1)
        },
        btnContainer: {
            marginTop: theme.spacing(1)
        },
        infoDivider: {
            marginBottom: theme.spacing(2)
        },
        form: {
            marginBottom: theme.spacing(3)
        },
        spinnerContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            minHeight: 16,
        }
    };
}

export default styles;
