import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const RegistrationsIcon = ({ color, size, ...rest }) => {
  return (
    <SvgIcon
      width={size}
      height={size}
      viewBox={size && `0 0 ${size} ${size}`}
      fill="none"
      {...rest}
    >
      <path d="M6.25 2C5.01 2 4 3.01 4 4.25V19.75C4 20.99 5.01 22 6.25 22H12.8154C12.3854 21.56 12.0254 21.055 11.7354 20.5H6.25C5.835 20.5 5.5 20.165 5.5 19.75V4.25C5.5 3.835 5.835 3.5 6.25 3.5H17.75C18.165 3.5 18.5 3.835 18.5 4.25V11.0752C19.02 11.1602 19.525 11.3 20 11.5V4.25C20 3.01 18.99 2 17.75 2H6.25ZM12 5C11.5359 5 11.0908 5.18437 10.7626 5.51256C10.4344 5.84075 10.25 6.28587 10.25 6.75C10.25 7.21413 10.4344 7.65925 10.7626 7.98744C11.0908 8.31563 11.5359 8.5 12 8.5C12.4641 8.5 12.9092 8.31563 13.2374 7.98744C13.5656 7.65925 13.75 7.21413 13.75 6.75C13.75 6.28587 13.5656 5.84075 13.2374 5.51256C12.9092 5.18437 12.4641 5 12 5ZM9.75 9.5C9.336 9.5 9 9.836 9 10.25V10.75C9 11.7165 10.343 12.5 12 12.5C13.657 12.5 15 11.7165 15 10.75V10.25C15 9.836 14.664 9.5 14.25 9.5H9.75ZM17.5 12C14.4625 12 12 14.4625 12 17.5C12 20.5375 14.4625 23 17.5 23C20.5375 23 23 20.5375 23 17.5C23 14.4625 20.5375 12 17.5 12ZM8.25 14C7.835 14 7.5 14.335 7.5 14.75C7.5 15.165 7.835 15.5 8.25 15.5H11.3203C11.4903 14.965 11.7304 14.465 12.0254 14H8.25ZM18 14.5C18.1279 14.5 18.2558 14.5487 18.3535 14.6465L20.8535 17.1465C21.049 17.342 21.049 17.658 20.8535 17.8535L18.3535 20.3535C18.158 20.549 17.842 20.549 17.6465 20.3535C17.549 20.256 17.5 20.128 17.5 20C17.5 19.872 17.549 19.744 17.6465 19.6465L19.293 18H14C13.724 18 13.5 17.7765 13.5 17.5C13.5 17.2235 13.724 17 14 17H19.293L17.6465 15.3535C17.451 15.158 17.451 14.842 17.6465 14.6465C17.7442 14.5487 17.8721 14.5 18 14.5ZM8.25 17C7.835 17 7.5 17.335 7.5 17.75C7.5 18.165 7.835 18.5 8.25 18.5H11.0752C11.0252 18.17 11 17.84 11 17.5C11 17.33 11.0054 17.165 11.0254 17H8.25Z"
        fill={color}
      />

    </SvgIcon>
  );
};

export default RegistrationsIcon;
