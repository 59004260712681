import React from "react";

import InputLabel from "@material-ui/core/InputLabel";
import Slider from "@material-ui/core/Slider";
import makeStyles from "@material-ui/styles/makeStyles";
import TextField from "@material-ui/core/TextField";

import styles from "./styles";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);
const BYTES_IN_MB = 1000000;
const ImagePickerInputSettings = ({ inputSettings, onSettingsChange }) => {
    const { t } = useTranslation("createForm");
    const classes = useStyles();

    const minValue = 1 * BYTES_IN_MB;
    const maxValue = 50 * BYTES_IN_MB;

    return (
        <>
            <TextField
                label={t("createForm:description")}
                value={inputSettings.description || ""}
                onChange={evt =>
                    onSettingsChange({
                        description: evt.target.value
                    })
                }
                margin="normal"
                fullWidth
            />
            <InputLabel className={classes.inputLabel}>
                {t("maxFileSize")}
            </InputLabel>
            <Slider
                className={classes.slider}
                value={inputSettings.maxFileSize}
                valueLabelFormat={(number) => number / BYTES_IN_MB}
                valueLabelDisplay="auto"
                step={BYTES_IN_MB}
                min={minValue}
                max={maxValue}
                onChange={(event, currVal) => {
                    onSettingsChange({
                        maxFileSize: currVal
                    })
                }}
                marks={[
                    {
                        value: minValue,
                        label: minValue / BYTES_IN_MB
                    },
                    {
                        value: maxValue,
                        label: maxValue / BYTES_IN_MB
                    }
                ]}
            />
        </>
    );
};

export default ImagePickerInputSettings;
