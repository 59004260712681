import { container } from "assets/jss/material-kit-react.jsx";

const pageNotFoundStyle = {
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    paddingTop: "20vh",
    color: "#FFFFFF",
    flex: 1
  }, 
  pageHeader: {
    minHeight: "100vh",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    "&:before": {
      background: "rgba(0, 0, 0, 0.5)"
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""'
    }
  },
  textOne:{
    fontWeight:"bold"
  }
};

export default pageNotFoundStyle;
