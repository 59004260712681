import React, { useState, useEffect, Fragment, useCallback } from "react"
// material-ui components
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import makeStyles from "@material-ui/core/styles/makeStyles"
import useTheme from "@material-ui/core/styles/useTheme";
// material-ui icons
import WbSunnyIcon from '@material-ui/icons/WbSunny';

import _isNil from "lodash/isNil";

import DarkModeIcon from "../../../../components/Icons/DarkModeIcon"
import ColorPicker from "../../../EventPage/TemplateBuilder/utils/ElementSettingsPanel/ColorPicker"
import { useTranslation } from "react-i18next";
import styles from "./styles"

const useStyles = makeStyles(styles, { name: "EventSettingsView" });

const EventSettingsTheme = ({ eventData, setIsThemeValid, onThemeUpdate }) => {
    const theme = useTheme();
    const [mode, setMode] = useState(eventData.theme ? eventData.theme.mode.toLowerCase() : theme.palette.type);
    const [primaryColor, setPrimaryColor] = useState(eventData.theme ? eventData.theme.primaryColor : theme.palette.primary.main);
    const { t } = useTranslation("eventSettingsView");
    const classes = useStyles();

    const onColorChange = async (primaryColor) => {
        setPrimaryColor(primaryColor)
    }
    const hasThemeChanged = useCallback(() => {

        if ((!eventData.theme && (theme.palette.primary.main !== primaryColor || theme.palette.type !== mode)) ||
            (eventData.theme && (eventData.theme.primaryColor !== primaryColor || eventData.theme.mode.toLowerCase() !== mode))) {
            const theme = {
                primaryColor,
                mode: mode.toUpperCase()
            }
            onThemeUpdate({ theme })
            return setIsThemeValid(true)
        }
        return setIsThemeValid(false)
    }, [eventData, mode, onThemeUpdate, primaryColor, setIsThemeValid, theme.palette.type, theme.palette.primary.main])

    useEffect(() => {
        hasThemeChanged()
    }, [mode, primaryColor, hasThemeChanged])

    return (
        <Fragment>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: theme.spacing(2.5) }}>
                <ColorPicker title={t("brandColor")} color={primaryColor} colorType="hex" onChange={onColorChange} />
                <ToggleButtonGroup
                    value={mode}
                    exclusive
                    onChange={(event, type) => {
                        if (!_isNil(type))
                            setMode(type)
                    }}
                    className={classes.toggleButtonGroup}
                >
                    <ToggleButton
                        classes={{
                            root: classes.lightModeButton,
                            selected: classes.selected,
                        }}
                        value="light"
                    >
                        <WbSunnyIcon />
                    </ToggleButton>
                    <ToggleButton
                        classes={{
                            root: classes.darkModeButton,
                            selected: classes.selected,
                        }}
                        value="dark"
                    >
                        <DarkModeIcon />
                    </ToggleButton>

                </ToggleButtonGroup>
            </div>
        </Fragment >
    )
}

export default EventSettingsTheme;