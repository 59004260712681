import { darken } from "@material-ui/core/styles"
const styles = theme => ({
    spinnerContainer: {
        flexGrow: 1
    },
    root: {
        padding: theme.spacing(3),
        display: "flex",
        flexDirection: "column"
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
    },
    formPaper: {
        maxWidth: 900,
        margin: "auto",
    },
    subElement: {
        marginRight: theme.spacing(1.25)
    },
    exhibitorsAvatar: {
        marginRight: theme.spacing(3.5),
        height: theme.spacing(7.5),
        width: theme.spacing(7.5),
    },
    cardBody: {
        height: 250,
        overflowY: "auto",
        padding: "0px 16px 0px 16px",
        wordBreak: "break-all",
        "&:last-child": {
            paddingBottom: 0
        },
    },
    largeCardBody: {
        maxHeight: 480,
        overflowY: "auto",
        padding: "0px 16px 0px 16px",
        "&:last-child": {
            paddingBottom: 0
        },
    },
    takeActionButton: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(1),
        background: theme.palette.background.paper,
        color: theme.palette.text.primary,
        "&:hover": {
            background: darken(theme.palette.background.paper, 0.05),
        }
    },
    notProvidedData: {
        color: theme.palette.text.disabled,
    },
    paddingRemoval: {
        paddingTop: 0,
    },
    buttonsText: {
        fontSize: 14,
    },
    emptyChecksText: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        color: theme.palette.text.disabled,
    },
    formErrorContainer: {
        padding: `${theme.spacing(5)}px 0px`,
    },
    badgeNotFound: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.divider,
    },
    avatarRoot: {
        backgroundColor: "transparent",
        color: theme.palette.text.primary,
    },
    takeActionContainer: {
        textAlign: "start",
    },
})

export default styles;
