import React, { Fragment, useCallback, useState } from "react"

// material-ui components
import Grid from "@material-ui/core/Grid"
import Fab from "@material-ui/core/Fab";
import DoneIcon from '@material-ui/icons/Done';
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles"
import Divider from "@material-ui/core/Divider"
import Paper from "@material-ui/core/Paper"
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import { useTranslation } from "react-i18next"
import { useQuery, useMutation } from "react-apollo";
import { useParams } from "react-router-dom";
import _get from "lodash/get";
import queryCreators from "../../../apollo/queryCreators";
import EventSettingsForm from "./EventSettingsForm";
import EventSettingsTheme from "./EventSettingsTheme";
import FbConnectedPage from "./FbConnectedPage";
import { showGlobalSnackbar } from "../../../components/GlobalSnackbar";
import routePaths from "../../../config/routePaths"
import ErrorPage from "../ErrorPage";
import styles from "./styles"

const useStyles = makeStyles(styles, { name: "EventSettingsView" });

const EventSettingsView = ({ history, routes }) => {
    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation("eventSettingsView");
    const { eventID } = useParams(); // the eventID gets passed from the currentURL
    const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const [resetForm, setResetForm] = useState(0);
    const [formData, setFormData] = useState({});
    const [themeData, setThemeData] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const [isThemeValid, setIsThemeValid] = useState(false);
    const [hasError, setHasError] = useState(false);

    const { loading: eventLoading, error, data } = useQuery(
        queryCreators.GET_EVENT_SETTINGS.query,
        {
            variables: {
                eventID
            }
        }
    );
    const eventData = _get(data, "event", []);

    const [updateEvent, { loading: updatingEventLoading }] = useMutation(
        queryCreators.UPDATE_EVENT.mutation,
        {
            onCompleted: () => {
                showGlobalSnackbar(t("eventUpdated"));
            },
            onError: () => {
                showGlobalSnackbar(t("failedUpdatingError"), "error");
            }
        }
    );
    const onEventUpdate = async (eventObject) => {
        for (const key in eventObject) { // deleting unchanged attributes
            if (key === "logo" && eventObject.logo && !eventObject.logo.name)
                delete eventObject[key];
            else if (eventObject[key] === undefined)
                delete eventObject[key];
        }
        try {
            await queryCreators.UPDATE_EVENT.mutate(updateEvent)(
                {
                    ...eventObject,
                    eventID
                }
            );
            setResetForm(c => c + 1); // to reset the form after it's submitted
        } catch (e) {

        }
    }
    const onFormUpdate = useCallback((formObject) => {
        setFormData(formObject);
    }, [])

    const onThemeUpdate = useCallback((themeObject) => {
        setThemeData(themeObject);
    }, [])

    return (
        <Fragment>
            <div className={classes.titleBar}>
                <Grid container alignItems="center">
                    <Grid container item xs={6}>
                        <Typography variant="h5" noWrap>
                            {t("settings")}
                        </Typography>
                    </Grid>
                </Grid>
            </div>

            {eventLoading ?
                <Grid container alignItems="center" justify="center"
                    className={classes.spinnerContainer}>
                    <CircularProgress thickness={7} />
                </Grid>
                :
                error || hasError ? <ErrorPage /> :
                    < Fragment >
                        <div className={classes.sectionContainer}>
                            <Grid
                                className={classes.headerContainer}
                            >
                                <Divider className={classes.sectionTitleLines}></Divider>
                                <Typography noWrap className={classes.sectionTitle}>
                                    {t("basicSettings")}
                                </Typography>
                                <Divider className={classes.sectionTitleLines}></Divider>

                            </Grid>
                            <Grid
                                container
                                item
                                xs={12}
                                sm={9}
                                md={9}
                                className={classes.bodyContainer}
                            >
                                <Paper className={classes.root}>
                                    <Paper elevation={0} className={classes.settingsContainer}>
                                        <EventSettingsForm
                                            key={resetForm}
                                            updatingEventLoading={updatingEventLoading}
                                            eventData={eventData}
                                            onEventUpdate={onEventUpdate}
                                            setIsFormValid={setIsFormValid}
                                            onFormUpdate={onFormUpdate}
                                        />
                                    </Paper>
                                </Paper>
                            </Grid>
                        </div>

                        <div className={classes.sectionContainer}>
                            <Grid
                                className={classes.headerContainer}
                            >
                                <Divider className={classes.sectionTitleLines}></Divider>
                                <Typography noWrap className={classes.sectionTitle}>
                                    {t("theme")}
                                </Typography>
                                <Divider className={classes.sectionTitleLines}></Divider>

                            </Grid>
                            <Grid
                                container
                                item
                                sm={5}
                                className={classes.bodyContainer}
                            >
                                <Paper className={classes.root}>
                                    <Paper elevation={0} className={classes.settingsContainer}>
                                        <EventSettingsTheme
                                            key={resetForm}
                                            eventData={eventData}
                                            setIsThemeValid={setIsThemeValid}
                                            onThemeUpdate={onThemeUpdate}
                                        />
                                    </Paper>
                                </Paper>
                            </Grid>
                        </div>

                        <div className={classes.sectionContainer}>
                            <Grid
                                className={classes.headerContainer}
                            >
                                <Divider className={classes.sectionTitleLines}></Divider>
                                <Typography noWrap className={classes.sectionTitle}>
                                    {t("connectedFbPage")}
                                </Typography>
                                <Divider className={classes.sectionTitleLines}></Divider>

                            </Grid>
                            <Grid
                                id="fb_page_container"
                                container
                                item
                                xs={12}
                                sm={9}
                                md={9}
                                className={classes.bodyContainer}
                            >
                                <Paper className={classes.root}>
                                    <Paper elevation={0} className={classes.settingsContainer}>
                                        <FbConnectedPage
                                            onFbConnectedPageError={setHasError}
                                        />
                                    </Paper>
                                </Paper>
                            </Grid>
                        </div>


                        {
                            (isFormValid || isThemeValid) && isFormValid !== "formError" &&
                            <Grid
                                className={classes.saveEventSettingButton}
                            >
                                <Fab
                                    size={smallScreen ? "medium" : "large"}
                                    color="primary"
                                    onClick={() => {
                                        onEventUpdate({
                                            ...themeData,
                                            ...formData
                                        })
                                    }}>
                                    {
                                        updatingEventLoading ?
                                            < CircularProgress size={60} className={classes.circularProgress} /> :
                                            <DoneIcon />
                                    }
                                </Fab>
                            </Grid>
                        }
                        {routes && routes({
                            onClose: () => {
                                history.replace(`${routePaths.SETTINGS_REDIRECT(eventID)}#fb_page_container`);
                            },
                        })}
                    </Fragment>
            }
        </Fragment >
    )
}

export default EventSettingsView;