const styles = theme => ({
    body: {
        minHeight: 280,
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    circularProgress: {
        marginLeft: theme.spacing(1),
    },
    circularProgressWithColor: {
        color: theme.palette.primary.contrastText,
        marginLeft: theme.spacing(1),
    },
    connectedFbPageContainer: {
        display: "flex",
        marginBottom: theme.spacing(2),
        padding: 24,
        alignItems: "center",
    },
    connectedFbPageData: {
        textAlign: "center",
        marginRight: theme.spacing(1),
    },
    connectedFbPageImage: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 10,
        marginBottom: theme.spacing(0.5),
        height: 100,
        width: 100,
    },
    connectedFbPageName: {
        maxWidth: 120,
        width: "100%",
    },
    fbIcon: {
        height: 24,
        width: 24,
        marginRight: 8,
    },
    loginContainer: {
        display: "flex",
        alignItems: "center",
    },
})

export default styles;
