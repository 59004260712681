import React from "react";
import compose from "recompose/compose";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from "@material-ui/core/styles/withTheme";
import withWidth from "@material-ui/core/withWidth";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
//i18n
import { withTranslation } from "react-i18next";

import loginPageStyle from "assets/jss/material-kit-react/views/loginPage.jsx";

import withAuth from "../../contexts/AuthenticationContext/withAuth";
import ExhibitorsIcon from "components/Icons/ExhibitorsIcon";
import DashboardIcon from "components/Icons/DashboardIcon";

import routePaths from "../../config/routePaths"
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // we use this to make the card to appear after the page has been rendered
      cardAnimaton: "cardHidden",
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  render() {
    const { t, classes, width, theme, routes } = this.props;
    const currentComponent = routes;
    return (
      <Card className={classes[this.state.cardAnimaton]}>
        <CardHeader color="primary" className={classes.cardHeader}>
          <Typography
            className={classes.cardHeaderTitle}
            variant="subtitle1"
          >
            {t("loginPage:login")}
          </Typography>
        </CardHeader>
        <div className={
          width !== "xs"
            ?
            classes.loginContent
            : classes.loginContentMobile
        }>
          <NavPills
            active={this.props.location.pathname === (routePaths.EXHIBITOR_LOGIN) ? 1 : 0}
            color="primary"
            direction={theme.direction}
            onTabChange={(activeTab) => this.props.history.replace(activeTab === 0 ? routePaths.ORGANIZER_LOGIN : routePaths.EXHIBITOR_LOGIN)}
            horizontal={
              width !== "xs"
                ? {
                  tabsGrid: { sm: 3 },
                  contentGrid: { sm: 8 }
                }
                : undefined
            }
            tabs={[
              {
                tabButton: t("loginPage:organizer"),
                tabIcon: props => <DashboardIcon {...props} />,
                tabContent: (
                  this.props.location.pathname !== routePaths.EXHIBITOR_LOGIN &&
                  currentComponent
                )
              },
              {
                tabButton: t("loginPage:exhibitor"),
                tabIcon: props => <ExhibitorsIcon {...props} />,
                tabContent: (
                  this.props.location.pathname === routePaths.EXHIBITOR_LOGIN &&
                  currentComponent
                )
              }
            ]}
          />
        </div>
      </Card >
    );
  }
}

export default compose(
  withTranslation("loginPage"),
  withMobileDialog({ breakpoint: "xs" }),
  withWidth(),
  withAuth,
  withTheme,
  withStyles(loginPageStyle)
)(LoginPage);
