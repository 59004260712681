import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const MessengerIcon = ({ color, size, ...rest }) => {
    return (
        <SvgIcon
            width={size}
            height={size}
            viewBox={size && `0 0 ${size} ${size}`}
            fill="none"
            color={color}
            {...rest}
        >
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M12 0C5.232 0 0 4.956 0 11.64C0 15.132 1.428 18.168 3.768 20.244C3.96 20.4 4.08 20.664 4.092 20.928L4.152 23.064C4.2 23.748 4.884 24.192 5.508 23.916L7.884 22.872C8.088 22.8 8.316 22.764 8.52 22.8C9.6 23.124 10.8 23.28 12 23.28C18.768 23.28 24 18.324 24 11.64C24 4.956 18.768 0 12 0ZM19.2 8.952L15.684 14.556C15.12 15.432 13.92 15.66 13.08 15L10.272 12.924C10.02 12.732 9.66 12.732 9.408 12.924L5.616 15.804C5.112 16.2 4.452 15.6 4.8 15.048L8.316 9.444C8.88 8.568 10.08 8.34 10.92 8.964L13.728 11.076C13.992 11.268 14.34 11.268 14.592 11.076L18.384 8.196C18.888 7.8 19.548 8.4 19.2 8.952Z"
                fill="#3693FF"
            />
        </SvgIcon>

    );
};

export default MessengerIcon;
