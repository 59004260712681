import React, { useState, useMemo, } from "react";

import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import AccountIcon from "@material-ui/icons/AccountCircle";
import GroupIcon from '@material-ui/icons/Group';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import AddIcon from "@material-ui/icons/Add";
import Button from "./../../../components/CustomButtons/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Snackbar from "../../../components/CustomSnackbar/Snackbar.jsx";

import { useQuery, useMutation } from "react-apollo";
import queryCreators from "../../../apollo/queryCreators";
import _get from "lodash/get";
import _map from "lodash/map";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import ErrorPage from "../ErrorPage"
import MembersActionMenu from "./MembersActionMenu";
import { useParams } from "react-router-dom";
import { errorTypes, invitationType } from "../../../enums";
import useAuth from 'contexts/AuthenticationContext/useAuth';

const { MEMBER_ALREADY_EXISTS, INVITATION_ALREADY_EXISTS } = errorTypes;
const { ORGANIZER_TO_ORGANIZER } = invitationType;

const MEMBERS_TABLE_COLUMNS = [
    { dataKey: "name", headerLabelI18nKey: "name" },
    { dataKey: "email", headerLabelI18nKey: "email" },
    { dataKey: "invitation", headerLabelI18nKey: "invitation" },
    { dataKey: "role", headerLabelI18nKey: "role" },
    {
        dataKey: "",
        headerLabelI18nKey: "",
        styles: {
            width: 100
        }
    }
];

const useStyles = makeStyles(styles, { name: "EventMembersView" });

const isEmail = email => {
    if (!email) return false;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

const EventMembersView = () => {
    const classes = useStyles();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [showSnackbar, toggleSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { eventID } = useParams();
    const memberToAddIsValid = name.trim() && isEmail(email);

    const { loading: membersLoading, error: membersError, data: membersData, refetch: getMembersRefetch } = useQuery(
        queryCreators.GET_EVENT_MEMBERS.query,
        {
            fetchPolicy: "cache-and-network",
            variables: {
                eventID
            }
        }
    );

    const { loading: invitationsLoading, error: invitationsError, data: invitationsdata, refetch: getInvsRefetch } = useQuery(
        queryCreators.GET_UNACCEPTED_INVITATIONS.query,
        {
            fetchPolicy: "cache-and-network",
            variables: {
                eventID,
                type: ORGANIZER_TO_ORGANIZER
            }
        }
    );
    const members = _get(membersData, "event.membersAccounts", []);
    const ownerData = _get(membersData, "event.owner", {});
    const invitations = _get(invitationsdata, "event.unacceptedInvitationsWithType", []);
    const { userInfo } = useAuth();

    const allMembers = useMemo(() => {
        const ownerDataWithFlag = {
            ...ownerData,
            objType: "Owner",
        }

        const membersWithFlag = members.map((element) => {
            return {
                ...element,
                objType: "Member",
            };
        });

        const invitationsWithFlag = invitations.map((element) => {
            return {
                ...element,
                objType: "Invitation",
            };
        });
        return [ownerDataWithFlag, ...membersWithFlag, ...invitationsWithFlag]
    }, [ownerData, invitations, members]);

    useMemo(() => {
        const currentLoggedInMemberIndex = allMembers.indexOf(allMembers.find(member => member.email === userInfo.email && member.objType !== "Owner"))
        const currentLoggedInMember = allMembers[currentLoggedInMemberIndex];
        if (currentLoggedInMemberIndex >= 0) {
            allMembers.splice(currentLoggedInMemberIndex, 1);
            allMembers.splice(0, 0, currentLoggedInMember);
        }
    }, [allMembers, userInfo.email]);

    const [addInvitation, { loading: addInvitationLoading }] = useMutation(
        queryCreators.ADD_INVITATION.mutation,
        {
            onCompleted: () => {
                setName("");
                setEmail("");
            },
            onError: (error) => {
                const errorCode = _get(error, "graphQLErrors[0].extensions.exception.errorCode")
                switch (errorCode) {
                    case INVITATION_ALREADY_EXISTS:
                        setSnackbarMessage("alreadySentInv");
                        break;
                    case MEMBER_ALREADY_EXISTS:
                        setSnackbarMessage("alreadyAddedMember");
                        break;
                    default:
                        setSnackbarMessage("cantAddMember");
                        break;
                }
                toggleSnackbar(true);
            }
        }
    );

    const onInvitationAdd = () => {

        const existingMember = allMembers.find( // to not allow adding duplicate invs or members
            element => (element.email === email || element.invitedEmail === email)
        )

        if (existingMember) {
            if (existingMember.email)
                setSnackbarMessage("alreadyAddedMember");
            else if (existingMember.invitedEmail)
                setSnackbarMessage("alreadySentInv");
            toggleSnackbar(true);
            return;
        }
        queryCreators.ADD_INVITATION.mutate(addInvitation)(
            {
                invitation: {
                    name: name.trim(),
                    email,
                    eventID,
                    type: ORGANIZER_TO_ORGANIZER,
                }
            },
            {
                eventID,
                type: ORGANIZER_TO_ORGANIZER,
            }
        );
    };

    const { t } = useTranslation("eventMembersView");

    return (
        <>
            <div className={classes.titleBar}>
                <Grid container direction="row" alignItems="center">
                    <Grid container item xs={12}>
                        <Typography variant="h5" noWrap>
                            {t("team")}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            {membersError || invitationsError ?
                <ErrorPage /> :

                <Grid
                    container
                    item
                    xs={12}
                    sm={9}
                    md={9}
                    style={{
                        alignSelf: "center"
                    }}
                >
                    <Box width="100%" display="flex" justifyContent="center">
                        <Box width="90%" display="flex" flexDirection="column">
                            <Paper className={classes.root}>
                                <Box
                                    display="flex"
                                    padding={1}
                                    paddingLeft={2}
                                    paddingRight={2}
                                    alignItems="center"
                                    flexDirection={isMobile ? "column" : "row"}
                                    justifyContent="center"
                                >
                                    <form
                                        noValidate // because we rely on our own validation
                                        onSubmit={e => {
                                            e.preventDefault(); // so the app doesn't reload after each submit
                                            if (memberToAddIsValid) {
                                                onInvitationAdd();
                                                return true;
                                            }
                                            return false;
                                        }}
                                    >
                                        <TextField
                                            className={isMobile ? "" : classes.nameField}
                                            label={t("name")}
                                            variant="outlined"
                                            margin="dense"
                                            value={name}
                                            onChange={evt => setName(evt.target.value)}
                                            fullWidth={isMobile}
                                            style={{
                                                flex: 0.3
                                            }}
                                        />
                                        <TextField
                                            className={isMobile ? "" : classes.emailField}
                                            label={t("email")}
                                            variant="outlined"
                                            margin="dense"
                                            value={email}
                                            onChange={evt => setEmail(evt.target.value.trim())}
                                            fullWidth={isMobile}
                                        />
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            className={
                                                classNames({
                                                    [classes.button]: !isMobile,
                                                    [classes.mobileButton]: isMobile
                                                })}
                                            type="submit"
                                            disabled={!memberToAddIsValid || addInvitationLoading}
                                            fullWidth={isMobile}
                                            size="sm"
                                        >
                                            {addInvitationLoading ? (
                                                <Box
                                                    color={theme.palette.primary.contrastText}
                                                    className={classes.icon}
                                                >
                                                    <CircularProgress color="inherit" size={18} />
                                                </Box>
                                            ) : (
                                                <AddIcon className={classes.icon} />
                                            )}
                                            {t("add")}
                                        </Button>
                                    </form>
                                </Box>
                                {(membersLoading || invitationsLoading) && (
                                    <LinearProgress style={{ width: "100%" }} />
                                )}
                                {membersLoading || invitationsLoading ? ( // if loading just show the table's head
                                    <Box display="flex" justifyContent="center">
                                        {!isMobile && (
                                            <Table className={classes.table}>
                                                <TableHead>
                                                    <TableRow>
                                                        {_map(MEMBERS_TABLE_COLUMNS, column => (
                                                            <TableCell
                                                                key={column.dataKey}
                                                                style={{
                                                                    ...column.styles
                                                                }}
                                                            >
                                                                {t(`${column.headerLabelI18nKey}`)}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                            </Table>
                                        )}
                                    </Box>
                                ) : allMembers.length > 0 ? (
                                    <Box display="flex" justifyContent="center">
                                        {!isMobile && (
                                            <Table className={classes.table}>
                                                <TableHead>
                                                    <TableRow>
                                                        {_map(MEMBERS_TABLE_COLUMNS, column => (
                                                            <TableCell
                                                                key={column.dataKey}
                                                                style={{
                                                                    ...column.styles
                                                                }}
                                                            >
                                                                {t(`${column.headerLabelI18nKey}`)}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {allMembers.map(member => (
                                                        <TableRow key={member.id} >
                                                            <TableCell component="th" scope="row" className={classes.breakText}>
                                                                {member.invitedName || member.fullName}
                                                                <span className={classes.yourAccountText}>
                                                                    {member.email === userInfo.email && `(${t("you")})`}
                                                                </span>
                                                            </TableCell>
                                                            <TableCell className={classes.breakText}>
                                                                {member.invitedEmail || member.email}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box display="flex" >
                                                                    {
                                                                        (member.objType !== "Owner" && member.email !== userInfo.email) ?
                                                                            <div
                                                                                className={classNames(classes.activity, {
                                                                                    [classes.pending]: !member.accepted,
                                                                                    [classes.active]: (member.objType === "Member" && member.id) || member.accepted
                                                                                })}
                                                                            >
                                                                                {
                                                                                    (member.objType === "Member" && member.id) || member.accepted
                                                                                        ? t("accepted")
                                                                                        : member.objType === "Owner" ? t("owner") : t("pending")
                                                                                }
                                                                            </div> :
                                                                            <div>-</div>
                                                                    }
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box display="flex">
                                                                    <div
                                                                        className={classNames(classes.activity, {
                                                                            [classes.active]: true
                                                                        })}
                                                                    >
                                                                        {
                                                                            member.objType === "Owner" ? t("owner") : t("member")
                                                                        }
                                                                    </div>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MembersActionMenu
                                                                    onError={error => {
                                                                        setSnackbarMessage(error);
                                                                        toggleSnackbar(true);
                                                                    }}
                                                                    onDeleteFailure={() => {
                                                                        getMembersRefetch()
                                                                        getInvsRefetch()
                                                                    }}
                                                                    currentItem={member}
                                                                    loggedInUserEmail={userInfo.email}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        )}
                                        {isMobile && (
                                            <List className={classes.fullWidth}>
                                                {allMembers.length > 0 &&
                                                    allMembers.map(member => (
                                                        <ListItem alignItems="flex-start" key={member.id}>
                                                            <Box display="flex" alignItems="center" className={classes.fullWidth}>
                                                                <Box marginRight={2}>
                                                                    <ListItemAvatar>
                                                                        <Box
                                                                            display="flex"
                                                                            justifyContent="center"
                                                                            style={{
                                                                                fontSize: 35
                                                                            }}
                                                                            marginBottom={0.5}
                                                                        >
                                                                            <AccountIcon fontSize="inherit" />
                                                                        </Box>
                                                                    </ListItemAvatar>
                                                                    <Box display="flex" justifyContent="center">
                                                                        {
                                                                            (member.objType !== "Owner" && member.email !== userInfo.email) &&
                                                                            <div
                                                                                className={classNames(
                                                                                    classes.activity,
                                                                                    classes.listActivity,
                                                                                    {
                                                                                        [classes.pending]: !member.accepted,
                                                                                        [classes.active]: (member.objType === "Member" && member.id) || member.accepted
                                                                                    })}
                                                                            >
                                                                                {
                                                                                    (member.objType === "Member" && member.id) || member.accepted
                                                                                        ? t("accepted")
                                                                                        : member.objType === "Owner" ? t("owner") : t("pending")
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </Box>
                                                                    <Box display="flex" justifyContent="center">
                                                                        <div
                                                                            className={classNames(
                                                                                classes.activity,
                                                                                classes.listActivity,
                                                                                {
                                                                                    [classes.active]: true
                                                                                })}
                                                                        >
                                                                            {
                                                                                member.objType === "Owner" ? t("owner") : t("member")
                                                                            }
                                                                        </div>
                                                                    </Box>
                                                                </Box>
                                                                <ListItemText
                                                                    primary={
                                                                        <div style={{ display: "flex" }}>
                                                                            <Typography noWrap>
                                                                                {member.invitedName || member.fullName}
                                                                            </Typography>
                                                                            {userInfo.email === member.email
                                                                                &&
                                                                                <span className={classes.yourAccountText}>
                                                                                    ({t("you")})
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    secondary={member.invitedEmail || member.email}

                                                                    secondaryTypographyProps={{
                                                                        variant: "body2",
                                                                        className: classes.listEmail,
                                                                        color: "textPrimary",
                                                                        noWrap: true,
                                                                    }}
                                                                />
                                                                <MembersActionMenu
                                                                    onError={error => {
                                                                        setSnackbarMessage(error);
                                                                        toggleSnackbar(true);
                                                                    }}
                                                                    onDeleteFailure={() => {
                                                                        getMembersRefetch()
                                                                        getInvsRefetch()
                                                                    }}
                                                                    currentItem={member}
                                                                    loggedInUserEmail={userInfo.email}
                                                                />
                                                            </Box>
                                                        </ListItem>
                                                    ))}
                                            </List>
                                        )}
                                    </Box>
                                ) : // rendering an empty placeholder if no members exist
                                    !membersLoading || !invitationsLoading ? (
                                        <div className={classes.cardNoData}>
                                            <GroupIcon style={{ fontSize: 60 }} />
                                            <Typography color="inherit" variant="h6" className={classes.noMembersText}>
                                                {t("pleaseAddMembers")}
                                            </Typography>
                                        </div>
                                    ) : null}
                            </Paper>
                        </Box>
                    </Box>
                    <Snackbar
                        open={showSnackbar}
                        autoHideDuration={6000}
                        onClose={() => toggleSnackbar(false)}
                        message={t(`${snackbarMessage}`)}
                        variant="error"
                    />
                </Grid>
            }
        </>
    );
};

export default EventMembersView;