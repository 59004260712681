import React, { useEffect, useMemo, useState } from "react"

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import _get from "lodash/get";

import { useQuery, useLazyQuery, useMutation } from "react-apollo";

import makeStyles from "@material-ui/core/styles/makeStyles";
import CardModal from '../../TemplateBuilder/CardModal';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from "@material-ui/core/CircularProgress";

import { useTheme } from "@material-ui/styles";
import CustomButton from "../../../../components/CustomButtons/Button.jsx";
import { showGlobalSnackbar } from "../../../../components/GlobalSnackbar";
import { Formly } from "../../../../components/FormlyForm";
import queryCreators from "../../../../apollo/queryCreators";
import useAuth from "../../../../contexts/AuthenticationContext/useAuth";

import styles from "./styles";

const useStyles = makeStyles(styles, { name: "RegistrationModal" });

const RegistrationModal = ({ open, onClose }) => {
    const [registrantLanguage, setRegistrantLanguage] = useState("");
    const [languages, setLanguages] = useState([]);
    const [registrationData, setRegistrationData] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);

    const classes = useStyles();
    const { t, i18n } = useTranslation("registrationModal");
    const theme = useTheme();
    const { eventID } = useParams(); // the eventID gets passed from the currentURL

    const { userInfo } = useAuth();

    // any screen width below xs "600px" will set fullscreen to true
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const [addNewRegistrant, { loading: addNewRegistrantLoading }] = useMutation(
        queryCreators.ADD_NEW_REGISTRANT.mutation
    );

    const { loading: isFormLoading, data } = useQuery(queryCreators.FETCH_EVENT_REGISTRATION_FORM.query, {
        variables: {
            eventID,
        }
    });

    const formFields = _get(data, "eventRegistrationForm", []);
    const eventLanguages = Object.keys(formFields) || [];

    const [getSupportedLanguages, { loading: supportedLanguagesLoading }] = useLazyQuery(queryCreators.GET_SUPPORTED_LANGUAGES, {
        onCompleted: (data) => {
            const supportedLanguages = _get(data, "supportedLanguages", [])
            setLanguages(eventLanguages.map(language => {
                const languageObject = supportedLanguages.find(object => object.language === language);
                if (languageObject) {
                    return languageObject;
                }
                return language;
            }))

            setRegistrantLanguage(eventLanguages[0]);
        },
    });

    useEffect(() => {
        if (eventLanguages.length > 0) {
            getSupportedLanguages({
                variables: {
                    language: i18n.language,
                },
            });
        }
    }, [getSupportedLanguages, eventLanguages.length, i18n.language])

    const formConfig = useMemo(() => ({
        fields: _get(formFields, i18n.language, _get(formFields, eventLanguages[0], [])).filter(field => field.type)
        // eventLanguages doesn't need to be a dependency 
        // eslint-disable-next-line 
    }), [formFields, i18n.language]);

    const formState = useMemo(() => ({
        readOnly: false,
        isOrganizerPortal: true,
    }), []);

    const handleAddNewRegistrant = async () => {
        try {
            await addNewRegistrant({
                variables: {
                    eventID,
                    formData: registrationData,
                    addedBy: _get(userInfo, "id"),
                    registrantLanguage: registrantLanguage,
                }
            })
            onClose();
            showGlobalSnackbar(t("registrantAddSuccess"));
        } catch (error) {
            showGlobalSnackbar(t("registrantAddFailure"), "error");
        }

    }

    return (
        <CardModal
            open={open}
            onClose={onClose}
            title={t("addRegistrant")}
            classes={{
                paper: fullScreen ? classes.paperFullScreen : classes.paper
            }}
            fullScreen={fullScreen}
        >
            {
                supportedLanguagesLoading || isFormLoading || !registrantLanguage ?
                    <div className={classes.circularProgressContainer}>
                        <CircularProgress
                            thickness={7}
                            size={24}
                        />
                    </div>
                    :
                    <>
                        <div className={classes.formFields}>
                            <Formly
                                config={formConfig}
                                formState={formState}
                                onFormlyUpdate={(m) => { setRegistrationData({ ...registrationData, ...m }) }}
                                onFormlyValidityChange={(isValid) => { setIsFormValid(isValid) }}
                                onFormlyStateChange={() => { }}
                            />
                        </div>

                        <div className={classes.selectContainer}>
                            <InputLabel style={{ marginBottom: 8 }}>{t("registrantLanguage")}</InputLabel>
                            <Select
                                style={{
                                    marginBottom: theme.spacing(2),
                                    minWidth: 130
                                }}
                                variant="outlined"
                                value={registrantLanguage}
                                onChange={(event) => setRegistrantLanguage(event.target.value)}
                            >
                                {
                                    languages.map(languageObject => (
                                        <MenuItem key={languageObject.language} value={languageObject.language}>
                                            {languageObject.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>

                        </div>

                        <CustomButton
                            disabled={!isFormValid || addNewRegistrantLoading}
                            fullWidth={true}
                            size="lg"
                            color="primary"
                            onClick={handleAddNewRegistrant}
                        >
                            {t("add")}
                            {addNewRegistrantLoading && (
                                <CircularProgress
                                    color="inherit"
                                    thickness={7}
                                    size={24}
                                    className={classes.circularProgress}
                                />
                            )}
                        </CustomButton>
                        {
                            fullScreen && (
                                <CustomButton
                                    fullWidth={true}
                                    simple
                                    color="primary"
                                    size="lg"
                                    onClick={onClose}
                                >
                                    {t("cancel")}
                                </CustomButton>
                            )
                        }
                    </>
            }
        </CardModal >
    )
}
export default RegistrationModal;
