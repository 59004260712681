const routePaths = {
  HOME: "/",
  EVENTS: "/events",
  ACCOUNT: "/account",
  LOGIN: "/login",
  ORGANIZER_LOGIN: "/login/organizer",
  EXHIBITOR_LOGIN: "/login/exhibitor",
  EVENT_CREATE: "/events/create",
  EVENT: "/events/:eventID",
  DASHBOARD_REDIRECT: eventID => `/events/${eventID}/dashboard`,
  DASHBOARD: "/events/:eventID/dashboard",
  REGISTRATIONS: "/events/:eventID/registrations",
  REGISTRATION: "/events/:eventID/registrations/:eventRegistrationID",
  REGISTRATION_REDIRECT: (eventID, eventRegistrationID) => `/events/${eventID}/registrations/${eventRegistrationID}`,
  EXHIBITORS: "/events/:eventID/exhibitors",
  EVENT_MEMBERS: "/events/:eventID/members",
  EVENT_MEMBERS_REDIRECT: eventID => `/events/${eventID}/members`,
  REGISTRATIONS_FORM: "/events/:eventID/buildForm",
  BADGE_TEMPLATES_REDIRECT: eventID => `/events/${eventID}/badgeTemplates`,
  BADGE_TEMPLATES: "/events/:eventID/badgeTemplates",
  BADGE_TEMPLATE_BUILDER_REDIRECT: (eventID, id) => `/events/${eventID}/badgeTemplates/${id}`,
  BADGE_TEMPLATE_BUILDER: "/events/:eventID/badgeTemplates/:templateID",
  VERIFY_ORGANIZER: "/verify/organizer",
  INVITATION: "/accounts/invitation",
  REGISTER: "/register",
  ENGAGEMENT: "/events/:eventID/engagement",
  SETTINGS: "/events/:eventID/settings",
  SETTINGS_REDIRECT: (eventID) => `/events/${eventID}/settings`,
  FACEBOOK_PAGES_MODAL: "/events/:eventID/settings/fbModal",
  FACEBOOK_PAGES_MODAL_REDIRECT: (eventID) => `/events/${eventID}/settings/fbModal`,
  FACEBOOK_CONNECTION_SECTION_REDIRECT: (eventID) => `/events/${eventID}/settings#fb_page_container`,
  AFTER_FB_LOGIN: "/fbRedirect",
  REGISTRATIONS_SCANS: "/events/:eventID/registrations_scans",
  REGISTRATIONS_SCANS_REDIRECT: (eventID) => `/events/${eventID}/registrations_scans`,
};

export default routePaths;
