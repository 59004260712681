import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FieldsRenderer from './../modules/FieldsRenderer';
import * as Utils from '../modules/Utils';
import FormContext from '../contexts/FormContext';

function typeOrComponent(props, propName, componentName) {
  var errorPrefix = componentName + ' config.fields field with key ' + props.key;
  if (props.type && props.component) {
    return new Error(errorPrefix + ' should only have either a type or a component, not both.');
  } else if (!props.type && !props.component) {
    return new Error(errorPrefix + ' should have either a type (string) or a component (React component)');
  }
}
var hideExpression = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.string,
  PropTypes.func
]);
var wrapper = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.string)
]);
var controller = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    componentWillMount: PropTypes.func,
    componentDidMount: PropTypes.func,
    componentWillReceiveProps: PropTypes.func,
    componentWillUpdate: PropTypes.func,
    componentDidUpdate: PropTypes.func,
    componentWillUnmount: PropTypes.func
  })
]);

var FieldGroupConfig = {
  key: PropTypes.string,
  // eslint-disable-next-line
  fieldGroup: PropTypes.arrayOf(field).isRequired,
  hideExpression: hideExpression,
  wrapper: wrapper,
  data: PropTypes.object
}

var fieldConfig = {
  key: PropTypes.string.isRequired,
  type: typeOrComponent.isRequired,
  component: typeOrComponent,
  templateOptions: PropTypes.object,
  expressionProperties: PropTypes.object,
  hideExpression: hideExpression,
  validators: PropTypes.object,
  validation: PropTypes.object,
  controller: PropTypes.oneOfType([
    controller,
    PropTypes.string,
    PropTypes.arrayOf(controller)
  ]),
  wrapper: wrapper,
  modelOptions: PropTypes.shape({
    updateOn: PropTypes.string,
    debounce: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object
    ])
  }),
  data: PropTypes.object
};

var field = PropTypes.oneOfType([
  PropTypes.shape(FieldGroupConfig),
  PropTypes.shape(fieldConfig)
]);

class Formly extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { formValidation: { isValid: undefined, fields: {} }, viewValues: props.model || {}, formState: (Utils.cloneObject(props.formState) || {}) };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.formState) {
      this.setState({ formState: Utils.cloneObject(nextProps.formState) });
    }
  }
  onFormStateChange = (formState) => {
    this.setState({ formState: formState });
    if (typeof this.props.onFormlyStateChange === 'function')
      this.props.onFormlyStateChange(formState);
  }
  onValueUpdate = (renderingKey, fieldKey, viewValue, modelValue, validationResult) => {
    if (!Utils.deepEqual(this.state.viewValues[fieldKey], viewValue)) {
      //clone viewValues
      let currentViewValuesState = { ...this.state.viewValues };
      currentViewValuesState[fieldKey] = viewValue;
      this.setState({ viewValues: currentViewValuesState });
    }

    var currentValidationState = this.state.formValidation;
    if (validationResult === undefined)
      delete currentValidationState.fields[renderingKey];
    else
      currentValidationState.fields[renderingKey] = validationResult;
    this.setState({ formValidation: currentValidationState });
    var prevFormValidity = this.state.formValidation.isValid;
    let newFormValidity = Utils.getFormValidity(this.state.formValidation.fields);
    this.setState(prevState => ({
      formValidation: {
        ...prevState.formValidation,
        isValid: newFormValidity
      }
    }))
    if (this.props.onFormlyValidityChange && prevFormValidity !== newFormValidity) {
      this.props.onFormlyValidityChange(newFormValidity);
    }
    //clone model
    let currentModel = { ...this.props.model }
    if (modelValue === undefined)
      delete currentModel[fieldKey];
    else
      currentModel[fieldKey] = modelValue;

    this.props.onFormlyUpdate(currentModel);


  }


  render() {
    var model = this.props.model;
    var formState = this.state.formState;
    var viewValues = this.state.viewValues;
    var fieldsValidation = this.state.formValidation.fields;
    var onValueUpdate = this.onValueUpdate;
    var onFormStateChange = this.onFormStateChange;
    var fields = Array.isArray(this.props.config.fields) ? this.props.config.fields.map(function (field, index) {
      return FieldsRenderer.generateFieldTag({ index, config: field });
    }) : [];
    return (
      <FormContext.Provider value={{
        formViewValues: viewValues,
        formModel: model,
        formFieldsValidation: fieldsValidation,
        viewValues,
        model,
        fieldsValidation,
        formState,
        onValueUpdate,
        onFormStateChange
      }}>
        {fields}
      </FormContext.Provider>
    );
  }
}
Formly.defaultProps = { model: {} }

Formly.propTypes = {
  onFormlyUpdate: PropTypes.func.isRequired,
  onFormlyStateChange: function (props, propName, componentName) {
    if (!!(props['formState'] && (props[propName] === undefined || typeof (props[propName]) != 'function'))) {
      return new Error(
        `The prop \`${propName}\` is marked as required in \`${componentName}\` if \`formState\` is passed but its value is \`${props[propName]}\``
      );
    }
  },
  onFormlyValidityChange: PropTypes.func,
  config: PropTypes.shape({
    fields: PropTypes.arrayOf(field).isRequired
  }),
  model: PropTypes.object,
  formState: PropTypes.object
}


export default Formly;
