const styles = theme => ({
    mainElement: {
        marginTop: theme.spacing(2.25),
        width: "100%",
    },
    alignmentTitle: {
        fontSize: 13,
        textAlign: "start",
        color: theme.palette.text.secondary
    },
    bodyContainer: {
        display: "grid",
        padding: "0 20px"
    },
    separateBorders: {
        display: "flex",
        animation: "$textFieldAnim 0.4s cubic-bezier(.37,.63,.53,1.3) forwards",
    },
    allBorders: {
        animation: "$textFieldAnim 0.4s cubic-bezier(.37,.63,.53,1.3) forwards",
    },
    "@keyframes textFieldAnim": {
        from: {
            transform: "translateX(-20px)",
        },
        to: {
            transform: "translateX(0px)",
        }
    },
    toggleSeparateBorders: {
        marginLeft: 20,
    },
    separateBorder: {
        marginLeft: 5,
    },
    backgroundColorPicker: {
        marginTop: 30,
        justifySelf: "flex-start",
    },
    borderColorPicker: {
        marginLeft: 20,
        marginRight: 45,
    },
    borderSizeColorContainer: {
        display: "flex",
        marginTop: 20,
    },
    title: {
        fontSize: 13,
        marginTop: 30,
        marginBottom: 12,
    },
    cornerRadiusContainer: {
        display: "flex",
    }
});

export default styles;
