import React, { useReducer, useEffect } from "react";

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CircularProgress from "@material-ui/core/CircularProgress";

import MovableTemplate from "./core/MovableTemplate";

import _isFunction from 'lodash/isFunction';
import usePrevious from "../../../hooks/usePrevious";

const ACTION_TYPES = {
    ADD_LOADING_MOVABLE: 'ADD_LOADING_MOVABLE',
    REMOVE_LOADING_MOVABLE: 'REMOVE_LOADING_MOVABLE'
};

const TemplateViewer = ({
    movableTemplates,
    zoomTransformation,
    height,
    width,
    sizeUnit = "px",
    showBorder = true,
    movableProps,
    containerNode,
    showMovables,
    isMovableActive,
    onLoadingChange,
    className,
    isDragging,
    isBuilderUIBlocked,
}, ref) => {
    const {
        onMouseDown,
        onMouseUp,
        onTouchStart,
        onTouchEnd,
        onClickAway,
        ...otherMovableProps
    } = movableProps || {};

    const [loadingMovables, setLoadingMovables] = useReducer((state, action) => {
        if (action.type === ACTION_TYPES.ADD_LOADING_MOVABLE) {
            const exists = state.find(movable => movable.key === action.payload);
            if (!exists)
                return state.concat(action.payload);
        }
        if (action.type === ACTION_TYPES.REMOVE_LOADING_MOVABLE) {
            return state.filter(movableKey => movableKey !== action.payload);
        }
        return state;
    }, []);

    const previousLoadingMovablesLength = usePrevious(loadingMovables.length);
    useEffect(() => {
        if (previousLoadingMovablesLength === 0 && loadingMovables.length > 0)
            _isFunction(onLoadingChange) && onLoadingChange(true);
        if (previousLoadingMovablesLength > 0 && loadingMovables.length === 0)
            _isFunction(onLoadingChange) && onLoadingChange(false);
    }, [loadingMovables.length, onLoadingChange, previousLoadingMovablesLength]);

    return (
        <div
            style={{
                background: "#ffffff",
                opacity: isBuilderUIBlocked ? 0.7 : 1,
                position: "relative",
                overflow: "hidden",
                border: showBorder ? `1px solid #e2e2e2` : undefined,
                width: showBorder ? `calc(${width}${sizeUnit} + 1px)` : `${width}${sizeUnit}`,
                height: showBorder ? `calc(${height}${sizeUnit} + 1px)` : `${height}${sizeUnit}`,
                minWidth: showBorder ? `calc(${width}${sizeUnit} + 1px)` : `${width}${sizeUnit}`, // needed as on resizing the screen to very small size browser tries to shrink the div
                minHeight: showBorder ? `calc(${height}${sizeUnit} + 1px)` : `${height}${sizeUnit}`,
                ...zoomTransformation
            }}
            ref={ref}
        >
            {
                Array.isArray(movableTemplates) && movableTemplates.map(template => {
                    const showMovable = showMovables;
                    const isActive = showMovables && typeof isMovableActive === 'function' && isMovableActive(template.key);
                    const movableTemplate = (
                        <div
                            key={template.key}
                            onMouseDown={event => onMouseDown && onMouseDown(event, template.key)}
                            onMouseUp={() => onMouseUp && onMouseUp()}
                            onTouchStart={event => onTouchStart && onTouchStart(event, template.key)}
                            onTouchEnd={() => onTouchEnd && onTouchEnd()}
                        >
                            <MovableTemplate
                                isBuilderUIBlocked={isBuilderUIBlocked}
                                {...otherMovableProps}
                                isDragging={isDragging}
                                movableConfig={template}
                                showMovable={showMovable}
                                containerNode={containerNode}
                                isActive={isActive}
                                onLoad={() => setLoadingMovables({
                                    type: ACTION_TYPES.REMOVE_LOADING_MOVABLE,
                                    payload: template.key
                                })}
                                onError={() => setLoadingMovables({
                                    type: ACTION_TYPES.REMOVE_LOADING_MOVABLE,
                                    payload: template.key
                                })}
                                onLoadStart={() => setLoadingMovables({
                                    type: ACTION_TYPES.ADD_LOADING_MOVABLE,
                                    payload: template.key
                                })}
                            />
                        </div>
                    );
                    if (showMovable) {
                        return (
                            <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={onClickAway} key={template.key}>
                                {movableTemplate}
                            </ClickAwayListener>
                        );
                    }
                    return movableTemplate;
                })
            }

            {
                isBuilderUIBlocked &&
                <CircularProgress
                    size={30}
                    style={{
                        position: "absolute",
                        right: 10,
                        top: 10,
                        zIndex: 1000,
                    }}
                />
            }
        </div>
    )
}

export default React.forwardRef(TemplateViewer);