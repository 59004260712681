import React from "react";
import { useTranslation } from "react-i18next"
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Alert from "@material-ui/lab/Alert";
import styles from "./styles";

const facbookArticle = "https://developers.facebook.com/docs/messenger-platform/send-messages/message-tags#new_supported_tags"

const useStyles = makeStyles(styles, { name: "PostPurchaseUpdate" });

const PostPurchaseUpdate = () => {

    const { t } = useTranslation("facebookMessageTypes");
    const classes = useStyles();

    return (
        <div>
            <Typography className={classes.illustrationText}>{t("postPurchaseUpdate")}</Typography>
            <Alert
                severity="warning"
                className={classes.alert}
            >
                {t("tagsMisuseMaySuspendPage")}
            </Alert>
            <Typography className={classes.illustrationText}>
                {t("notifyUserOfPurchase")}:<br /><br />
            - {t("invoicesOrReceipts")}<br />
            - {t("notificationOfShipmentStatus")}<br />
            - {t("changesRelatedToOrder")}<br /><br />
                {t("forMoreInformationCheckOfficial")} <a href={facbookArticle} target="blank" className={classes.fbHyperLink}>{t("facebookArticle")}.</a>
            </Typography>
        </div>
    )
}
export default PostPurchaseUpdate;