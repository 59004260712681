import React, { useState, useEffect, useMemo, Fragment, cloneElement } from "react"

import useTheme from "@material-ui/core/styles/useTheme";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import LinearProgress from '@material-ui/core/LinearProgress';

//graphql
import { useQuery } from "react-apollo";

import { useParams } from "react-router-dom";
import _get from "lodash/get";

import queryCreators from "../../apollo/queryCreators";
import PageNotFound from "views/PageNotFound/PageNotFound.jsx";

/* Component to pass the custom theme for each event separately */
const BrandEventPage = props => {
    const theme = useTheme();
    const [primaryColor, setPrimaryColor] = useState(theme.palette.primary.main)
    const [mode, setMode] = useState(theme.palette.type)
    const { eventID } = useParams();

    const { loading: eventLoading, error, data } = useQuery(
        queryCreators.GET_EVENT_THEME.query,
        {
            variables: {
                eventID
            }
        }
    );
    const eventData = _get(data, "event", []);

    useEffect(() => {
        if (!eventData) return;
        if (eventData.theme) {
            if (eventData.theme.primaryColor)
                setPrimaryColor(eventData.theme.primaryColor)
            if (eventData.theme.mode)
                setMode(eventData.theme.mode.toLowerCase())
        }
    }, [eventData])


    const brandTheme =
        useMemo(() => {
            /* omitting text, divider, background, and action from 
            the main theme's palette as they don't get affected by 
            the new palette's type */
            const {
                text,
                divider,
                background,
                action,
                ...afterOmissionTheme
            } = theme.palette;

            return createMuiTheme({
                ...theme,
                palette:
                {
                    ...afterOmissionTheme,
                    type: mode,
                    primary: {
                        main: primaryColor
                    },
                }
            })
        },
            [mode, primaryColor, theme]
        )

    if (eventLoading) return <LinearProgress />;

    return (
        <Fragment>
            {
                eventData ?
                    <MuiThemeProvider theme={brandTheme}>
                        {cloneElement(props.children, { errorOnLoad: error })}
                    </MuiThemeProvider> :
                    <PageNotFound />
            }
        </Fragment>
    );
}

export default BrandEventPage;