const styles = theme => ({
    container: {
        width: "100%",
        '&:hover': {
            cursor: "pointer"
        }
    },
    containerDisabled: {
        width: "100%",
        opacity: 0.7,
    },
    card: {
        margin: 0,
        overflow: 'hidden'
    },
    cardHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: 15,
    },
    cardTitle: {
        fontWeight: 400,
        color: theme.palette.text.primary,
        flex: 1,
        textAlign: 'left',
    },
    cardBody: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: 0
    },
    printButton: {
        marginLeft: 14,
        color: theme.palette.text.primary
    },
    deleteButton: {
        marginLeft: 14,
        color: theme.palette.text.primary
    },
    badgeTemplateViewContainer: {
        height: 180,
        flex: 1,
        borderTop: `1px solid ${theme.palette.divider}`,
        overflow: 'hidden',
        pointerEvents: 'none',
    },
    placeHolderArea: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.divider,
        background: theme.palette.divider,
        borderTop: `1px solid ${theme.palette.divider}`,
        height: 180,
        flex: 1,
    },
    iconButtonLoading: {
        position: "absolute",
        zIndex: 1
    },
    deleteButtonLoading: {
        color: theme.palette.error.dark,
    }
});

export default styles;
