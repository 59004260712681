const styles = theme => ({
    cardHeader: {
        width: "auto",
        textAlign: "center",
        marginLeft: 20,
        marginRight: 20,
        marginTop: -55,
        padding: "20px 0",
        marginBottom: 15
    },
    paper: {
        overflowY: "inherit",
        width: 440,
    },
    paperFullScreen: {
        overflowY: "inherit",
    },
    cardFullScreen: {
        marginBottom: 0,
        paddingTop: 50,
        boxShadow: "none",
        overflowY: "auto"
    },
    card: {
        marginBottom: 0,
        boxShadow: "none"
    },
    badgeName: {
        margin: 8,
    },
    canvasPlaceholder: {
        position: "relative",
        width: 155,
        height: 155,
        border: `1px dashed ${theme.palette.action.disabled}`,
        borderRadius: 4,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginRight: 35,
    },
    heightTextField: {
        position: "absolute",
        background: theme.palette.background.paper,
        width: 72,
        left: "100%",
        transform: "translateX(-50%)"
    },
    widthTextField: {
        position: "absolute",
        background: theme.palette.background.paper,
        width: 72,
        top: "100%",
        transform: "translateY(-50%)"
    },
    toggleButton: {
        width: 50,
        height: 30,
        fontSize: 13,
    },
    toggleButtonGroup: {
        display: "grid",
        margin: "30px 10px 0 10px",
    },
    inputFeild: {
        padding: 10
    },
    togglesTitle: {
        fontSize: 13,
        marginRight: "auto",
        paddingBottom: 12
    },
    togglesContent: {
        lineHeight: 0,
        fontSize: 13
    },
    bodyContainer: {
        display: "flex",
        alignItems: "flex-end",
        padding: "90px 0px",
        flexWrap: "wrap",
        justifyContent: "center",
    }
});

export default styles;
