const styles = theme => ({
  inputContainer: {
    background: theme.palette.common.white,
    borderColor: theme.palette.grey["300"],
    position: "relative",
    transition: "all 0.4s ease"
  },
  iconContainer: {
    fontSize: 20,
    zIndex: 1,
    display: "flex"
  },
  inputRoot: {
    marginTop: theme.spacing(1)
  },
  actionButtonsContainer: {
    right: `-${theme.spacing(1) / 2}px`,
    top: `-${theme.spacing(1) / 2}px`,
  },
  formControl: {
    marginTop: theme.spacing(1)
  },
  noPointerEvents: {
    pointerEvents: "none"
  },
  hiddenInput: {
    background: theme.palette.grey["50"],
    opacity: 0.5
  },
  multiCheckboxRtl: {
    display: "flex"
  },
  mutliChecboxLabelRoot: {
    textAlign: "start"
  },
  multiCheckboxError: {
    color: theme.palette.error.main
  },
  errorRtl: {
    textAlign: "right"
  },
  inputContainerSelected: {
    borderColor: theme.palette.primary.main,
  },
  fieldDragging: {
    opacity: 0.7,
  },
});

export default styles;
