import hexToRgba from "hex-rgba";

const styles = theme => ({
    mainElement: {
        marginTop: theme.spacing(2.25),
        width: "100%",
    },
    toggleContainer: {
        marginTop: theme.spacing(2.5),
    },
    textField: {
        marginTop: theme.spacing(3)
    },
    toggleButton: {
        border: 0,
        color: theme.palette.text.secondary,
        "&$selected": {
            color: theme.palette.primary.main,
            background: hexToRgba(theme.palette.primary.main, 15)
        }
    },
    panelContent: {
        padding: theme.spacing(1.5),
        marginTop: theme.spacing(2)
    },
    fontSettingsContainer: {
        display: 'flex',
        marginTop: theme.spacing(3)
    },
    pickerContainer: {
        marginLeft: theme.spacing(3)
    },
    selected: {},
});

export default styles;
