import React, { Fragment, useEffect } from "react";
import validationErrors from "./validationErrors";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import request from "../../modules/axios";
import { withTranslation } from "react-i18next";
import { compose } from "recompose";

const styles = theme => ({
  link: {
    "&:hover": {
      color: "#FFFFFF"
    },
    color: "#FFFFFF"
  }
});

const resendVerificationToken = async email => {
  try {
    await request.get(`/accounts/verification/organizer/resend?email=${email}`);
  } catch (error) {
    console.log(error);
  }
};

const ErrorView = ({ error, classes, email, t }) => {
  useEffect(() => {
    if (error === validationErrors.INVALID_TOKEN) {
      resendVerificationToken(email);
    }
  }, [error, email]);

  switch (error) {
    case validationErrors.INVALID_TOKEN:
      return (
        <Fragment>
          <Typography variant="h4" gutterBottom>
            {t("verifyPage:invalidToken")}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {t("verifyPage:verificationMailWasResent")},{" "}
            {t("verifyPage:checkYourMail").toLowerCase()}
          </Typography>
          <Button>
            <Link className={classes.link} to="/">
              {t("home")}
            </Link>
          </Button>
        </Fragment>
      );
    case validationErrors.ACCOUNT_ALREADY_VERIFIED:
      return (
        <Fragment>
          <Typography variant="h4" gutterBottom>
            {t("verifyPage:accountAlreadyVerified")}
          </Typography>
          <Button>
            <Link className={classes.link} to="/login">
              {t("verifyPage:login")}
            </Link>
          </Button>
        </Fragment>
      );
    case validationErrors.ACCOUNT_NOT_FOUND:
      return (
        <Fragment>
          <Typography variant="h4" gutterBottom>
            {t("verifyPage:invalidToken")}
          </Typography>
          <Button>
            <Link className={classes.link} to="/">
              {t("home")}
            </Link>
          </Button>
        </Fragment>
      );
    default:
      return null;
  }
};

export default compose(
  withTranslation("verifyPage"),
  withStyles(styles)
)(ErrorView);
