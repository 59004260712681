import React, { useEffect, useState, useContext, useMemo } from 'react';
import hexToRgba from "hex-rgba";
import { useQuery } from "react-apollo";
import _get from "lodash/get";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next"

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from "@material-ui/core/styles/useTheme";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from "@material-ui/core/CircularProgress";

import queryCreators from "../../apollo/queryCreators";

import { MentionsField, Mention } from "../MentionsField";
import { templatesConfig, parser } from "../../views/EventPage/TemplateBuilder/core/templateUtils";
import TemplateBuilderContext from "../../contexts/TemplateBuilderContext";
import { showGlobalSnackbar } from "../../components/GlobalSnackbar";

import styles from './styles';


const useStyles = makeStyles(styles);

const TranslationPreview = ({ languages = [], messageToTranslate = "", finalTranslations, savedMessageTranslations = [] }) => {
    const [buttonSelected, setButtonSelected] = useState(0);
    const [messageTranslations, setMessageTranslations] = useState(savedMessageTranslations);
    const { placeholderKeys } = useContext(TemplateBuilderContext);
    const { eventID } = useParams();
    const theme = useTheme();
    const { t, i18n } = useTranslation("eventEngagementView");
    const classes = useStyles();

    const { loading: supportedLanguagesLoading, data: supportedLanguagesData, error: supportedLanguagesError } = useQuery(queryCreators.GET_SUPPORTED_LANGUAGES, {
        variables: {
            language: i18n.language,
        },
        onError: (error) => {
            showGlobalSnackbar(t("cantTranslate"), "error");
        }
    });

    const supportedLanguages = _get(supportedLanguagesData, "supportedLanguages", []);

    const titles = useMemo(() => {
        if (supportedLanguages.length > 0) {
            return languages.map(language => {
                return supportedLanguages.find(object => object.language === language)
            })
        }
        return [];
    }, [supportedLanguages, languages])

    const { loading: translationLoading, data: translations } = useQuery(queryCreators.GET_MESSAGE_TRANSLATION, {
        skip: (supportedLanguagesLoading || !messageToTranslate) || messageTranslations.find(object => object.language === _get(titles[buttonSelected], "language", "")),
        variables: {
            message: messageToTranslate,
            eventId: eventID,
            targetLang: _get(titles[buttonSelected], "language", ""),
        },
        onError: (error) => {
            showGlobalSnackbar(t("cantTranslate"), "error");
        }
    });

    const translationData = _get(translations, "messageTranslations", {
        language: _get(titles[buttonSelected], "language", ""),
        translation: messageToTranslate,
    })

    useEffect(() => {
        if (!translationLoading && translationData.language) {
            const sameLanguageObject = messageTranslations.find(object => object.language === titles[buttonSelected].language)
            if (!sameLanguageObject) {
                setMessageTranslations([
                    ...messageTranslations,
                    translationData,
                ])
            }
        }
    }, [translationLoading, translationData, messageTranslations, titles, buttonSelected])

    useEffect(() => {
        const messageTranslationsWithoutTypeName = messageTranslations.map(object => {
            return { language: object.language, translation: object.translation }
        })
        finalTranslations(messageTranslationsWithoutTypeName);
    }, [messageTranslations, finalTranslations])

    const handleTabSwitch = (event, newValue) => {
        if (newValue != null) // so the button can't be unselected unless another one is selected
            setButtonSelected(newValue);
    }

    return (
        <div className={classes.container}>
            {
                supportedLanguagesError ?
                    <Box p={3}>
                        <Typography color="textPrimary">{messageToTranslate}</Typography>
                    </Box>
                    :
                    (
                        supportedLanguagesLoading ?
                            <div className={classes.circularProgress}>
                                <CircularProgress />
                            </div>
                            :
                            <>
                                <Typography className={classes.translationTitle} color="textPrimary">{t("reviewYourTranslation")}</Typography>
                                <Tabs
                                    value={buttonSelected}
                                    onChange={handleTabSwitch}
                                    textColor="primary"
                                    TabIndicatorProps={{
                                        style: { background: theme.palette.text.primary }
                                    }}
                                >
                                    {
                                        titles.map(title =>
                                            <Tab
                                                key={title.name}
                                                classes={{
                                                    root: classes.tab,
                                                    textColorPrimary: classes.tabTextColor,
                                                    selected: classes.selected
                                                }}
                                                label={title.name}
                                            />
                                        )
                                    }

                                </Tabs>
                                <div className={classes.translationSectionContainer}>
                                    {
                                        translationLoading &&
                                        <CircularProgress
                                            size={20}
                                            className={classes.translationMessageLoading}
                                        />
                                    }
                                    <MentionsField
                                        className={classes.announcementsTextarea}
                                        disabled={translationLoading || !messageToTranslate}
                                        multiline
                                        variant="outlined"
                                        rows={5}
                                        maxTextLength={1000} // TODO: we need to research the exact max text length needed
                                        value={_get(messageTranslations.find(object => object.language === titles[buttonSelected].language), "translation")}
                                        onChange={text => {
                                            const newMessageTranslations = [...messageTranslations];
                                            const messageToChangeIndex = messageTranslations.indexOf(messageTranslations.find(object => object.language === titles[buttonSelected].language));
                                            newMessageTranslations[messageToChangeIndex].translation = text;
                                            setMessageTranslations([...newMessageTranslations])
                                        }}
                                        InputProps={{
                                            style: {
                                                fontSize: 13,
                                                lineHeight: 'inherit',
                                            }
                                        }}
                                        fullWidth
                                    >
                                        <Mention
                                            markup={`${templatesConfig.PARSING_CONSTANTS.MARKER_START}__id__${templatesConfig.PARSING_CONSTANTS.MARKER_END}`}
                                            trigger={templatesConfig.PARSING_CONSTANTS.TRIGGER}
                                            style={{
                                                fontSize: 'inherit',
                                                lineHeight: 'inherit',
                                                backgroundColor: hexToRgba(theme.palette.primary.main, 15),
                                                borderRadius: 2,
                                                fontWeight: 'inherit'
                                            }}
                                            data={placeholderKeys.map(key => ({
                                                display: parser.getLocalizedContextKey(key, t, false, false),
                                                id: key
                                            }))}
                                            displayTransform={display => parser.getLocalizedContextKey(display, t, false)}
                                            renderSuggestion={({ display }) => parser.getLocalizedContextKey(display, t, false, false)}
                                        />
                                    </MentionsField>
                                </div>
                            </>
                    )
            }

        </div >
    );
};

export default TranslationPreview;