import React, { useState, useMemo } from "react"
import { useMutation } from "react-apollo";
import _get from "lodash/get";
// material-ui components
import makeStyles from "@material-ui/core/styles/makeStyles"
import Grid from "@material-ui/core/Grid"
import Switch from '@material-ui/core/Switch';
import Typography from "@material-ui/core/Typography"
// import Divider from "@material-ui/core/Divider"
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
// material-ui icons
import DoneIcon from '@material-ui/icons/Done';
// import DoneAllIcon from '@material-ui/icons/DoneAll';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PropTypes from 'prop-types';

import { useTranslation } from "react-i18next"
import queryCreators from "../../../../apollo/queryCreators";
import MessengerIcon from "../../../../components/Icons/MessengerIcon";
import { showGlobalSnackbar } from "../../../../components/GlobalSnackbar"
import { numberFormatter } from "./../../../../modules/utils"
import { automatedMessageType, errorTypes } from "../../../../enums";
import CustomizeMessagesModal from "../CustomizeMessagesModal";
import TemplateBuilderContext from '../../../../contexts/TemplateBuilderContext';
import { templatesConfig } from '../../../../views/EventPage/TemplateBuilder/core/templateUtils';
import styles from "./styles"

const useStyles = makeStyles(styles, { name: "EventEngagementView" });
const { CHECKIN_GREETING, EXHIBITOR_CONTACTS, EVENT_SUMMARY } = automatedMessageType;
const { ATTACHMENT_LIMIT_EXCEEDED } = errorTypes;

/**
 * Automated Messages Cards
 */
const AutomatedMessagesSetting = ({
    title,
    isAlwaysOn,
    isMessengerOnly,
    isEmailOnly,
    messageType,
    automatedMessage,
    eventID,
    tooltipMessage,
    enableCustomizeMessage,
    languages,
    isfbPageNotConnected,
}) => {

    const messageProperties = automatedMessage.filter((message) => {
        return message.automatedMsgType === messageType; // getting the message object related to the current message type
    })[0];
    const attachedFilesUrlsToObj = messageProperties && messageProperties.attachedFiles;
    const classes = useStyles();
    const { t } = useTranslation("eventEngagementView");
    const initialSwitchState = (isAlwaysOn && messageProperties) ?
        messageProperties.enabled : (messageProperties ? messageProperties.enabled : false)
    const [switchState, setSwitchState] = useState(initialSwitchState);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [customMessage, setCustomMessage] = useState(messageProperties ? messageProperties.customMessage : "");
    const [translationMessages, setCustomMessageTranslations] = useState([]);
    const [fbMessageType, setFbMessageType] = useState({ value: _get(messageProperties, "fbMessageType", "CONFIRMED_EVENT_UPDATE") });
    const [targetChannels, setTargetChannels] = useState(_get(messageProperties, "targetChannels", []));
    const [attachedFileUrls, setAttachedFileUrl] = useState(attachedFilesUrlsToObj || []);
    const templateContextVal = useMemo(() => ({
        placeholderKeys: templatesConfig.ENGAGEMENT_DATA_KEYS,
    }), []);
    const isCustomMessageEmpty = enableCustomizeMessage && (!customMessage && attachedFileUrls.length <= 0);
    const alwaysOnSwitch = ((isAlwaysOn && messageProperties) || isCustomMessageEmpty) ? classes.alwaysOnSwitch : undefined;
    const fbMessageDeliveredCount = messageProperties ? messageProperties.fbMessageDeliveredCount : 0;
    const emailDeliveredCount = messageProperties ? messageProperties.emailDeliveredCount : 0;
    // const fbMessageReadCount = messageProperties ? messageProperties.fbMessageReadCount : 0;
    // const emailReadCount = messageProperties ? messageProperties.emailReadCount : 0;

    /* conditions to disable notification texts' tooltips 
    while number shown is equal in both tooltip and notification text*/
    const disableFbDeliveredTooltip = fbMessageDeliveredCount.toString() === numberFormatter(fbMessageDeliveredCount);
    const disableEmailDeliveredTooltip = emailDeliveredCount.toString() === numberFormatter(emailDeliveredCount);
    // const disableFbReadTooltip = fbMessageReadCount.toString() === numberFormatter(fbMessageReadCount);
    // const disableEmailReadTooltip = emailReadCount.toString() === numberFormatter(emailReadCount);
    const customMessageTranslations = _get(messageProperties, "customMessageTranslations", []);

    const [upsertAutomatedMessage] = useMutation(
        queryCreators.UPSERT_AUTOMATED_MESSAGE.mutation,
        {
            onCompleted: (data) => {
                const isSwitchEnabled = data.upsertAutomatedMessage.enabled;
                setSwitchState(isSwitchEnabled);
                showGlobalSnackbar(t("settingsSaved"));
            }
        }
    );

    const onAutoMessageBehaviorChange = async (newAutoMessageConfig) => {
        const { newSwitchState, customMessage, attachedFiles, customMessageTranslations, fbMessageType, targetChannels } = newAutoMessageConfig
        const formattedAttachedFiles = attachedFiles.map(fileObj => ({
            fileName: fileObj.name || fileObj.fileName,
            fileType: fileObj.type || fileObj.fileType,
            url: fileObj.url || undefined,
            file: fileObj.file || undefined,
        }))

        try {
            await queryCreators.UPSERT_AUTOMATED_MESSAGE.mutate(upsertAutomatedMessage)({
                autoMessage: {
                    eventID,
                    customMessage: customMessage || null,
                    customMessageTranslations: customMessageTranslations,
                    automatedMsgType: messageType,
                    enabled: newSwitchState,
                    attachedFiles: formattedAttachedFiles || [],
                    fbMessageType: _get(fbMessageType, "value"),
                    targetChannels,
                }
            }
                ,
                {
                    eventID,
                }
            );
        } catch (error) {
            const errorCode = _get(error, "graphQLErrors[0].extensions.exception.errorCode")
            switch (errorCode) {
                case ATTACHMENT_LIMIT_EXCEEDED:
                    showGlobalSnackbar(t("cantAddMultipleAttachments"), "error")
                    break;
                default:
                    showGlobalSnackbar(t("faildToChangeBehavior"), "error")
                    break;
            }

            if (newSwitchState !== switchState) setSwitchState(!newSwitchState);
        }
    }

    return (
        <Grid item md={6} lg={3} xl={3} className={classes.parentGrid}>

            <Paper elevation={3} className={classes.main}>
                <div className={classes.cardUpperSection}>
                    <div className={classes.cardTitle}>
                        <Typography className={classes.title} noWrap>{title}</Typography>
                        {!!tooltipMessage && (
                            <Tooltip
                                title={tooltipMessage}
                                placement="top"
                                enterTouchDelay={0}
                                classes={{
                                    popper: classes.tooltipPopper,
                                }}
                            >
                                <InfoOutlinedIcon onClick={(event) => event.stopPropagation()} className={classes.infoIcon} />
                            </Tooltip>
                        )}
                    </div>
                    {
                        enableCustomizeMessage &&
                        <Tooltip
                            title={t("customizeMessages")}
                            placement="top"
                            enterTouchDelay={200}
                        >
                            <IconButton
                                size="small"
                                onClick={() => setIsModalOpen(true)}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    <Tooltip
                        title={t("addACustomMessage")}
                        placement="top"
                        enterTouchDelay={0}
                        disableHoverListener={!isCustomMessageEmpty}
                        disableFocusListener={!isCustomMessageEmpty}
                        disableTouchListener={!isCustomMessageEmpty}
                    >
                        <Switch
                            disableRipple={(isAlwaysOn && switchState) || isCustomMessageEmpty}
                            className={alwaysOnSwitch}
                            checked={switchState}
                            onChange={() => {
                                if ((isAlwaysOn && switchState) || isCustomMessageEmpty) return;
                                const newSwitchState = !switchState
                                setSwitchState(newSwitchState);
                                onAutoMessageBehaviorChange({
                                    newSwitchState: newSwitchState,
                                    customMessage,
                                    attachedFiles: attachedFileUrls,
                                    fbMessageType,
                                    customMessageTranslations: translationMessages,
                                    targetChannels,
                                })
                            }}
                            color="primary"
                        />
                    </Tooltip>
                </div>
                <div className={classes.cardMiddleSection}>
                    <div className={classes.subMiddleSection}>

                        <div className={classes.subMiddleTitle}>
                            <DoneIcon className={classes.subMiddleTitleIcon} fontSize="small" />
                            <Typography className={classes.subMiddleTitleText} noWrap>{t("sent")}:</Typography>
                        </div>

                        <div className={classes.subMiddleBody}>
                            {
                                !isEmailOnly &&
                                <Tooltip
                                    title={fbMessageDeliveredCount}
                                    placement="top"
                                    enterTouchDelay={200}
                                    disableHoverListener={disableFbDeliveredTooltip}
                                    disableFocusListener={disableFbDeliveredTooltip}
                                    disableTouchListener={disableFbDeliveredTooltip}
                                >
                                    <Badge
                                        max={1000000}
                                        className={classes.badgeIcon}
                                        badgeContent={numberFormatter(fbMessageDeliveredCount)}
                                        color="primary"
                                        classes={{
                                            badge: classes.notificationBadge
                                        }}
                                    >
                                        <MessengerIcon className={isfbPageNotConnected ? classes.fbNotConnected : undefined} />
                                    </Badge>

                                </Tooltip>
                            }
                            {
                                !isMessengerOnly &&
                                <Tooltip
                                    title={emailDeliveredCount}
                                    placement="top"
                                    enterTouchDelay={200}
                                    disableHoverListener={disableEmailDeliveredTooltip}
                                    disableFocusListener={disableEmailDeliveredTooltip}
                                    disableTouchListener={disableEmailDeliveredTooltip}
                                >
                                    <Badge
                                        max={1000000}
                                        className={classes.badgeIcon}
                                        badgeContent={numberFormatter(emailDeliveredCount)}
                                        color="primary"
                                        classes={{
                                            badge: classes.notificationBadge
                                        }}
                                    >
                                        <MailOutlineIcon className={classes.emailIcon} />

                                    </Badge>
                                </Tooltip>
                            }
                        </div>
                    </div>

                    {/* <Divider className={classes.divider} orientation="vertical" flexItem />

                    <div className={classes.subMiddleSection}>
                        <div className={classes.subMiddleTitle}>
                            <DoneAllIcon className={classes.subMiddleTitleIcon} fontSize="small" />
                            <Typography className={classes.subMiddleTitleText} noWrap>{t("read")}:</Typography>
                        </div>

                        <div className={classes.subMiddleBody}>
                            {
                                !isEmailOnly &&
                                <Tooltip
                                    title={fbMessageReadCount}
                                    placement="top"
                                    enterTouchDelay={200}
                                    disableHoverListener={disableFbReadTooltip}
                                    disableFocusListener={disableFbReadTooltip}
                                    disableTouchListener={disableFbReadTooltip}
                                >
                                    <Badge
                                        max={1000000}
                                        className={classes.badgeIcon}
                                        badgeContent={numberFormatter(fbMessageReadCount)}
                                        color="primary"
                                        classes={{
                                            badge: classes.notificationBadge
                                        }}
                                    >
                                        <MessengerIcon />
                                    </Badge>

                                </Tooltip>
                            }
                            {
                                !isMessengerOnly &&
                                <Tooltip
                                    title={emailReadCount}
                                    placement="top"
                                    enterTouchDelay={200}
                                    disableHoverListener={disableEmailReadTooltip}
                                    disableFocusListener={disableEmailReadTooltip}
                                    disableTouchListener={disableEmailReadTooltip}
                                >
                                    <Badge
                                        max={1000000}
                                        className={classes.badgeIcon}
                                        badgeContent={numberFormatter(emailReadCount)}
                                        color="primary"
                                        classes={{
                                            badge: classes.notificationBadge
                                        }}
                                    >
                                        <MailOutlineIcon className={classes.emailIcon} />

                                    </Badge>
                                </Tooltip>
                            }
                        </div>

                    </div> */}
                    <TemplateBuilderContext.Provider
                        value={templateContextVal}
                    >
                        <CustomizeMessagesModal
                            isOpen={isModalOpen}
                            onClose={() => setIsModalOpen(false)}
                            message={customMessage}
                            messageTranslations={customMessageTranslations}
                            attachedFileUrls={attachedFileUrls}
                            languages={languages}

                            fbMessageTag={fbMessageType}
                            isfbPageNotConnected={isfbPageNotConnected}
                            targetChannels={targetChannels}
                            onMessageSubmit={(attachedFiles, message, fbMessageType, messageTranslations, targetChannels) => {
                                setAttachedFileUrl(attachedFiles)
                                setCustomMessage(message)
                                setFbMessageType(fbMessageType)
                                setCustomMessageTranslations(messageTranslations)
                                setTargetChannels(targetChannels)
                                onAutoMessageBehaviorChange({
                                    newSwitchState: switchState,
                                    customMessage: message,
                                    attachedFiles: attachedFiles,
                                    customMessageTranslations: messageTranslations,
                                    fbMessageType,
                                    targetChannels,
                                })
                            }}
                        />
                    </TemplateBuilderContext.Provider>
                </div>
                <Typography className={classes.cardLowerSection} noWrap>
                    {t("messageTarget")}:{" "}
                    {
                        messageType === EVENT_SUMMARY ?
                            `${t("emailOnly")}` :
                            messageType === CHECKIN_GREETING ?
                                `${t("messengerOrEmail")}` :
                                messageType === EXHIBITOR_CONTACTS ?
                                    `${t("messengerOnly")}` :
                                    `${t("messengerAndEmail")}`
                    }
                </Typography>
            </Paper>
        </Grid >
    )
}
AutomatedMessagesSetting.propTypes = {
    isAlwaysOn: PropTypes.bool,
    isMessengerOnly: PropTypes.bool,
    isEmailOnly: PropTypes.bool,
    title: PropTypes.string,
    tooltipMessage: PropTypes.string,
};
export default AutomatedMessagesSetting;