import React from "react";
import withParsedValues from "../../withParsedValues";

function ShapeTemplate({ contentConfig, layoutConfig }, ref) {
    if (!contentConfig || !layoutConfig)
        return null;
    const {
        backgroundColor,
        borderColor,
        borderSize,
        borderRadius,
    } = contentConfig;
    const { dimensions, origin, transform } = layoutConfig;

    return (
        <div
            ref={ref}
            style={{
                position: "absolute",
                overflow: "hidden",
                ...dimensions,
                ...origin,
                transform
            }}
        >
            <div style={{
                ...dimensions,
                background: backgroundColor,
                borderRadius:
                    borderRadius ?
                        `
                ${borderRadius.topLeft === "" ? 0 : borderRadius.topLeft}px 
                ${borderRadius.topRight === "" ? 0 : borderRadius.topRight}px 
                ${borderRadius.bottomRight === "" ? 0 : borderRadius.bottomRight}px 
                ${borderRadius.bottomLeft === "" ? 0 : borderRadius.bottomLeft}px 
                        `
                        : 0,
                border: `${borderSize === "" ? 0 : borderSize}px solid ${borderColor}`
            }}>

            </div>
        </div>
    );
}

export default withParsedValues(React.memo(React.forwardRef(ShapeTemplate)));
