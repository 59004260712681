import React, { useState, useEffect, Fragment } from "react";
import Background from "../Background/Background";
import LogoIcon from "components/Icons/Logo";
import Header from "components/Header/Header.jsx";
import { withStyles, Grid, CircularProgress } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import * as mainStyles from "../../assets/jss/material-kit-react";
import verifyPageStyles from "../../assets/jss/VerifyPage";
import classNames from "classnames";
import ErrorView from "./ErrorView";
import Button from "@material-ui/core/Button";
import request from "../../modules/axios";
import queryString from "query-string";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { compose } from "recompose";

const styles = () => ({
  ...mainStyles,
  ...verifyPageStyles,
  containerCentered: {
    paddingTop: 0,
    display: "flex",
    justifyContent: "center"
  },
  link: {
    "&:hover": {
      color: "#FFFFFF"
    },
    color: "#FFFFFF"
  }
});

const VerifyPage = ({ classes, location, t }) => {
  const { token, email } = queryString.parse(location.search);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!success && !error && token && email) {
      async function verify() {
        try {
          await request.get(
            `/accounts/verification/organizer?token=${token}&email=${email}`
          );
          setSuccess(true);
        } catch (error) {
          console.log(error.response);
          if (
            error.response &&
            error.response.data &&
            error.response.data.errorType
          ) {
            setError(error.response.data.errorType);
          }
        }
      }
      verify();
    }
    // we don't need to reverify on error/success 
    // eslint-disable-next-line
  }, [email, token]);

  return (
    <div>
      <Background>
        <div
          className={classNames(classes.container, classes.containerCentered)}
        >
          <Header
            absolute
            color="transparent"
            brand="Eventsquare"
            brandLogo={
              <LogoIcon style={{ height: 24, width: 24, margin: "0 5px" }} />
            }
            rightLinks={null}
          />
          <Grid container justify="center" alignItems="center">
            <Grid container direction="column" alignItems="center">
              {error && <ErrorView error={error} email={email} />}
              {success && (
                <Fragment>
                  <Typography color="inherit" variant="h4" gutterBottom>
                    {t("verifyPage:accountVerifiedSuccessfully")}
                  </Typography>
                  <Button>
                    <Link className={classes.link} to="/login">
                      {t("verifyPage:login")}
                    </Link>
                  </Button>
                </Fragment>
              )}
              {!error && !success && (
                email && token ?
                  <Fragment>
                    <div
                      style={{
                        color: "white",
                        marginBottom: 10
                      }}
                    >
                      <CircularProgress color="inherit" size={70} thickness={2} />
                    </div>
                    <Typography color="inherit" variant="subtitle1" gutterBottom>
                      {t("verifyPage:verifying")}
                    </Typography>
                  </Fragment>
                  :
                  <Typography color="inherit" variant="subtitle1" gutterBottom>
                    {t("verifyPage:invalidVerificationLink")}
                  </Typography>
              )}
            </Grid>
          </Grid>
        </div>
      </Background>
    </div>
  );
};

export default compose(
  withTranslation("verifyPage"),
  withStyles(styles)
)(VerifyPage);
