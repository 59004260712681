const styles = theme => ({
    logoPickerContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: theme.spacing(2),
    },
    logoPicker: {
        width: 150,
        height: 150
    },
    datePicker: {
        marginTop: 16,
    },
    datePickerRoot: {
        fontSize: theme.typography.body2.fontSize,
        "&::before": {
            height: "1px !important",
            backgroundColor: "#D2D2D2"
        }
    },
    form: {
        padding: theme.spacing(2.5)
    },
    restrictCheckModeLabel: {
        color: theme.palette.action.active,
    },
    restrictCheckMode: {
        display: "flex",
    },
    tooltipPopper: {
        textAlign: "start",
    },
    infoIcon: {
        width: 16,
    },
    checkboxContainer: {
        display: "flex",
        alignItems: "center",
    },
})

export default styles;
