import React from 'react';
import Print from '../../../components/Print';
import TemplateViewer from '../TemplateBuilder/TemplateViewer';

const PrintTemplate = ({ template, children, ...rest }) => {
    if (!template) return null;
    const { id, width, height, unit, movableTemplates } = template;
    return (
        <Print trigger={children} {...rest}>
            <TemplateViewer
                key={id}
                height={height}
                width={width}
                sizeUnit={unit}
                movableTemplates={movableTemplates}
                showBorder={false}
                zoomTransformation={{}}
            />
        </Print>
    )
};

export default PrintTemplate;