const styles = theme => ({
    titleBar: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3),
        borderBottom: `solid 1px ${theme.palette.divider}`,
        color: theme.palette.text.primary
    },
    saveEventSettingButton: {
        position: "fixed",
        bottom: theme.spacing(2.5),
        alignSelf: "flex-end",
        paddingRight: 12,
        zIndex: 50
    },
    root: {
        margin: theme.spacing(3.75),
        display: 'flex',
        flexDirection: 'column',
        alignItems: "center"
    },
    spinnerContainer: {
        flexGrow: 1
    },
    sectionTitle: {
        margin: `0 ${theme.spacing(1)}px`,
        textAlign: "center",
    },
    sectionTitleLines: {
        flex: 1
    },
    sectionContainer: {
        display: "contents",
    },
    bodyContainer: {
        flexDirection: "column",
        alignSelf: "center",
    },
    headerContainer: {
        marginTop: 45,
        padding: "0 30px",
        alignItems: "center",
        minWidth: 300,
        display: "flex",
        boxSizing: "border-box",
        color: theme.palette.text.primary
    },
    settingsContainer: {
        minWidth: 250,
        justifySelf: "center"
    },
    circularProgress: {
        color: theme.palette.common.white,
        position: "absolute"
    }
})

export default styles;
