import React from "react"
import compose from "recompose/compose";

import withStyles from "@material-ui/core/styles/withStyles";
import PhoneAndroidOutlined from "@material-ui/icons/PhoneAndroidOutlined";

import { withTranslation } from "react-i18next";

import CardBody from "components/Card/CardBody.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import loginPageStyle from "assets/jss/material-kit-react/views/loginPage.jsx";



class ExhibitorTab extends React.Component {
    render() {
        const { t, classes, i18n } = this.props;

        const currentLanguage = i18n.language.split(/[_-]/)[0]; // removing underscore or dash

        return (
            <CardBody>
                <InfoArea
                    title={t("loginPage:downloadExhibitorApp")}
                    description={t("loginPage:downloadExhAppEnjoy")}
                    icon={props => <PhoneAndroidOutlined {...props} />}
                    iconColor="primary"
                />
                <div className={classes.storeBadgesContainer}>
                    <a
                        className={classes.storeBadgeLink}
                        target="blank" // to open in a new tab
                        href="https://play.google.com/store/apps/details?id=com.eventsquare_exhibitors&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    >
                        <img
                            className={classes.googlePlayImage}
                            alt={t("loginPage:getFromGooglePlay")}
                            src={`https://play.google.com/intl/${currentLanguage}/badges/static/images/badges/${currentLanguage}_badge_web_generic.png`}
                        />
                    </a>

                    <a
                        className={classes.storeBadgeLink}
                        target="blank" // to open in a new tab
                        href="https://apps.apple.com/us/app/eventsquare-exhibitors/id1581513946?itsct=apps_box_badge&amp;itscg=30200"
                    >
                        <img
                            style={{
                                margin: "6%",
                                width: "82%",
                            }}
                            alt={t("loginPage:downloadOnAppStore")}
                            src={`https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black//${currentLanguage}?size=250x83&amp;releaseDate=1629504000&h=55a268f8cc583663feb7fc29ec72aaca`}
                        />
                    </a>

                </div>
            </CardBody >
        )
    }


}
export default compose(
    withTranslation("loginPage"),
    withStyles(loginPageStyle)
)(ExhibitorTab);