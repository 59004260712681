import tinyColor from 'tinycolor2';

const styles = theme => {
    const appBarHeight = 64;
    return {
        root: {
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column"
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            height: appBarHeight,
            padding: `0px 0px 0px ${theme.spacing(3)}px`
        },
        appBarRight: {
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            marginRight: 20,
        },
        appBarRightList: {
            display: "flex"
        },
        appBarRightListItem: {
            padding: 0
        },
        appBarLogo: {
            height: 30,
            width: 30,
            margin: `0px ${theme.spacing(2)}px 0px 0px`
        },
        appBarTitle: {
            fontWeight: 400,
            fontSize: 20
        },
        avatar: {
            width: 35,
            height: 35,
            fontSize: 13,
            color: theme.palette.primary.contrastText,
            backgroundColor: tinyColor(theme.palette.primary.contrastText).setAlpha(.2).toRgbString(),
        },
        listItemText: {
            textAlign: "start",
        },
        listItemIcon: {
            color: "unset",
        },
        appBarLogoContainer: {
            "&:hover": {
                cursor: "pointer",
            },
            display: "flex",
        },
    };
}

export default styles;
