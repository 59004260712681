const styles = theme => ({
    root: {
        margin: theme.spacing(3.75),
        display: 'flex',
        flexDirection: 'column',
        alignItems: "center"
    },
    bodyContainer: {
        flexDirection: "column",
        alignSelf: "center",
        flex: 1,
    },
    settingsContainer: {
        minWidth: 250,
        justifySelf: "center",
        width: "100%",
    },
    form: {
        padding: theme.spacing(2.5),
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3)
    },
    body: {
        display: "flex",
        flex: 1,
        flexDirection: "column"
    },
    titleBar: {
        backgroundColor: theme.palette.background.paper,
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        borderBottom: `solid 1px ${theme.palette.divider}`,
        color: theme.palette.text.primary
    },
    divider: {
        backgroundColor: theme.palette.background.default,
        padding: "45px 30px 0",
    },
    logoPickerContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: theme.spacing(2),
    },
    logoPicker: {
        width: 150,
        height: 150,
    },
    spinnerContainer: {
        flexGrow: 1,
    },
    circularProgress: {
        color: theme.palette.common.white,
        position: "absolute",
    },
    submitButtonContainer: {
        position: "fixed",
        bottom: theme.spacing(2.5),
        right: 0,
        alignSelf: "flex-end",
        paddingRight: 12,
        zIndex: 50,
    },
    passwordSectionToggleButton: {
        alignSelf: "flex-start",
        minWidth: 90,
        marginLeft: 8,
    },
    textField: {
        marginBottom: 16,
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
    },
})

export default styles;
