import React, { useContext } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { useMutation } from "react-apollo";
import queryCreators from "../../apollo/queryCreators";

import useTheme from "@material-ui/styles/useTheme";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import AddIcon from "@material-ui/icons/Add";

import { showGlobalSnackbar } from "../../components/GlobalSnackbar";
import routePaths from "../../config/routePaths";
import AuthenticationContext from "../../contexts/AuthenticationContext/AuthenticationContext";

import Events from "./Events";
import styles from "./styles";

const useStyles = makeStyles(styles, { name: "EventsPage" });

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const EventsPage = ({ routes = () => { } }) => {
  const { userInfo } = useContext(AuthenticationContext);
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation("eventsPage");
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [addEvent] = useMutation(queryCreators.CREATE_EVENT.mutation, {
    onCompleted: () => {
      showGlobalSnackbar(t("eventCreated"));
    },
    onError: () => {
      showGlobalSnackbar(t("eventFailed", "error"));
    }
  });

  return (
    <div className={classes.root}>
      <div className={classes.titleBar}>
        <Grid container direction="row" alignItems="center">
          <Grid container item xs={4}>
            <Typography variant="h6" noWrap>
              {t("eventsPage:events")}
            </Typography>
          </Grid>
          <Grid container item xs={8} justify="flex-end">
            {
              <Button
                onClick={() => {
                  history.replace(routePaths.EVENT_CREATE);
                }}
              >
                <AddIcon />
                {t("eventsPage:createEvent")}
              </Button>
            }
            {routes({
              onClose: () => {
                history.replace(routePaths.EVENTS);
              },
              transition: Transition,
              fullScreen: fullScreen,
              onSubmit: eventObj => {
                queryCreators.CREATE_EVENT.mutate(addEvent)(eventObj, {
                  email: userInfo && userInfo.email
                });
                history.replace(routePaths.EVENTS);
              }
            })}
          </Grid>
        </Grid>
      </div>
      <Events />
    </div>
  );
};

export default EventsPage;
