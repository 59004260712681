import React, { memo, useMemo } from "react";
import cn from "classnames";
import _get from "lodash/get";
import _isObjectLike from "lodash/isObjectLike";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/styles/makeStyles";

import styles from "./styles";

const useStyles = makeStyles(styles, { name: "Select" });

const Select = (props) => {
    const classes = useStyles();
    const { viewValue, config, onChange, fieldValidation, formState } = props;
    const { templateOptions } = config;
    const { label, description, required, options } = templateOptions || {};
    const { readOnly } = formState || {};

    const hasError = fieldValidation.isValid === false;
    const errorMsg = Object.values(_get(fieldValidation, 'messages', {}))[0];

    const optionsFromTemplateOptions = useMemo(() => {
        return (options || []).map((o) => _isObjectLike(o) ? o : { name: o, value: o })
    }, [options]);

    return (<TextField
        className={cn({ [classes.formControl]: true, [classes.readOnly]: readOnly })}
        InputLabelProps={{
            shrink: true,
        }}
        InputProps={{
            readOnly,
        }}
        select
        label={label}
        value={viewValue}
        helperText={errorMsg || description}
        error={hasError}
        required={required}
        onChange={(e) => onChange(e.target.value)}
    >
        {optionsFromTemplateOptions.map((option, index) => (
            <MenuItem key={option.value + index} value={option.value}>
                {option.name}
            </MenuItem>
        ))}

    </TextField>);
}

export default memo(Select);