const styles = theme => ({
    logoPickerContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    logoPicker: {
        width: "100%",
        height: "100%",
        position: "relative",
    },
    imagePickerContainer: {
        alignItems: "flex-end",
        padding: "45px 0px",
    },
    imagePickerItem: {
        padding: 8,
        maxWidth: "100%",
        width: 145,
        height: 145,
    },
    deleteImageButton: {
        zIndex: 1,
        padding: 8,
        width: 20,
        height: 20,
        background: "rgba(250,250,250,0.1)",
        position: "absolute",
        top: 5,
        right: 5,
    },
})

export default styles;
