import React from 'react';
import { MentionsInput, Mention } from 'react-mentions'
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";

import styles from './style';

const useStyles = makeStyles(styles);

const MUIMentionsInput = ({ onChange, children, style, value, multiline, maxTextLength, ...props }) => {
    const classes = useStyles();
    const theme = useTheme();
    const mentionsStyle = multiline ? classes.multilineMentions : classes.mentions
    return <MentionsInput
        {...props}
        value={value ? value : ''}
        onChange={(e, newValue, newPLainTextValue, mentions) =>
            (onChange && (maxTextLength ? e.target.value.length <= maxTextLength : true))
            && onChange(newValue)
        }
        className={mentionsStyle}
        classNames={{
            [mentionsStyle]: mentionsStyle,
            [`${mentionsStyle}__input`]: classes.input,
            [`${mentionsStyle}__control`]: classes.control,
            [`${mentionsStyle}__suggestions__list`]: classes.suggestionsList,
            [`${mentionsStyle}__suggestions__item`]: classes.suggestionsItem,
            [`${mentionsStyle}__suggestions__item--focused`]: classes.focusedSuggestionsItem,
        }}
        singleLine={!multiline}
        style={{
            input: {
                ...style,
                overflow: multiline ? "auto" : "unset",
            },
            suggestions: {
                zIndex: 10000,
                item: {
                    '&focused': {
                        backgroundColor: theme.palette.action.selected,
                    },
                },
            },
            highlighter: {
                boxSizing: 'border-box',
                overflow: 'hidden',
                height: 70,
            }
        }}
    >
        {children}
    </MentionsInput>
};

export { MUIMentionsInput, Mention };