import React, { useState } from "react"
// material-ui components
import makeStyles from "@material-ui/core/styles/makeStyles"
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';

// material-ui icons
import CloseIcon from '@material-ui/icons/Close';

import LogoPicker from "../../../../../components/LogoPicker";

import styles from "./styles"

const useStyles = makeStyles(styles, { name: "MultiImagePicker" });

const MultiImagePicker = ({ attachedFiles, setAttachments, maxAttachmentsNumber = 1 }) => {
    const classes = useStyles();
    const [resetLogoPicker, setResetLogoPicker] = useState(0);
    const [isError, setIsError] = useState(false);
    let attachments = attachedFiles || [];

    const handleLogoAdd = (newAttachment, index) => {
        if (isNaN(index))
            attachments.push(newAttachment);
        else {
            attachments.splice(index, 1);
            attachments.splice(index, 0, newAttachment);
        }
        setAttachments(attachments);
        setResetLogoPicker(c => c + 1);
    }

    const handleLogoRemove = (index) => {
        attachments.splice(index, 1);
        setAttachments(attachments);
        setResetLogoPicker(c => c + 1);
    }

    return (
        <Grid container className={classes.imagePickerContainer}>
            {
                attachments.map((attachment, index) =>
                    <Grid item key={index} xs={6} sm={4} md={4} lg={4} xl={4} className={classes.imagePickerItem}>
                        <div className={classes.logoPickerContainer}>
                            <div className={classes.logoPicker}>
                                {
                                    !isError &&
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        className={classes.deleteImageButton}
                                        onClick={event => {
                                            event.preventDefault();
                                            handleLogoRemove(index)
                                        }}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                }
                                <LogoPicker
                                    key={resetLogoPicker}
                                    url={attachment && (attachment.file || attachment.url)}
                                    onChange={(file) => handleLogoAdd(file, index)}
                                    onError={setIsError}
                                />
                            </div>
                        </div>
                    </Grid>
                )
            }
            {
                attachments.length < maxAttachmentsNumber &&
                <Grid item xs={6} sm={4} md={4} lg={4} xl={4} className={classes.imagePickerItem}>
                    <div className={classes.logoPickerContainer}>
                        <div className={classes.logoPicker}>

                            <LogoPicker
                                key={resetLogoPicker}
                                onChange={(file) => handleLogoAdd(file)}
                            />
                        </div>
                    </div>
                </Grid>
            }
        </Grid>
    )
}
export default MultiImagePicker;