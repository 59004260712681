const styles = theme => ({
  centerAligned: {
    textAlign: "center"
  },
  noMarginInput: {
    marginTop: 0,
    marginBottom: 0
  }
});

export default styles;
