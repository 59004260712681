import React, { useState, useEffect } from 'react';

import OverlappingIcons from '../../../../../../../components/OverlappingIcons/OverlappingIcons';

import ImageIcon from '@material-ui/icons/Image';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './styles';

const useStyles = makeStyles(styles, { name: 'Image' });

const Image = ({ src, alt, onError, onLoadStart, onLoad, ...props }) => {
    const [failed, setFailed] = useState(false);
    const [loading, setLoading] = useState(true);
    const classes = useStyles();

    // eslint-disable-next-line
    useEffect(() => { setFailed(false); setLoading(true); onLoadStart && onLoadStart(); }, [src]);

    return (
        <>
            {(failed || loading) &&
                <div className={classes.iconContainer}>
                    {failed && <OverlappingIcons parentIcon={ImageIcon} childIcon={HighlightOffIcon} />}
                    {loading && <CircularProgress color="inherit" />}
                </div>
            }
            <img
                src={src}
                {...props}
                className={(loading || failed) ? classes.hiddenImage : ''}
                onError={err => {
                    setFailed(true);
                    setLoading(false);
                    onError && onError(err);
                }}
                onLoad={evt => {
                    setLoading(false);
                    onLoad && onLoad(evt);
                }}
                alt={alt}
            />
        </>
    );
}

export default Image;