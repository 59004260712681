import React, { useState } from "react";

import routePaths from "config/routePaths";

import Box from "@material-ui/core/Box";
import useTheme from "@material-ui/styles/useTheme";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

import SelfRegistrantActionsIcon from "../../components/Icons/SelfRegistrantActionsIcon";

const CopyKioskUrl = ({ eventID }) => {
    const [copied, setCopied] = useState(false);
    const theme = useTheme();
    const { t } = useTranslation("registrationsView");

    if (!eventID) return null;
    return (
        <Box
            display="inline-block"
            paddingTop={2}
            paddingRight={5}
        >
            <Tooltip
                title={t("copied")}
                open={copied}
                leaveDelay={2000}
                onClose={() => setCopied(false)}
            >
                <CopyToClipboard
                    text={`${window.location.origin}${routePaths.REGISTRATIONS_SCANS_REDIRECT(eventID)}`}
                    onCopy={() => setCopied(true)}
                >
                    <Button
                        style={{
                            background: theme.palette.background.paper,
                            paddingTop: theme.spacing(1),
                            paddingBottom: theme.spacing(1),
                            paddingLeft: theme.spacing(2),
                            paddingRight: theme.spacing(2),
                            boxShadow: theme.shadows[1],
                            color: theme.palette.text.primary
                        }}
                    >
                        <Typography noWrap>
                            {t("registrantSelfActions")}
                        </Typography>
                        <Box
                            marginLeft={1}
                            marginRight={1}
                            display="flex"
                            alignItems="center"
                        >
                            <SelfRegistrantActionsIcon size={30} />
                        </Box>
                    </Button>
                </CopyToClipboard>
            </Tooltip>
        </Box>
    );
};

export default CopyKioskUrl;
