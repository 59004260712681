import React, { Fragment, useState } from "react";
import Moveable from "react-moveable";
import getTemplateContent from "./getTemplateContent";

const MovableTemplate = props => {
  const {
    containerNode,
    onOriginChange,
    onDimensionsChange,
    onRotationChange,
    movableConfig,
    zoom = 1,
    showMovable,
    isActive,
    isDragging,
    isBuilderUIBlocked,
    ...rest
  } = props;
  const { dimensions, origin } = movableConfig.layoutConfig;
  const [templateRef, setTemplateRef] = useState(null);
  const Template = getTemplateContent(movableConfig.type);

  if (!movableConfig || !movableConfig.layoutConfig || !Template) return null;
  return (
    <Fragment>
      <Template
        ref={ref => setTemplateRef(ref)}
        movableConfig={movableConfig}
        {...rest}
      />
      {showMovable && (
        <span
          style={{ opacity: isActive ? 1 : 0 }}
        >
          <Moveable
            container={containerNode}
            target={templateRef}
            edge={false}
            throttleDrag={0}
            draggable={!isBuilderUIBlocked}
            snappable={!isBuilderUIBlocked}
            snapThreshold={7} // distance value that can snap to guidelines (default: 5)
            snapCenter // allow snapping for movable's center 
            verticalGuidelines={containerNode && [0, containerNode.offsetWidth / 2, containerNode.offsetWidth - 2]} // start, center, end
            horizontalGuidelines={containerNode && [0, containerNode.offsetHeight / 2, containerNode.offsetHeight - 2]} // start, center, end
            pinchable={!isBuilderUIBlocked}
            onDrag={({ beforeDelta }) => {
              isDragging(true);
              // using beforeDelta as it represents the change on each call
              // top, left , translate is accumulative till the draging ends
              let newLeft = origin.left + beforeDelta[0] / zoom;
              let newTop = origin.top + beforeDelta[1] / zoom;

              onOriginChange(movableConfig.key, {
                left: newLeft,
                top: newTop
              });
            }}
            onDragEnd={() => {
              isDragging(false);
            }}
            resizable={!isBuilderUIBlocked}
            throttleResize={0}
            keepRatio={movableConfig.keepDimensionsRatio}
            onResize={({ drag, delta }) => {
              //That's how we tell if the corner was used for resizing
              // if (width !== dimensions.width && height !== dimensions.height) {
              //   const widthChange = width - dimensions.width;
              //   const heightChange = height - dimensions.height;
              //   if (Math.abs(widthChange) > Math.abs(heightChange)) {
              //     const scale = width / dimensions.width;
              //     const newHeight =
              //       heightChange > 0
              //         ? dimensions.height * scale
              //         : scale >= 2
              //         ? 0
              //         : dimensions.height * (scale - 1);
              //     onDimensionsChange(movableConfig.key, {
              //       width,
              //       height: newHeight
              //     });
              //     console.log(
              //       `width: ${dimensions.width}, height:${dimensions.height}`
              //     );
              //     console.log(
              //       `width: ${width}, height: ${height}, scaled height:${newHeight}`
              //     );
              //   } else {
              //     const scale = height / dimensions.height;
              //     const newWidth =
              //       widthChange > 0
              //         ? dimensions.width * scale
              //         : scale >= 2
              //         ? 0
              //         : dimensions.width * (scale - 1);
              //     onDimensionsChange(movableConfig.key, {
              //       width: newWidth,
              //       height
              //     });
              //     console.log(
              //       `width: ${dimensions.width}, height:${dimensions.height}`
              //     );
              //     console.log(
              //       `width: ${width}, scaled width: ${newWidth}, height:${height}`
              //     );
              //   }
              // }
              const { left, top } = origin;
              onOriginChange(movableConfig.key, {
                left: left + drag.beforeDelta[0] / zoom,
                top: top + drag.beforeDelta[1] / zoom
              });
              onDimensionsChange(movableConfig.key, {
                width: dimensions.width + delta[0] / zoom,
                height: dimensions.height + delta[1] / zoom
              });
            }}
            origin={false}
            rotatable={!isBuilderUIBlocked}
            onRotate={({ transform }) => {
              onRotationChange(movableConfig.key, transform);
            }}
          />
        </span>

      )}
    </Fragment>
  );
};

export default MovableTemplate;
