const styles = theme => ({
  root: {
    marginTop: theme.spacing(3),
    width: "100%",
    display: 'flex',
    flexDirection: 'column'
  },
  activity: {
    padding: theme.spacing(0.5),
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    color: theme.palette.error.dark,
    borderRadius: 4,
    fontSize: theme.direction === "rtl" ? 11 : 12
  },
  pending: {
    color: theme.palette.text.disabled,
    border: `1px solid ${theme.palette.text.disabled}`,
  },
  inactive: {
    color: theme.palette.error.dark,
    border: `1px solid ${theme.palette.error.dark}`
  },
  active: {
    color: theme.palette.success[700],
    border: `1px solid ${theme.palette.success[700]}`
  },
  listActivity: {
    lineHeight: "14px",
    fontSize: 11,
    marginTop: theme.spacing(1),
  },
  titleBar: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    borderBottom: `solid 1px ${theme.palette.divider}`,
    color: theme.palette.text.primary
  },
  button: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    background: theme.palette.primary.main
  },
  mobileButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    background: theme.palette.primary.main
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  fullWidth: {
    width: "100%",
  },
  marginDenseRtl: {
    transform: "translate(-14px, 12px) scale(1)"
  },
  cardNoData: {
    display: "flex",
    minHeight: 300,
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.divider
  },
  content: {
    padding: theme.spacing(3)
  },
  emailField: {
    marginLeft: 15,
    maxWidth: 210,
  },
  nameField: {
    maxWidth: 210,
  },
  listEmail: {
    marginBottom: theme.spacing(0.5),
  },
  noExhibitorText: {
    textAlign: "center",
  },
  breakText: {
    wordBreak: "break-all",
  }
});

export default styles;
