import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import useTheme from "@material-ui/core/styles/useTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Fab from "@material-ui/core/Fab"
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider"
import Fade from '@material-ui/core/Fade';
import MinimizeIcon from './MinimizeIcon';
import SettingsIcon from "components/Icons/SettingsIcon";
import styles from "./styles";

const useStyles = makeStyles(styles, { name: "ElementSettingsPanel" });

const ElementSettingsPanel = props => {

  const theme = useTheme();
  const { t } = useTranslation("elementSettingsPanel");
  const classes = useStyles();

  const settingsButtonStyling = classNames({
    [classes.button]: true,
    [classes.buttonOn]: props.isExpanded,
    [classes.buttonOff]: !props.isExpanded
  });
  const panelStyling = classNames({
    [classes.panel]: true,
    [classes.panelOn]: props.isExpanded,
    [classes.panelOff]: !props.isExpanded
  });
  return (

    <React.Fragment>

      <Fab className={settingsButtonStyling}  // settings panel toggle button
        onClick={props.handleExpand}
        disabled={props.isExpanded}
        size={props.buttonSize}
      >
        <SettingsIcon />
      </Fab>

      <div //main header
        className={panelStyling}
      >
        <Fade in={props.isExpanded} mountOnEnter unmountOnExit>
          <div style={{
            display: "flex",
            flexWrap: "wrap"
          }}>
            <div className={classes.headerContainer}>

              <div className={classes.flipsContainer}>
                <SettingsIcon
                  className={classes.settingsIcon}
                />
                <Typography
                  className={classes.title}
                >
                  {t(`panelTitle`)}
                </Typography>
              </div>

              <IconButton
                className={classes.minimizeIcon}
                onClick={props.handleCollapse}
                size="small"
              >
                <MinimizeIcon className={classes.blackColor} />
              </IconButton>
            </div>
            <Divider absolute style={{ top: theme.spacing(6.62) }}></Divider>

            {/*end of the main header part*/}

            {//Other components should be rendered here based on what's selected
              props.children}
          </div>
        </Fade>
      </div >
    </React.Fragment>
  );
};

export default ElementSettingsPanel;