const styles = theme => ({
    mentions: {
        width: '100%',
        height: '1.1875em',
        margin: '6px 0 7px'
    },
    multilineMentions: {
        width: '100%',
        height: '100%',
        margin: '6px 0 7px'
    },
    input: {
        border: 0,
        color: theme.palette.text.primary,
        fontSize: 'inherit',
        fontWeight: 'inherit',
    },
    control: {
        display: 'inline-block',
        width: '100%',
        textAlign: "start",
    },
    suggestionsList: {
        maxHeight: 150,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        fontWeight: 400,
        boxShadow: theme.shadows[8],
        borderRadius: 4,
        fontFamily: 'inherit',
        color: theme.palette.text.secondary,
        padding: `${theme.spacing(1)}px 0`,
        cursor: 'pointer'
    },
    suggestionsItem: {
        padding: `${theme.spacing(0.75)}px ${theme.spacing(2)}px`,
    },
    focusedSuggestionsItem: {
        backgroundColor: theme.palette.background.default,
    },
});

export default styles;
