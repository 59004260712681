const styles = theme => ({
    infoIconRoot: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: theme.spacing(2)
    },
    printBadgeItemContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    printBadgeItem: {
        width: '100%'
    },
    iconRoot: {
        minWidth: 'auto',
        marginRight: theme.spacing(3.5)
    },
    buttonRoot: {
        margin: `${theme.spacing(2)}px 0px`,
    },
});

export default styles;
