import React, { useState, Fragment } from "react"
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-apollo";
import { withRouter } from "react-router";
import { useParams } from "react-router-dom";
import queryString from "query-string";
import _get from "lodash/get";
// material-ui components
import makeStyles from "@material-ui/core/styles/makeStyles"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import Alert from '@material-ui/lab/Alert';


import { fbPageStatus } from "../../../../enums"
import routePaths from "../../../../config/routePaths";
import queryCreators from "../../../../apollo/queryCreators";
import { showGlobalSnackbar } from "../../../../components/GlobalSnackbar";
import FacebookCircularIcon from "components/Icons/FacebookCircularIcon";

import useAuth from 'contexts/AuthenticationContext/useAuth';

import styles from "./styles";

const useStyles = makeStyles(styles, { name: "FbConnectedPage" });
const { NEEDS_RECONNECT, UNKNOWN } = fbPageStatus;

const FbConnectedPage = ({ onFbConnectedPageError, onFbContainerFocus }) => {
    const { t } = useTranslation("FbConnectedPage");
    const [fbPageConnectionStatus, setFbPageConnectionStatus] = useState(undefined);
    const classes = useStyles();
    const [loginButtonLoading, setLoginButtonLoading] = useState(false);
    const { eventID } = useParams(); // the eventID gets passed from the currentURL
    const { userInfo } = useAuth();
    const whiteListedFbEmails = [
        "imohamedh14@gmail.com",
        "assem.a.hafez@gmail.com",
        "demo@event-square.net",
        "fbtest@event-square.net",
        "waeltrade@hotmail.com",
    ]
    const isLoggedInUserWhiteListed = !!whiteListedFbEmails.find(email => userInfo.email.toLowerCase() === email.toLowerCase());

    const stringifiedParams = queryString.stringify({
        client_id: "231769454512502",
        redirect_uri: `${window.location.origin}${routePaths.AFTER_FB_LOGIN}`,
        scope: ["pages_show_list", "pages_manage_metadata", "pages_messaging"].join(','), // comma seperated string
        response_type: 'token',
        auth_type: 'rerequest',
    });

    const facebookLoginUrl = `https://www.facebook.com/v9.0/dialog/oauth?${stringifiedParams}`;

    const { loading: fetchConnectedFbPageLoading, error, data, refetch: getConnectedFbPageRefetch } = useQuery(
        queryCreators.GET_CONNECTED_FB_PAGE.query,
        {
            variables: {
                eventId: eventID,
            },
            onCompleted: (data) => {
                const connectionStatus = _get(data, "event.connectedFbPage.connectionStatus");
                setFbPageConnectionStatus(connectionStatus);
            }
        }
    );

    const connectedFbPage = _get(data, "event.connectedFbPage", {});

    const [disconnectFbPage, { loading: disconnectingPageLoading }] = useMutation(queryCreators.DISCONNECT_FB_PAGE.mutation, {
        onCompleted: () => {
            if (fbPageConnectionStatus === NEEDS_RECONNECT) {
                sessionStorage.setItem("fbLoginEventId", eventID); // to be able to fetch it later after finishing logging in
                window.open(facebookLoginUrl, "_self");
                setFbPageConnectionStatus(undefined);
            }
            showGlobalSnackbar(t("disconnectedSuccessfully"))
        },
        onError: (error) => {
            const errorCode = _get(error, "graphQLErrors[0].extensions.code.errorCode");
            switch (errorCode) {
                case "NO_PAGE_TO_DISCONNECT":
                    showGlobalSnackbar(t("cannotDisconnectNonExistingPage"), "error");
                    getConnectedFbPageRefetch();
                    break;
                case "REQUEST_LIMIT_EXCEEDED":
                    showGlobalSnackbar(t("tryLater"), "error");
                    break;

                default:
                    showGlobalSnackbar(t("errorAtDisconnect"), "error");
                    break;
            }
        }
    });
    const disconnectFbPageHandler = async () => {
        try {
            await queryCreators.DISCONNECT_FB_PAGE.mutate(disconnectFbPage)(
                {
                    eventId: eventID
                },
                {
                    eventId: eventID,
                }
            );

        } catch (error) {
            showGlobalSnackbar(t("errorAtDisconnect"), "error");
            return;
        }
    }
    if (error) onFbConnectedPageError(error);

    return (
        <div className={classes.body}>
            {
                fetchConnectedFbPageLoading ?
                    <CircularProgress
                        thickness={7}
                        size={24}
                        className={classes.circularProgress}
                    /> :
                    (
                        _get(connectedFbPage, "pageId") ?
                            <Fragment>
                                <div
                                    className={classes.connectedFbPageContainer}
                                    key={connectedFbPage.pageId}
                                >
                                    <div className={classes.connectedFbPageData}>
                                        <img
                                            className={classes.connectedFbPageImage}
                                            src={`https://graph.facebook.com/${connectedFbPage.pageId}/picture?height=100&width=100`}
                                            alt="connect-page"
                                        />
                                        <Typography
                                            noWrap
                                            className={classes.connectedFbPageName}
                                        >
                                            {connectedFbPage.name}
                                        </Typography>
                                    </div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={disconnectingPageLoading || connectedFbPage.connectionStatus === UNKNOWN}
                                        onClick={disconnectFbPageHandler}
                                    >
                                        {
                                            connectedFbPage.connectionStatus === NEEDS_RECONNECT ?
                                                t("reconnect") :
                                                connectedFbPage.connectionStatus === UNKNOWN ?
                                                    `${t("loading")}...` :
                                                    t("disconnectFbPage")
                                        }
                                        {
                                            disconnectingPageLoading &&
                                            <CircularProgress
                                                thickness={7}
                                                size={24}
                                                className={classes.circularProgressWithColor}
                                            />
                                        }
                                    </Button>

                                </div>
                                {
                                    connectedFbPage.connectionStatus === NEEDS_RECONNECT &&
                                    <Alert severity="error">{t("pageNeedsReconnect")}</Alert>
                                }
                            </Fragment>
                            :
                            <div className={classes.loginContainer}>
                                <Tooltip
                                    disableFocusListener={isLoggedInUserWhiteListed}
                                    disableHoverListener={isLoggedInUserWhiteListed}
                                    disableTouchListener={isLoggedInUserWhiteListed}
                                    title={t("cannotConnectToFB")}
                                    placement="top"
                                >
                                    <div>
                                        <Button
                                            variant="contained"
                                            disabled={!isLoggedInUserWhiteListed || loginButtonLoading}
                                            style={{
                                                color: "#ffffff",
                                                backgroundColor: "#1877F2",
                                                textTransform: "none"
                                            }}
                                            onClick={() => {
                                                setLoginButtonLoading(true);
                                                getConnectedFbPageRefetch().then(response => {
                                                    if (!_get(response, "data.event.connectedFbPage.pageId") && !fetchConnectedFbPageLoading) {
                                                        sessionStorage.setItem("fbLoginEventId", eventID); // to be able to fetch it later after finishing logging in
                                                        window.open(facebookLoginUrl, "_self")
                                                    }
                                                    setLoginButtonLoading(false);
                                                }).catch(error => {
                                                    showGlobalSnackbar(t("tryLater"), "error");
                                                    setLoginButtonLoading(false);
                                                });

                                            }}
                                        >
                                            <FacebookCircularIcon className={classes.fbIcon} /> {t("connectWithFacebook")}
                                            {
                                                loginButtonLoading &&
                                                <CircularProgress
                                                    thickness={7}
                                                    size={24}
                                                    className={classes.circularProgressWithColor}
                                                />
                                            }
                                        </Button>
                                    </div>
                                </Tooltip>
                            </div>
                    )
            }
        </div >
    )
}
export default withRouter(FbConnectedPage);