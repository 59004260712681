export const en = {
  translation: {
    backToHome: "Back to home",
    home: "home",
    errorOccurred: "An error has occurred",
    requiredField: "This field is required",
    invalidEmail: "Email is invalid",
    save: "Save",
    no: "No",
    yes: "Yes",
  },
  header: {
    login: "Login",
    pricing: "Pricing"
  },
  loginPage: {
    createAccount: "Create account",
    login: "Login",
    organizer: "Organizer",
    exhibitor: "Exhibitor",
    email: "Email",
    password: "Password",
    downloadExhibitorApp: "Download Mobile App",
    downloadExhAppEnjoy: "Start by downloading the app from one of the links below, and enjoy using Eventsquare!",
    getFromGooglePlay: "Get it on Google Play",
    downloadOnAppStore: "Download on the App Store",
    invalidData: "Invalid email or password",
    notVerifiedEmail: "Email is not verified, check your email for verification link",
    networkError: "Network error! Check your internet connection",
  },
  eventsPage: {
    events: "Events",
    createEvent: "Create Event",
    createdOn: "Created on",
    noEvents: "No Events",
    eventCreated: "Event created successfully",
    eventFailed: "Failed to create event",
    cancel: "Cancel",
    account: "Account",
    logout: "Logout",
  },
  accountSettings: {
    account: "Account",
    basicInfo: "Basic Info",
    update: "update",
    successfulUpdate: "Account was updated successfully",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    currentPassword: "Current Password",
    newPassword: "New Password",
    tooShortPassword: "Password has to be at least 6 characters",
    incorrectCurrentPassword: "Current password is incorrect",
    currentPasswordNotProvided: "Current password is not provided",
    passwordTooShort: "New password is too short",
    cantUpdateAccount: "Can't update account",
    cancel: "Cancel",
    change: "Change",
    password: "Password",
    newPasswordCantBeEmpty: "New password can't be empty",
  },
  eventPage: {
    dashboard: "Dashboard",
    registrations: "Registrations",
    exhibitors: "Exhibitors",
    nameBadges: "Name Badges",
    registrationForm: "Registration Form",
    errorOccurred: "An error has occurred",
  },
  createEventForm: {
    name: "Name",
    startDate: "Start Date",
    endDate: "End Date",
    eventLanguages: "Event Languages",
    chooseThreeLanguages: "Select at most three languages",
    betweenThreeAndOne: "Can't select more than three languages nor less than one",
    create: "Create",
    ok: "OK",
    cancel: "CANCEL",
    noOptions: "No Options",
    defaultLanguage: "default",
  },
  dashboardView: {
    dashboard: "Dashboard",
    visitors: "Visitors",
    visits: "Visits",
    registrations: "Registrations",
    leadCaptures: "Lead captures",
    exhibitors: "Exhibitors",
    team: "Team",
    leadsCapturedByExhibitors: "Leads captured by exhibitors",
    noVisits: "No visits",
    noRegistrations: "No registrations",
    registrationForm: "Registration Form",
    currentVisitors: "Current visitors",
    registrantsLanguages: "Registrants' Languages",
    averageVisitorScans: "Average visitor scans",
    averageVisitorsRating: "Average visitors rating",
    averageVisitTime: "Average visit time",
    hours: "hours",
    couldntFetchLanguages: "Couldn't fetch event's languages",
  },
  registrationsView: {
    registrations: "Registrations",
    email: "Email",
    phone: "Phone",
    title: "Title",
    name: "Name",
    id: "Id",
    search: "Search",
    rowsPerPage: "Rows per page",
    noRegistrations: "No registrations",
    sendEmails: "Send e-mails",
    sendSMS: "Send SMS",
    closeRegistration: "Close online registration",
    openRegistration: "Open online registration",
    noRegistrationForm: "There's no registration form for this event yet.",
    navigateToRegistrationForm: "Add registration form",
    eventRegistrationID: "Registration id",
    closeRegistrationFailed: "Failed to close online registration",
    openRegistrationFailed: "Failed to open online registration",
    checkIn: "Check-in",
    checkout: "Checkout",
    print: "Print",
    checkinSuccess: "Registrant checked in successfully",
    checkinFailure: "Failed to check-in registrant",
    checkoutSuccess: "Registrant checked out successfully",
    checkoutFailure: "Failed to checkout registrant",
    copied: "Copied",
    registrationLink: "Registration link",
    printQrCode: "Print QR code",
    noBadgesToPrint: "No badge templates to print",
    printRegistrantsBadge: "Print badge",
    searchRegistrantsByQrCode: "Search registrants by their QR code",
    addedBy: "Added by",
    alreadyCheckedin: "Registrant is already checked in",
    alreadyCheckedout: "Failed to check out. Registrant is not checked in",
    needsCheckinFirst: "Registrant needs to check in first",
    registrantSelfActions: "Registrant's self actions",
    checkOutAllRegistrants: "Check-out all visitors",
    allCheckedInAreCheckedOut: "All checked-in visitors are checked-out successfully",
    failedToCheckOutAll: "Failed to checked-out all checked-in visitors",
    noCheckedInVisitors: "No visitors checked-in currently",
  },
  registrationView: {
    registrationDetails: "Registration details",
    basicInfo: "Basic info",
    checksHistory: "Checks history",
    printBadge: "Print badge",
    notProvided: "Not provided",
    exhibitorsInteractions: "Interactions with exhibitors",
    checkIn: "Check in",
    checkOut: "Check out",
    checkinSuccess: "Registrant checked in successfully",
    checkinFailure: "Failed to check-in registant",
    checkoutSuccess: "Registrant checked out successfully",
    checkoutFailure: "Failed to checkout registrant",
    checked: "Checked",
    in: "in",
    out: "out",
    emptyChecks: "Did not check-in/out yet",
    registrantNotFound: "Registrant not found",
    hours: "hours",
    minutes: "minutes",
    alreadyCheckedin: "Registrant is already checked in",
    alreadyCheckedout: "Failed to check out. Registrant is not checked in",
    needsCheckinFirst: "Registrant needs to check in first",
  },
  pageNotFound: {
    pageNotFound: "Page not found",
  },
  registerPage: {
    register: "Register",
    firstName: "First name",
    lastName: "Last name",
    email: "Email",
    password: "Password",
    mobileNumber: "Mobile number",
    login: "Login",
    verifyPassword: "Verify Password",
    firstNameRequired: "First name is required",
    lastNameRequired: "Last name is required",
    invalidEmail: "Email is invalid",
    requiredEmail: "Email is required",
    tooShortPassword: "Password must be at least 6 characters",
    requiredPassword: "Password is required",
    matchPasswordField: "This field has to match password field",
    invalidMobileNumber: "Invalid mobile number",
    requiredMobileNumber: "Mobile number is required",
    country: "Country",
    errorOccurred: "An error has occurred",
    noLongerAvailable: "Invitation is no longer available",
    accountAlreadyExist: "Account with the same email already exists"
  },
  verifyPage: {
    accountVerifiedSuccessfully: "Account verified successfully",
    verifying: "Verifying...",
    invalidToken: "Invalid token",
    verificationMailWasResent: "Verification mail was resent",
    checkYourMail: "Please check your email",
    accountAlreadyVerified: "Account was already verified",
    login: "Login",
    failedToVerify: "Failed to verify invalid email",
    register: "Register",
    invalidVerificationLink: "Invalid verification link!",
  },
  exhibitorsView: {
    exhibitors: "Exhibitors",
    name: "Name",
    email: "Email",
    invitation: "Invitation",
    noMembers: "No Members",
    membersCount: "{{count}} member",
    membersCount_plural: "{{count}} members",
    add: "Add",
    activated: "Activated",
    delete: "Delete",
    inactive: "Inactive",
    active: "Active",
    accepted: "Accepted",
    pending: "Pending",
    cantAddExhibitor: "Exhibitor could not be added",
    alreadySentInv: "Invitation was already sent before",
    alreadyAddedExhibitor: "Exhibitor was already added before",
    cantDeleteExhibitor: "Exhibitor could not be deleted",
    invAlreadyAccepted: "Invitation was already accepted",
    cantDeleteInvitation: "Invitation could not be deleted",
    pleaseAddExhibitor: "No exhibitors, please add one",
    printBadge: "Print Badge",
    noBadgesToPrint: "No badge templates to print",
    cantDeleteActivatedExhibitor: "Can't delete activated exhibitor"
  },
  createForm: {
    createForm: "Create Form",
    reset: "Reset",
    name: "Name",
    email: "Email",
    phone: "Phone",
    interests: "Interests",
    firstInterest: "First Interest",
    secondInterest: "Second Interest",
    cantHideName: "You can't hide the Name field",
    cantHideEmailAndPhone: "You can't hide both of Email and Phone fields",
    hide: "Hide",
    show: "Show",
    delete: "Delete",
    required: "Required",
    unique: "Unique",
    placeholder: "Placeholder",
    description: "Description",
    label: "Label",
    type: "Type",
    text: "Text",
    options: "Options",
    textarea: "Textarea",
    checkbox: "Checkbox",
    multiCheckbox: "Multi checkbox",
    imagePicker: "Image picker",
    maxFileSize: "Maximum File Size (MB)",
    select: "Select",
    formCreated: "Form was created successfully",
    formUpdated: "Form was updated successfully",
    formCreateFailed: "Failed to create form",
    formUpdateFailed: "Failed to update form",
    notParseable: "Form contains unsupported field types",
    notModifiable: "Form is not modifiable",
    addOption: "Add option",
    labelRequired: "Label is required",
    optionsRequired: "Options are required",
    labelExists: "Another input with the same label already exists",
    noForm: "You didn't create a registration form yet",
    addForm: "Start building registration form",
    firstValue: "First value",
    secondValue: "Second value",
    translations: "Translations",
  },
  templateBuilder: {
    addText: "Add text here",
    zoomSliderFit: "Fit",
    zoomSliderFill: "Fill",
    badgeNotFound: "Badge not found",
  },
  templatesPage: {
    pageTitle: "Name badges",
    createModalTitle: "Create Name Badge",
    height: "Height",
    width: "Width",
    badgeName: "Badge name",
    unit: "Unit",
    cm: "Cm",
    inch: "Inch",
    create: "Create",
    failedDeleteError: "Failed to delete badge!",
    faildCreateError: "Failed to create badge!",
    emptyBadge: "Empty Badge",
    cancel: "Cancel",
    failedToLoadData: "Failed to fetch relevant printing data",
    pleaseAddBadges: "No badges, please add one"
  },
  elementSettingsPanel: {
    panelTitle: "Element settings",
    textLabel: "Text",
    textPlaceHolder: "Type '@' to insert values e.g. 'registrant name'",
    alignment: "Alignment",
    color: "Color",
    fontSize: "Font size",
    imageUrl: "Image URL",
    qRContent: "QR code content",
    qRCType: "QR code type",
    imageMode: "Image resizing mode",
    fontFamily: "Font Family",
    registrationQrCode: "Registration QR code",
    custom: 'Custom',
    fill: "Fill",
    contain: "Contain",
    cover: "Cover",
  },
  eventSettingsView: {
    settings: "Settings",
    basicSettings: "Basic Settings",
    connectedFbPage: "Connected Facebook Page",
    theme: "Theme",
    beforeMinimalDate: "Date should not be before minimal date",
    failedUpdatingError: "Failed to update event!",
    eventUpdated: "Event was updated successfully",
    brandColor: "Brand color",
    allowRestrictCheckMode: "Strict check-in/out mode",
    strictModeDescription: "If this option is checked registrants wont be able to check in/out twice consecutively",
  },
  logoPicker: {
    tooLargeImage: "Too large file, maximum file size {{maxMBSize}} MB",
    unsupportedFile: "Unsupported file type, please select an image",
  },
  eventEngagementView: {
    engagement: "Engagement",
    automatedMessages: "Automated Messages",
    announcements: "Announcements",
    announce: "Announce",
    checkTranslation: "Check Translation",
    back: "back",
    cantTranslate: "Error happened while translating your message",
    eventSummary: "Event Summary",
    exhibitorContacts: "Exhibitor Contacts",
    checkinGreeting: "Checkin Greeting",
    registrationConfirmation: "Registration Confirmation",
    emptyHistory: "Empty Announcements History",
    newAnnouncement: "New Announcement",
    announcementsLeft: "Announcements Left",
    limitExceeded: "You have exceeded the maximum message length",
    read: "Read",
    sent: "Sent",
    sendTo: "Send to",
    messageTarget: "Message Target",
    messengerOnly: "Messenger only",
    emailOnly: "Email only",
    messengerAndEmail: "Messenger and Email",
    messengerOrEmail: "Messenger or Email",
    chooseOneTarget: "You have to choose at least one target to send to",
    faildCreateError: "Failed to create announcement",
    faildToChangeBehavior: "Failed to update the automated message",
    sendRegistrationMessage: "Sends a registration information message for registrants",
    sendCheckInMessage: "Sends a greeting message for visitors on checking in",
    sendVisitorScanMessage: "Sends exhibitor information for the visitor after their interaction (exhibitor adds the visitor as a lead)",
    sendEventSummaryMessage: "Sends an email for the visitors with the summary of their interactions with exhibitors and their contacts",
    checkedInOnly: "Checked in registrants only",
    customizeMessages: "Customize Messages",
    reset: "Reset",
    customizeGreetingMessage: "Customize the greeting message sent to registrants on check in",
    translationPreview: "Translation Preview",
    cantAddMultipleAttachments: "Can't add more than one attachment",
    settingsSaved: "Settings saved successfully",
    fbIsNotConnected: "Facebook is not connected",
    fbIsNotConnectedErrorMessage: "Facebook page is not connected or needs a reconnect. Messages won't be sent via Facebook till you fix this issue",
    reviewYourTranslation: "Review your message translations and modify them if needed",
    addACustomMessage: "To enable this feature you have to add a custom message",
  },
  printingPreviewModal: {
    cancel: 'Cancel',
    pickTemplate: 'Pick a badge template',
    printNameBadge: 'Print Name Badge',
    print: 'Print',
    confirmationDialogTitle: 'Missing Badge Data',
    confirmationDialogText: 'Some badge data is missing, proceed to print?',
    badgeInfo: 'Badge Info',
    preview: 'Preview'
  },
  contextData: {
    target: {
      name: "Target name",
      id: "Target id",
    },
    event: {
      id: 'Event id',
      name: 'Event name',
      logoURL: 'Event logo url',
      startDate: 'Event start date',
      endDate: 'Event end date',
      createdAt: 'Event creation date'
    },
    registrant: {
      id: 'Registrant id',
      name: 'Registrant name',
      title: 'Registrant title',
      email: 'Registrant email',
      phone: 'Registrant phone'
    },
    exhibitor: {
      id: 'Exhibitor id',
      name: 'Exhibitor name',
      email: 'Exhibitor email'
    },
    organizer: {
      id: 'Organizer id',
      fullName: "Organizer name",
      firstName: "Organizer first name",
      lastName: "Organizer last name",
    },
  },
  shapeTemplateSettings: {
    backgroundFill: "Fill",
    borderColor: "Border color",
    borderSize: "Border size",
    cornersRadius: "Corners radius"
  },
  errorBoundry: {
    somethingWentWrong: "Something went wrong",
  },
  invitationPage: {
    noLongerAvailable: "Invitation is no longer available",
    alreadyAccepted: "Invitation was already accepted",
    login: "Login",
    errorOccurred: "An error has occurred",
    alreadyAddedExhibitor: "Exhibitor was already added before",
  },
  eventMembersView: {
    team: "Team",
    pleaseAddMembers: "No members, please add one",
    alreadyAddedMember: "Member was already added before",
    alreadySentInv: "Invitation was already sent before",
    cantAddMember: "Member could not be added",
    name: "Name",
    email: "Email",
    invitation: "Invitation",
    add: "Add",
    cantDeleteMember: "Member could not be deleted",
    cantDeleteInvitation: "Invitation could not be deleted",
    accepted: "Accepted",
    pending: "Pending",
    owner: "Owner",
    delete: "Delete",
    printBadge: "Print Badge",
    noBadgesToPrint: "No badge templates to print",
    leave: "Leave",
    member: "Member",
    role: "Role",
    you: "You",
    cantDeleteOwner: "Can't delete owner",
    ownerCantLeave: "Owner Can't Leave",
    invitationNotAccepted: "Invitation is not accepted yet",
    invAlreadyAccepted: "Invitation was already accepted",
    noLongerMember: "User is no longer a member",
  },
  AuthErrors: {
    sessionTimedOut: "Session timeout, please login again",
    provideToken: "A valid token must be provided",
    permissionDenied: "Permission denied",
  },
  QRCodeScannerModal: {
    searchByQrCode: "Search by QR code",
    scanError: "An error has occurred. Please make sure Camera permission is set to allowed",
  },
  FbConnectedPage: {
    connectWithFacebook: "Connect with Facebook",
    disconnectFbPage: "Disconnect page",
    loading: "Loading",
    reconnect: "reconnect",
    pageNeedsReconnect: "Page is not connected properly. Please reconnect",
    cannotDisconnectNonExistingPage: "Can't disconnect a nonexistent page",
    tryLater: "Please try after a couple of minutes",
    errorAtDisconnect: "Something went wrong while trying to disconnect",
    disconnectedSuccessfully: "Disconnected successfully",
    cannotConnectToFB: "Cannot connect to Facebook. Please contact an administrator",
  },
  FbLoginModal: {
    fbPagesList: "Login with Facebook",
    connect: "Connect",
    noPagesToList: "You have no pages to list",
    cancel: "Cancel",
    contactPageAdmin: "Only Facebook page admins can connect this event to a page. Please ask page admin to connect this page",
    fbPageAlreadyConnected: "This event is already connected to a facebook page",
    errorAtListing: "Something went wrong while trying to list your Facebook pages",
    connectedSuccessfully: "Connected successfully",
    tryLater: "Please try after a couple of minutes",
    errorAtConnection: "Something went wrong while trying to connect",
  },
  facebookMessageTypes: {
    fbMessageType: "Facebook Message Type",
    selectType: "Select a type",
    choosingContentType: "Choosing A Content Type",
    misuseContentTypeMaySuspendPageOrBot: "Using Content Type for any reason outside of their defined use cases may result in your bot or page being blocked",
    messagesSentMoreThanADayAfterInteraction: "Any message sent more than 24 hours after a contact’s last interaction must have a Content Type",
    promotionsCanBeSentByOtn: "Promotions (sales, coupons, discounts, etc.) can only be sent using “Other” or “One-Time Notifications” (OTNs)",
    sendingMessagesThatDonotMatchContentType: "Sending messages that do not match their content type may result in your page being suspended by Facebook",
    learnMoreHere: "Learn more here",
    postPurchaseUpdate: "Post-Purchase Update",
    tagsMisuseMaySuspendPage: "Misuse of tags may result in your page being suspended by Facebook",
    notifyUserOfPurchase: "Notify the user of an update on a recent purchase. For example",
    invoicesOrReceipts: "Invoices or receipts",
    notificationOfShipmentStatus: "Notifications of shipment status",
    changesRelatedToOrder: "Changes related to an order that the user placed",
    forMoreInformationCheckOfficial: "For more information check official",
    facebookArticle: "Facebook article",
    confirmedEventUpdate: "Confirmed Event Update",
    updateContactsAboutEventTheyRegisteredFor: "Update contacts regarding the event they have registered for. For example",
    reminderOfEventOrAppointment: "Reminder of an event or appointment",
    confirmReservationOrAttendance: "Confirmation of reservation or attendance",
    transportationScheduleUpdates: "Transportation schedule updates",
    accountUpdate: "Account Update",
    notifyUseOfNonrecurringChange: "Notify the user of a non-recurring change to their application or account",
  },
  AfterFbLoginRedirect: {
    errorOccurred: "An error has occurred",
  },
  formTranslations: {
    formTranslations: "Form Translations",
    defaultLanguage: "Default Language",
    formTranslationsUpdated: "Form translations was updated successfully",
    translations: "{{language}} translations",
  },
  connectFacebookModal: {
    connectFacebookPage: "Connect Facebook Page",
    noFacebookPageConnected: "You do not have any Facebook page connected to this event. Would you like to connect one?",
  },
  registrationsScansView: {
    search: "Search",
    searchRegistrantsByQrCode: "Search registrants by their QR code",
    scanError: "An error has occurred. Please make sure Camera permission is set to allowed",
    printBadge: "Print badge",
    checkIn: "Check-in",
    checkOut: "Check-out",
    viewDetails: "View Details",
    registrantDetails: "Registrant details",
    chooseActionsToPerform: "Choose actions to perform",
    notProvided: "Not provided",
    belongsToAnotherEvent: "Registrant belongs to another event",
    checkinSuccess: "Registrant checked in successfully",
    checkinFailure: "Failed to check-in registrant",
    checkoutSuccess: "Registrant checked out successfully",
    checkoutFailure: "Failed to checkout registrant",
    pickTemplate: "Pick a badge template",
    noBadgesToPrint: "No badges to print",
    typeCorrectData: "Please make sure you type a full email, id or phone number",
    noMatches: "No matches",
    qrCodeWasScannedBefore: "This QR code was scanned before. Do you want to scan it one more time?",
    cancel: "Cancel",
    alreadyCheckedin: "Registrant is already checked in",
    alreadyCheckedout: "Failed to check out. Registrant is not checked in",
    registrantNotFound: "Registrant not found",
    needsCheckinFirst: "Registrant needs to check in first",
    exhibitorChecked: "Exhibitor checked successfully",
  },
  registrationModal: {
    addRegistrant: "Add a New Registrant",
    add: "Add",
    registrantLanguage: "Registrant's Language",
    registrantAddSuccess: "New registrant added successfully",
    registrantAddFailure: "Failed to add a new registrant",
    cancel: "Cancel",
  },
  checkOutAllVisitorsModal: {
    checkOutAllVisitors: "Check-out all visitors",
    wouldYouLikeToProceed: "All checked-in visitors will be checked-out. Would you like to proceed?",
  },
};

export const ar = {
  translation: {
    backToHome: "عودة للصفحة الرئيسية",
    home: "الرئيسية",
    errorOccurred: "حدث خطأ",
    requiredField: "هذا الحقل مطلوب",
    invalidEmail: "البريد الإلكتروني غير صالح",
    save: "حفظ",
    no: "لا",
    yes: "نعم",
  },
  header: {
    login: "تسجيل الدخول",
    pricing: "الأسعار"
  },
  loginPage: {
    createAccount: "إنشاء حساب",
    login: "تسجيل الدخول",
    organizer: "منظم",
    exhibitor: "عارض",
    email: "البريد الإلكتروني",
    password: "كلمة المرور",
    downloadExhibitorApp: "تحميل تطبيق الهاتف",
    downloadExhAppEnjoy: "ابدأ بإستخدام تطبيق العارضين, قم بتحميل التطبيق من احد الروابط التالية:",
    getFromGooglePlay: "Google Play احصل عليه من",
    downloadOnAppStore: "تنزيل من App Store",
    invalidData: "يوجد خطأ بالبريد الإلكتروني او كلمة السر",
    notVerifiedEmail: "لم يتم تفعيل البريد الإلكتروني، تحقق من بريدك الإلكتروني للحصول على رابط التفعيل",
    networkError: "خطأ في الشبكة! تحقق من اتصالك بالإنترنت",
  },
  eventsPage: {
    events: "المعارض",
    createEvent: "إنشاء معرض",
    createdOn: "تاريخ الإنشاء",
    noEvents: "لا يوجد معارض",
    eventCreated: "تم إنشاء المعرض بنجاح",
    eventFailed: "حدث خطأ و لم يتم إنشاء المعرض",
    cancel: "إلغاء",
    account: "الحساب",
    logout: "تسجيل خروج",
  },
  accountSettings: {
    account: "الحساب",
    basicInfo: "معلومات أساسية",
    update: "تحديث",
    successfulUpdate: "تم تحديث الحساب بنجاح",
    firstName: "الاسم",
    lastName: "اسم العائلة",
    email: "البريد الإلكتروني",
    currentPassword: "كلمة المرور الحالية",
    newPassword: "كلمة المرور الجديدة",
    tooShortPassword: "يجب أن تتكون كلمة المرور من 6 أحرف على الأقل",
    incorrectCurrentPassword: "كلمة المرور الحالية غير صحيحة",
    currentPasswordNotProvided: "لم يتم توفير كلمة المرور الحالية",
    passwordTooShort: "كلمة المرور الجديدة قصيرة جدًا",
    cantUpdateAccount: "لم يتم تحديث الحساب",
    cancel: "إلغاء",
    change: "تغيير",
    password: "كلمة المرور",
    newPasswordCantBeEmpty: "لا يمكن أن تكون كلمة المرور الجديدة فارغة",
  },
  createEventForm: {
    name: "الاسم",
    startDate: "تاريخ البداية",
    endDate: "تاريخ النهاية",
    eventLanguages: "لغات المعرض",
    chooseThreeLanguages: "أختر ثلاث لغات على الأكثر",
    betweenThreeAndOne: "لا يمكن اختيار أكثر من ثلاث لغات ولا أقل من لغة واحدة",
    create: "انشاء",
    ok: "موافق",
    cancel: "إلغاء",
    noOptions: "لا يوجد خيارات",
    defaultLanguage: "الرئيسية",
  },
  eventPage: {
    dashboard: "لوحة المعلومات",
    registrations: "التسجيلات",
    exhibitors: "العارضين",
    nameBadges: "شارات الاسم",
    registrationForm: "استمارة التسجيل",
    errorOccurred: "حدث خطأ",
  },
  dashboardView: {
    dashboard: "لوحة المعلومات",
    visitors: "الزوار",
    visits: "الزيارات",
    registrations: "التسجيلات",
    leadCaptures: "العملاء المُحتملين",
    exhibitors: "العارضين",
    team: "الفريق",
    leadsCapturedByExhibitors: "العملاء المحتملين للعارضين",
    noVisits: "لا توجد زيارات",
    noRegistrations: "لا توجد تسجيلات",
    registrationForm: "استمارة التسجيل",
    currentVisitors: "الزوار الحاليين",
    registrantsLanguages: "لغات المسجلين",
    averageVisitorScans: "متوسط عمليات المسح للزائر",
    averageVisitorsRating: "متوسط تقييم الزوار",
    averageVisitTime: "متوسط وقت الزيارة",
    hours: "ساعات",
    couldntFetchLanguages: "تعذر جلب لغات المعرض",
  },
  registrationsView: {
    registrations: "التسجيلات",
    email: "البريد الإلكتروني",
    phone: "رقم الهاتف",
    title: "اللقب",
    name: "الاسم",
    id: "رقم التعريف",
    search: "بحث",
    rowsPerPage: "عدد الصفوف",
    noRegistrations: "لا توجد تسجيلات",
    sendEmails: "إرسال بريد إلكتروني",
    sendSMS: "إرسال رسائل نصية",
    closeRegistration: "غلق التسجيل عبر الإنترنت",
    openRegistration: "فتح التسجيل عبر الإنترنت",
    noRegistrationForm: "لا يوجد نموذج لهذا المعرض",
    navigateToRegistrationForm: "إضافة نموذج تسجيل",
    eventRegistrationID: "هوية التسجيل",
    closeRegistrationFailed: "حدث خطأ أثناء غلق التسجيل",
    openRegistrationFailed: "حدث خطأ أثناء فتح التسجيل",
    checkIn: "تسجيل الدخول",
    checkout: "تسجيل الخروج",
    print: "طباعة",
    checkinSuccess: "تم تسجيل الدخول بنجاح",
    checkinFailure: "حدث خطأ أثناء تسجيل الدخول",
    checkoutSuccess: "تم تسجيل الخروج بنجاح",
    checkoutFailure: "حدث خطأ أثناء تسجيل الخروج",
    copied: "تم النسخ",
    registrationLink: "رابط التسجيل",
    printQrCode: "طباعة",
    noBadgesToPrint: "لا توجد شارات للطباعة",
    printRegistrantsBadge: "طباعة الشارة",
    searchRegistrantsByQrCode: "ابحث عن المسجلين بإستخدام رمز الاستجابة السريعة الخاص بهم",
    addedBy: "أضيف بواسطة",
    alreadyCheckedin: "تم تسجيل الدخول للمسجل بالفعل",
    alreadyCheckedout: "فشل تسجيل الخروج. المسجل لم يسجل الدخول",
    needsCheckinFirst: "يحتاج المسجل إلى تسجيل الدخول أولاً",
    registrantSelfActions: "الإجرائات الذاتية للمسجل",
    checkOutAllRegistrants: "تسجيل الخروج لجميع الزوار",
    allCheckedInAreCheckedOut: "تم تسجيل خروج جميع الزوار بنجاح",
    failedToCheckOutAll: "فشل تسجيل الخروج لجميع الزوار",
    noCheckedInVisitors: "لا يوجد زوار مُسجلين الدخول حالياً",
  },
  registrationView: {
    registrationDetails: "تفاصيل التسجيل",
    basicInfo: "معلومات أساسية",
    checksHistory: "تسجيلات الدخول و الخروج",
    printBadge: "طباعة الشارة",
    notProvided: "غير متوفر",
    exhibitorsInteractions: "التفاعلات مع العارضين",
    checkIn: "تسجيل الدخول",
    checkOut: "تسجيل الخروج",
    checkinSuccess: "تم تسجيل الدخول بنجاح",
    checkinFailure: "حدث خطأ أثناء تسجيل الدخول",
    checkoutSuccess: "تم تسجيل الخروج بنجاح",
    checkoutFailure: "حدث خطأ أثناء تسجيل الخروج",
    checked: "سجل",
    in: "الدخول",
    out: "الخروج",
    emptyChecks: "لم يُسجِل الدخول/الخروج بعد",
    registrantNotFound: "لم يتم العثور علي المُسجِل",
    hours: "ساعات",
    minutes: "دقائق",
    alreadyCheckedin: "تم تسجيل الدخول للمسجل بالفعل",
    alreadyCheckedout: "فشل تسجيل الخروج. المسجل لم يسجل الدخول",
    needsCheckinFirst: "يحتاج المسجل إلى تسجيل الدخول أولاً",
  },
  pageNotFound: {
    pageNotFound: "الصفحة غير متوفرة",
  },
  registerPage: {
    register: "التسجيل",
    firstName: "الاسم",
    lastName: "اسم العائلة",
    email: "البريد الإلكتروني",
    password: "كلمة المرور",
    mobileNumber: "الهاتف المحمول",
    login: "تسجيل الدخول",
    verifyPassword: "اكد كلمة المرور",
    firstNameRequired: "الإسم الأول مطلوب",
    lastNameRequired: "إسم العائلة مطلوب",
    invalidEmail: "البريد الإلكتروني غير صالح",
    requiredEmail: "البريد الالكتروني مطلوب",
    tooShortPassword: "يجب أن تتكون كلمة المرور من 6 أحرف على الأقل",
    requiredPassword: "كلمة المرور مطلوبة",
    matchPasswordField: "يجب أن يتطابق هذا الحقل مع حقل كلمة المرور",
    invalidMobileNumber: "رقم الهاتف المحمول غير صالح",
    requiredMobileNumber: "رقم الهاتف مطلوب",
    country: "البلد",
    errorOccurred: "حدث خطأ",
    noLongerAvailable: "الدعوة لم تعد متاحة",
    accountAlreadyExist: "يوجد حساب بهذا البريد اللإكتروني"
  },
  verifyPage: {
    accountVerifiedSuccessfully: "تم التفعيل بنجاح",
    verifying: "جاري التفعيل...",
    invalidToken: "الرمز خاطئ",
    verificationMailWasResent: "تم إعادة ارسال بريد التفعيل",
    checkYourMail: "برجاء تفقّد بريدك الإلكتروني",
    accountAlreadyVerified: "تم تفعيل الحساب مسبقاً",
    login: "تسجيل الدخول",
    failedToVerify: "حدث خطأ في التفعيل",
    register: "التسجيل",
    invalidVerificationLink: "رابط التحقق خاطئ!",
  },
  exhibitorsView: {
    exhibitors: "العارضين",
    name: "الاسم",
    email: "البريد الإلكتروني",
    invitation: "الدعوة",
    noMembers: "لا يوجد أعضاء",
    membersCount: "{{count}} اعضاء",
    add: "إضافة",
    activated: "مُفعَّل",
    delete: "مسح",
    inactive: "غير مُفعَّل",
    active: "مُفعَّل",
    accepted: "قُبلت",
    pending: "قيد الإنتظار",
    cantAddExhibitor: "حدث خطأ أثناءإضافة العارض",
    alreadySentInv: "تم إرسال الدعوة بالفعل من قبل",
    alreadyAddedExhibitor: "تم إضافة العارض من قبل",
    cantDeleteExhibitor: "حدث خطأ أثناء حذف العارض",
    invAlreadyAccepted: "لقد تم قبول الدعوة",
    cantDeleteInvitation: "حدث خطأ أثناء حذف الدعوة",
    pleaseAddExhibitor: "لا يوجد عارضين, قم بإضافة عارض",
    printBadge: "طباعة الشارة",
    noBadgesToPrint: "لا توجد شارات للطباعة",
    cantDeleteActivatedExhibitor: "لا يمكن مسح عارض مُفعَّل"
  },
  createForm: {
    createForm: "إنشاء استمارة",
    reset: "إعادة تعيين",
    name: "الاسم",
    email: "البريد الإلكتروني",
    phone: "رقم الهاتف",
    interests: "الاهتمامات",
    firstInterest: "الاهتمام الاول",
    secondInterest: "الاهتمام الثاني",
    cantHideName: "لا يمكن اخفاء الاسم",
    cantHideEmailAndPhone: "لا يمكن اخفاء البريد الإلكتروني و رقم الهاتف",
    hide: "اخفاء",
    show: "اظهار",
    delete: "مسح",
    required: "مطلوب",
    unique: "فريد",
    placeholder: "نائب",
    description: "وصف",
    label: "عنوان الحقل",
    type: "النوع",
    text: "نص",
    options: "خيارات",
    textarea: "منطقة النص",
    checkbox: "مربع تأشير",
    multiCheckbox: "مربع تأشير متعدد",
    imagePicker: "منتقي الصور",
    maxFileSize: "الحد الأقصى لحجم الملف (ميغا بايت)",
    select: "اختيار",
    formCreated: "تم إنشاء النموذج بنجاح",
    formUpdated: "تم تعديل النموذج بنجاح",
    formCreateFailed: "حدث خطأ و لم يتم إنشاء النموذج",
    formUpdateFailed: "حدث خطأ و لم يتم تعديل النموذج",
    notParseable: "يوجد بالاستمارة انواع حقول غير مدعومة",
    notModifiable: "الاستمارة غير قابلة للتعديل",
    addOption: "إضافة خيار",
    labelRequired: "عنوان الحقل مطلوب",
    optionsRequired: "الخيارات مطلوبة",
    labelExists: "يوجد حقل آخر بنفس العنوان",
    noForm: "لم يتم إنشاء استمارة التسجيل بعد",
    addForm: "البدأ في إنشاء استمارة التسجيل",
    firstValue: "القيمة الاولى",
    secondValue: "القيمة الثانية",
    translations: "الترجمات",
  },
  templateBuilder: {
    addText: "يمكنك إضافة نص هنا",
    zoomSliderFit: "ملائم",
    zoomSliderFill: "ملئ",
    badgeNotFound: "لم يتم العثور على الشارة",
  },
  templatesPage: {
    pageTitle: "شارات الاسم",
    createModalTitle: "إنشاء الشارة",
    height: "الطول",
    width: "العرض",
    badgeName: "اسم الشارة",
    unit: "الوحدة",
    cm: "سم",
    inch: "بوصة",
    create: "إنشاء",
    failedDeleteError: "حدث خطأ و لم يتم حذف الشارة",
    faildCreateError: "حدث خطأ و لم يتم إنشاء الشارة",
    emptyBadge: "شارة فارغة",
    cancel: "إلغاء",
    failedToLoadData: "حدث خطأ في جلب البيانات اللازمة للطباعة ",
    pleaseAddBadges: "لا توجد شارات, قم بإضافة شارة"
  },
  elementSettingsPanel: {
    panelTitle: "الإعدادات",
    textLabel: "النص",
    textPlaceHolder: "اكتب '@' لإدخال قيمة. مثلا 'اسم المسجل'",
    alignment: "المحاذاة",
    color: "اللون",
    fontSize: "حجم الخط",
    imageUrl: "رابط الصورة",
    qRContent: "محتوى رمز الاستجابة السريعة",
    qRCType: "نوع رمز الاستجابة السريعة",
    imageMode: "تغيير وضع حجم الصورة",
    fontFamily: "الخط",
    registrationQrCode: "رمز الاستجابة السريعة للمُسجِّل",
    custom: 'مخصص',
    fill: "ملئ",
    contain: "احتواء",
    cover: "تغطية",
  },
  eventSettingsView: {
    settings: "الإعدادات",
    basicSettings: "الإعدادات الأساسية",
    connectedFbPage: "صفحة الفيسبوك المتصلة",
    theme: "السمة",
    beforeMinimalDate: "يجب ألا يكون التاريخ قبل الحد الأدنى ",
    failedUpdatingError: "حدث خطأ و لم يتم تحديث المعرض",
    eventUpdated: "تم تحديث المعرض بنجاح",
    brandColor: "لون العلامة التجارية",
    allowRestrictCheckMode: "تقييد تسجيل الوصول/المغادرة المتتالي",
    strictModeDescription: "إذا تم تحديد هذا الخيار, لن يسمح بتسجيل دخول الزوار إذا كانوا بداخل المعرض ولن يسمح أيضًا بتسجيل خروجهم إذا كانوا خارج المعرض.",
  },
  logoPicker: {
    tooLargeImage: "ملف كبير جدًا ، الحد الأقصى لحجم الملف {{maxMBSize}} ميجابايت",
    unsupportedFile: "نوع ملف غير مدعوم، يرجى اختيار صورة",
  },
  eventEngagementView: {
    engagement: "التفاعل",
    automatedMessages: "الرسائل الآلية",
    announcements: "الإعلانات",
    announce: "اعلن",
    checkTranslation: "تحقق من الترجمة",
    back: "رجوع",
    cantTranslate: "حدث خطأ أثناء ترجمة رسالتك",
    eventSummary: "ملخص المعرض",
    exhibitorContacts: "الإتصالات مع العارضين",
    checkinGreeting: "تحية تسجيل الدخول",
    registrationConfirmation: "تأكيد التسجيل",
    emptyHistory: "سجل الإعلانات فارغة",
    newAnnouncement: "إعلان جديد",
    announcementsLeft: "اعلانات متبقية",
    limitExceeded: "لقد تجاوزت الحد الأقصى لطول الرسالة",
    read: "قُرِأت",
    sent: "أُرسلت",
    sendTo: "إرسل إلى",
    messageTarget: "هدف الرسالة",
    messengerOnly: "الماسنجر فقط",
    emailOnly: "البريد الإلكتروني فقط",
    messengerAndEmail: "الماسنجر و البريد الإلكتروني",
    messengerOrEmail: "الماسنجر او البريد الإلكتروني",
    chooseOneTarget: "يجب عليك اختيار هدف واحد على الأقل للإرسال إليه",
    faildCreateError: "حدث خطأ و لم يتم إنشاء الإعلان",
    faildToChangeBehavior: "حدث خطأ و لم يتم تحديث الرسالة الآلية",
    sendRegistrationMessage: "يرسل رسالة معلومات التسجيل للمسجلين",
    sendCheckInMessage: "يرسل رسالة ترحيب للزوار عند تسجيل الوصول",
    sendVisitorScanMessage: "يرسل معلومات العارض للزائر بعد تفاعلهم (يضيف العارض الزائر كعميل)",
    sendEventSummaryMessage: "يرسل بريدًا إلكترونيًا للزوار مع ملخص تفاعلاتهم مع العارضين وجهات الإتصال الخاصة بهم",
    checkedInOnly: "المسجلين الحاضرين فقط",
    customizeMessages: "تعديل الرسائل",
    reset: "إعادة تعيين",
    customizeGreetingMessage: "تخصيص رسالة الترحيب المرسلة إلى المسجلين عند تسجيل الدخول",
    translationPreview: "معاينة الترجمة",
    cantAddMultipleAttachments: "لا يمكن إضافة أكثر من مُرفق واحد",
    settingsSaved: "تم حفظ الإعدادات بنجاح",
    fbIsNotConnected: "الفيسبوك غير متصل",
    fbIsNotConnectedErrorMessage: "صفحة الفيسبوك غير متصلة أو تحتاج إلى إعادة الاتصال. لن يتم إرسال الرسائل عبر الفيسبوك حتى تقوم بإصلاح هذه المشكلة",
    reviewYourTranslation: "راجع ترجمات رسالتك و عدلها إذا لزم الأمر",
    addACustomMessage: "لتفعيل هذه الميزة يجب عليك إضافة رسالة مخصصة",
  },
  printingPreviewModal: {
    cancel: "إلغاء",
    pickTemplate: "اختيار قالب لشارة الاسم",
    printNameBadge: 'طباعة الشارة',
    print: 'طباعة',
    confirmationDialogTitle: 'بيانات الشارة غير كاملة',
    confirmationDialogText: 'بيانات الشارة غير كاملة , إكمال الطباعة رغم ذلك؟',
    badgeInfo: 'بيانات الشارة',
    preview: 'نظرة عامة على الشارة'
  },
  contextData: {
    target: {
      name: 'اسم الهدف',
      id: 'رقم تعريف الهدف',
    },
    event: {
      id: 'رقم تعريف المعرض',
      name: 'اسم المعرض',
      logoURL: 'رابط شعار المعرض',
      startDate: 'تاريخ بداية المعرض',
      endDate: 'تاريخ إنتهاء المعرض',
      createdAt: 'تاريخ إنشاء المعرض'
    },
    registrant: {
      id: 'رقم تعريف المسجل',
      name: 'اسم المسجل',
      title: 'لقب المسجل',
      email: 'البريد الإلكتروني للمسجل',
      phone: 'رقم تليفون المسجل'
    },
    exhibitor: {
      id: 'رقم تعريف العارض',
      name: 'اسم العارض',
      email: 'البريد الإلكتروني للعارض'
    },
    organizer: {
      id: "رقم تعريف المُنظِم",
      fullName: "اسم المُنظِم",
      firstName: "الاسم الأول للمُنظِم",
      lastName: "الاسم الأخير للمُنظِم",
    },
  },
  shapeTemplateSettings: {
    backgroundFill: "ملئ",
    borderColor: "لون الحدود",
    borderSize: "حجم الحدود",
    cornersRadius: "إنحناء الزاوايا"
  },
  errorBoundry: {
    somethingWentWrong: "حدث خطأ ما",
  },
  invitationPage: {
    noLongerAvailable: "الدعوة لم تعد متاحة",
    alreadyAccepted: "تم قبول الدعوة بالفعل",
    login: "تسجيل الدخول",
    errorOccurred: "حدث خطأ",
    alreadyAddedExhibitor: "تم إضافة العارض من قبل",
  },
  eventMembersView: {
    team: "الفريق",
    pleaseAddMembers: "لا يوجد اعضاء, قم بإضافة عضو",
    alreadyAddedExhibitor: "تم إضافة العضو من قبل",
    alreadySentInv: "تم إرسال الدعوة بالفعل من قبل",
    cantAddMember: "حدث خطأ أثناءإضافة العضو",
    name: "الاسم",
    email: "البريد الإلكتروني",
    invitation: "الدعوة",
    add: "إضافة",
    cantDeleteMember: "حدث خطأ أثناء حذف العضو",
    cantDeleteInvitation: "حدث خطأ أثناء حذف الدعوة",
    accepted: "قُبلت",
    pending: "قيد الإنتظار",
    owner: "المالك",
    delete: "مسح",
    printBadge: "طباعة الشارة",
    noBadgesToPrint: "لا توجد شارات للطباعة",
    leave: "غادِر",
    member: "عضو",
    role: "دور",
    you: "أنت",
    cantDeleteOwner: "لا يمكن مسح المالك",
    ownerCantLeave: "لا يمكن للمالك المغادرة",
    invitationNotAccepted: "لم يتم قبول الدعوة بعد",
    invAlreadyAccepted: "لقد تم قبول الدعوة",
    noLongerMember: "المستخدم لم يعد عضوا",
  },
  AuthErrors: {
    sessionTimedOut: "إنتهت جلسة العمل, قم بتسجيل الدخول مرة أخري",
    provideToken: "يجب تقديم رمز صالح",
    permissionDenied: "لايوجد لديك صلاحيات كافية",
  },
  QRCodeScannerModal: {
    searchByQrCode: "ابحث بإستخدام رمز الاستجابة السريعة",
    scanError: "حدث خطأ. الرجاء التأكد من تعيين إذن الكاميرا على مسموح به",
  },
  FbConnectedPage: {
    connectWithFacebook: "إتصال عبر الفيسبوك",
    disconnectFbPage: "إفصل هذه الصفحة",
    loading: "جاري التحميل",
    reconnect: "أعِد الإتصال",
    pageNeedsReconnect: "الصفحة غير متصلة بشكل صحيح. الرجاء إعادة الإتصال",
    cannotDisconnectNonExistingPage: "لا يمكن قطع الإتصال بصفحة غير موجودة",
    tryLater: "يرجى المحاولة بعد بضع دقائق",
    errorAtDisconnect: "حدث خطأ ما أثناء محاولة قطع الإتصال",
    disconnectedSuccessfully: "تم قطع الإتصال بنجاح",
    cannotConnectToFB: "لا يمكن الاتصال بـ فيسبوك. يرجى الاتصال بأحد المسؤولين",
  },
  FbLoginModal: {
    fbPagesList: "تسجيل الدخول بإستخدام الفيسبوك",
    connect: "إتصل",
    noPagesToList: "ليس لديك صفحات",
    cancel: "إلغاء",
    contactPageAdmin: "يمكن فقط لمسؤولي صفحة الفيسبوك توصيل هذا المعرض بصفحة. من فضلك اطلب من مسؤول الصفحة توصيل هذه الصفحة",
    fbPageAlreadyConnected: "هذا المعرض متصل بالفعل بصفحة فيسبوك",
    errorAtListing: "حدث خطأ ما أثناء محاولة سرد صفحات الفيسبوك الخاصة بك",
    connectedSuccessfully: "تم الإتصال بنجاح",
    tryLater: "يرجى المحاولة بعد بضع دقائق",
    errorAtConnection: "حدث خطأ ما أثناء محاولة الإتصال"
  },
  facebookMessageTypes: {
    fbMessageType: "نوع رسالة الفيسبوك",
    selectType: "حدد نوعًا",
    choosingContentType: "اختيار نوع المحتوى",
    misuseContentTypeMaySuspendPageOrBot: "قد يؤدي إستخدام نوع المحتوى لأي سبب خارج حالات الإستخدام المحددة إلى حظر روبوتك أو صفحتك",
    messagesSentMoreThanADayAfterInteraction: "يجب أن تحتوي أي رسالة يتم إرسالها بعد أكثر من 24 ساعة من آخر تفاعل لجهة الاتصال على نوع محتوى",
    promotionsCanBeSentByOtn: `لا يمكن إرسال العروض الترويجية (المبيعات والقسائم والخصومات وما إلى ذلك) إلا بإستخدام "إشعارات أخرى" أو "إشعارات لمرة واحدة" (OTNs)`,
    sendingMessagesThatDonotMatchContentType: "قد يؤدي إرسال رسائل لا تتطابق مع نوع المحتوى الخاص بها إلى تعليق صفحتك بواسطة فيسبوك",
    learnMoreHere: "تعلم المزيد هنا",
    postPurchaseUpdate: "تحديث ما بعد الشراء",
    tagsMisuseMaySuspendPage: "قد تؤدي إساءة إستخدام العلامات إلى تعليق صفحتك بواسطة فيسبوك",
    notifyUserOfPurchase: "قم بإعلام المستخدم بالتحديثات الخاصة بعملية الشراء الأخيرة. علي سبيل المثال",
    invoicesOrReceipts: "الفواتير أو الإيصالات",
    notificationOfShipmentStatus: "إخطارات بحالة الشحن",
    changesRelatedToOrder: "التغييرات المتعلقة بالطلب الذي قدمه المستخدم",
    forMoreInformationCheckOfficial: "لمزيد من المعلومات تحقق من",
    facebookArticle: "مقالة فيسبوك الرسمية",
    confirmedEventUpdate: "تأكيد تحديث الحدث",
    updateContactsAboutEventTheyRegisteredFor: "تحديث جهات الاتصال فيما يتعلق بالمعرض الذي قاموا بالتسجيل فيه. علي سبيل المثال",
    reminderOfEventOrAppointment: "تذكير بحدث أو موعد",
    confirmReservationOrAttendance: "تأكيد الحجز أو الحضور",
    transportationScheduleUpdates: "تحديثات جدول النقل",
    accountUpdate: "تحديث الحساب",
    notifyUseOfNonrecurringChange: "إخطار المستخدم بتغيير غير متكرر في التطبيق أو الحساب الخاص به",
  },
  AfterFbLoginRedirect: {
    errorOccurred: "حدث خطأ",
  },
  formTranslations: {
    formTranslations: "ترجمة استمارة التسجيل",
    defaultLanguage: "اللغة الرئيسية",
    formTranslationsUpdated: "تم تحديث ترجمات استمارة التسجيل بنجاح",
    translations: "ترجمات {{language}}",
  },
  connectFacebookModal: {
    connectFacebookPage: "إيصال صفحة فيسبوك",
    noFacebookPageConnected: "ليس لديك أي صفحة فيسبوك متصلة بهذا المعرض. هل ترغب في توصيل واحدة؟",
  },
  registrationsScansView: {
    search: "بحث",
    searchRegistrantsByQrCode: "إبحث عن المسجلين بإستخدام رمز الاستجابة السريعة الخاص بهم",
    scanError: "حدث خطأ. الرجاء التأكد من تعيين إذن الكاميرا على مسموح به",
    printBadge: "طباعة الشارة",
    checkIn: "تسجيل الدخول",
    checkOut: "تسجيل الخروج",
    viewDetails: "عرض التفاصيل",
    registrantDetails: "تفاصيل المسجل",
    chooseActionsToPerform: "إختر الإجراءات المطلوب تنفيذها",
    notProvided: "غير متوفر",
    belongsToAnotherEvent: "المسجل ينتمي إلى معرض آخر",
    checkinSuccess: "تم تسجيل الدخول بنجاح",
    checkinFailure: "حدث خطأ أثناء تسجيل الدخول",
    checkoutSuccess: "تم تسجيل الخروج بنجاح",
    checkoutFailure: "حدث خطأ أثناء تسجيل الخروج",
    pickTemplate: "إختيار قالب لشارة الإسم",
    noBadgesToPrint: "لا توجد شارات للطباعة",
    typeCorrectData: "يرجى التأكد من كتابة بريد إلكتروني أو رقم مسلسل أو رقم هاتف كامل",
    noMatches: "لا يوجد تطابق",
    qrCodeWasScannedBefore: "تم مسح رمز الإستجابة السريعة هذا من قبل. هل تريد مسحه مرة أخرى؟",
    cancel: "إلغاء",
    alreadyCheckedin: "تم تسجيل الدخول للمسجل بالفعل",
    alreadyCheckedout: "فشل تسجيل الخروج. المسجل لم يسجل الدخول",
    registrantNotFound: "لم يتم العثور علي المُسجِل",
    needsCheckinFirst: "يحتاج المسجل إلى تسجيل الدخول أولاً",
    exhibitorChecked: "تم التسجيل للعارض بنجاح",
  },
  registrationModal: {
    addRegistrant: "أضف مسجل جديد",
    add: "إضافة",
    registrantLanguage: "لغة المسجل",
    registrantAddSuccess: "تم اضافة مسجل جديد بنجاح",
    registrantAddFailure: "حدث خطأ أثناء أضافة مسجل جديد",
    cancel: "إلغاء",
  },
  checkOutAllVisitorsModal: {
    checkOutAllVisitors: "تسجيل خروج جميع الزوار",
    wouldYouLikeToProceed: "سيتم تسجيل الخروج لجميع الزوار الذين تم تسجيل دخولهم. هل ترغب في الاستمرار؟",
  },
};

export const LANGUAGES = {
  ARABIC: "ar",
  ENGLISH: "en"
};
