import React, { useState } from "react"
import { useTranslation } from "react-i18next";
// material-ui components
import { useTheme } from "@material-ui/styles";
import makeStyles from "@material-ui/core/styles/makeStyles"
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography"


import AnnouncementsIcon from "../../../../components/Icons/AnnouncementsIcon";
import CardBody from "../../../../components/Card/CardBody.jsx";
import CardHeader from "../../../../components/Card/CardHeader.jsx";
import Card from "../../../../components/Card/Card.jsx";
import CustomButton from "../../../../components/CustomButtons/Button.jsx";
import TranslationPreview from "../../../../components/TranslationPreview";
import styles from "./styles"

const useStyles = makeStyles(styles, "CustomizeMessagesModal");

const TranslationPreviewModal = ({
    isOpen,
    onClose,
    languages,
    message,
    onAnnouncementMessageAdd,
}) => {
    const { t } = useTranslation("eventEngagementView");
    const [translationMessages, setTranslationMessages] = useState([]);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const classes = useStyles();

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            classes={{
                paper: fullScreen ? classes.paperFullScreen : classes.paper
            }}
            fullScreen={fullScreen}
        >
            <Card className={fullScreen ? classes.cardFullScreen : classes.card}>

                <CardHeader className={classes.cardHeader} color="primary">
                    <Typography variant="subtitle1">
                        {t("translationPreview")}
                    </Typography>
                </CardHeader>
                <Card className={classes.cardBody} >
                    <CardBody>
                        <TranslationPreview
                            languages={languages}
                            messageToTranslate={message}
                            finalTranslations={setTranslationMessages}
                        />
                        <div style={{
                            display: "flex",
                        }}>
                            <CustomButton
                                className={classes.addButton}
                                fullWidth={true}
                                color="primary"
                                size="lg"
                                onClick={() => {
                                    onAnnouncementMessageAdd(translationMessages);
                                    onClose();
                                }}
                            >
                                <Typography variant="button" noWrap>{t("announce")}</Typography>
                                <AnnouncementsIcon className={classes.announceButtonIcon} size={36} />
                            </CustomButton>
                        </div>
                        {fullScreen && (
                            <CustomButton
                                fullWidth={true}
                                simple
                                color="primary"
                                size="lg"
                                onClick={onClose}
                                className={classes.noMargins}
                            >
                                {t("templatesPage:cancel")}
                            </CustomButton>
                        )}
                    </CardBody>
                </Card>
            </Card>
        </Dialog >
    )
}
export default TranslationPreviewModal;